import { RedirectionService } from 'src/app/services/redirection.service';
import 'firebase/auth';
import 'firebase/firestore';

import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { LogLevelsDictionary } from '../dictionaries/LogLevels';
import { UserRoles } from '../dictionaries/UserRoles';
import { LoaderComponent } from '../loader/loader.component';
import { LogService } from '../log.service';
import { User } from '../models/User';
import { SessionStorageService } from './session-storage.service';
import { UIMessagingService } from './uimessaging.service';
import { UtilsService } from './utils.service';
import { LocalStorageService } from './local-storage.service';

const dbUsers = firebase.firestore().collection('users');
const dbPatients = firebase.firestore().collection('patients');
const dbPlans = firebase.firestore().collection('plans');

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  auth = firebase.auth();
  firestore = firebase.firestore();
  uid: string;
  currentUserSignedIn: firebase.User;
  public currentUser = new BehaviorSubject(null);
  user = this.currentUser.asObservable();
  errorAuth: Subject<string> = new Subject();
  public userData = new BehaviorSubject({});
  searchComponent = false;
  public drawer;
  clioTokenRenewRunning = false;
  loader: MatDialogRef<LoaderComponent, any>;
  userIsSignedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  permissions: firebase.firestore.DocumentData[];
  redirectURL: string;
  preventRedirections: boolean;
  constructor(
    private sessionStorage_$: SessionStorageService,
    private redirectiron_$: RedirectionService,
    private localStorage_$: LocalStorageService,
    public ng: NgZone,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public snackBar: MatSnackBar,
    private http: HttpClient,
    private log_$: LogService,
    private utils_$: UtilsService,
    private dialog: MatDialog,
    private uiMessaging_$: UIMessagingService,
    private toastMessage_$: UIMessagingService,
  ) {
    this.observeUser();
  }

  isClioRegistered() {
    return this.userData.value['clioregistered'] === true;
  }

  setToastMessage(message, action, options?) {
    this.toastMessage_$.toastMessage(message, action, options || null);
  }

  getPermissions(label) {
    const user = this.userData.getValue();
    return user['permissions'] && user['permissions'][label] ? user['permissions'][label] : null;
  }

  syncMyData() {
    return this.userReady(this.userData.getValue(), 'syncMyData');
  }

  setPermissions(data) {
    const user = this.userData.getValue() || {};
    this.userData.next({ ...user, permissions: data });
  }

  checkIfPasswordIsExpired(docid: string) {
    return firebase.functions().httpsCallable('password-checkIfPasswordIsExpiredV2')({ docid });
  }

  deleteCustomActions(userDocId) {
    return firebase.functions().httpsCallable('clio-deleteCustomActions')({ userdocid: userDocId });
  }

  removeClioWebHooks() {
    return firebase.functions().httpsCallable('clio-deleteWebHooks')({ userdocid: this.userData.getValue()['id'] });
  }

  async removeClioToken() {
    const id = this.userData.getValue()['id'];
    await this.deleteCustomActions(id)
      .then(res => {
        console.log('Custom Actions deleted', res);
      })
      .catch(err => {
        console.log('Error deleting custom actions', err);
      });

    await dbUsers
      .doc(id)
      .update({
        ['clioAccessToken']: firebase.firestore.FieldValue.delete(),
        ['clioRefreshToken']: firebase.firestore.FieldValue.delete(),
        ['clioCustomActions']: firebase.firestore.FieldValue.delete(),
        ['lpm']: firebase.firestore.FieldValue.delete(),
        ['client']: firebase.firestore.FieldValue.delete(),
      })
      .catch(err => this.errorAuth.next(err.error.message));

    await this.userReady(this.userData.value, 'removeClioToken');
    this.uiMessaging_$.toastMessage('Clio Access Token Removed', null);
  }

  checkOwnerPlan(): void {
    const userData = this.userData.value;
    const realRole = userData['realRole'];
    const plancode = userData['plancode'];
    const email = userData['email'];
    const timeoutDuration = 5000;

    if (realRole === UserRoles.owner && this.isNullOrUndefined(plancode)) {
      this.uiMessaging_$.toastMessage(
        'This user has no plan, several problems can derivate from this. Please contact the administrator in order to get support.',
        'IMPORTANT',
      );
      const logData = { text: `A user(${email})/role:${realRole} without plan has tried to log in` };

      // STORE A LOG RECORD.
      try {
        this.log_$.storeLog(userData, LogLevelsDictionary.info, logData, 'Error');
      } catch (err) {
        console.error('There was an error with the function storeLog', err);
      } finally {
        console.log('The log has been recorded successfully');
      }

      setTimeout(() => {
        this.logout();
        return;
      }, timeoutDuration);
    }
  }

  async checkIfUserHasPlan(email): Promise<boolean> {
    const { plan } = (await dbUsers.where('email', '==', email).limit(1).get()).docs[0].data();
    return this.isNullOrUndefined(plan);
  }

  callCloudFunction(functionName: string, payload: any, method: string) {
    const url = `${environment.constants.cloudfunctionsURL}${functionName}`;
    switch (method) {
      case 'POST':
        return this.http.post(url, payload);
      case 'GET':
        return this.http.get(url, {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
          responseType: 'text',
        });
      default:
        break;
    }
  }

  createClientAndSendPasswordResetEmail(
    email: string,
    password: string,
    newUser: User,
    loggedUser,
    companyName: string,
    role?: string,
  ) {
    const { name } = newUser;
    const { user } = loggedUser;
    const useruid = user.uid;
    const displayName = name;
    const url = `${environment.constants.cloudfunctionsURL}admin-createAppUser`;
    const body = {
      email,
      password,
      useruid,
      displayName,
      companyName,
      role,
      ownerID: newUser.ownerID,
      testaccount: newUser.testaccount,
    };

    this.http
      .post(url, body)
      .toPromise()
      .then(response => {
        this.auth
          .sendPasswordResetEmail(email)
          .then(() => {
            newUser.uid = response['uid'];
            this.uiMessaging_$.toastMessage('Owner Created!', null);
          })
          .catch(error => {
            console.log('error :', error);
            this.errorAuth.next(error.message);
          });
      })
      .catch(err => this.errorAuth.next(err.error.message));
  }

  createPatient(fname: string, lname: string, patientId: string, legalCaseId: string, dob: string, ownerID?: string) {
    dbPatients
      .doc(patientId)
      .set({
        FirstName: fname,
        LastName: lname,
        caseName: patientId,
        LegalCaseId: legalCaseId,
        DateOfBirth: dob,
        uidOfCreator: this.auth.currentUser.uid,
        files: [],
        fileSearchQuery: [],
        sharedWith: [],
        ownerID: ownerID || '',
      })
      .then(result => {
        this.uiMessaging_$.toastMessage('Client/Matter Added', null);
        this.errorAuth.next('');
      })
      .catch(nerror => this.errorAuth.next('Error Creating Client'));
  }

  async createUserAndSendPasswordResetEmail(password: string, user: User, createdBy: string) {
    const cu = this.auth.currentUser;
    let createdUser;
    const userObject = {
      password: password,
      email: user.email,
      role: user.role,
      useruid: cu.uid,
      phone: user.phone || '',
      displayName: user.name,
      ownerID: user.ownerID,
      companyName: ``,
      firstname: user.firstname || '',
      lastname: user.lastname || '',
      clientMatter: user.clientMatter || '',
    };

    console.log('userObject :', userObject);

    try {
      createdUser = await this.callCloudFunction('admin-createAppUser', userObject, 'POST').toPromise();
    } catch (err) {
      console.log('err :', err);
      this.uiMessaging_$.toastMessage(`Error Creating User: ${err.error.message}`, null);
      this.errorAuth.next(err.message);
      return err;
    } finally {
      console.log('The user has been created successfully');
    }

    const userData = this.userData.getValue();
    const userEmail = user.email;
    const userRole = user.role;
    const logData = { text: `A new user(${userEmail})/role:${userRole} has been created` };
    const action = 'User Creation';

    // STORE A LOG RECORD.
    try {
      this.log_$.storeLog(userData, LogLevelsDictionary.info, logData, action);
    } catch (err) {
      console.error('There was an error with the function storeLog', err);
    } finally {
      console.log('The log has been recorded successfully');
    }

    // SENDING THE PASSWORD RESET EMAIL.
    /* try {
      this.auth.sendPasswordResetEmail(user.email);
    } catch (err) {
      console.error('There was a problem with sendPasswordResetEmail function', err);
      return err;
    } finally {
      console.log('The password reset email has been sent successfully');
    } */

    user.uid = createdUser.uid;
    this.uiMessaging_$.toastMessage(`${user.role} created. Please check your ${user.role}'s list to verify.`, 'INFO');
    // this.writeUserToDB(user, 'N/A', createdBy, () => this.auth.updateCurrentUser(cu));
    this.errorAuth.next('');
  }

  determineRoute(role: any) {
    this.ng.run(() => this.router.navigateByUrl('/')).then(() => location.reload());
  }

  firePatientCantAdd() {
    this.errorAuth.next('Fill In the required fields');
  }

  async get2FAInfo(email) {
    let twoFAInfo;
    await dbUsers
      .where('email', '==', email)
      .limit(1)
      .get()
      .then(r => {
        const userData = r.docs[0].data();
        twoFAInfo = {
          twoFactorAuthenticationSet: userData['twoFactorAuthenticationSet'],
          twoFactorEnabled: userData['twoFactorEnabled'],
          twoFactorMethod: userData['twoFactorMethod'],
          twofactorCode: userData['twofactorCode'],
        };
      })
      .catch(err => console.error(err));
    return twoFAInfo;
  }

  getUid() {
    const user: firebase.User = this.auth.currentUser;
    if (user === null || user === undefined) {
      return '';
    }
    return this.auth.currentUser.uid;
  }

  async getOwnerID(uid: string) {
    return dbUsers
      .where('uid', '==', uid)
      .get()
      .then(querySnapshot => querySnapshot.docs[0].data().ownerID || uid);
  }

  async getUserRole(uid: string) {
    return dbUsers
      .where('uid', '==', uid)
      .get()
      .then(querySnapshot => querySnapshot.docs[0].data().role);
  }

  async getUserByUID(uid: string) {
    return dbUsers
      .where('uid', '==', uid)
      .get()
      .then(querySnapshot => querySnapshot.docs[0].data());
  }

  private isNullOrUndefined(el) {
    return el == null || el === 'undefined';
  }

  async login(email: string, password: string) {
    return this.auth
      .signInWithEmailAndPassword(email, password)
      .catch(err => {
        this.errorAuth.next(err.message);
        console.log('Error at login', err);
      })
      .then(result => {
        if (!result) return;
        if (result['user']) {
          this.sessionStorage_$.setSignedIn(true);
          this.userReady(result['user'], 'login');
        }
      });
  }

  isSignedIn() {
    return this.sessionStorage_$.getSignedIn() === 'true';
  }

  showLoader(message?: string) {
    this.ng.run(() => {
      if (this.loader?.getState() === MatDialogState.OPEN) {
        this.loader.close();
      }
      const config: MatDialogConfig = {
        height: 'auto',
        width: 'auto',
        closeOnNavigation: true,
        disableClose: false,
        data: { message },
      };
      this.loader = this.dialog.open(LoaderComponent, config);
    });
  }

  hideLoader() {
    if (this.loader) {
      this.ng.run(() => {
        this.loader.close();
        this.loader = null;
      });
    } else {
      console.log('No loader to hide');
    }
  }

  createCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  private getRandomState() {
    return Math.random()
      .toString(36)
      .split('')
      .map(x => x.charCodeAt(0).toString(16))
      .join('');
  }

  handleSSOClioLogin(params) {
    const { sso } = params;
    this.sessionStorage_$.setSSO(sso);
    const randomState = this.getRandomState();
    this.createCookie(`sso_state`, randomState, 1 / 24 / 60);
    this.redirectToClioAuthorizeV2(randomState);
  }

  private createURLWithParams(paramsObj) {
    const { redirect_uri, scope, state, client_id } = paramsObj;
    let params = new HttpParams().set('response_type', 'code');

    if (client_id) {
      params = params.set('client_id', client_id);
    }

    if (redirect_uri) {
      params = params.set('redirect_uri', redirect_uri);
    } else {
      params = params.set('redirect_url', environment.config.clio.redirectsGroup.clientProfile);
    }

    if (scope) {
      params = params.set('scope', scope);
    }

    if (state) {
      params = params.set('state', state);
    }
    return params;
  }

  private redirectToClioAuthorizeV2(randomState) {
    const paramsObj = {
      redirect_uri: environment.config.clio.SSO.callback,
      scope: 'openid',
      state: randomState,
      client_id: environment.config.clio.SSO.key,
      authVersionURL: environment.config.clio.SSO.authVersionURL,
    };
    const request = new HttpRequest('GET', paramsObj.authVersionURL || environment.config.clio.authorizeURL, null, {
      params: this.createURLWithParams(paramsObj),
    });
    window.location.href = request.urlWithParams;
  }

  logout(message?: string) {
    this.drawer?.close();
    if (this.sessionStorage_$.getAddToClioStarted() === 'true')
      return this.auth.signOut().then(() => {
        this.afterSignOut(message);
        this.sessionStorage_$?.setAddToClioStarted(true);
      });
    else return this.auth.signOut().then(() => this.afterSignOut(message));
  }

  observeUser() {
    this.auth.onAuthStateChanged(user => {
      try {
        this.uid = user.uid;
        this.currentUser.next(user);
      } catch (e) {}
    });
  }

  private generateCode() {
    return this.utils_$.getRandomString_(25);
  }

  passwordForgetEmail(email: string) {
    const code = this.generateCode();
    const setPasswordRequest = this.utils_$.setPasswordResetRequest(this.userData.getValue()['id'], {
      code: code,
      type: 'changepassword',
      useremail: email,
    });
    const body =
      `<h2>You have requested to change your password</h2>` +
      `<p>Please use the following link to change it:</p>` +
      `<a href='${environment.config.host}/resetpassword?code=${code}'>Click here to reset the password</a>` +
      `<p>There is a prompt input where you have to enter your new password.</p>`;
    const subject = 'Change your password';

    const myemail = 'alexisrengifo@gmail.com';
    return setPasswordRequest.toPromise().then(async () => {
      await this.utils_$.simpleEmail(body, [myemail, email], subject).toPromise();
      this.uiMessaging_$.toastMessage(
        'An email has been sent to your email address. Please check your inbox.',
        'EMAIL SENT',
      );
    });
  }

  async sendVerificationCodeEmail(email, code) {
    await this.setUserCode(code, 'email');
    const url = `${environment.constants.cloudfunctionsURL}email-simpleMail`;
    const dest = email;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'secret-key',
      }),
    };

    const body =
      `<h2>Please confirm</h2>` +
      `<p>Use the following code to confirm your Two-factor Authentication (2FA):</p>` +
      `<strong>${code}</strong>` +
      `<p>There is a prompt input where you have to paste/type this code.</p>`;
    const subject = `Please confirm your email address`;

    return this.http.post(url, { dest, body, subject }, httpOptions).subscribe({
      next: res => {
        if (res['data']['status'] === 200) {
          console.log('RES:', res);
        }
      },
      complete: () => console.log('Completed'),
      error: err => console.error(err),
    });
  }

  async setPlan(newplan: string) {
    const id = (await dbUsers.where('uid', '==', this.uid).limit(1).get()).docs[0].id;
    dbUsers
      .doc(id)
      .update({ plancode: newplan, plan: firebase.firestore().doc('plans/' + newplan) })
      .then(() => {
        this.uiMessaging_$.toastMessage('Plan changed successfully', null);
      })
      .catch(err => {
        this.uiMessaging_$.toastMessage(err.message, null);
      });
  }

  setNewPasswordExpirationDate(userID) {
    const url = `${environment.constants.cloudfunctionsURL}password-setPasswordExpirationDate`;
    this.http
      .post(url, { docid: userID })
      .toPromise()
      .then(updatePasswordStatus => {
        console.log('updatePasswordStatus :', updatePasswordStatus);
      });
  }

  async sendVerificationCode(code: number, email: string) {
    let get2faInfo;
    try {
      // NOTE: What is this for?
      get2faInfo = await this.get2FAInfo(email);
      console.log('get2faInfo :', get2faInfo);
    } catch (err) {
      console.error(err);
    }

    try {
      await this.sendVerificationCodeEmail(email, code);
    } catch (err) {
      console.error(err);
    }
  }

  async setUserCode(code, type) {
    const userId = (await dbUsers.where('uid', '==', this.uid).limit(1).get()).docs[0].id;
    return dbUsers.doc(userId).update({ twofactorCode: JSON.stringify({ code: code, type: type }) });
  }

  updateUserPassword(newPassword: string) {
    const user = firebase.auth().currentUser;
    return user
      .updatePassword(newPassword)
      .then(() => this.setNewPasswordExpirationDate(this.userData.getValue()['id']))
      .catch(error => console.log('updateUserPassword error', error));
  }

  async updateRecurlyPlan(plan) {
    const planRef = dbPlans.doc(plan);
    const docId = (await dbUsers.where('uid', '==', this.uid).get()).docs[0].id;
    return dbUsers.doc(docId).update({ plan: planRef, plancode: plan });
  }

  validatePermission(permission: string): boolean {
    const permissionsData = this.userData.getValue()['permissions'];
    if (!permissionsData) {
      return false;
    }
    return permissionsData
      .filter(p => p.access === true)
      .filter(p => p.role === this.userData['role'])
      .find(p => p.object === permission).access;
  }

  getAllPermissions() {
    return this.userData.getValue()['permissions'];
  }

  async loadPermissions() {
    try {
      this.permissions = (await firebase.firestore().collection('permissions').get()).docs.map(doc => doc.data());
    } catch (e) {
      console.error(e);
    }
  }

  async userReady(user, origin?: string) {
    if (!user || Object.keys(user).length === 0) {
      console.log(' User ready: user is empty');
      return;
    }

    this.showLoader('Loading user data...');

    this.uid = user.uid;

    const userData = await this.getUserData(user);

    if (userData.docs.length === 0) {
      console.log(' User ready: user data is empty');
      this.logout();
      return;
    }

    const { clioAccessToken } = userData.docs[0].data();

    if (clioAccessToken !== undefined && JSON.parse(clioAccessToken)['access_token'])
      await this.existingClioAccessToken(user);
    else this.noClioAccessToken(userData, user);

    // Clean up shared files that are inactive.
    if (origin === 'login' && userData.docs[0].data().role === UserRoles.consultant) {
      await firebase
        .functions()
        .httpsCallable('files-cleanSharedButInactiveFiles')({ email: user.email })
        .then(result => console.log('cleanSharedButInactiveFiles', result))
        .catch(err => {
          console.log('cleanSharedButInactiveFiles', err);
        });
    }

    if (userData.empty) {
      console.log('User not found');
      this.logout();
      this.hideLoader();
      return;
    }

    this.userIsSignedIn.next(true);
    this.sessionStorage_$.setSignedIn(true);

    let userObj = this.getUserObject(userData.docs[0].id, user, userData.docs[0].data(), await this.getUserIp());

    await this.loadPermissions().then(() => (userObj = { ...userObj, permissions: this.permissions }));

    this.userData.next(userObj);

    if (this.userData.value['lpm'] === 'clio') {
      const isNotClioRegistered = this.isClioRegistered() === false;
      const isNotClioSSO = !this.userData.value['clioSSO'];
      const isNotTestAccount = !this.userData.value['testaccount'];
      const isNotAddToClioStarted = !Boolean(this.sessionStorage_$.getAddToClioStarted());
      const condition = isNotClioSSO || isNotAddToClioStarted;

      if (isNotClioRegistered && condition && isNotTestAccount && this.userData.getValue()['plancode'] === 'lpmp') {
        this.redirectiron_$.redirectToCompleteClioRegistration();
      }
    }

    if (this.userData.getValue()['clioregistered']) this.ng.run(() => this.drawer?.open());

    this.hideLoader();
    return userObj;
  }

  private async handleGetAuthorizationCodeV3Result(result: firebase.functions.HttpsCallableResult, user: any) {
    if (result['data'] && Object.keys(result['data']).length > 0) {
      this.uiMessaging_$.toastMessage('Clio Access Token Updated', null);
      console.log('AuthService > handleGetAuthorizationCodeV3Result');
      if (this.sessionStorage_$.getAddToClioStarted() !== 'true') {
        await this.updateClioCustomActions(user.uid, 'auth.service:661 > handleGetAuthorizationCodeV3Result')
          .then(customactionsUpdated => console.log('Clio custom actions updated', customactionsUpdated))
          .catch(err => console.log('Clio custom actions update error', err));
      } else console.log('Add to Clio process started');
    }
  }

  getUserSettings(useruid: string) {
    return dbUsers
      .where('uid', '==', useruid)
      .limit(1)
      .get()
      .then(res => res.docs[0].data().settings);
  }

  async handleClioTokenRenew(userObj) {
    if (userObj.clioAccessToken && this.clioTokenRenewRunning === false) {
      const { data } = await this.renewToken(userObj).catch(err => {
        if (err.error.code === 501) {
          this.uiMessaging_$.toastMessage('Clio token not renewed. Please try to get Clio Authorization again.', null);
          this.clioTokenRenewRunning = false;
          return err;
        }
      });
      if (data.status === 200) {
        console.log(data.message);
        this.clioTokenRenewRunning = false;
      } else {
        console.log('Clio token not renewed');
        this.clioTokenRenewRunning = false;
      }
      this.clioTokenRenewRunning = true;
    }
  }

  writeUserToDB(user: User, companyName: string = 'N/A', createdBy: string = 'N/A', action: Function = null) {
    dbUsers
      .add({
        name: user.name,
        email: user.email,
        role: user.role,
        uid: user.uid,
        plancode: user.plancode,
        plan: user.plan,
        companyName: companyName,
        createdBy: createdBy,
        ownerID: user.ownerID,
        disabled: false,
      })
      .then(document => {
        if (action) {
          action();
        } else {
          this.determineRoute(user.role);
        }
      })
      .catch(error => console.log(error));
  }

  getUserObject(id, user, dbUser, userip) {
    return {
      id: id,
      user: user,
      lpm: dbUser.lpm,
      filesSharedWith: dbUser.filesSharedWith,
      ownerID: dbUser.ownerID,
      realRole: dbUser.role,
      userDBRole: dbUser.role,
      role: dbUser.role,
      testaccount: dbUser.testaccount,
      userName: dbUser.name,
      userEmail: dbUser.email,
      email: dbUser.email,
      uid: dbUser.uid,
      clioSSO: dbUser.clioSSO,
      settings: dbUser.settings,
      plan: dbUser.plan,
      plancode: dbUser.plancode,
      recurlyaccountcode: dbUser.recurlyaccountcode,
      clioAccessToken: dbUser.clioAccessToken,
      practicepantherAccessToken: dbUser.practicepantherAccessToken,
      lastSession: dbUser.lastSession || '',
      userip: userip,
      clioCustomActions: dbUser.clioCustomActions,
      permissions: [],
      clioregistered: dbUser.clioregistered || false,
      clientMatter: dbUser.clientMatter || null,
    };
  }

  async updateClioCustomActions(uid, origin?: string) {
    if (origin) {
      console.log('origin: ', origin);
    }
    return firebase.functions().httpsCallable('clio-updateClioCustomActions')({ uid: uid });
  }

  async getUserData(user) {
    return dbUsers.where('uid', '==', user.uid).limit(1).get();
  }

  async getUserIp() {
    const result = await this.http
      .get('https://jsonip.com')
      .toPromise()
      .catch(err => err);
    if (result['ip']) {
      return result['ip'];
    } else {
      return '';
    }
  }

  renewToken(userObj) {
    return firebase.functions().httpsCallable('clio-renewToken')({ userdocid: userObj.id });
  }

  signInWithCustomToken(token) {
    return firebase.auth().signInWithCustomToken(token);
  }

  setLastSession(sessionObj, userdocid) {
    return firebase.firestore().collection('users').doc(userdocid).update(sessionObj);
  }

  updateUserClioRegistered(newvalue) {
    console.log('userid :', this.userData.getValue()['id']);
    return dbUsers
      .doc(this.userData.getValue()['id'])
      .update({ clioregistered: newvalue })
      .catch(err => {
        console.error(err);
      });
  }

  async updateUserData() {
    return this.userReady((await dbUsers.doc(this.userData.getValue()['id']).get()).data(), 'updateUserData');
  }

  async getFreshFirestoreUserData() {
    return this.userReady(
      (await dbUsers.doc(this.userData.getValue()['id']).get()).data(),
      'getFreshFirestoreUserData',
    );
  }

  private async existingClioAccessToken(user) {
    if (this.sessionStorage_$.getAddToClioStarted() !== 'true') {
      await this.updateClioCustomActions(user.uid, 'auth.service:661 > userReady')
        .then(result => console.log('Clio custom actions updated', result))
        .catch(err => console.log('Clio custom actions update error', err));
    } else {
      console.log('Add to Clio process started');
    }
  }

  private async noClioAccessToken(userData, user) {
    console.log('Clio Access Token not found');
    console.log('It is impossible to update Clio Custom Actions');

    // Check if it comes from Clio Authorization.
    if (this.activatedRoute.snapshot.queryParams.code) {
      const params = {
        userdocid: userData.docs[0].id,
        code: this.activatedRoute.snapshot.queryParams.code,
        // redirect_uri: `${environment.config.clio.redirect_uri}/${this.activatedRoute.snapshot.params.origin}`,
        redirect_uri: environment.config.clio.redirectsGroup.clientProfile,
      };

      if (this.sessionStorage_$.getClioGetAuthorizationCodeV4Executed() !== 'true') {
        this.sessionStorage_$.setClioGetAuthorizationCodeV4Executed('true');
        await firebase
          .functions()
          .httpsCallable('clio-getAuthorizationCodeV4')(params)
          .then(async result => {
            await this.handleGetAuthorizationCodeV3Result(result, user);
            console.log('⭐');

            // Complete Add To Clio process.
            if (this.sessionStorage_$?.getAddToClioStarted() === 'true') {
              window.location.href = environment.config.clio.SSO.appIntegrationsCallBackURL;
            }
          })
          .catch(err => console.log('clio-getAuthorizationCodeV3', err));
      }
    }
  }

  async handleGetAuthorizationCodeV3ResultV2(result: any, user: any) {
    if (result['data'] && Object.keys(result['data']).length > 0) {
      this.uiMessaging_$.toastMessage('Clio Access Token Updated', null);

      console.log('AuthService > handleGetAuthorizationCodeV3Result');

      if (this.sessionStorage_$.getAddToClioStarted() !== 'true') {
        await this.updateClioCustomActions(user.uid, 'auth.service:661 > handleGetAuthorizationCodeV3Result')
          .then(customactionsUpdated => {
            console.log('Clio custom actions updated', customactionsUpdated);
          })
          .catch(err => console.log('Clio custom actions update error', err));
      } else {
        console.log('Add to Clio process started');
      }
    }
  }

  private afterSignOut(message) {
    this.hideLoader();
    this.sessionStorage_$.cleanAll();
    this.localStorage_$.cleanAll();

    this.ng.run(() => {
      // Clean browser history
      location.replace('/login');
      console.log('>>');
      if (message) this.snackBar.open(message, 'Close', { duration: 3000 });
    });
  }
}
