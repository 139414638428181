<app-window-title
  style="width: 100%"
  [closable]="true"
  [subtitle]="'Client/Matter: ' + data.patient.caseName"
  title="Create Client/Matter Profile user"></app-window-title>
<p>
  A Client Upload Account will be able to access only to this Client Profile and upload documents, files and medical
  images to it.
</p>
<form
  *ngIf="clientForm"
  [formGroup]="clientForm"
  (ngSubmit)="onSubmit()">
  <div
    fxLayout="row"
    fxLayoutGap="15px">
    <mat-form-field
      fxFlex="50"
      appearance="fill">
      <mat-label>First Name</mat-label>
      <input
        formControlName="firstname"
        required
        matInput />
      <mat-error *ngIf="clientForm.controls['firstname'].invalid">{{
        getErrorMessage(clientForm.controls['firstname'], 'firstname')
      }}</mat-error>
    </mat-form-field>
    <mat-form-field
      fxFlex="50"
      appearance="fill">
      <mat-label>Last Name</mat-label>
      <input
        formControlName="lastname"
        required
        matInput />
      <mat-error *ngIf="clientForm.controls['lastname'].invalid">{{
        getErrorMessage(clientForm.controls['lastname'], 'lastname')
      }}</mat-error>
    </mat-form-field>
  </div>
  <div
    fxLayout="row"
    fxLayoutGap="15px">
    <mat-form-field
      fxFlex="50"
      appearance="fill">
      <mat-label>Email</mat-label>
      <input
        formControlName="email"
        required
        type="email"
        matInput />
      <mat-error *ngIf="clientForm.controls['email'].invalid">{{
        getErrorMessage(clientForm.controls['email'], 'email')
      }}</mat-error>
    </mat-form-field>

    <div fxFlex="50">
      <!-- PASSWORD FIELD -->
      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input
          minlength="6"
          formControlName="password"
          required
          class="password-input"
          matInput />
        <mat-error *ngIf="!clientForm.controls.password.valid">{{
          getErrorMessage(clientForm.controls.password, 'password')
        }}</mat-error>
        <mat-error *ngIf="clientForm.invalid && clientForm.errors"> The password is not strong enough. </mat-error>
      </mat-form-field>
      <br /><br />
      <mat-password-strength
        (onStrengthChanged)="onStrengthChanged($event)"
        #passwordComponent
        [password]="clientForm.controls.password.value"
        [customValidator]="whitespace">
      </mat-password-strength>
      <mat-password-strength-info [passwordComponent]="passwordComponent"></mat-password-strength-info>

      <!-- CONFIRTM PASSWORD -->
      <mat-form-field appearance="fill">
        <mat-label>Confirm password</mat-label>
        <input
          class="password-input"
          required
          name="confirmpassword"
          formControlName="passwordconfirm"
          matInput />
        <mat-error *ngIf="!clientForm.controls.passwordconfirm.valid">
          {{ getErrorMessage(clientForm.controls.passwordconfirm, 'passwordconfirm', 1) }}</mat-error
        >
      </mat-form-field>
      <mat-card class="info-box mat-small"> Note: A password reset link will be sent to the new user. </mat-card>
    </div>
  </div>
  <div
    fxLayout="row"
    fxLayoutGap="15px">
    <mat-form-field
      fxFlex="50"
      appearance="fill">
      <mat-label>Cell Phone</mat-label>
      <input
        formControlName="phone"
        mask="(000) 000-0000"
        placeholder="Ex. (000) 000-0000"
        required
        matInput />
      <mat-error *ngIf="clientForm.controls['phone'].invalid">{{
        getErrorMessage(clientForm.controls['phone'], 'phone')
      }}</mat-error>
    </mat-form-field>
  </div>
  <p
    class="mat-body"
    style="color: red">
    {{ generalError }}
  </p>

  <button
    mat-raised-button
    type="submit"
    color="warn">
    CREATE USER
  </button>
</form>
