import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { pricing } from './pricing';

@Component({
  selector: 'app-pricing-clio',
  templateUrl: './pricing-clio.component.html',
  styleUrls: ['./pricing-clio.component.scss'],
})
export class PricingClioComponent {
  constructor(public dialog: MatDialogRef<PricingClioComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  pricingplans = {
    lpmp: 'lpmp',
    gold: 'gold',
    silver: 'silver',
    platinum: 'platinum',
    enterpriseplatinum: 'enterpriseplatinum',
    enterpriseplatinumplus: 'enterpriseplatinumplus',
  };
  pricing = pricing;

  choosePlan(plan: string, event) {
    if (Boolean(event.currentTarget.attributes.disabled?.value)) return;
    this.dialog.close(plan);
  }
}
