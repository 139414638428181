<form *ngIf="updateUserPasswordForm" [formGroup]="updateUserPasswordForm" (ngSubmit)="onSubmit()">
  <h3 class="mat-title">Password Expiration</h3>
  <p>
    Your password has expired. Please provide a new password for your account:
  </p>

  <!-- CURRENT PASSWORD -->
  <mat-form-field class="full-width" appearance="fill">
    <mat-label>Current password</mat-label>
    <input matInput #oldPasswordControl placeholder="Ex. Secure password" class="password-input"
      formControlName="currentPasswordFormControl" required />
    <mat-error *ngIf="checkError(updateUserPasswordForm,'currentPasswordFormControl', 'required')">
      Current password is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <!-- NEW PASSWORD -->
  <mat-form-field class="full-width" appearance="fill">
    <mat-label>New password</mat-label>
    <input formControlName="newPasswordFormControl" matInput class="password-input" required />
    <mat-error *ngIf="updateUserPasswordForm.controls['newPasswordFormControl'].invalid">
      <span *ngIf="checkError(updateUserPasswordForm,'newPasswordFormControl', 'required')">New password is
        <strong>required</strong>
      </span>
      <span *ngIf="checkError(updateUserPasswordForm,'newPasswordFormControl', 'minlength')">New password should have at
        least 6 characters.
      </span>
      <span *ngIf="checkError(updateUserPasswordForm,'newPasswordFormControl', 'passwordStrength')">New password is not
        strong enough.
      </span>
    </mat-error>
  </mat-form-field>
  <br><br>
  <mat-password-strength (onStrengthChanged)="onStrengthChanged($event)" #passwordComponent
    [password]="updateUserPasswordForm.controls.newPasswordFormControl.value">
  </mat-password-strength>
  <mat-password-strength-info [passwordComponent]="passwordComponent"></mat-password-strength-info>

  <!-- CONFIRM NEW PASSWORD -->
  <mat-form-field class="full-width" appearance="fill">
    <mat-label>Confirm New password</mat-label>
    <input matInput formControlName="confirmPasswordFormControl" #confirmPasswordControl class="password-input"
      value="" />
    <mat-error *ngIf="checkError(updateUserPasswordForm,'confirmPasswordFormControl', 'required')">
      Confirm New password is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="checkError(updateUserPasswordForm,'confirmPasswordFormControl', 'minlength')">
      New password should have at least 6 characters.
    </mat-error>
    <mat-error *ngIf="
        f['confirmPasswordFormControl'].errors &&
        f['confirmPasswordFormControl'].errors['matching']
      ">
      Confirm Password does not match
    </mat-error>
  </mat-form-field>
  <br>
  <div>
    <button type="submit" mat-flat-button color="warn">UPDATE PASSWORD</button>&nbsp;
    <button (click)="handleLogOut()" mat-flat-button color="warn">LOGOUT</button>
  </div>
</form>
