import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  template: `<app-window-title
      [title]="title"
      [closable]="true"></app-window-title>
    <video
      class="video-viewer"
      *ngIf="viewer === 'video'"
      controls>
      <source
        src="{{ src }}"
        type="video/mp4" />
    </video>
    <ngx-doc-viewer
      *ngIf="viewer !== 'video'"
      [url]="src"
      [viewer]="viewer"
      class="viewer"></ngx-doc-viewer>`,
  styles: [
    `
      .video-viewer {
        width: 100%;
      }

      :host {
        display: block;
      }

      .viewer {
        width: 100%;
        height: 50vh;
      }
    `,
  ],
})
export class DocViewerComponent {
  src: any;
  title: any;
  info: any;
  viewer: 'google' | 'office' | 'mammoth' | 'video' | 'pdf' | 'url' = 'google';

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    const { title, src, info, viewer } = data;
    this.title = title;
    this.info = info;
    this.src = src;
    this.viewer = viewer || 'google';
    console.log('this.viewer :', this.viewer);
  }
}
