<div [ngClass]="'active-consultants-container'">
  <mat-selection-list
    #consultants
    [multiple]="false">
    <mat-list-option
      *ngFor="let consultant of availableConsultantsList"
      [value]="consultant"
      [ngClass]="'mat-small'"
      #option
      (click)="handleOptionClick(consultant)">
      <div fxLayout="row">
        <div fxLayout="column">
          <div [ngClass]="'mat-line small-font'">
            <b>{{ consultant.name }}</b>
          </div>
          <div [ngClass]="'mat-line small-font'">
            {{ consultant.email }}
          </div>
        </div>
        <div
          fxLayout="column"
          fxFlexOffset="4">
          <button
            mat-flat-button
            fxFlexOffset="4"
            (click)="$event.stopPropagation(); unShare(consultant)"
            matTooltip="Stop sharing selected files with this consultant."
            *ngIf="consultant.shared === true"
            [ngClass]="'small-font'">
            Unshare all selected
          </button>

          <button
            mat-flat-button
            color="warn"
            matTooltip="Share selected files with this consultant."
            fxFlexOffset="4"
            [ngClass]="'small-font'"
            (click)="$event.stopPropagation(); share(consultant)"
            *ngIf="consultant.shared === false && option.selected && availableConsultantsList.length > 0">
            Share all selected
          </button>
        </div>
      </div>
      <mat-divider></mat-divider>
    </mat-list-option>
  </mat-selection-list>
  <p
    *ngIf="!availableConsultantsList || !availableConsultantsList.length"
    [ngClass]="'small-font margin-left-20'">
    There are <b>no available consultants</b> for this client. Go an
    <a
      href="#"
      (click)="createConsultant($event)"
      >create a consultant</a
    >.
  </p>
</div>
