import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { RecurlyService } from 'src/app/services/recurly.service';

import { AuthService } from './../../services/auth.service';
import { FirebaseUtilitiesService } from './../../services/firebase-utilities.service';
import { UIMessagingService } from './../../services/uimessaging.service';

@Component({
  selector: 'app-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss'],
})
export class UpgradePlanComponent implements OnInit {
  checkImage = './../../../assets/png/1398911_correct_mark_success_tick_valid_icon.png';
  blueAsterisk = 'https://nuagedx.com/wp-content/uploads/2021/03/blue.png';
  planSelected: any;
  plans: any;
  planValue = '';
  planTitle = '';
  subscriptionId: any;
  upgradablePlans = [];
  dialogConfig: MatDialogConfig;
  yearly: boolean;
  tabIndex = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public authS: AuthService,
    public recurlyS: RecurlyService,
    private uiMessaging_$: UIMessagingService,
    public firebaseUtilitiesS: FirebaseUtilitiesService,
    public dialog: MatDialogRef<UpgradePlanComponent>,
  ) {
    this.planSelected = this.data.currentPlan;
  }

  async ngOnInit() {
    this.getPlansData(this.planSelected.id);

    /** */
    // 1. Get Subscription.

    // a.0 Get current user
    const userData = this.authS.userData.getValue();
    const upo = await userData['plan'].get();
    const planDocId = upo.id;

    // a. Get accountcode.
    // console.log('accountcode :', userData['accountcode']);
    const recurlyaccountcode = userData['recurlyaccountcode'];

    // b. Get account.
    const result = await this.recurlyS.getAccount(recurlyaccountcode);
    if (!result['accounts'].length) {
      this.uiMessaging_$.toastMessage(
        'There is no Recurly account that matches with the current user. Please contact the app admin: admin@nuagedx.com.',
        null,
      );
      this.dialog.close('thanks');
      return;
    }
    const accountId = result['accounts'][0].id;

    // b.1 Get plan
    const planData = await this.firebaseUtilitiesS.getPlan(planDocId);

    // c. Get subscriptions
    const accountSubscriptions = await this.recurlyS.getSubscriptions(accountId);

    // c.1 Filter active.
    this.subscriptionId = accountSubscriptions.subscriptions[0]['id'];

    // c.1 Get subscription data.
    // const subscriptionData = this.recurlyS.getSubscription(subscriptionId);

    // d. Load restrictions.
    this.upgradablePlans = await this.recurlyS.getPlanRestrictions(planDocId);
  }

  isUpgradable(plancode) {
    if (this.upgradablePlans.length === 0) {
      return true;
    }
    return !this.upgradablePlans.includes(plancode);
  }

  updateUserPlan(newplan) {
    this.authS.setPlan(newplan);
    this.authS.userReady(this.authS.currentUser.getValue(), 'updateUserPlan');
    this.showThanks();
  }

  showThanks() {
    this.dialog.close('thanks');
  }

  checkIfYearly() {
    return (<HTMLInputElement>document.querySelector('#yearly')).checked;
  }

  getPlan(planname, yearly: boolean) {
    const planFound = () => this.plans.find(p => p.key === planname);
    return planFound().subscriptions.find(s => s.type === (yearly ? 'yearly' : 'monthly')).key;
  }

  getPlanFromKey(key: string = 'silver') {
    const defaultPlan = this.plans.find(p => p.key === key);
    if (!this.plans) {
      return { plan: defaultPlan, yearly: false };
    }
    for (let index = 0; index < this.plans.length; index++) {
      const plan = this.plans[index];
      for (let indexSubscription = 0; indexSubscription < plan.subscriptions.length; indexSubscription++) {
        const subscription = plan.subscriptions[indexSubscription];
        if (subscription.key === key) {
          return { plan: plan, yearly: subscription.type === 'yearly' ? true : false };
        }
      }
    }
    return false;
  }

  async handleUpgrade(newplan) {
    newplan = this.getPlan(newplan, false);
    // 2. Modify Subscription.
    const result = await this.recurlyS.changeSubscriotion(this.subscriptionId, newplan);
    console.log('handleUpgrade result :', result);
    // const { subscriptions } = result;
    const { plan } = result['subscriptions'];
    const { code } = plan;

    const planUpdateResult = await this.authS.updateRecurlyPlan(code);
    this.uiMessaging_$.toastMessage('Your account plan has been updated', 'PLAN UPGRADED');
    console.log('planUpdateResult :', planUpdateResult);

    if (result['subscriptions']['error']) {
      console.error(result['subscriptions']['error']['message']);
    } else {
      // const newplan = result['subscriptions']['plan']['code'];
      this.updateUserPlan(newplan);
    }
    // 3. Confirm change.
    if (result['code'] === 200) {
      console.log(result);
      // 3.1 Update user plan.
    } else {
      console.error('Something went wrong :(');
      // 3.2 Create ZenDeskTicket.
    }
    /**/
  }

  handleSelectPlan(plan, yearly: boolean) {
    plan.id = plan.key;
    const found = plan.subscriptions.find(s => (s.type === yearly ? 'yearly' : 'monthly'));
    this.plans.forEach(cplan => (cplan.active = cplan.id === plan.id));
    this.planSelected = found.key;

    ajax(`https://nuagedx.com/getplandata.v2.php?type=${this.planSelected}`)
      .pipe(map(r => r.response))
      .subscribe({
        next: val => {
          this.planValue = val.body;
          this.planTitle = val.title;
          this.yearly = true;
        },
        error: err => console.error(err),
        complete: () => {
          const currentPlanKey = this.getPlanFromKey(this.data.currentPlan.id)['plan'].key;
          if (yearly && currentPlanKey === plan.id && currentPlanKey !== 'silver') {
            setTimeout(() => (<HTMLInputElement>document.querySelector('#yearly')).setAttribute('checked', ''), 100);
          }
        },
      });
    this.tabIndex = 0;
  }

  getPlansData(param?) {
    ajax('https://nuagedx.com/getplans.v2.php')
      .pipe(map(r => r.response))
      .subscribe({
        next: data => {
          this.plans = data;
          const planFromKey = this.getPlanFromKey(param);
          if (!planFromKey) {
            return;
          }
          const { plan, yearly } = planFromKey;
          plan.id = plan.key;
          this.handleSelectPlan(plan, yearly);
        },
        complete: () => console.log('Completed'),
      });
  }
}
