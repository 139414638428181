import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-import-clio-matter',
  template: `
  <p>import-clio-matter works!</p>
  <pre>
    <code>
      {{ data | json }}
    </code>
  </pre>`,
})
export class ImportClioMatterComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('data :', data);
  }
}
