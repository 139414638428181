import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RedirectionService {
  checkAndRedirectQaSite() {
    if (window.location.hostname === 'nuagedx-qa.web.app') window.location.href = 'https://qa.nuagedx.com';
  }
  constructor(private router: Router) {}

  redirectToCompleteClioRegistration() {
    this.router.navigate(['/', 'completeclioregistration']);
  }

  goToHome(error: string) {
    this.router.navigate(['/', { params: { error } }]);
  }

  goToLogin(params?) {
    this.router.navigate(['/login'], params ? params : {});
  }

  redirectToLoginClio(queryParams?) {
    this.router.navigate(['/login/clio'], queryParams ? queryParams : null);
  }

  goToLoginClio() {
    this.router.navigate(['/login/clio']);
  }
}
