import { Component, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { MDCTopAppBar } from '@material/top-app-bar';
import firebase from 'firebase/app';
import { UserRoles } from 'src/app/dictionaries/UserRoles';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { environment } from 'src/environments/environment';

import { AuthService } from '../../services/auth.service';
import { UIMessagingService } from '../../services/uimessaging.service';
import { UploadHelperService } from '../../services/upload-helper.service';
import { WindowsManagerService } from '../../services/windows-manager.service';
import { CreateclientComponent } from '../createclient/createclient.component';
import { CreatepatientComponent } from '../createpatient/createpatient.component';
import { CreateuserComponent } from '../createuser/createuser.component';
import { ReportComponent } from '../report/report.component';
import { UpdateUserPasswordComponent } from '../update-user-password/update-user-password.component';
import { AuditLogViewerComponent } from './../../audit-log-viewer/audit-log-viewer.component';
import { EditProfileComponent } from './../../edit-profile/edit-profile.component';
import { RedirectionService } from './../../services/redirection.service';
import { SessionService } from './../../session.service';
import { TwoFactorAuthenticationService } from './../../two-factor-authentication.service';
import { AllusersComponent } from './../allusers/allusers.component';
import { SimpleMessageWindowComponent } from './../ui/simple-message-window/simple-message-window.component';
import { UpgradePlanComponent } from './../upgrade-plan/upgrade-plan.component';
import { ClientProfileComponent } from '../clientprofile/clientprofile.component';
import { ClientMatterService } from 'src/app/services/client-matter.service';

const clientRole = 'client';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  lpmClio = false;
  url: string;
  topAppBar: any;
  user: any;
  uid: any;
  userDBRole: any;
  userEmail: string;
  userName: string;
  realRole: string;
  userPermissions: any;
  createuser = false;
  searchComponentRef: any;
  uploadProgressMaximized: any;
  uploadProgressVisibility: boolean;
  filesSharedWith: any;
  maintenance: boolean;
  userData: any;
  public userEnabled = false;
  UserRoles: { owner: string; admin: string; associate: string; consultant: string; superuser: string; client: string };
  noLoginLink: boolean;
  loaded: boolean;
  rDialog: any;
  timeOut: NodeJS.Timeout;
  @ViewChild('drawer', { static: true }) drawer: MatDrawer;
  currentUrl: any;
  clioregistered: any;
  noUserData = true;
  activateMenuItems = true;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.handleSessionTimeOut();
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.handleSessionTimeOut();
  }

  constructor(
    private uploadHelper_$: UploadHelperService,
    public router: Router,
    private clientMatter_$: ClientMatterService,
    public dialog: MatDialog,
    public auth_$: AuthService,
    private session_$: SessionService,
    private sessionStorage_$: SessionStorageService,
    private ngZone: NgZone,
    public redirection_$: RedirectionService,
    private twoFactorAuthentication_$: TwoFactorAuthenticationService,
    private uiMessaging_$: UIMessagingService,
  ) {
    this.userData = { userDBRole: '' };
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      client: UserRoles.client,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.ngZone.run(() => {
          this.currentUrl = event.url;
        });
      }
    });
  }

  getSessionTimeOutState() {
    const sessionTimeOutState = sessionStorage.getItem('sessionTimeOutState') || 'true';
    return sessionTimeOutState === 'true';
  }

  handleSessionTimeOut() {
    const sessionTimeOutState = this.getSessionTimeOutState();
    if (!sessionTimeOutState) {
      return false;
    }
    clearTimeout(this.session_$.sessionTimeout);
    if (this.auth_$.uid) {
      this.setSessionTimeOut();
    }
  }

  setSessionTimeOut() {
    if (!this.getSessionTimeOutState()) {
      return false;
    }
    this.session_$.clearSessionTimeOutState();
    this.session_$.sessionTimeout = setTimeout(() => {
      this.session_$.handleTimeEnd(v => {
        switch (v) {
          case 'session_end':
            this.dialog.closeAll();
            this.session_$.clearSessionTimeOutState();
            this.session_$.clearSessionTimeout();
            this.auth_$.logout();
            break;
          case 'session_continue':
            this.dialog.closeAll();
            this.setSessionTimeOut();
            break;
          default:
            this.dialog.closeAll();
            this.setSessionTimeOut();
            break;
        }
      });
    }, environment.config.sessionTimeLimit);
  }

  validatePermission(permission: string): boolean {
    if (!this.userPermissions || this.userPermissions.length === 0) {
      return false;
    }

    return this.userPermissions
      .filter(p => p.role === this.auth_$.userData.getValue()['role'])
      .filter(p => p.object === permission)['access'];
  }

  syncMyData($event) {
    this.auth_$.syncMyData().then(() => {
      const message = 'Your data has been synced';
      const label = 'OK';
      this.uiMessaging_$.toastMessage(message, label);
    });
  }

  public goToLogin() {
    this.redirection_$.goToLogin();
  }

  ngOnInit() {
    if (this.sessionStorage_$.getAddToClioStarted() === 'true') return;

    this.auth_$.drawer = this.drawer;
    this.loaded = false;

    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        if (ev.urlAfterRedirects === '/login') this.noLoginLink = true;
        if (ev.urlAfterRedirects === '/logout') this.auth_$.logout();
      }
    });

    this.maintenance = environment.maintenance;

    // As soon as the user is signed in Firebase...
    this.auth_$.auth.onAuthStateChanged(user => {
      if (this.sessionStorage_$.getSSO() === '0') {
        return;
      }

      if (user && Object.keys(user).length > 0) {
        if (this.sessionStorage_$.getAddToClioStarted() !== 'true') {
          // User is signed in.
          this.handleSessionTimeOut();
        }

        // Execute userReady to get the user data from the DB.
        this.auth_$.userReady(user, 'onAuthStateChanged').then(() => {
          this.userEnabled = true;
          this.loaded = true;
          this.userData = this.auth_$.userData.getValue();

          this.checkPasswordExpiration();

          // If the user comes from SSO there is no need to ask for 2FA.
          if (this.userData.lpm === 'clio' && !this.auth_$.isClioRegistered()) {
          } else {
            this.twoFactorAuthentication_$.handleTwoFactorSignIn(user.email);
          }
        });

        this.auth_$.searchComponent = true;
      } else {
        console.log('No user is signed in.');
        // No user is signed in.
        this.ngZone.run(() => {
          this.userEnabled = false;
          this.loaded = true;
          // NOTE: This was causing the user on ResetPassword flow lands on login page.
          // this.router.navigate(['/login']);
        });
        this.userData = null;
      }
    });

    // When we have USERDATA completed, we can execute actions with its data.
    const observer = this.auth_$.userData.subscribe({
      next: async userData => {
        if (Object.keys(userData).length === 0) {
          // no user data yet.
          this.ngZone.run(() => (this.noUserData = true));
          return;
        }

        /*********** TAKEN FROM USERREADY inside AFTEAUTHSTATECHANGED ********** */
        this.userEnabled = true;
        this.loaded = true;
        this.userData = this.auth_$.userData.getValue();
        this.checkPasswordExpiration();
        /********************* */

        // There is user data.

        if (!userData['permissions']) {
          return;
        }

        this.userPermissions = userData['permissions'];
        this.createuser = this.validatePermission('createuser');

        this.noUserData = false;
        this.clioregistered = userData['clioregistered'] || this.sessionStorage_$.getClioRegistered();

        if (userData['user']) {
          this.lpmClio = userData['lpm'] === 'clio' || false;
          this.userEnabled = true;
          this.loaded = true;

          const { clioregistered, lpm, testaccount, clioSSO } = this.userData;

          //
          if (this.lpmClio && !this.auth_$.isClioRegistered() && testaccount && clioSSO === 1) {
            this.activateMenuItems = false;
          }

          if (!this.auth_$.preventRedirections) {
            // Only open the drawer if the user is fully registered with Clio.
            if (clioregistered) {
              this.auth_$.drawer.open().then(() => observer.unsubscribe());
              const referer = this.sessionStorage_$.getReferer();
              if (!referer) {
                this.router.navigate(['/']);
                this.sessionStorage_$.cleanSSO();
                if (this.sessionStorage_$.getAddToClioStarted() !== 'true') {
                  this.auth_$
                    .updateClioCustomActions(this.auth_$.userData.getValue()['uid'])
                    .catch(err => console.log('Clio custom actions update error', err));
                } else {
                  console.log('Add to Clio process started');
                  // return;
                }
                console.log('No referer found, redirecting to /');
              } else {
                const dataURL = [referer.split('?')[0], ...referer.split('?')[1].split('&')];
                await this.router.navigate([dataURL[0]], {
                  replaceUrl: true,
                  queryParams: dataURL.slice(1).reduce((acc, cur) => {
                    const [key, value] = cur.split('=');
                    acc[key] = value;
                    return acc;
                  }, {}),
                });
                console.log('Redirecting to ' + referer);
              }
            } else {
              if (
                !this.auth_$.isClioRegistered() &&
                lpm === 'clio' &&
                !userData['testaccount'] &&
                !userData['clioSSO'] &&
                this.auth_$.userData.getValue()['plancode'] === 'lpmp' &&
                this.sessionStorage_$.getAddToClioStarted() !== 'true'
              ) {
                this.redirection_$.redirectToCompleteClioRegistration();
                return;
              }
              observer.unsubscribe();
            }
          } else {
            console.log('Prevent redirections is true');
          }
        }
      },
      error: err => console.error('Something went wrong: ' + err),
    });

    this.uploadHelper_$.scannedBlocksSource.subscribe(
      obs => (this.uploadProgressVisibility = this.uploadHelper_$.getScannedUploadBlocksList().length > 0),
    );

    this.topAppBar = MDCTopAppBar.attachTo(document.getElementById('app-bar')).setScrollTarget(
      document.getElementById('app-bar'),
    );

    this.uploadHelper_$.uploadProgressMaximizedO.subscribe(val => (this.uploadProgressMaximized = val));
  }

  async openAuditLog() {
    const dialogConfig = {
      width: '950px',
      data: { user: this.userData, logs: await this.getLogsByUID(this.userData.user.uid) },
    };
    this.dialog.open(AuditLogViewerComponent, dialogConfig);
  }

  async getLogsByUID(uid: string) {
    const ownerLogs = (await firebase.firestore().collection('logs').where('uid', '==', uid).get()).docs.map(doc =>
      doc.data(),
    );
    const ownerChildrenLogs = (
      await firebase.firestore().collection('logs').where('ownerID', '==', uid).get()
    ).docs.map(doc => doc.data());
    return ownerLogs.concat(ownerChildrenLogs);
  }

  validatePermissions(permission: string) {
    return this.auth_$.validatePermission(permission);
  }

  checkPasswordExpiration() {
    const { id, lpm, clioregistered } = <any>this.auth_$.userData.getValue();
    if (!id) {
      console.log('No user id found');
      this.auth_$.logout();
    }

    if (lpm === 'clio' && !clioregistered) {
      console.log('The user is not registered with Clio yet. Do not check for password expiration.');
    } else {
      if (lpm === 'clio' && clioregistered && this.sessionStorage_$.getSSO() !== '1') {
        this.auth_$.checkIfPasswordIsExpired(id).then(passwordExpired => {
          if (passwordExpired.data) {
            this.handleUpdateUserPasswordComponent();
          } else {
            console.log('The password has not expired yet.');
          }
        });
      }
    }
  }

  handleUpdateUserPasswordComponent() {
    this.dialog
      .open(UpdateUserPasswordComponent, { width: '400px', disableClose: true })
      .afterClosed()
      .subscribe({ next: passwordData => this.handlePasswordData(passwordData) });
  }

  handlePasswordData(passwordData) {
    const { newPassword, oldPassword } = passwordData;
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);
    user
      .reauthenticateWithCredential(credential)
      .then(async () => {
        await this.auth_$.updateUserPassword(newPassword);
        this.dialog.open(SimpleMessageWindowComponent, {
          width: '300px',
          data: { message: 'Your password has been updated', title: 'Password updated' },
        });
      })
      .catch(err => {
        const { code } = err;
        if (code === 'auth/wrong-password') {
          const message = 'Your current password is wrong, please try again';
          const label = null;
          this.uiMessaging_$.toastMessage(message, label);
          this.checkPasswordExpiration();
        }
      });
  }

  editProfile(event) {
    const currentUser = this.auth_$.currentUser;
    const dialogConfig = {
      width: '600px',
      data: this.userData,
    };
    const dialogRef = this.dialog.open(EditProfileComponent, dialogConfig);
  }

  openAllUsersComponent(role: string) {
    console.log('Open All Users Component');
    const dialogConfig = {
      width: '600px',
      data: { role },
    };
    this.dialog.open(AllusersComponent, dialogConfig);
  }

  navigateToConsultant() {
    this.router.navigate(['consultants']);
    // this.openAllUsersComponent(UserRoles.consultant);
  }

  navigateToOwner() {
    this.router.navigate(['owners']);
    // this.openAllUsersComponent(UserRoles.owner);
  }

  navigateToAssociate() {
    this.router.navigate(['associates']);
    // this.openAllUsersComponent(UserRoles.associate);
  }

  navigateToAdmin() {
    this.router.navigate(['admins']);
    // this.openAllUsersComponent(UserRoles.admin);
  }

  navigateToClientUsers() {
    this.router.navigate(['clients']);
    // this.openAllUsersComponent(UserRoles.admin);
  }

  navigateToClient() {
    this.router.navigate(['clientsmatters']);
  }

  async openClient() {
    const data = {
      patient: await this.clientMatter_$.getPatientData(this.userData.clientMatter),
      user_id: this.userData.user.uid,
      targetFolder: '',
    };

    if (this.getRole() === UserRoles.client) {
      data.targetFolder = 'Client Upload';
    }

    this.dialog.open(ClientProfileComponent, { data, width: '100vw' });
  }

  private getRole() {
    return this.auth_$.userData.value['role'];
  }

  navigateToCreateClient() {
    this.dialog.open(CreatepatientComponent, {
      width: '380px',
      data: null,
    });
  }

  upgradeYourPlan() {
    const userDataValue = this.auth_$.userData.getValue();
    const userPlan = userDataValue['plan'] || { id: 'basic' };
    console.log('Upgrade your plan');
    this.dialog.open(UpgradePlanComponent, {
      width: '850px',
      data: { title: 'Upgrade Plan', currentPlan: userPlan, message: '<p>Go and upgrade your current plan.</p>' },
    });
  }

  VideoConferencing() {
    this.router.navigate(['meetings']);
    this.drawer.close();
  }

  ConferenceList() {
    this.dialog.open(CreateuserComponent, {
      width: '380px',
      data: {
        userType: 'None',
        title: 'List of Conferences',
        button: 'blank',
        screen: 'empty',
      },
    });
  }

  async createUser(role: string) {
    let r_dialog: any;
    switch (role) {
      case 'consultant':
        this.dialog.open(CreateuserComponent, {
          width: '380px',
          data: {
            userType: 'Consultant',
            title: 'Create Consultant',
            button: 'Create',
            screen: 'full',
          },
        });
        break;
      case 'admin':
        r_dialog = this.dialog.open(CreateuserComponent, {
          width: '380px',
          height: '90vh',
          data: {
            userType: 'Admin',
            title: 'Create Admin',
            button: 'Create',
            screen: 'full',
          },
        });
        break;
      case clientRole:
        r_dialog = this.dialog.open(CreatepatientComponent, {
          width: '380px',
          data: null,
        });
        break;
      case UserRoles.owner:
        r_dialog = null;
        if (this.userData.userDBRole === UserRoles.superuser) {
          this.navigateToCreateOwner();
        } else {
          const message = 'You are not authorized to access this page';
          const label = null;
          this.uiMessaging_$.toastMessage(message, label);
        }
        break;
      default:
        break;
    }
    const dialogRef = r_dialog;
  }

  navigateToCreateUser() {
    this.dialog.open(CreatepatientComponent, {
      width: '380px',
      data: null,
    });
  }

  navigateToCreateOwner() {
    this.dialog.open(CreateclientComponent, {
      width: '380px',
      data: null,
    });
  }

  navigateGenerateReport() {
    this.dialog.open(ReportComponent, {
      width: '70vw',
      data: this.uid,
    });
  }
}
