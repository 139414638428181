import 'firebase/firestore';

import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { from } from 'rxjs';
import { ClioService } from 'src/app/services/clio.service';
import { WindowsManagerService } from 'src/app/services/windows-manager.service';
import { environment } from 'src/environments/environment';

import { AuthService } from '../../../services/auth.service';
import { UIMessagingService } from '../../../services/uimessaging.service';
import { ClientProfileComponent } from '../../clientprofile/clientprofile.component';
import { SessionStorageService } from './../../../services/session-storage.service';
import { RedirectionService } from 'src/app/services/redirection.service';
const addtoclioString = 'addtoclio';
const apiauthcallback = 'apiauthcallback';
const openapp = 'openapp';
const clientOrigin = 'client';
@Component({
  template: `<div
    class="paragraph"
    *ngIf="error">
    {{ errorMessage }}
    <a
      href="#"
      (click)="reloadThisPage($event)"
      >Reload</a
    >
    |
    <a
      href="#"
      (click)="rememberLastSession($event)"
      >Back to Client/Matter Profile</a
    >
  </div>`,
  styles: [
    `
      .paragraph {
        margin-left: 20px;
      }
    `,
  ],
})
export class ClioAuthComponent implements OnInit {
  profileDialogRef: any;
  v: any;
  error = false;
  errorMessage: string;
  SSOCompleted = false;
  sso: string;

  constructor(
    private windowsManager_$: WindowsManagerService,
    private sessionStorage_$: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private clio_$: ClioService,
    public auth_$: AuthService,
    private dialog: MatDialog,
    public ref: ChangeDetectorRef,
    private router: Router,
    private redirection_$: RedirectionService,
    private uiMessaging_$: UIMessagingService,
  ) {}

  async ngOnInit() {
    if (this.activatedRoute.snapshot.params['origin'] === addtoclioString)
      this.sessionStorage_$.setAddToClioStarted(true);

    // Check if the user is already logged in
    if (this.auth_$.isSignedIn()) this.signedInActions();
    else this.notSignedInActions();
  }

  authUserSubscription() {
    this.auth_$.user.subscribe({
      next: async v => {
        if (v) {
          console.log('v: ', v);
          this.handleExistingUser(v);
        } else {
          console.log('No user');
        }
      },
      error: e => console.log('error', e),
      complete: () => console.log('=== end ==='),
    });
  }

  reloadThisPage(event) {
    event.preventDefault();
    window.location.href = window.location.href;
  }

  createClioWebHooks() {
    const userdocid = this.auth_$.userData.value['id'];
    const url = `${environment.constants.cloudfunctionsURL}clio-createWebHooks`;

    // FIXME: Have to validate existing webhooks before create new ones.
    this.http.post(url, { userdocid }).subscribe({
      next: async answer => console.log('createClioWebHooks', answer),
      error: err => console.error('createClioWebHooks', err),
    });
  }

  getAuthorizationCode2(observer, code, nonce, sso?: number) {
    this.auth_$.showLoader('Handling SSO Sign In...');
    from(
      firebase.functions().httpsCallable('clio-getSSONewAuthorizationCode')({
        code,
        redirect_uri: environment.config.clio.SSO.callback,
        nonce,
        sso,
      }),
    ).subscribe(observer);
  }

  getAuthorizationCode(observer, code) {
    const userdocid = this.auth_$.userData.value['id'];
    if (!userdocid) {
      console.log('No userdocid');
      return;
    }
    const redirect_uri = `${environment.config.clio.redirect_uri}/client`;
    from(this.clio_$.clioGetAuthorizationCodeV3({ userdocid, code, redirect_uri })).subscribe(observer);
  }

  handleObserverError() {
    this.error = true;
    this.errorMessage = this.v.error;
    this.ref.detectChanges();
  }

  handleExistingCode(code) {
    const observer = {
      next: async x => {
        await this.auth_$.userReady(this.auth_$.userData.value, 'handleExistingCode').catch(err => {
          console.error(err);
          return;
        });
        this.redirectToHome(true);
        this.createClioWebHooks();
        this.getUserLastSession();
        this.error = false;
        this.errorMessage = '';
      },
      error: err => {
        const message = 'There was an error, please try again.';
        console.error(message, err);
        const label = null;
        this.uiMessaging_$.toastMessage(message, label);
        this.error = true;
        this.errorMessage = 'There was an error, please try to reload this page.';
        this.ref.detectChanges();
      },
      complete: () => console.log('Observer completed'),
    };

    this.auth_$.userData.subscribe({
      next: async v => {
        console.log('>>>> v', v);
        if (Object.keys(v).length > 0) {
          this.getAuthorizationCode(observer, code);
        }
      },
      error: e => console.log('error', e),
      complete: () => console.log('=== end ==='),
    });
  }

  handleExistingUser(v) {
    this.v = v;
    this.handleRouteParamsSubscription();
    this.handleRouteMapSubscription();
  }

  handleRouteParamsSubscription() {
    this.v = this.activatedRoute.snapshot.queryParams;
    if (this.v.error) {
      this.handleObserverError();
    } else if (this.v.code) {
      this.handleExistingCode(this.v.code);
    }
  }

  handleRouteMapSubscription() {
    this.activatedRoute.paramMap.subscribe(async params => {
      if (params['origin'] === addtoclioString) console.log(addtoclioString + ':192');
      if (params['origin'] === openapp) console.log(params['origin']);

      // Lets define when the user could be logged in or not.

      // Check if the user is logged in
      const { code, state } = this.activatedRoute.snapshot.queryParams;
      if (code)
        this.auth_$.userIsSignedIn.getValue() ? this.makeSignedInActions(code) : this.makeSignedOffActions(code, state);

      if (this.activatedRoute.snapshot.queryParams['error'] === 'access_denied') {
        console.log('access_denied');
        if (this.auth_$.userData.value['id']) {
          this.auth_$.logout('SSO cancelled');
          window.location.href = environment.config.host + `logout`;
        } else {
          this.auth_$.logout(params['error_description']);
        }
        this.redirectToHome(true);
      }
    });
  }

  makeSignedOffActions(code, nonce) {
    console.log('user is not signed in');
    this.getAuthorizationCode2(
      {
        next: async ({ data }) =>
          this.signInWithCustomToken_local(data['customToken']).then(() => this.afterSignInWithToken()),
        error: (err: any) => console.log('error > >', err),
        complete: () => console.log('getAuthorizationCode2 observer completed'),
      },
      code,
      nonce,
    );
  }

  signInWithCustomToken_local(customToken) {
    return this.auth_$
      .signInWithCustomToken(customToken)
      .then(async userCredential => this.afterSignInWithTokenV2(userCredential))
      .catch(error => console.log('signInWithCustomToken_local error :', error));
  }

  makeSignedInActions(code) {
    const buildAuthUserObserver = (origin, code) => {
      console.log('origin :', origin);
      let obs;

      switch (origin) {
        case 'calendar':
          obs = this.getCalendarObserver();
          break;
        case clientOrigin:
          obs = {
            next: async x => await this.handleClientNext(x),
            error: err => this.handleClientError(err),
            complete: () => console.log('Observer completed'),
          };
          break;
        case addtoclioString:
          this.sessionStorage_$.setAddToClioStarted(true);
          this.redirection_$.redirectToLoginClio();
          return;
        default:
          console.log('default: no obs here');
          break;
      }

      return {
        next: async v => {
          if (v !== null && Object.keys(v).length) {
            this.auth_$
              .userReady(v, 'makeSignedInActions')
              .then(async () => this.getAuthorizationCode(obs, code))
              .catch(err => console.log(err));
          }
        },
        error: e => console.log('error', e),
        complete: () => console.log('=== end ==='),
      };
    };

    this.auth_$.user.subscribe(buildAuthUserObserver(origin, code));

    this.auth_$.user.subscribe({
      next: async v => {
        if (v) {
          console.log('v: ', v);
          this.handleExistingUser(v);
        } else {
          console.log('No user');
        }
      },
      error: e => console.log('error', e),
      complete: () => console.log('=== end ==='),
    });

    console.log('user is signed in');
  }

  getCalendarObserver() {
    return {
      next: async x => {
        await this.auth_$.userReady(this.auth_$.userData.value, 'getCalendarObserver').catch(err => {
          console.error(err);
          return;
        });
        this.redirectToHome(true);
        this.createClioWebHooks();
        this.getUserLastSession();
        this.error = false;
        this.errorMessage = '';
      },
      error: err => {
        console.error(err);
        const message = 'There was an error, please try again.';
        const label = null;
        this.uiMessaging_$.toastMessage(message, label);
        this.error = true;
        this.errorMessage = 'There was an error, please try to reload this page.';
        this.ref.detectChanges();
      },
      complete: () => console.log('Observer completed'),
    };
  }

  getAddToClioObserver() {
    return {
      next: async x => {
        await this.auth_$.userReady(this.auth_$.userData.value, 'getAddToClioObserver').catch(err => {
          console.error(err);
          return;
        });
        this.redirectToHome(true);
        if (!this.auth_$.isClioRegistered() || !this.auth_$.userData['clioSSO']) {
          this.clio_$.updateClioSSO(0, this.auth_$.userData.getValue()['id']).then(() => {
            if (this.sessionStorage_$.getSSO() === '1') {
              this.sessionStorage_$.setAddToClioStarted(false);
              this.clio_$.completeAddToClioAction();
            } else {
              this.SSOCompleted = true;
            }
          });
        }
        this.error = false;
        this.errorMessage = '';
      },
      error: err => {
        console.error(err);
        const message = 'There was an error, please try again.';
        const label = null;
        this.uiMessaging_$.toastMessage(message, label);
        this.error = true;
        this.errorMessage = 'There was an error, please try to reload this page.';
        this.ref.detectChanges();
      },
      complete: () => console.log('Observer completed'),
    };
  }

  rememberLastSession(event) {
    event.preventDefault();
    this.getUserLastSession();
  }

  handleOpenMeetings(origin: string) {
    console.log('origin :', origin);
    // this.dialog.open(OpenMeetingsComponent, {});
    this.router.navigate(['/meetings'], { queryParams: { origin } });
  }

  async getUserLastSession() {
    const lastSession = this.auth_$.userData.value['lastSession'];
    if (!lastSession || lastSession === '') {
      console.log('== no session saved ==');
      return false;
    }

    const { patientDocId, origin } = lastSession;
    if (!patientDocId) {
      return;
    }

    if (origin === 'updateDefaultCalendar') {
      this.handleOpenMeetings(origin);
      return;
    }
    if (origin === 'afterLogin') {
      this.handleOpenMeetings(origin);
      return;
    }
    if (origin === openapp) {
      this.sessionStorage_$.setOpenAppStarted(true);
      console.log('== handle open app ==');
      return;
    }

    const data = (await firebase.firestore().collection('patients').doc(patientDocId).get()).data();
    if (data === undefined) {
      return;
    }
    const FirstName = data.FirstName.charAt(0).toUpperCase() + data.FirstName.slice(1);
    const LastName = (data.LastName = data.LastName.charAt(0).toUpperCase() + data.LastName.slice(1));
    const caseName = data.caseName;
    const LegalCaseId = data.LegalCaseId;
    const DateOfBirth = data.DateOfBirth;
    const sharedUserFiles = data.files;
    const ownerID = data.ownerID;
    const defaultFolders = data.defaultFolders;
    const patient = {
      FirstName,
      LastName,
      caseName,
      LegalCaseId,
      DateOfBirth,
      sharedUserFiles,
      ownerID,
      defaultFolders,
    };
    this.routeToProfile(patient);
  }

  routeToProfile(patient) {
    const { ownerID } = patient;
    if (!ownerID) {
      const message = `This profile does not have a valid ownerID, please contact support before retry.`;
      const label = 'ALERT';
      this.uiMessaging_$.toastMessage(message, label);
      return;
    }
    if (!this.windowsManager_$.clientProfileOpened) {
      this.profileDialogRef = this.dialog.open(ClientProfileComponent, {
        width: '100vw',
        height: '90vh',
        data: { patient: patient, button: 'clio-matters' },
      });
    }
  }

  async handleExistingCodeV2(code) {
    console.log('handleExistingCodeV2');
    const success = async () => {
      console.log(' Handling Existing Code ');

      await this.auth_$.userReady(this.auth_$.userData.value, 'handleExistingCodeV2').catch(err => {
        console.error(err);
        return;
      });
      this.redirectToHome(true);
      this.createClioWebHooks();
      this.getUserLastSession();
      this.error = false;
      this.errorMessage = '';
    };

    const error = err => {
      const message = 'There was an error, please try again.';
      console.error(message, err);
      const label = null;
      this.uiMessaging_$.toastMessage(message, label);
      this.error = true;
      this.errorMessage = 'There was an error, please try to reload this page.';
      this.ref.detectChanges();
    };

    await this.auth_$.userData.toPromise().then(v => {
      console.log('>>>> v', v);
      if (Object.keys(v).length > 0) {
        this.getAuthorizationCodeV2(success, error, code);
      }
    });
  }

  async handleClientNext(x) {
    this.redirectToHome(true);
    this.createClioWebHooks();
    this.getUserLastSession();
    this.error = false;
    this.errorMessage = '';
  }

  handleClientError(err) {
    console.error(err);
    const message = 'There was an error, please try again.';
    const label = null;
    this.uiMessaging_$.toastMessage(message, label);
    this.error = true;
    this.errorMessage = 'There was an error, please try to reload this page.';
    this.ref.detectChanges();
  }

  async afterClioGetAuthorizationCodeV3(res) {
    console.log('res: ', res);

    // Call UserReady with fresh data from Firestore.
    await this.auth_$.getFreshFirestoreUserData();
    console.log('This.UserData', this.auth_$.userData.getValue());

    if (!this.auth_$.isClioRegistered() || this.auth_$.userData.value['clioSSO'] === 1) {
      // Pasa primero.
      this.clio_$.updateClioSSO(0, this.auth_$.userData.getValue()['id']).then(() => {
        if (this.sessionStorage_$.getSSO() === '1') {
          this.sessionStorage_$.setAddToClioStarted(false);
          this.clio_$.completeAddToClioAction();
        } else {
          this.SSOCompleted = true;
        }
      });
      this.handleCheckReferer('afterClioGetAuthorizationCodeV3:493');
    } else if (this.auth_$.isClioRegistered() && !this.sessionStorage_$['clioSSO']) {
      console.log('🐵');
      console.log('.......', this.activatedRoute.snapshot.queryParams);
      this.handleCheckReferer('afterClioGetAuthorizationCodeV3:497');
    }
  }

  handleCheckReferer(origin?: string) {
    if (origin) {
      console.log('handleCheckReferer origin: ', origin);
    }

    const referer = this.sessionStorage_$.getReferer();
    if (referer) {
      const elements = referer.split('/')[1].split('?');
      const action = elements[0];
      const params = elements[1].split('&');

      const queryParams = {
        [params[0].split('=')[0]]: params[0].split('=')[1],
        [params[1].split('=')[0]]: params[1].split('=')[1],
        [params[2].split('=')[0]]: params[2].split('=')[1],
        [params[3].split('=')[0]]: params[3].split('=')[1],
      };
      console.log('queryParams :', queryParams);
      this.router.navigate([`/${action}`], { queryParams }).then(() => this.sessionStorage_$.removeReferer());
    }
  }

  getAuthorizationCodeV2(success, error, code) {
    // Am I really need to get Authorization Code?
    if (this.auth_$.userData.value['clioAccessToken']) {
      const { access_token, refresh_token, token_type, expires_in, datetime } = JSON.parse(
        this.auth_$.userData.value['clioAccessToken'],
      );
      const date = new Date(datetime);
      const now = new Date();
      const diff = now.getTime() - date.getTime();
      const diffHours = Math.round(diff / 1000 / 60 / 60);
      if (diffHours < 24) {
        console.log('No need to get Authorization Code');
        this.afterClioGetAuthorizationCodeV3(null).then(() => {
          console.log('success time diffHours < 24');
          // success();
        });
        return;
      }
    }

    const userdocid = this.auth_$.userData.value['id'];
    if (!userdocid) {
      console.log('No userdocid');
      return;
    }
    const redirect_uri = `${environment.config.clio.redirect_uri}/client`;
    this.clio_$
      .clioGetAuthorizationCodeV3({ userdocid, code, redirect_uri })
      .then(async res => {
        this.afterClioGetAuthorizationCodeV3(res).then(() => {
          console.log('success time');
          // success();
        });
      })
      .catch(err => {
        console.log('err: ', err);
        error();
      });
  }

  redirectToHome(replaceUrl?: boolean) {
    this.router.navigate(['/'], { replaceUrl });
  }

  notSignedInActions() {
    console.log('notSignedInActions');
    const handleClioAuthCase = () => {
      const { code } = this.activatedRoute.snapshot.queryParams;
      switch (this.activatedRoute.snapshot.params.origin) {
        case addtoclioString:
          this.sessionStorage_$.setAddToClioStarted(true);
          // this.redirectToLoginClio();
          break;
        case openapp:
          // this.sessionStorage_$.setSSO('0');
          this.sessionStorage_$.removeSSO();
          this.sessionStorage_$.setOpenAppStarted(true);
          this.redirection_$.redirectToLoginClio({ queryParams: { sso: 0 } });
          break;
        case clientOrigin:
          if (!code) {
            this.redirectToHome();
          } else {
            // Get Clio Authorization Code
            this.handleExistingCode(code);
          }
          return;
        case apiauthcallback:
          if (!code) this.redirectToHome();
          else this.handleExistingCode(code);

          if (this.sessionStorage_$.getAddToClioStarted() === 'true') this.clio_$.completeAddToClioAction();
          return;
        default:
          this.redirection_$.goToLogin();
          break;
      }
    };

    if (this.activatedRoute.snapshot.params.origin === apiauthcallback) {
      console.log(apiauthcallback);
      return;
    }

    if (this.activatedRoute.snapshot.url[0].path !== 'sso_callback') {
      // this.redirection_$.goToLogin();
      this.router.navigate(['/login']);
    }

    this.auth_$.redirectURL = this.router.routerState.snapshot.url;
    switch (this.activatedRoute.snapshot.url[0].toString()) {
      case 'clioauth':
        handleClioAuthCase();
        break;
      case 'sso_callback':
        this.handleRouteMapSubscription();
        break;
      case addtoclioString:
        this.clio_$.completeAddToClioAction();
        break;
      default:
        console.log('No ClioAuth Actions');
        break;
    }
  }

  async signedInActions() {
    console.log('signedInActions');

    const postSuccessUserReady = async () => await this.postSuccessUserReady();

    switch (this.activatedRoute.snapshot.params.origin) {
      case addtoclioString:
        if (this.sessionStorage_$.getSignedIn() === 'true') {
          await this.auth_$.logout();
          this.sessionStorage_$.setAddToClioStarted(true);
        }
        break;
      default:
        break;
    }

    this.auth_$.auth.onAuthStateChanged({
      next: async v => {
        console.log('v: ', v);
        if (v) {
          console.log('v :', v);
          console.log('There is user');

          if (this.activatedRoute.snapshot.params.origin === apiauthcallback) {
            const redirect_uri = `${environment.config.clio.redirect_uri}/${apiauthcallback}`;
            const uid = this.auth_$.auth.currentUser.uid;
            const code = this.activatedRoute.snapshot.queryParams.code;

            if (this.sessionStorage_$.getClioGetAuthorizationCodeV4Executed() !== 'true') {
              this.sessionStorage_$.setClioGetAuthorizationCodeV4Executed('true');
              this.auth_$.showLoader('Getting Clio Authorization Code...');

              let functionName = 'clio-getAuthorizationCodeV5';
              if (this.sessionStorage_$.getAddToClioStarted() === 'true') {
                functionName = 'clio-getAuthorizationCodeV6';
              }

              await firebase
                .functions()
                .httpsCallable(functionName)({ code, uid, redirect_uri })
                .then(async result => {
                  console.log(functionName + ' result :', result);
                  await this.auth_$.handleGetAuthorizationCodeV3ResultV2(result, this.auth_$.auth.currentUser);
                  if (this.sessionStorage_$.getAddToClioStarted() === 'true') {
                    this.clio_$.completeAddToClioAction();
                  }
                })
                .catch(err => {
                  console.log(functionName + ' err :', err);
                  this.auth_$.showLoader('An error has ocurred, please close this window and give it a second try...');
                  if (this.sessionStorage_$.getAddToClioStarted() === 'true') {
                    this.clio_$.completeAddToClioAction();
                  }
                });
              this.auth_$.hideLoader();
            } else {
              this.auth_$.showLoader('An error has ocurred...');
              setTimeout(() => {
                this.auth_$.hideLoader();
              }, 5000);
            }
          }

          // Run userReady
          this.auth_$
            .userReady(v, 'signedInActions')
            .then(async result => {
              console.log('This is the result of signedInActions :', result);
              await postSuccessUserReady();
            })
            .catch(e => console.log('e :', e));
        } else {
          console.log('No user');
        }
      },
      error: e => console.log('error', e),
      complete: () => console.log('=== end ==='),
    });
  }

  private async postSuccessUserReady() {
    const userEmail = this.auth_$.userData.getValue()['email'];
    if (!this.auth_$.userData.value['clioAccessToken'] && userEmail) await this.populateClioData(userEmail);

    console.log('postSuccessUserReady');
    const isNotClioRegistered = this.auth_$.isClioRegistered() === false;
    const isNotClioSSO = !this.auth_$.userData.value['clioSSO'];
    const isNotTestAccount = !this.auth_$.userData.value['testaccount'];
    const isNotAddToClioStarted = !Boolean(this.sessionStorage_$.getAddToClioStarted());
    const condition = isNotClioSSO || isNotAddToClioStarted;

    if (
      isNotClioRegistered &&
      condition &&
      isNotTestAccount &&
      this.auth_$.userData.getValue()['plancode'] === 'lpmp'
    ) {
      this.redirection_$.redirectToCompleteClioRegistration();
      return;
    }

    if (!this.activatedRoute.snapshot.queryParams.code) {
      throw new Error('No code parameter');
    }

    if ('clioauth' === this.activatedRoute.snapshot.url[0].path && this.activatedRoute.snapshot.queryParams.code) {
      const success = () => {
        if (
          this.sessionStorage_$.getSSO() === '1' &&
          (!this.auth_$.isClioRegistered() || this.sessionStorage_$.getSSO() === '0') &&
          !this.SSOCompleted
        ) {
          this.clio_$.updateClioSSO(0, this.auth_$.userData.getValue()['id']).then(() => {
            if (this.sessionStorage_$.getSSO() === '1') {
              this.sessionStorage_$.setAddToClioStarted(false);
              this.clio_$.completeAddToClioAction();
            } else {
              this.SSOCompleted = true;
            }
          });
        }
      };

      // Do I need to get Authorization Code at this point?
      const needToGetAuthorizationCodeV2 =
        !this.auth_$.userData.value['clioAccessToken'] ||
        this.validateDateTimeAccessToken(JSON.parse(this.auth_$.userData.value['clioAccessToken'])['datetime']);

      if (needToGetAuthorizationCodeV2) {
        console.log('getAuthorizationCodeV2');
        this.getAuthorizationCodeV2(
          success,
          (error: any) => console.log('error', error),
          this.activatedRoute.snapshot.queryParams.code,
        );
        return;
      }

      // This is for SSO process
      const isSSOPRocess = this.sessionStorage_$.getSSO() === '1';
      if (isSSOPRocess) {
        this.sessionStorage_$.setAddToClioStarted(false);
        this.clio_$.completeAddToClioAction();
      }

      this.handleCheckReferer('signedInActions');
    }
  }

  validateDateTimeAccessToken(datetime) {
    if (datetime) {
      const date = new Date(datetime);
      const now = new Date();
      const diff = now.getTime() - date.getTime();
      const diffHours = Math.round(diff / 1000 / 60 / 60);
      if (diffHours < 24) {
        console.log('No need to get Authorization Code');
        return false;
      }
    }
    return true;
  }

  async populateClioData(email) {
    await firebase
      .firestore()
      .collection('users')
      .where('email', '==', email)
      .limit(1)
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs[0].data();
        console.log('data: ', data);
        const { clioAccessToken, clioCustomActions } = data;
        const currentUserData = this.auth_$.userData.getValue();
        this.auth_$.userData.next({ ...currentUserData, clioAccessToken, clioCustomActions });
      })
      .catch(e => {
        console.log('e :', e);
      });
  }

  afterSignInWithToken() {
    const uid = this.auth_$.currentUser.getValue()['uid'];
    this.sessionStorage_$.setUID(uid);
    if (this.sessionStorage_$.getAddToClioStarted() === 'true') {
      this.redirectToAuthorize_API_AUTH_CALLBACK();
    }
  }

  afterSignInWithTokenV2(userCredential) {
    this.sessionStorage_$.setSignedIn(true);
    this.auth_$.userReady(userCredential.user, 'handleActionAfterSigInWithCustomToken').then(({ id }) => {
      this.auth_$
        .setLastSession({ lastSession: { origin: 'clio' } }, id)
        .then(() => {
          this.clio_$.redirectToClioAuthorize();
        })
        .catch(err => console.log('err :', err))
        .finally(() => this.auth_$.hideLoader());
      // this.sessionStorage_$.setClioSSOLogin(true);
    });
  }

  private redirectToAuthorize_API_AUTH_CALLBACK() {
    this.clio_$.redirectToAuthorize('https://qa.nuagedx.com/clioauth/' + apiauthcallback);
  }
}
