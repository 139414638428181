<div
  fxLayout="row"
  fxLayoutAlign="space-around center">
  <div *ngIf="showform === 2">
    <h2>Reset your password</h2>
    <form
      (ngSubmit)="handleSubmit($event)"
      class="change-password-form">
      <mat-form-field appearance="fill">
        <mat-label>New password</mat-label>
        <input
          class="password-input"
          name="password"
          type="text"
          id="password"
          [formControl]="password"
          placeholder="Enter password"
          matInput />
      </mat-form-field>
      <mat-password-strength
        (onStrengthChanged)="onStrengthChanged($event)"
        #passwordComponent
        [password]="password.value"></mat-password-strength>
      <mat-password-strength-info [passwordComponent]="passwordComponent"></mat-password-strength-info>
      <mat-form-field appearance="fill">
        <mat-label>Confirm password</mat-label>
        <input
          class="password-input"
          name="confirmpassword"
          (input)="checkPasswordConfirm($event)"
          [formControl]="passwordconfirm"
          matInput />
      </mat-form-field>
      <button
        [disabled]="!submitApproval"
        type="submit"
        mat-flat-button
        color="warn">
        CHANGE PASSWORD
      </button>
    </form>
  </div>
  <p *ngIf="showform === 1">The link is outdated, please try again, you will be redirected soon.</p>
</div>
