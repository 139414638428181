<div fxLayout="row">
  <div fxFlex="none">
    <h3
      class="headline"
      [ngClass]="'mat-headline no-margin'">
      Client/Matter Profile:
      <b>{{ patientName }}</b>
    </h3>
  </div>
  <div
    class="client-matter-data"
    [ngClass]="'mat-body'"
    fxFlex
    fxLayout="row wrap"
    fxLayoutGap="10px"
    fxFlexOffset="1">
    <mat-chip-list
      *ngIf="patient"
      selectable="false"
      aria-label="User Information">
      <mat-chip
        *ngIf="patient.caseName"
        disableRipple="true"
        [ngClass]="['mat-body', 'case-name']">
        {{ patient.caseName }}
      </mat-chip>
      <mat-chip
        *ngIf="patient.LegalCaseId"
        disableRipple="true"
        [ngClass]="'mat-body'">
        Client/Matter ID - {{ patient.LegalCaseId }}
      </mat-chip>
      <mat-chip
        *ngIf="patient.DateOfBirth"
        disableRipple="true"
        [ngClass]="'mat-body'"
        color="warn">
        DOB - {{ patient.DateOfBirth }}
      </mat-chip>
      <mat-chip
        *ngIf="patient.clientUser && patient.clientUser.length > 0"
        disableRipple="true"
        >{{ patient.clientUser }}</mat-chip
      >
    </mat-chip-list>
    <button
      *ngIf="realRole === UserRoles.owner && (!patient.clientUser || patient.clientUser.length === 0)"
      mat-flat-button
      color="warn"
      (click)="handleAddClientUser($event)">
      <mat-icon>person_add</mat-icon>
      Add Client Upload Account
    </button>
  </div>
  <div fxFlex="none">
    <button
      mat-flat-button
      mat-dialog-close
      color="warn">
      X
    </button>
  </div>
</div>
<div *ngIf="patientName !== '...'">
  <div fxLayout="row">
    <div
      fxFlex
      fxLayout="column">
      <div
        *ngIf="loadingPermissions"
        style="margin-bottom: 10px"
        fxLayout="row"
        fxLayoutAlign="start center">
        <app-loader></app-loader>
      </div>
      <div
        *ngIf="permissions_uploadfiles || realRole === 'Client'"
        fxLayout="row"
        fxLayoutAlign="start center">
        <button
          (click)="openDialog($event)"
          mat-flat-button
          color="warn">
          <mat-icon>cloud_upload</mat-icon>
          Choose new files or disks to upload to your folders
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button
          *ngIf="realRole !== 'Client'"
          [disabled]="!importFromAppsBtn"
          (click)="showImportFromApps()"
          mat-flat-button
          color="warn">
          <mat-icon>download</mat-icon>
          Import from Apps
        </button>

        <button
          *ngIf="clioMatterId"
          (click)="backToClio(clioMatterId)"
          mat-flat-button
          matTooltip="Open this Matter in Clio">
          <img
            src="./../../../assets/png/clio-blue-square.png"
            width="15px"
            alt="Clio logo" />
          Back to Clio
        </button>
      </div>
      <hr />
      <app-breadcrumb
        *ngIf="UserRoles.client !== realRole"
        [folderColor]="folderColor"
        [currentFolder]="currentFolder"
        [currentFolderName]="currentFolderName"
        (buttonClick)="handleBreadcrumbClick($event)"></app-breadcrumb>
      <p
        *ngIf="
          currentFolder !== '' &&
          filesTableDataSource.data.length &&
          permissions_uploadfiles &&
          UserRoles.client !== realRole
        "
        [ngClass]="'mat-body'">
        1. You must select one or more of the
        <b> files you have uploaded </b>
        to have the file actions enabled.
        <br />
        2. Click
        <mat-icon
          [ngClass]="'file-icon'"
          class="people-icon"
          aria-hidden="false"
          aria-label="This file is shared."
          matTooltip="This file is shared.">
          people
        </mat-icon>
        to see who has this file.
      </p>
      <mat-toolbar
        [ngStyle]="{ height: 'auto' }"
        fxLayout
        fxLayoutAlign="space-between center">
        <div fxFlex="72">
          <button
            *ngIf="selection.selected.length > 1"
            [disabled]="!allowDeleteNote || selection.selected.length === 0"
            (click)="openDeleteConfirmDialog()"
            mat-button>
            <mat-icon>delete</mat-icon>
            &nbsp;
            <span [ngClass]="'mdc-button__label'">DELETE</span>
          </button>
          <button
            *ngIf="fileSelected()"
            (click)="openAddNoteDialog()"
            mat-button
            id="marginBFileInfo">
            <mat-icon>note_add</mat-icon>
            &nbsp;
            <span [ngClass]="'mdc-button__label'">ADD NOTE</span>
          </button>
          <button
            *ngIf="viewNotesButton"
            (click)="openAllNotes()"
            mat-button>
            <mat-icon>note</mat-icon>
            &nbsp;
            <span [ngClass]="'mdc-button__label'">VIEW NOTES</span>
          </button>
          <button
            [disabled]="!refreshButton"
            (click)="
              getFilesForPatient(currentFolder, {
                folderName: currentFolderName,
                roleConsultant: this.realRole === UserRoles.consultant,
                caseFolders: this.caseFolders
              })
            "
            mat-button>
            <mat-icon>refresh</mat-icon>
            &nbsp;
            <span [ngClass]="'mdc-button__label'">REFRESH</span>
          </button>
          <button
            *ngIf="permissions_createfolder && currentFolderName === ''"
            (click)="createFolder(currentFolder, { folderName: currentFolderName })"
            mat-button>
            <mat-icon>create_new_folder</mat-icon>
            &nbsp;
            <span [ngClass]="'mdc-button__label'">CREATE FOLDER</span>
          </button>
          <!-- SELECTED FILES ACTIONS -->
          <button
            *ngIf="filesSelected() && this.realRole !== this.UserRoles.consultant && UserRoles.client !== this.realRole"
            [matMenuTriggerFor]="menu"
            mat-button>
            <mat-icon>description</mat-icon>
            &nbsp;
            <span [ngClass]="'mdc-button__label'">FILE ACTIONS</span>
          </button>
          <mat-menu #menu="matMenu">
            <button
              (click)="selectTarget(FileActionsDictionary.COPY)"
              mat-menu-item>
              <mat-icon>content_copy</mat-icon>
              COPY TO
            </button>
            <button
              *ngIf="currentFolder !== 'all'"
              (click)="selectTarget(FileActionsDictionary.MOVE)"
              mat-menu-item>
              <mat-icon>content_paste</mat-icon>
              MOVE TO
            </button>
          </mat-menu>
          <!-- SEARCH -->
          <button
            *ngIf="
              currentFolder !== '' &&
              selection.selected.length === 0 &&
              files.length > 0 &&
              realRole !== UserRoles.consultant &&
              realRole !== UserRoles.client
            "
            [disabled]="!refreshButton || files.length <= 1"
            (click)="openFileSearch()"
            mat-button>
            <mat-icon>search</mat-icon>
            &nbsp; SEARCH
          </button>
          <!-- SORT FILES BY SELECTED -->
          <button
            *ngIf="selection.selected.length > 1 && currentFolder !== ''"
            [disabled]="!refreshButton"
            (click)="sortFilesBySelected()"
            mat-button>
            <mat-icon>fact_check</mat-icon>
            &nbsp; SORT FILES BY SELECTED
          </button>
          <!-- SORT BY MENU -->
          <button
            *ngIf="selection.selected.length === 0 && currentFolder !== ''"
            [disabled]="files.length <= 1"
            [matMenuTriggerFor]="sortmenu"
            mat-button>
            <mat-icon>sort</mat-icon>
            &nbsp; SORT FILES BY
          </button>
          <!-- FILTER CHIP -->
          <mat-chip
            disableRipple="true"
            *ngIf="filesFilter.name !== ''"
            (removed)="removeFilter()">
            {{ filesFilter.name }}
            <button
              matChipRemove
              aria-label="'remove ' + filesFilter.name">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <mat-menu #sortmenu="matMenu">
            <button
              *ngFor="let sort of sortTypes; index as i"
              [value]="i"
              (click)="handleSelect(i)"
              mat-menu-item>
              {{ sort.label }}
            </button>
          </mat-menu>
        </div>
        <button
          *ngIf="activeConsultant && realRole !== 'Consultant'"
          [disabled]="!activeConsultant"
          (click)="unShareAll(activeConsultant)"
          gdAlignColumns
          mat-flat-button
          color="warn">
          {{ unshareAllFilesMsg }}
        </button>
      </mat-toolbar>
      <div
        *ngIf="filesTableDataSource.data"
        [ngClass]="'mat-elevation-z0 stickyheader-table-container table-big-container'"
        class="files-table">
        <div
          *ngIf="filesTableDataSource.data.length === 0 && loading === false"
          class="no-files-message">
          <h2 [ngClass]="'mat-body'">{{ nofilesMessage }}</h2>
        </div>
        <app-loader
          *ngIf="loading"
          [message]="loadingMessage || ''"></app-loader>
        <div
          *ngIf="filesTableDataSource.data.length > 0; else nofiles"
          [ngClass]="'table-container'">
          <table
            [ngClass]="'mat-elevation-z1'"
            [dataSource]="filesTableDataSource"
            (matSortChange)="handleSortData($event)"
            matSort
            mat-table
            matSortActive="fileName"
            matSortDirection="desc"
            matSortDisableClear>
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th
                *matHeaderCellDef
                [ngClass]="{ hidden: currentFolder === '' }"
                mat-header-cell>
                <mat-checkbox
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  (change)="$event ? masterToggle() : null">
                </mat-checkbox>
              </th>
              <td
                *matCellDef="let element"
                [ngClass]="{ hidden: currentFolder === '' }"
                mat-cell>
                <mat-checkbox
                  [checked]="selection.isSelected(element)"
                  (click)="handleCheckboxClick($event)"
                  (change)="$event ? selection.toggle(element) : null">
                </mat-checkbox>
              </td>
            </ng-container>
            <!-- File Name Column -->
            <ng-container matColumnDef="fileName">
              <th
                *matHeaderCellDef
                [ngClass]="'bold-black-table-header'"
                mat-header-cell
                mat-sort-header="fileName">
                <span *ngIf="currentFolder !== '' && realRole !== 'Consultant'"> Files you have uploaded </span>
                <span *ngIf="currentFolder !== '' && realRole === 'Consultant'">
                  Files you have available for review:
                </span>
                <span *ngIf="currentFolder === ''">Your folders</span>
              </th>
              <td
                *matCellDef="let element"
                mat-cell>
                <span
                  *ngIf="checkInactiveStore(element)"
                  class="loading-message">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="preview icon">
                    preview
                  </mat-icon>
                  &nbsp; Study loading...
                </span>
                <a
                  *ngIf="!element.viewerurl && element.type !== 'folder'"
                  [ngClass]="'folder-button'"
                  (click)="handleFileClick($event, element)"
                  mat-flat-button
                  attr.data-storename="{{ element.storename }}"
                  matTooltip="Click here to browse the file in a new tab."
                  target="_blank"
                  download>
                  {{ element.fileName }}
                </a>
                <button
                  *ngIf="element.clioDocId"
                  (click)="openDocumentInClio(element.clioDocId)"
                  mat-flat-button
                  matTooltip="Open this Document in Clio">
                  <img
                    src="./../../../assets/png/clio-blue-square.png"
                    width="15px"
                    alt="Clio logo" />
                </button>
                <a
                  *ngIf="element.type === 'folder'"
                  [ngStyle]="getButtonStyle(element.name)"
                  [ngClass]="'folder-button'"
                  (click)="handleFolderClick($event, element)"
                  mat-flat-button
                  matTooltip="Click here to browse the folder contents.">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="material-icons-outlined">
                    folder
                  </mat-icon>
                  {{ element.label || element.name }}
                </a>
                <button
                  *ngIf="element.viewerurl && !checkInactiveStore(element)"
                  [ngClass]="{ 'view-link': true }"
                  [ngStyle]="{ display: 'inline-flex' }"
                  (click)="handleFileClick($event, element)"
                  matTooltip="Click here to browse the study in the DICOM viewer.">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="preview icon">
                    preview
                  </mat-icon>
                  {{ element.fileName === dicomdirFileName ? 'Imaging Study' : element.fileName }}
                </button>
                <mat-icon
                  *ngIf="element.sharedUsers && element.sharedUsers.length > 0 && permissions_sharefileicon"
                  [ngClass]="'file-icon'"
                  (click)="$event.preventDefault(); $event.stopPropagation(); toggleHighLight($event, element)"
                  aria-hidden="false"
                  aria-label="This file is shared."
                  matTooltip="This file is shared.">
                  people
                </mat-icon>
                <mat-icon
                  *ngIf="element.notes && element.notes.length"
                  [ngClass]="'file-icon'"
                  aria-hidden="false"
                  (click)="handleNoteClick($event, element)"
                  aria-label="sticky_note_2 icon"
                  matTooltip="This file has note(s).">
                  sticky_note_2
                </mat-icon>
              </td>
            </ng-container>
            <ng-container matColumnDef="fileDesc">
              <th
                *matHeaderCellDef
                [ngClass]="{ hidden: currentFolder === '' }"
                mat-header-cell
                mat-sort-header="fileDesc">
                File content
              </th>
              <td
                *matCellDef="let element"
                [ngClass]="{ hidden: currentFolder === '' }"
                mat-cell>
                {{ element.type !== 'folder' ? element.fileDesc : '' }}
              </td>
            </ng-container>
            <!-- Last Modified Column -->
            <ng-container matColumnDef="lastModified">
              <th
                *matHeaderCellDef
                [ngClass]="{ hidden: currentFolder === '' }"
                mat-header-cell
                mat-sort-header="lastModified">
                Last Modified
              </th>
              <td
                *matCellDef="let element"
                [ngClass]="{ hidden: currentFolder === '' }"
                mat-cell>
                {{ element.type !== 'folder' ? (element.lastModified ? formatDate(element.lastModified) : '') : '' }}
              </td>
            </ng-container>
            <!-- Date Uploaded Column -->
            <ng-container matColumnDef="uploadedDate">
              <th
                *matHeaderCellDef
                [ngClass]="{ hidden: currentFolder === '' }"
                mat-header-cell
                mat-sort-header="uploadedDate">
                Date Uploaded
              </th>
              <td
                *matCellDef="let element"
                [ngClass]="{ hidden: currentFolder === '' }"
                mat-cell>
                {{ element.type !== 'folder' ? formatDate(element.uploadedDate) : '' }}
              </td>
            </ng-container>
            <!-- Date of File Column -->
            <ng-container matColumnDef="fdate">
              <!-- <th mat-header-cell mat-sort-header="Date of file" *matHeaderCellDef mat-sort-header>Date of File</th> -->
              <th
                *matHeaderCellDef
                [ngClass]="{ hidden: currentFolder === '' }"
                mat-header-cell
                mat-sort-header="fdate">
                Date of File
              </th>
              <td
                *matCellDef="let element"
                [ngClass]="{ hidden: currentFolder === '' }"
                mat-cell>
                {{ element.type !== 'folder' ? (element.fdate ? formatDate(element.fdate) : '') : '' }}
              </td>
            </ng-container>
            <!-- File parentFolderName Column -->
            <ng-container matColumnDef="parentFolderName">
              <th
                *matHeaderCellDef
                [ngClass]="{ hidden: currentFolder !== 'all' }"
                mat-header-cell>
                File Folder Name
              </th>
              <td
                *matCellDef="let element"
                [ngClass]="{ hidden: currentFolder !== 'all' }"
                mat-cell>
                <a
                  [ngClass]="['folder-button', 'short']"
                  [ngStyle]="getButtonStyle(element.parentFolderName || element.parentFolder)"
                  (click)="handleFolderNameClick($event, element)"
                  matTooltip=" {{ element.parentFolderName || element.parentFolder || '-' }}"
                  mat-flat-button>
                  <mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="material-icons-outlined">
                    folder
                  </mat-icon>
                  {{ element.parentFolderName || element.parentFolder || '-' }}
                </a>
              </td>
            </ng-container>
            <!-- File ID Column -->
            <ng-container matColumnDef="fileId">
              <th
                *matHeaderCellDef
                mat-header-cell
                class="table-actions-column"></th>
              <td
                *matCellDef="let element"
                mat-cell>
                <button
                  *ngIf="permissions_editfiles && element.folderId !== 'all' && element.predefined !== true"
                  [ngClass]="'small-mat-icon-button'"
                  (click)="renameElement(element, $event)"
                  mat-icon-button
                  matTooltip="Click to edit this file."
                  aria-label="Click here to edit this upload.">
                  <mat-icon>create</mat-icon>
                </button>
                <button
                  *ngIf="permissions_deletefiles && element.type !== 'folder'"
                  [ngClass]="'small-mat-icon-button'"
                  (click)="deleteSingleRow(element, $event)"
                  mat-icon-button
                  aria-label="Click here to delete this from the files you have uploaded."
                  matTooltip="Click here to delete this from the files you have uploaded.">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>
            <!-- File predefined Column -->
            <ng-container matColumnDef="predefined">
              <th
                *matHeaderCellDef
                class="hidden"
                mat-header-cell>
                predefined
              </th>
              <td
                *matCellDef="let element"
                class="hidden"
                mat-cell>
                {{ element.predefined }}
              </td>
            </ng-container>
            <!-- File Shared Column -->
            <ng-container matColumnDef="isShared">
              <th
                *matHeaderCellDef
                [ngClass]="{
                  hidden: !activeConsultant || realRole === 'Consultant'
                }"
                mat-header-cell
                mat-sort-header="isShared">
                Shared status
              </th>
              <td
                *matCellDef="let element"
                [ngClass]="{
                  hidden: !activeConsultant || realRole === 'Consultant'
                }"
                mat-cell>
                <span
                  *ngIf="activeConsultant !== null && !fileSharedValidation(element) && !checkIfSelected(element)"
                  class="not-shared-message">
                  Not shared
                </span>
                <button
                  *ngIf="activeConsultant !== null && !fileSharedValidation(element) && checkIfSelected(element)"
                  [ngClass]="{ grayed: !checkIfSelected(element) }"
                  (click)="$event.stopPropagation(); shareSingleFile(element)"
                  mat-flat-button
                  color="warn"
                  matTooltip="Click here to share this file with active consultant.">
                  Share
                </button>
                <span *ngIf="activeConsultant && fileSharedValidation(element) && !checkIfSelected(element)"
                  >Already shared</span
                >
                <button
                  *ngIf="activeConsultant && fileSharedValidation(element) && checkIfSelected(element)"
                  [ngClass]="{ blushed: checkIfSelected(element) }"
                  (click)="$event.stopPropagation(); unShareSingleFile(element)"
                  mat-flat-button
                  matTooltip="Click here to stop sharing this file with active consultant.">
                  Unshare
                </button>
              </td>
            </ng-container>
            <tbody>
              <tr
                *matHeaderRowDef="displayedColumns; sticky: true"
                mat-header-row></tr>
              <tr
                *matRowDef="let element; columns: displayedColumns; let i = index"
                [ngClass]="{
                  highlighted: fileSharedValidation(element) && realRole !== 'Consultant',
                  'inactive-store': checkInactiveStore(element)
                }"
                (click)="
                  isDisplayContextMenu = false;
                  handleRowClick(element, $event, {
                    currentFolder: currentFolder
                  })
                "
                mat-row></tr>
            </tbody>
          </table>
        </div>
        <ng-template #nofiles>
          <div class="no-files">
            <h2 class="mat-h3 red-title">No files found</h2>
          </div>
        </ng-template>
        <mat-paginator
          *ngIf="pageSize"
          [length]="pagesLength"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizes"
          showFirstLastButtons
          color="warn"></mat-paginator>
      </div>
    </div>
    <div
      *ngIf="permissions_consultantslist && filesTableDataSource.data.length > 0 && currentFolder !== ''"
      fxFlex="25"
      fxFlexOffset="2">
      <h2 [ngClass]="'mat-h3 red-title'">Consultants</h2>
      <mat-tab-group
        [ngClass]="['custom-tab-label']"
        [(selectedIndex)]="selectedTabIndex"
        (selectedTabChange)="handleTabsClick($event)"
        color="warn">
        <mat-tab
          *ngIf="activeConsultants"
          label="Active">
          <p class="mat-body active-consultants-message">Click Consultant to see which files are Shared with them.</p>
          <app-active-consultants
            #active_consultants
            *ngIf="activeConsultants"
            [dataFromParent]="activeConsultants"
            [selectedFiles]="selection.selected"
            (unshareEvent)="unShareWith($event)"
            (shareEvent)="shareWith($event)"
            (rowClickEvent)="handleActiveConsultantClick($event)"></app-active-consultants>
        </mat-tab>
        <mat-tab
          *ngIf="availableConsultants"
          label="Available">
          <app-available-consultants
            #available_consultants
            *ngIf="availableConsultants"
            [dataFromParent]="availableConsultants"
            (parentReload)="clientProfileReload($event)"
            (shareEvent)="shareWith($event)">
          </app-available-consultants>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div
    *ngIf="infectedFiles && infectedFiles.length"
    class="mat-small">
    <p>
      The followinfg file(s) has been deleted because of malware found in it. Please click
      <a
        (click)="goRefresh($event)"
        class="refresh-link"
        href="#">
        REFRESH
      </a>
      to update the list of files
    </p>
    <ul>
      <li
        *ngFor="let file of infectedFiles"
        class="infected-file">
        {{ file }}
      </li>
    </ul>
  </div>
</div>
