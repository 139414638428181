import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UIMessagingService {
  constructor(private snackBar: MatSnackBar) {}

  toastMessage(message: string, action: string, additionalparams?: MatSnackBarConfig) {
    this.snackBar.open(message, action, <MatSnackBarConfig>{ ...environment.config.snackBar, ...additionalparams });
  }
}
