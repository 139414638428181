import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomActions } from 'src/app/dictionaries/CustomActions';
import { MattersService } from 'src/app/services/matters.service';
import { UIMessagingService } from 'src/app/services/uimessaging.service';
import { WindowsManagerService } from 'src/app/services/windows-manager.service';

import { AuthService } from '../../../services/auth.service';
import { RedirectionService } from 'src/app/services/redirection.service';

@Component({
  template: `<p></p>`,
})
export class HandleClioCustomActionsComponent implements OnInit {
  existingMatter: any;
  observable: any;
  comparisonWindowOpened: any;
  constructor(
    private windowsManager_$: WindowsManagerService,
    private activatedRoute: ActivatedRoute,
    private auth_$: AuthService,
    private matters_$: MattersService,
    private router: Router,
    private uiMessaging_$: UIMessagingService,
    private redirect_$: RedirectionService,
  ) {}

  ngOnInit(): void {
    this.auth_$.auth.onAuthStateChanged(user => {
      if (user) {
        this.handleExistingUser(user);
      } else {
        this.handleNotLoggedIn();
      }
    });
  }

  handleExistingUser(user) {
    let executed = false;

    this.auth_$.userData.subscribe({
      next: async userData => {
        if (Object.keys(userData).length) {
          if (this.getUserClioCustomActions(userData)) {
            const observer = {
              next: queryParams => {
                if (executed === false) {
                  this.handleCustomActionRequest(
                    queryParams.custom_action_id,
                    queryParams.custom_action_nonce,
                    queryParams.subject_url,
                    queryParams.user_id,
                  );
                  executed = true;
                }
              },
              error: err => console.error('Observer got an error: ' + err),
              complete: () => console.log('Observer got a complete notification'),
            };
            this.activatedRoute.queryParams.subscribe(observer);
          } else {
            console.log('There are no custom actions for this user.');
          }
        }
      },
      error: err => console.log('err :', err),
      complete: () => console.log('complete'),
    });
  }

  getUserClioCustomActions(userData) {
    return userData.clioCustomActions;
  }

  handleNotLoggedIn() {
    this.activatedRoute.queryParams.subscribe(({ custom_action_id, custom_action_nonce, subject_url, user_id }) => {
      this.redirect_$.goToLogin({
        queryParams: {
          referer: this.activatedRoute.routeConfig.path,
          custom_action_id,
          custom_action_nonce,
          subject_url,
          user_id,
        },
      });
    });
  }

  handleCustomActionRequest(custom_action_id, custom_action_nonce, subject_url, user_id) {
    this.handleAction(custom_action_id, subject_url.split('/').pop().split('%2F').pop(), user_id);
  }

  handleInvalidCustomAction() {
    this.auth_$.hideLoader();
    this.uiMessaging_$.toastMessage(
      'Invalid custom action. ' +
        'Please go back to Clio and try again. Probably you have logged in to NuageDx with a different account than the one you are logged into Clio.',
      'ERROR',
    );
    this.redirectToHome();
  }

  removeClioCustomActionUrlParams() {
    this.redirectToHome();
  }

  private async handleAction(custom_action_id, clioObjectId, user_id) {
    const custom_action = this.auth_$.userData
      .getValue()
      ['clioCustomActions'].find(ca => ca.id === parseInt(custom_action_id, 10));

    if (custom_action === undefined) {
      console.log(
        'Invalid custom action. The requested custom action does not exist in the list of the custom actions from the current logged in NuageDx user.',
      );
      this.handleInvalidCustomAction();
      return;
    }

    const target = custom_action.target_url.split('/').pop();

    // FIXME: What happen when a custom action is changed on Clio, how to update stored custom actions on user's data.
    if (['meetings', CustomActions.meetingsScheduling].includes(target)) {
      this.auth_$.preventRedirections = true;
      return await this.router.navigate(['/meetings'], { queryParams: { defaultCalendar: 'clio' } }).then(
        success => {
          console.log('Navigate to meetings page success :', success);
          this.auth_$.drawer.close();
          return;
        },
        error => console.log('error :', error),
      );
    } else {
      switch (custom_action.ui_reference) {
        case 'folders/show':
          const clioFolderId = clioObjectId;
          // this.removeClioCustomActionUrlParams();
          this.handleFolderAction(custom_action, clioFolderId, user_id, { target, clioObjectId });
          break;
        case 'matters/show':
          const clioMatterId = clioObjectId;
          // this.removeClioCustomActionUrlParams();
          this.handleMatterAction(custom_action, clioMatterId, user_id, { target, clioObjectId });
          break;
        case 'documents/show':
          const clioDocumentId = clioObjectId;
          // this.removeClioCustomActionUrlParams();
          this.handleDocumentAction(custom_action, clioDocumentId, user_id, { target, clioObjectId });
          break;
        case 'contact/show':
        case 'activities/show':
          this.uiMessaging_$.toastMessage(`This action is not supported yet`, 'ERROR');
          break;
        default:
          break;
      }
    }
  }

  async handleFolderAction(custom_action, clioFolderId, user_id, options) {
    console.log('handleFolderAction');
    this.checkSetAvoidFilesCompare(custom_action, options);
    const matterInfo = await this.matters_$.getMatterIdFromFolderId(clioFolderId);
    const matterId = matterInfo['data']['data']['matter']['id'];
    console.log('matterId :', matterId);
    this.openClioMatter(matterId, user_id, {
      ...options,
      folderMatterInfo: matterInfo['data']['data'],
      clioFolderId: clioFolderId,
      targetFolder: 'Clio',
    });
  }

  checkSetAvoidFilesCompare(custom_action, options) {
    console.log('checkSetAvoidFilesCompare');
    options.avoidFilesCompare = [CustomActions.uploadDicomDisk, CustomActions.viewMedicalImages].includes(
      custom_action.target_url.split('/').pop(),
    )
      ? true
      : false;
  }

  getClioMatterId(clioDocumentId) {
    return this.matters_$.getClioMatterIdFromDocumentId(clioDocumentId);
  }

  async handleMatterAction(custom_action, clioMatterId, user_id, options) {
    console.log('handleMatterAction');
    this.checkSetAvoidFilesCompare(custom_action, options);
    if (options.target === CustomActions.openClioMatterFromDocument) {
      const localClioMatterId = await this.getClioMatterId(clioMatterId);
      console.log('localClioMatterId :', localClioMatterId);
      this.openClioMatter(localClioMatterId, user_id, { ...options, targetFolder: 'Clio' });
    }
    if (options.target === CustomActions.openClioMatter) {
      console.log('clioMatterId :', clioMatterId);
      this.openClioMatter(clioMatterId, user_id, { ...options, targetFolder: 'Clio' });
    }
    if (options.target === CustomActions.viewMedicalImages) {
      this.openClioMatter(clioMatterId, user_id, { ...options, section: CustomActions.viewMedicalImages });
    }
    if (options.target === CustomActions.uploadDicomDisk) {
      this.openClioMatter(clioMatterId, user_id, { ...options, section: CustomActions.uploadDicomDisk });
    }
    if (options.target === CustomActions.goToNuageDx) {
      this.redirectToHome();
    }
  }

  async handleDocumentAction(custom_action, clioDocumentId, user_id, options) {
    if (CustomActions.goToNuageDx === custom_action.target_url.split('/').pop()) {
      this.redirectToHome();
      return;
    }

    this.checkSetAvoidFilesCompare(custom_action, options);
    const matterInfo = await this.matters_$.getMatterInfoFromDocumentId(clioDocumentId);
    options = {
      ...options,
      documentMatterInfo: matterInfo['data']['data'],
      clioDocumentId: clioDocumentId,
      targetFolder: 'Clio',
    };
    console.log('matterInfo :', matterInfo);
    if (matterInfo['data']['error'] === true) {
      this.uiMessaging_$.toastMessage('This document has no Matter associated in Clio', 'ERROR');
      return;
    }
    this.openClioMatter(matterInfo['data']['data']['data']['matter']['id'], user_id, options);
  }

  async openClioMatter(matterId, user_id, options) {
    console.log('openClioMatter', { matterId, user_id, options });
    const existingMatter = await this.matters_$.checkClientMatterExistsFromClioMatterId(matterId);
    if (existingMatter.size > 0) {
      this.existingMatter = existingMatter.docs[0].data();
      const clientMatterCaseName = existingMatter.docs[0].data()['caseName'];
      const clioFolderId = await this.matters_$.getClioFolderIdFromCaseName(clientMatterCaseName);
      // The Clio Matter exists.
      this.handleMatterExists(matterId, existingMatter.docs[0].data(), { ...options, clioFolderId: clioFolderId });
      return;
      // this.matters_$.openExistingMatter(existingMatter.docs[0].data(), user_id, { ...options, targetFolder: 'Clio' });
    }

    this.handleMatterDoesntExist(matterId, user_id, options);
    return;
  }

  async handleMatterDoesntExist(matterId, user_id, options) {
    const newMatterData = await this.matters_$.importMatterOnly(matterId, user_id);
    console.log('newMatterData imported :', newMatterData);
    this.existingMatter = newMatterData;
    this.handleMatterExists(matterId, newMatterData, { ...options, clioFolderId: newMatterData.clioFolderId });
  }

  async handleMatterExists(matterId, existingMatter, options) {
    // Check if documents exist in Clio
    const clioDocumentsList = (await this.matters_$.getDocumentsListFromClioMatterId(matterId))['data'];
    if (clioDocumentsList.code === 501) {
      // The Clio matter has no documents
      console.log(clioDocumentsList.message);
      this.handleClioMatterNoDocuments(options);
      return;
    }

    if (clioDocumentsList.code === 200) {
      // The Clio matter has documents
      this.handleClioMatterDocuments(existingMatter, clioDocumentsList['data']['data'], matterId, options);
      return;
    }
  }

  handleClioMatterNoDocuments(options) {
    console.log('options :>', options);
    this.uiMessaging_$.toastMessage('This matter has no documents', 'INFORMATION');
    this.matters_$.openExistingMatter(this.existingMatter, this.auth_$.userData.getValue()['id'], {
      ...options,
      targetFolder: 'Clio',
    });
  }

  async handleClioMatterDocuments(existingMatter, clioDocumentsList, matterId, options) {
    const caseName = existingMatter.caseName;
    const existingMatterDocuments = await this.matters_$.getDocumentsListFromCaseName(caseName, options.clioFolderId);

    if (existingMatterDocuments.length === 0 && options.avoidFilesCompare === false) {
      if (!this.comparisonWindowOpened) {
        this.handleCompareDocuments(clioDocumentsList, existingMatterDocuments, matterId, options, existingMatter);
      }
      return;
    }

    const answer = this.checkIfPendingDocumentsToBeImported(clioDocumentsList, existingMatterDocuments);

    if (answer === 'yes' && options.avoidFilesCompare === false) {
      if (!this.comparisonWindowOpened) {
        this.handleCompareDocuments(clioDocumentsList, existingMatterDocuments, matterId, options, existingMatter);
      }
    } else {
      this.handleNoPendingDocumentsToBeImported(options);
    }
  }

  handleNoPendingDocumentsToBeImported(options) {
    console.log('options :>>', options);
    this.uiMessaging_$.toastMessage('All documents have already been imported', 'INFORMATION');
    this.matters_$.openExistingMatter(this.existingMatter, this.auth_$.userData.getValue()['id'], options);
  }

  checkIfPendingDocumentsToBeImported(clioDocumentsList, existingMatterDocuments) {
    if (existingMatterDocuments.length === 0) {
      return 'yes';
    }

    let answer = 'no';
    for (let index = 0; index < clioDocumentsList.length; index++) {
      const document = clioDocumentsList[index];
      if (!existingMatterDocuments.find(doc => doc.clioDocId === document.id)) {
        answer = 'yes';
        break;
      }
    }

    return answer;
  }

  handleCompareDocuments(clioDocumentsList, existingMatterDocuments, matterId, options, existingMatter) {
    if (this.windowsManager_$.clientProfileOpened) {
      console.log('clientProfileOpened');
    }
    const dialog = this.matters_$.openFilesComparisonModal(clioDocumentsList, existingMatterDocuments, matterId, {
      ...options,
      caseName: existingMatter.caseName,
      matterData: existingMatter,
      clioFolderId: options.clioFolderId,
    });
    this.comparisonWindowOpened = true;
    dialog.afterAllClosed.subscribe(() => {
      this.comparisonWindowOpened = false;
    });
  }

  private redirectToHome() {
    this.router.navigate(['/']);
  }
}
