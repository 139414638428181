<div [ngClass]="'mat-body'" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon *ngIf="currentFolder === ''">home</mat-icon>
  <span *ngIf="currentFolder === ''">&nbsp;Home</span>

  <button
    mat-button
    (click)="handleGo('')"
    aria-label="Back to home"
    *ngIf="currentFolder !== ''"
    [ngStyle]="{ padding: 0 }">
    <mat-icon>home</mat-icon> &nbsp;<span class="mat-body">Home</span>
  </button>

  <mat-icon *ngIf="currentFolder !== ''">keyboard_arrow_right</mat-icon>

  <span class="folder-name" [ngStyle]="getButtonStyle(currentFolderName)" *ngIf="currentFolderName != ''">
    {{ currentFolderName }}
  </span>
</div>
