import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import firebase from 'firebase/app';

import { FirebaseUtilitiesService } from '../../../services/firebase-utilities.service';
import { EditNoteComponent } from '../../edit-note/edit-note.component';

@Component({
  selector: 'app-all-notes-dialog',
  templateUrl: './all-notes-dialog.component.html',
  styleUrls: ['./all-notes-dialog.component.scss'],
})

export class AllNotesDIalogComponent implements OnInit {
  title: string;
  message: string;
  selected_files: [];
  notes: any;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[];
  fileid: any;
  patientName: any;
  currentFolder: any;
  currentFolderName: any;
  constructor (
    private dialogRef: MatDialogRef<AllNotesDIalogComponent>,
    private firebaseUtilities$: FirebaseUtilitiesService,
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) {
      title,
      message,
      selected_files,
      notes,
      fileid,
      patientName,
      currentFolder,
      currentFolderName
    }
  ) {
    this.dataSource = new MatTableDataSource<any>(notes);
    this.displayedColumns = ['name', 'description', 'createdby', 'attachments', 'delete'];
    this.title = title;
    this.patientName = patientName;
    this.currentFolder = currentFolder;
    this.currentFolderName = currentFolderName;
    this.message = message;
    this.selected_files = selected_files;
    this.notes = notes;
    this.fileid = fileid;
  }

  ngOnInit() { }

  deleteNoteHandler(ev, idx) {
    this.notes = this.notes.filter((val, index, arr) => index !== idx);
    this.dataSource = new MatTableDataSource<any>(this.notes);
    if (this.dataSource.data.length === 0) { this.message = 'This file has 0 notes attached.'; }
    this.firebaseUtilities$.updateNotesByFile(this.fileid, this.notes);
  }

  jsonParse(jsonString) {
    return jsonString ? JSON.parse(jsonString) : {};
  }

  browseFile(path: string) {
    firebase.storage().ref().child(path).getDownloadURL().then(url => {
      window.open(url, '_blank');
    });
  }

  substringFileName(path) {
    return path.split('/')[3];
  }

  editNoteHandler(ev, idx) {
    const dialogConfig = new MatDialogConfig();
    this.dialog.openDialogs.pop();

    dialogConfig.minWidth = 600;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxHeight = '80vw';

    const { title, note, createdBy, attachments } = this.notes[idx];

    dialogConfig.data = {
      title,
      createdBy,
      note,
      index: idx,
      attachments,
      patientName: this.patientName
    };

    const dialogRef = this.dialog.open(EditNoteComponent, dialogConfig);

    /* const subs = dialogRef.componentInstance.finish.subscribe((data) => {
      console.log('Do somehting when UploadDialogComponent finish.');
    }); */

    dialogRef.keydownEvents().subscribe((e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        dialogRef.close({ currentFolder: this.currentFolder, currentFolderName: this.currentFolderName });
      }
    });

    dialogRef.afterClosed().toPromise().then(result => {
      if (!result) { return false; }
      if (result.type === 'save') {
        this.saveNote(result);
      }
      // subs.unsubscribe();
    });
  }

  async saveNote(result: any) {
    if (result.newAttachments.length) {
      // NOTE: Store new files and get file paths.
      const attachmentLists = this.firebaseUtilities$.storeNoteNewAttachments(result.newAttachments, this.patientName);
      const oldAttachments = result.note.attachments ? JSON.parse(result.note.attachments) : [];
      const newAttachments = attachmentLists ? oldAttachments.concat(attachmentLists) : [];
      result.note.attachments = JSON.stringify(newAttachments);
    }
    this.notes[result.noteIndex] = result.note;
    await this.firebaseUtilities$.updateNotesByFile(this.fileid, this.notes);
    this.dataSource = new MatTableDataSource<any>(this.notes);
  }

  close() {
    this.dialogRef.close({ currentFolder: this.currentFolder, currentFolderName: this.currentFolderName });
  }
}
