import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  public filesArray = new BehaviorSubject([]);
  async getFilesByCaseName(casename: any, docs: boolean) {
    const docsData = (
      await firebase
        .firestore()
        .collection('files')
        .where('belongsTo', '==', casename)
        .where('forDelete', '==', false)
        .get()
    ).docs;
    return docs ? docsData : docsData.map(doc => doc.data());
  }

  async getFolderLabel(folderId: string, folderName: string, { isConsultant, useremail, isClient }) {
    let count = 0;
    if (isConsultant || isClient) {
      const filesInThisFolder = (
        await firebase
          .firestore()
          .collection('files')
          .where('parentFolder', '==', folderId)
          .where('ftype', '==', 'File')
          .where('forDelete', '==', false)
          .get()
      ).docs;
      console.log('filesInThisFolder.length: ', filesInThisFolder.length);

      if (filesInThisFolder.length === 0) {
        console.log('No files in this folder');
        return folderName;
      }

      filesInThisFolder
        .map(file => file.data())
        .forEach(file => {
          file.sharedUsers?.forEach(({ email }) => {
            if (email.toLowerCase() === useremail.toLowerCase()) {
              count++;
            }
          });
        });
    } else {
      count = (
        await firebase
          .firestore()
          .collection('files')
          .where('parentFolder', '==', folderId)
          .where('forDelete', '==', false)
          .get()
      ).size;
    }
    return `${folderName} (${count})`;
  }

  async getFilesByParentFolderId(folderId: any, casename: any, authoredBy?) {
    if (!authoredBy) {
      return (
        await firebase
          .firestore()
          .collection('files')
          .where('belongsTo', '==', casename)
          .where('forDelete', '==', false)
          .where('parentFolder', '==', folderId)
          .get()
      ).docs;
    } else {
      return (
        await firebase
          .firestore()
          .collection('files')
          .where('belongsTo', '==', casename)
          .where('forDelete', '==', false)
          .where('parentFolder', '==', folderId)
          .where('creator', '==', authoredBy)
          .get()
      ).docs;
    }
  }

  getOtherFolderId(casename: string) {
    return firebase
      .firestore()
      .collection('files')
      .where('belongsTo', '==', casename)
      .where('type', '==', 'folder')
      .where('name', '==', 'Other')
      .get()
      .then(({ docs }) => docs[0].data().folderId);
  }

  public addCurrentPatientFile(file) {
    const newArr = this.filesArray.getValue().push(file);
    this.filesArray.next([newArr]);
  }

  public addPatientFiles(filesArr) {
    this.filesArray.next(filesArr);
  }

  public getFilesArray() {
    return this.filesArray.getValue();
  }

  updateFileByDocId(docId: string, data: any) {
    return firebase.firestore().collection('files').doc(docId).update(data);
  }

  getFileByFileId(fileId: string) {
    return firebase.firestore().collection('files').where('fileId', '==', fileId).limit(1).get();
  }

  getFilesByFileIdsArr(fileIdsArr: string[]) {
    return firebase.firestore().collection('files').where('fileId', 'in', fileIdsArr).get();
  }

  getFilesByFolderId(folderId: string) {
    return firebase.firestore().collection('files').where('folderId', '==', folderId).get();
  }

  deleteFileByDocId(file) {
    return firebase
      .firestore()
      .collection('files')
      .doc(file.id)
      .update({ forDelete: true })
      .then(() => ({ fileId: file.fileId, docId: file.id }));
  }

  async getParentFolders(filesIds): Promise<string[]> {
    return (
      await firebase
        .firestore()
        .collection('files')
        .where('fileId', 'in', filesIds)
        .where('forDelete', '==', false)
        .get()
    ).docs.map(file => file.data().parentFolder);
  }

  async getFilesCountByClient(casename: string, roleConsultant, consultantEmail: string) {
    const { docs } = await firebase
      .firestore()
      .collection('files')
      .where('belongsTo', '==', casename)
      .where('forDelete', '==', false)
      .get();
    const items = docs.map(item => item.data()).filter(({ type }) => type !== 'folder');
    if (!roleConsultant) {
      return items.length;
    } else {
      return items.filter(
        ({ sharedUsers }) =>
          sharedUsers && sharedUsers.map(({ email }) => email.toLowerCase()).includes(consultantEmail.toLowerCase()),
      ).length;
    }
  }
}
