import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { GoogleEvent } from '../models/GoogleEvent';
import { environment } from './../../environments/environment';
import { AuthService } from './auth.service';
import { UIMessagingService } from './uimessaging.service';

declare const gapi: any;

const CLIENT_ID = '409596160399-jvos1lrt8q6lqc1u2u9fi2vokn4upe4a.apps.googleusercontent.com';
const API_KEY = environment.config.apiKey;
// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

@Injectable({
  providedIn: 'root',
})
export class GapiOperationsService {
  private _healthcare: any;
  gcloud_projectname: any;
  gcloud_projectlocation: any;
  healthcare: any;
  isSignedIn: boolean;

  constructor(
    private sanitizer: DomSanitizer,
    private a$: AuthService,
    private http: HttpClient,
    private uiMessaging_$: UIMessagingService,
  ) {
    this.gcloud_projectname = environment.config.gapi.projectname;
    this.gcloud_projectlocation = environment.config.gapi.location;
  }

  getGapi() {
    return gapi;
  }

  handleClientLoad() {
    // Load the API client and auth2 library.
    const loadGapiClient = new Promise(function (resolve, reject) {
      gapi.load('client:auth2', resolve);
    });
    return Promise.all([loadGapiClient]);
  }

  /**
   * Called when the signed in status changes, to update the UI
   * appropriately. After a sign-in, the API is called.
   * */
  updateSigninStatus(isSignedIn) {
    if (isSignedIn) {
      console.log('isSignedIn: ', isSignedIn);
      this.isSignedIn = true;
    } else {
      console.log('isSignedIn: ', isSignedIn);
      this.isSignedIn = false;
    }
  }

  /**
   * Print the summary and start datetime/date of the next ten events in
   * the authorized user's calendar. If no events are found an
   * appropriate message is printed.
   */
  createGoogleCalendarEvent(googleEventObject: GoogleEvent) {
    console.log('Event', googleEventObject);
    return new Promise((resolve, reject) => {
      gapi.client.calendar.events
        .insert({
          calendarId: 'primary',
          resource: googleEventObject,
        })
        .then(
          function (response) {
            const event = response.result;
            resolve(event);
            // this.gotoUrl(event.htmlLink);
          },
          error => {
            reject(error);
          },
        );
    });
  }

  gotoUrl(url: string, target?: string) {
    window.open(url, target || '_blank');
  }

  /**
   * Initializes the API client library and sets up sign-in state
   * listeners.
   * */
  async initClient() {
    const updateSigninStatus = this.updateSigninStatus;
    const gapiClientInit = await gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(
        () => true,
        error => {
          console.log('init error', error);
          return error;
        },
      );

    return gapiClientInit.error || { code: 200 };
  }

  setGapiAuth2IsSignedInListener(func) {
    gapi.auth2.getAuthInstance().isSignedIn.listen(func);
  }

  getApiAuthInstance() {
    // Listen for sign-in state changes.
    gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);
    // Handle the initial sign-in state.
    this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
  }

  /**
   * Append a pre element to the body containing the given message
   * as its text node. Used to display the results of the API call.
   *
   * @param message Text to be placed in pre element.
   * */
  appendPre(message) {
    const pre = document.getElementById('content');
    const textContent = document.createTextNode(message + '\n');
    pre.appendChild(textContent);
  }

  async checkIfDataSetExists(datasetname) {
    const projectId = environment.config.gapi.projectname;
    const cloudRegion = environment.config.gapi.location;
    const url = `${environment.config.healthcareApi}/projects/${projectId}/locations/${cloudRegion}/datasets/${datasetname}`;
    return this.http
      .get(url)
      .toPromise()
      .then(
        data => (data['name'] ? true : false),
        reason => {
          console.log(`Some error during dataset existence check: ${datasetname}`, reason);
          return false;
        },
      )
      .catch(err => {
        console.error(err);
      });
  }

  async createDataSet(dataSetName: string) {
    const datasetId = dataSetName.replace(/\s/g, '');
    const projectId = environment.config.gapi.projectname;
    const cloudRegion = environment.config.gapi.location;

    if (await this.checkIfDataSetExists(datasetId)) {
      console.log('The dataset exists');
    } else {
      const url = `${environment.config.healthcareApi}/projects/${projectId}/locations/${cloudRegion}/datasets/${datasetId}/create`;
      return this.http
        .get(url)
        .toPromise()
        .then(
          response => {
            if (response['done']) {
              return 200;
            } else {
              return 0;
            }
          },
          reason => {
            console.log('reason: ', reason);
            return 0;
          },
        );
    }
  }

  // TODO: Improve the STUDY delete with
  // https://cloud.google.com/healthcare/docs/reference/rest/v1beta1/projects.locations.datasets.dicomStores.studies/delete.
  deleteStudy() {
    console.log('Work needed here.');
  }

  async createDataStore(dicomStoreId: string, datasetId: string, projectId, cloudRegion) {
    const storename = dicomStoreId.replace(/\s/g, '');
    const datasetname = datasetId.replace(/\s/g, '');
    const url = `${environment.config.healthcareApi}/projects/${projectId}/locations/${cloudRegion}/datasets/${datasetname}/dicomStores/${storename}/create`;
    return this.http
      .get(url)
      .toPromise()
      .then(result => {
        console.log(`Store ${storename} created`, result);
        return 200;
      })
      .catch(reason => {
        console.log(`Store ${storename} not created`, reason);
        return 0;
      });
  }

  async deleteStoresByCaseName(datasetId, dataStoreList) {
    datasetId = datasetId.replace(/\s/g, '');
    // FIXME: This cloud function takes so long to finish, ar at least to answer.... this is important to be fixed ASAP.
    if (!dataStoreList && !dataStoreList.length) {
      return;
    }
    const projectId = environment.config.gapi.projectname;
    const cloudRegion = environment.config.gapi.location;

    const promisesStack = [];
    dataStoreList.forEach(dataStoreName => {
      dataStoreName = dataStoreName.replace(/\s/g, '');
      const url = `${environment.config.healthcareApi}/projects/${projectId}/locations/${cloudRegion}/datasets/${datasetId}/dicomStores/${dataStoreName}/delete`;
      promisesStack.push(this.http.get(url).toPromise());
    });
    return Promise.all(promisesStack);
  }

  gapi_search4studies(searchObject, url, healthcareRef) {
    return healthcareRef.projects.locations.datasets.dicomStores.searchForStudies(searchObject).then(
      d => {
        const list = [];
        console.log('d.result', d.result);
        d.result.forEach((object: any) => {
          let key_vr = '';
          let link: SafeUrl;
          let studyID = '';
          let date = '';
          let st_patientName = '';
          let st_doctorName = '';

          for (const key in object) {
            if (object.hasOwnProperty(key)) {
              key_vr = object[key].vr;
              if (key_vr === 'UI') {
                studyID = object[key].Value[0];
                link = this.sanitizer.bypassSecurityTrustUrl(
                  `${environment.config.ohifViewerURL}${url}/study/${studyID}`,
                );
              }
              if (key_vr === 'DA') {
                const objValue = object[key.toString()].Value;
                date = key_vr === 'DA' && objValue ? object[key.toString()].Value[0] : date;
              }
              if (key_vr === 'PN') {
                if (st_doctorName) {
                  st_patientName = object[key.toString()].Value[0]['Alphabetic'];
                } else {
                  st_doctorName = object[key.toString()].Value[0]['Alphabetic'];
                }
              }
            }
          }

          const doctorName = st_doctorName.replace('^', ' ');
          const patientName = st_patientName.replace('^', ' ');
          list.push({ studyID, link, date, doctorName, patientName });
        });

        console.log('LIST:', list);
        return list;
      },
      (err: any) => {
        console.log('error:', err);
        return 0;
      },
    );
  }

  // NOTE: This is probably obsolete.
  gapi_getHealthcareRef() {
    return this._healthcare;
  }

  getDataStoreName(
    storename: string,
    dataset: string,
    setexisting: number,
    projectName,
    projectLocation,
    healthcareRef,
  ) {
    if (setexisting !== 200) {
      return 0;
    }

    const caseName = storename;
    const google_healthcare_ds_path = `projects/${projectName}/locations/${projectLocation}/datasets/${dataset}/dicomStores/`;

    return healthcareRef.projects.locations.datasets.dicomStores
      .get({ name: `${google_healthcare_ds_path}${caseName}` })
      .then(
        () => {
          console.log('Store already exists.');
          return 200;
        },
        error => {
          console.log("This store doesn't exist", error);
          return 0;
        },
      );
  }

  async healthcareCreate(params) {
    const { datasetname, datastorename } = params;
    const files = params.files;
    const setNameExists = await this.createDataSet(datasetname);
    if (setNameExists !== 200) await this.createDataSet(datasetname);

    return {
      files,
      dataset: datasetname,
      go: 200,
      datastore: datastorename,
      stackID: params.stackID,
    };
  }

  async importDICOMFiles(datasetId, dicomStoreId) {
    const projectId = environment.config.gapi.projectname;
    const cloudRegion = environment.config.gapi.location;
    const baseAppUrl = environment.config.storageBucket;
    const gsuri = encodeURI(`gs://${baseAppUrl}/dcmstore/${datasetId}/${dicomStoreId}/**`);
    const url = `${environment.config.healthcareApi}/projects/${projectId}/locations/${cloudRegion}/datasets/${datasetId}/dicomStores/${dicomStoreId}`;

    const handleResult = result => {
      this.uiMessaging_$.toastMessage(`Import ${gsuri} succeeded`, 'INFO');
      return result;
    };

    const handleError = reason => {
      this.uiMessaging_$.toastMessage(`Error importing ${gsuri}: ${reason}`, 'INFO');
      return reason;
    };

    return this.http
      .post(url, { gsuri })
      .toPromise()
      .then(result => handleResult(result))
      .catch(reason => handleError(reason));
  }
}
