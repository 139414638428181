import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-footer',
  template: `
    <div
      class="div"
      fxLayoutAlign="space-around center"
      fxLayout="row">
      <div fxFlex="5">&nbsp;</div>
      <p
        fxFlex="80"
        style="text-align: center; font-size: 13px; color: #e53935;"
        id="profileFont">
        For
        <a
          href="https://nuagedx.com/terms-and-conditions/"
          target="_blank"
          >Terms & Conditions</a
        >
        and
        <a
          href="https://nuagedx.com/privacy-policy/"
          target="_blank"
          >Privacy Policy</a
        >
        <br />
        please visit
        <a
          href="https://nuagedx.com/"
          target="_blank"
          style="color: #e53935"
          >NuageDx</a
        >
      </p>
      <span style="color: pink">{{ getAppVersion() }}</span>
    </div>
  `,
  styles: [
    `
      .div {
        background-color: #232323;
        color: white;
        position: fixed;
        margin: 0;
        bottom: 0;
        padding: 0;
        left: 0;
        width: 100%;
        z-index: 2;
      }

      @media (max-width: 500px) {
        div {
          display: none;
        }
      }

      a:link,
      a:visited {
        color: #d73a31;
      }
    `,
  ],
})
export class FooterComponent {
  constructor(private u$: UtilsService) {}

  getAppVersion() {
    return environment.config.version;
  }
}
