import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-show-studies-dialog',
  templateUrl: './show-studies-dialog.component.html'
})

export class ShowStudiesDialogComponent implements OnInit {
  title: string;
  message: string;
  studylisturl: string;

  constructor (
    private dialogRef: MatDialogRef<ShowStudiesDialogComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) { title, message, studies, studylisturl }
  ) {
    this.title = title;
    this.message = message;
    this.studylisturl = studylisturl;
  }

  ngOnInit() {
  }

  confirm() {
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

}
