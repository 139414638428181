<div
  [ngClass]="'meeting-page-container'"
  *ngIf="!userData">
  <mat-spinner
    style="display: inline-block"
    diameter="20"
    color="warn"
    strokeWidth="5"
    mode="indeterminate">
  </mat-spinner>
  &nbsp;Loading content
</div>
<div
  [ngClass]="'meeting-page-container'"
  *ngIf="userData">
  <h3 class="mat-title">Create a meeting</h3>
  <span *ngIf="!zoomUser && [UserRoles.associate, UserRoles.owner].includes(user.role)"
    ><mat-spinner
      color="warn"
      class="mat-small"
      style="display: inline-block; vertical-align: middle"
      diameter="20"
      strokeWidth="4"></mat-spinner>
    Loading...</span
  >
  <span *ngIf="meetingCreated"
    >Your meeting has been created. Now go and edit it in the calendar of your preference.<br /><br
  /></span>
  &nbsp;
  <button
    mat-flat-button
    [ngClass]="'schedule-btn'"
    *ngIf="zoomUser && !meetingCreated && ![null, 'none', 'Google'].includes(defaultCalendar)"
    color="warn"
    (click)="handleCreateMeeting(zoomUser)">
    SCHEDULE &nbsp;<mat-icon>edit_calendar</mat-icon>
  </button>

  <div
    *ngIf="['Google'].includes(defaultCalendar)"
    fxLayout="row"
    fxLayoutAlign="start center">
    <button
      mat-flat-button
      color="warn"
      class="join-btn"
      *ngIf="googleSignedIn"
      (click)="gapiSignOut()">
      GOOGLE SIGN OUT</button
    >&nbsp;
    <button
      *ngIf="!googleSignedIn"
      (click)="gapiSignIn()"
      class="google-signin-btn"
      type="image"></button>
    <button
      *ngIf="googleSignedIn && zoomUser && !meetingCreated && ['Google'].includes(defaultCalendar)"
      mat-flat-button
      color="warn"
      class="join-btn"
      (click)="handleCreateMeeting(zoomUser)">
      SCHEDULE &nbsp; <mat-icon>edit_calendar</mat-icon></button
    >&nbsp;
    <button
      mat-flat-button
      *ngIf="['Google'].includes(defaultCalendar)"
      color="warn"
      class="join-btn"
      (click)="openCalendar(defaultCalendar)">
      JOIN &nbsp; <mat-icon>event_available</mat-icon>
    </button>
  </div>

  <button
    mat-flat-button
    [ngClass]="'schedule-btn'"
    *ngIf="
      zoomUser &&
      !meetingCreated &&
      ![null, 'none', 'Office365', 'Outlook', 'Clio', 'Google', 'Other'].includes(defaultCalendar)
    "
    color="warn"
    (click)="showZoomMeetingForm()">
    SCHEDULE NUAGEDX MEETING &nbsp;
    <mat-icon>edit_calendar</mat-icon>
  </button>

  <button
    mat-flat-button
    *ngIf="![null, 'none', 'Google'].includes(defaultCalendar)"
    color="warn"
    (click)="openCalendar(defaultCalendar)">
    JOIN &nbsp; <mat-icon>event_available</mat-icon></button
  >&nbsp;<br />

  <br />
  <span *ngIf="zoomUser && ![null, 'none'].includes(defaultCalendar)"> <br /><br /> </span>
  <span *ngIf="['none', null].includes(defaultCalendar)">
    You have no default calendar configured. You need to set up a default calendar
    <a
      href="#"
      (click)="changeDefaultCalendar($event)"
      [ngStyle]="{ color: 'red' }"
      ><b>now</b></a
    >.
  </span>
  <span *ngIf="!['none', null].includes(defaultCalendar)"
    >The default calendar is
    <span [ngStyle]="{ color: 'red' }"
      ><b>{{ defaultCalendar }}</b> </span
    ><span *ngIf="[UserRoles.associate, UserRoles.owner].includes(user.role) && !['Google'].includes(defaultCalendar)"
      >. which will open after you click <b>SCHEDULE</b>.</span
    >
  </span>

  <br /><br />
  <span *ngIf="![null, 'none'].includes(defaultCalendar)"
    >If you want to change the default calendar please click
    <a
      href="#"
      (click)="changeDefaultCalendar($event)"
      [ngStyle]="{ color: 'red' }"
      ><b>here</b></a
    >.
  </span>
</div>
