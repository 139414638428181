import { SessionStorageService } from './../../services/session-storage.service';
import 'firebase/auth';

import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { UIMessagingService } from 'src/app/services/uimessaging.service';
import { LogLevelsDictionary } from '../../dictionaries/LogLevels';
import { UserRoles } from '../../dictionaries/UserRoles';
import { LogService } from '../../log.service';
import { AuthService } from '../../services/auth.service';
import { FirebaseUtilitiesService } from '../../services/firebase-utilities.service';
import { TwoFactorAuthenticationService } from '../../two-factor-authentication.service';
import { FgpasswordComponent } from '../fgpassword/fgpassword.component';

// import {TwoFactorAuthenticationComponent} from '../';

@Component({
  selector: 'app-signin',
  template: `<div
    [class]="hiddenClass"
    fxLayoutAlign="center center">
    <div class="mdc-card mdc-card--outlined signin-card mdc-elevation--z1 element-1">
      <a
        class="signin-logo"
        href="/">
        <img
          class="element-3"
          src="../../assets/Nuage-Logo.png"
          alt />
      </a>
      <form
        fxLayout="column"
        fxLayoutAlign="center center"
        autocomplete="off">
        <!-- Email Text Field -->
        <mat-form-field
          *ngIf="!twofacode_needed"
          class="element-5"
          color="warn"
          appearance="fill">
          <mat-label>Email</mat-label>
          <input
            #emailInput
            [formControl]="email"
            matInput
            type="email"
            class="mdc-text-field__input"
            placeholder="Enter your username (email address)"
            name="email-input"
            autocomplete="new-email-input" />
          <mat-error *ngIf="email.invalid">
            {{ getErrorMessage(email) }}
          </mat-error>
        </mat-form-field>
        <!-- Password Text Field -->
        <mat-form-field
          *ngIf="!twofacode_needed"
          class="element-4"
          color="warn"
          appearance="fill">
          <mat-label>Password</mat-label>
          <input
            #passwordInput
            [formControl]="password"
            [class.password-input]="hide"
            (keydown.enter)="login($event)"
            matInput
            name="password-input"
            type="text"
            class="mdc-text-field__input"
            autocomplete="off" />
          <mat-error *ngIf="password.invalid">
            {{ getErrorMessage(password) }}
          </mat-error>
          <button
            type="button"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
            (click)="hide = !hide"
            mat-icon-button
            matSuffix>
            <mat-icon>
              {{ hide ? 'visibility_off' : 'visibility' }}
            </mat-icon>
          </button>
        </mat-form-field>
        <!-- Submission Button -->
        <button
          *ngIf="!twofacode_needed"
          [disabled]="!email.valid || !password.valid"
          (click)="signIn()"
          mat-flat-button
          class="sign-in-button"
          type="button"
          color="warn">
          <span class="mdc-button__label">LOGIN</span>
        </button>
        <button
          type="button"
          (click)="ssoClio()"
          class="sso-clio-button">
          <img
            title="Clio"
            src="./../../../assets/svg/clio-logo-blue.svg" />
          Sign in with Clio
        </button>
        <h5 class="mat-body error-message">{{ errorMessage }}</h5>
        <!-- Forgot your password Text -->
        <p>
          <a
            (click)="navigateToFGPass()"
            class="fgpass-text-dec">
            Reset your password
          </a>
          &nbsp;
          <a
            (click)="backToHome()"
            class="backtohome-link">
            Back to home.
          </a>
        </p>
      </form>
    </div>
  </div> `,
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  hide = true;
  twofacode: string;
  twofacode_needed: boolean;
  errorMessage: string;
  userSignedIn: boolean;
  hiddenClass: string;
  UserRoles: {
    owner: string;
    admin: string;
    associate: string;
    consultant: string;
    superuser: string;
  };
  dialogConfig: MatDialogConfig;
  dialogRef: any;
  codeValidated: Promise<true | Error>;
  emailFieldDiabled: boolean;
  forbidSignIn = false;
  password = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private router: Router,
    private auth_$: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private sessionStorage_$: SessionStorageService,
    private firebaseUtilities_$: FirebaseUtilitiesService,
    private uiMessaging_$: UIMessagingService,
    private log_$: LogService,
  ) {
    this.emailFieldDiabled = false;
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
    this.userSignedIn = false;
    this.twofacode = '';
    this.twofacode_needed = false;
    this.errorMessage = '';
    window.document.body.focus();
  }

  ngOnInit() {
    this.email.valueChanges.subscribe(r => {
      if (r.trim() !== r) {
        this.email.setValue(r.trim(), { emitEvent: false });
        return;
      }
    });

    this.password.valueChanges.subscribe(r => {
      if (r.trim() !== r) {
        this.password.setValue(r.trim(), { emitEvent: false });
        return;
      }
    });

    this.errorAuthSub();
  }

  backToHome() {
    this.router.navigateByUrl('/');
  }

  errorAuthSub() {
    this.auth_$.errorAuth.subscribe(error => {
      this.errorMessage = error;
    });
  }

  getPrompt2faTitle() {
    return 'Two-factor authentication';
  }

  getPrompt2faMessage() {
    return 'Please enter the code sent to your phone or email.';
  }

  handleCancel(ev) {
    window.location.reload();
  }

  handleConfirm(ev) {
    if (!ev) {
      console.error('The code is incorrect');
      this.auth_$.errorAuth.next('The code is incorrect');
    } else {
      console.log('Ready to signin');
      this.handleLogin().then(r => console.log(r));
    }
  }

  getErrorMessage(formControl) {
    if (formControl.hasError('required')) {
      return 'You must enter a value';
    }

    return formControl.hasError('email') ? 'Not a valid email' : '';
  }

  async login(event) {
    event.preventDefault();
    event.stopPropagation();
    this.hide = true;
    if (this.email.valid && this.password.valid) {
      await this.handleLogin();
      return;
    }
    return;
  }

  handleLoginError(error) {
    console.log(error);
    const { code } = error;
    switch (code) {
      case 'auth/too-many-requests':
        this.uiMessaging_$.toastMessage('Too many requests. Please try again later or reset your password.', 'Error');
        break;
      case 'auth/wrong-password':
        this.uiMessaging_$.toastMessage(
          'There was an error with your credentials. Please check and try again.',
          'Error',
        );
        break;
      default:
        this.uiMessaging_$.toastMessage(
          'There was an error with your credentials. Please check and try again.',
          'Error',
        );
        break;
    }
    return;
  }

  ssoClio() {
    if (this.route.snapshot.queryParams.referer) {
      this.sessionStorage_$.setReferer(this.route.snapshot.queryParams.referer);
    }
    this.router.navigateByUrl(`/login/clio?sso=0`);
  }

  async handleLogin() {
    let user;
    try {
      this.setEmailAndPasswordValues();
      user = await this.auth_$.login(this.email.value.toLowerCase(), this.password.value).catch(error => {
        console.log(error);
      });
    } catch (error) {
      this.handleLoginError(error);
    }
    if (this.auth_$.redirectURL) {
      this.router.navigateByUrl(this.auth_$.redirectURL);
    } else {
      return this.runUserReady(user);
    }
  }

  runUserReady(user) {
    try {
      this.auth_$.userReady(user.user, 'runUserReady 2').then(r => {
        this.log_$.storeLog(this.auth_$.userData.getValue(), LogLevelsDictionary.info, {}, 'Login');
        this.auth_$.checkOwnerPlan();
      });
      return 200;
    } catch (error) {
      return this.handleUserReadyError(error);
    }
  }

  async handle2FAData(email = this.email.value) {
    const res = await this.handleLogin().catch(error => {
      console.log(error);
      this.auth_$.errorAuth.next('There was an error with your credentials. Please check and try again.');
      return;
    });
    if (!res || res.a === null) {
      return;
    }
    // await this.handleTwoFactorSignIn(email);
  }

  handleChange() {
    this.forbidSignIn = false;
  }

  navigateToFGPass() {
    this.dialog.open(FgpasswordComponent, { width: '350px', data: { email: this.email.value } });
  }

  async signIn() {
    this.forbidSignIn = true;
    this.handle2FAData();
    this.route.queryParams.subscribe(params => {
      if (params['referer']) {
        const cloneObject = { ...params };
        delete cloneObject['referer'];
        const url = this.router.createUrlTree([params['referer']], { queryParams: cloneObject });
        this.router.navigateByUrl(url, { replaceUrl: true });
      }
    });
    this.forbidSignIn = false;
  }

  async validateCode(code) {
    return await this.firebaseUtilities_$.validate2FACode(this.auth_$.uid, code, 'email');
  }

  setEmailAndPasswordValues() {
    this.email.setValue(this.email.value.trim().toLowerCase());
    this.password.setValue(this.password.value.trim());
  }

  handleUserReadyError(error) {
    switch (error.code) {
      case 'auth/wrong-password':
        this.auth_$.errorAuth.next(`There was an error with your credentials, please try again or sign up.`);
        break;
      case 'auth/user-not-found':
        this.auth_$.errorAuth.next(`There was an error with your credentials, please try again or sign up.`);
        break;
      default:
        break;
    }
    this.twofacode_needed = false;
    return error;
  }
}
