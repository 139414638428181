import { Component, OnInit } from '@angular/core';

import { environment } from './../environments/environment';
import { ReportService } from './services/report.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'vna';
  maintenance: boolean;

  constructor (
    public rs: ReportService,
  ) { }

  ngOnInit() {
    this.maintenance = environment.maintenance;

    // NOTE: Need to check error generated here before activate it back.
    /* this.rs.getTotalFileUsageSizeForClient('b2cxuGqm9XbUNLn0p8NYwgcJ9y03').then((totalSize) => {
      console.log(totalSize);
    }); */
  }
}
