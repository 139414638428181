import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { PricingClioComponent } from 'src/app/pricing-clio/pricing-clio.component';
import { AuthService } from 'src/app/services/auth.service';
import { RecurlyService } from 'src/app/services/recurly.service';
import { RedirectionService } from 'src/app/services/redirection.service';
import { MatDialog } from '@angular/material/dialog';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { SimpleMessageWindowComponent } from '../../ui/simple-message-window/simple-message-window.component';

@Component({
  selector: 'app-complete-clio-registration',
  templateUrl: './complete-clio-registration.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      #recurly-elements > .recurly-element-card {
        height: 40px;
      }
      .form-actions {
        margin-top: 20px;
      }
      .plan-details {
        font-size: 14px;
      }
    `,
  ],
})
export class CompleteClioRegistrationComponent implements OnInit {
  passwordMessageShown: boolean = false;
  pricingOpened: boolean = false;
  planContent: any;
  currentPlan: string;

  constructor(
    private ngZone: NgZone,
    private redirect_$: RedirectionService,
    private auth_$: AuthService,
    private router: Router,
    private recurly_$: RecurlyService,
    private sessionStorage_$: SessionStorageService,
    private dialog: MatDialog,
  ) {}

  errorMessage = {
    first_name: [
      { type: 'required', message: 'First name is required' },
      { type: 'minlength', message: 'First name must be at least 3 characters long' },
      { type: 'maxlength', message: 'First name cannot be more than 20 characters long' },
      { type: 'pattern', message: 'Your first name must contain only numbers and letters' },
    ],
    last_name: [
      { type: 'required', message: 'Last name is required' },
      { type: 'minlength', message: 'Last name must be at least 3 characters long' },
      { type: 'maxlength', message: 'Last name cannot be more than 20 characters long' },
      { type: 'pattern', message: 'Your last name must contain only numbers and letters' },
    ],
    email: [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Please enter a valid email' },
    ],
    password: [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password must be 6 characters long' },
      { type: 'maxlength', message: 'Password must be 6 characters long' },
    ],
    phone: [
      { type: 'required', message: 'Cell phone number is required' },
      { type: 'pattern', message: 'Please enter a valid cell phone number' },
    ],
    extension: [
      { type: 'required', message: 'Extension is required' },
      { type: 'pattern', message: 'Please enter a valid extension' },
    ],
    companyName: [
      { type: 'required', message: 'Company name is required' },
      { type: 'minlength', message: 'Company name must be at least 3 characters long' },
      { type: 'maxlength', message: 'Company name cannot be more than 20 characters long' },
    ],
    cardNumber: [
      { type: 'required', message: 'Card number is required' },
      { type: 'pattern', message: 'Please enter a valid card number' },
      { type: 'minlength', message: 'Card number must be at least 16 characters long' },
      { type: 'maxlength', message: 'Card number cannot be more than 16 characters long' },
    ],
    expiration: [
      { type: 'required', message: 'Expiration is required' },
      { type: 'pattern', message: 'Please enter a valid expiration' },

      { type: 'minlength', message: 'Expiration must be at least 4 characters long' },
      { type: 'maxlength', message: 'Expiration cannot be more than 4 characters long' },
    ],
    cvv: [
      { type: 'required', message: 'CVV is required' },
      { type: 'pattern', message: 'Please enter a valid CVV' },
      { type: 'minlength', message: 'CVV must be at least 3 characters long' },
      { type: 'maxlength', message: 'CVV cannot be more than 4 characters long' },
    ],
    address: [{ type: 'required', message: 'Address is required' }],
    address2: [{ type: 'required', message: 'Address 2 is required' }],
    state: [
      { type: 'required', message: 'State is required' },
      { type: 'pattern', message: 'Please enter a valid state' },
    ],
    city: [
      { type: 'required', message: 'City is required' },
      { type: 'pattern', message: 'Please enter a valid city' },
    ],
    zip: [
      { type: 'required', message: 'Zip is required' },
      { type: 'pattern', message: 'Please enter a valid zip' },
      { type: 'minlength', message: 'Zip must be at least 3 characters long' },
      { type: 'maxlength', message: 'Zip cannot be more than 5 characters long' },
    ],
  };

  backToPlans() {
    this.openPricingModal();
  }

  completeClioRegistrationForm: FormGroup = new FormGroup({
    first_name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20),
      Validators.pattern('[a-zA-Z ]*'),
    ]),
    last_name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20),
      Validators.pattern('[a-zA-Z ]*'),
    ]),
    recurlyToken: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    recurlyplan: new FormControl('', [Validators.required]),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern('^\\(?([0-9]{3})\\)?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$'),
    ]),
    extension: new FormControl('', [Validators.pattern('[0-9]*')]),
    companyName: new FormControl('', [Validators.minLength(3)]),

    creditCard: new FormGroup({
      address: new FormControl('', [Validators.required]),
      address2: new FormControl(''),
      state: new FormControl('', [
        Validators.required,
        Validators.pattern('[A-Z]*'),
        Validators.minLength(2),
        Validators.maxLength(2),
      ]),
      city: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      zip: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(5)]),
    }),

    terms: new FormControl(false, [Validators.requiredTrue]),
    baa: new FormControl(false, [Validators.requiredTrue]),
  });

  openPricingModal(plancode?: string) {
    if (this.pricingOpened) return;
    const dialog = this.dialog.open(PricingClioComponent, {
      panelClass: 'pricing-modal',
      closeOnNavigation: false,
      disableClose: true,
      data: {
        closable: plancode ? true : false,
        plan: plancode,
      },
    });
    dialog.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log('Choosen plan :', result);
      this.completeClioRegistrationForm.patchValue({
        recurlyplan: result,
      });
      if (this.currentPlan !== result) {
        this.getPlanContent(result);
      }
      this.pricingOpened = false;
    });
    dialog.afterOpened().subscribe(result => {
      console.log('The dialog was opened');
      this.pricingOpened = true;
    });
  }

  getPlanContent(plan: string) {
    if (!plan) return;
    firebase
      .firestore()
      .collection('plans')
      .doc(plan)
      .get()
      .then((doc: any) => {
        if (doc.exists) {
          this.planContent = doc.data().content;
          console.log('this.planContent: ', this.planContent);
          this.currentPlan = plan;
        }
      });
  }

  ngOnInit(): void {
    this.getPlanContent('gold');

    this.auth_$.auth.onAuthStateChanged({
      next: user => {
        if (user) {
          console.log('user :', user);
          this.auth_$.getUserData(user).then((data: any) => {
            if (data) {
              const { email, firstName, lastName, lpm, clioregistered, plancode } = data.docs[0].data();
              const isOpenAppStarted = this.sessionStorage_$.getOpenAppStarted() === 'true';
              const isPlanCodeEmpty = plancode ? plancode === '' : true;

              if (!lpm || lpm.length < 1) {
                this.redirect_$.goToHome('notauthorized');
                return;
              } else {
                this.completeClioRegistrationForm.patchValue({
                  email: email,
                  first_name: firstName,
                  last_name: lastName,
                });

                this.ngZone.run(() => {
                  this.dialog.closeAll();
                  this.openPricingModal(plancode);
                });
              }
            } else {
              console.log('no data');
            }
          });
        }
      },
      error: err => console.log(err),
      complete: () => console.log('complete'),
    });

    this.loadScript();

    console.log(this.completeClioRegistrationForm.get('creditCard').get('cardNumber'));

    // this.auth_$.userData.subscribe(data => {
    //   console.log('user data :', data);
    //   if (Object.keys(data).length === 0) {
    //     this.redirect_$.goToHome('notauthorized');
    //   } else {
    //     this.ngZone.run(() => {
    //       this.dialog.closeAll();
    //       this.openPricingModal();
    //     });
    //   }
    // });
  }

  getErrorMessage(controlname) {
    return this.errorMessage[controlname]
      .filter(error => this.completeClioRegistrationForm.get(controlname).hasError(error.type))
      .map(error => error.message)[0];
  }

  getErrorMessageN(controlnames) {
    return this.errorMessage[controlnames[1]]
      .filter(error => this.completeClioRegistrationForm.get(controlnames[0]).get(controlnames[1]).hasError(error.type))
      .map(error => error.message)[0];
  }

  clean(str) {
    return str.replace(' ', '-').replace(/[^A-Za-z0-9-]/g, '');
  }

  loadScript() {
    const scripts = document.createElement('script');
    scripts.src = 'https://js.recurly.com/v4/recurly.js';
    scripts.type = 'text/javascript';
    scripts.async = true;
    scripts.onload = () => {
      const recurly = window['recurly'];
      recurly.configure('ewr1-hpWVLdx5fGDLtHj2Ekg9go');
      window['elements'] = recurly.Elements();

      const cardElement = window['elements'].CardElement({
        inputType: 'mobileSelect',
        style: {
          fontSize: '1em',
          placeholder: {
            content: {
              number: 'Card number',
              cvv: 'CVC',
            },
          },
          invalid: {
            fontColor: 'red',
          },
        },
      });
      cardElement.attach('#recurly-elements');
    };
    document.getElementsByTagName('head')[0].appendChild(scripts);
  }

  updateUserRecurlyCode(code) {
    return this.recurly_$.updateUserRecurlyCode(this.auth_$.userData.value['id'], code);
  }

  async handleSuccessfulSubscriptionCreation(data) {
    this.auth_$.showLoader('Updating your account...');
    const { code } = data.account;
    this.updateUserRecurlyCode(code);
    console.log('Your subscription is active!');
    this.sessionStorage_$.setClioRegistered(true);
    this.auth_$
      .userReady(this.auth_$.userData.getValue()['user'], 'handleSuccessfulSubscriptionCreation')
      .then(async () => {
        const newValue = true;
        await this.auth_$
          .updateUserClioRegistered(newValue)
          .catch(err => console.log('err :', err))
          .then(res => console.log('Clio Registered updated res :', res));
        this.auth_$.userData.next({ ...this.auth_$.userData.getValue(), clioregistered: newValue });
        this.auth_$.userReady(this.auth_$.user, 'handleSuccessfulSubscriptionCreation 2');
        this.auth_$.hideLoader();
        this.redirectToWelcomPage();
      });
  }

  redirectToWelcomPage() {
    this.dialog.closeAll();
    this.router.navigate(['lpmwelcome']);
  }

  redirectToHome() {
    this.router.navigate(['/']);
  }

  onSubmit() {
    const recurly = window['recurly'];
    const elements = window['elements'];
    this.auth_$.showLoader('Updating your payment information...');
    recurly.token(elements, document.querySelector('form'), (err, token) => {
      if (err) {
        console.log('err :', err);
        return;
      }
      console.log('token :', token);
      this.completeClioRegistrationForm.patchValue({ recurlyToken: token });
      console.log(this.completeClioRegistrationForm.value);
      firebase
        .functions()
        .httpsCallable('recurly-handleSubscriptionCreation')(this.completeClioRegistrationForm.value)
        .then(res => {
          console.log(res);
          if (res.data.error) {
            this.auth_$.setToastMessage(res.data.error, 'ERROR');
            this.auth_$.hideLoader();
          } else {
            this.handleSuccessfulSubscriptionCreation(res.data);
            this.auth_$.hideLoader();
          }
        });
    });
  }

  showPasswordMessage() {
    if (this.passwordMessageShown) return;
    this.passwordMessageShown = true;
    this.dialog.open(SimpleMessageWindowComponent, {
      width: '400px',
      data: {
        title: 'Password Tip',
        message: `Your password must be simple and easy to remember. It must be 6 characters long. This password will be used to login to the Medical Images Viewer.`,
      },
    });
  }
}
