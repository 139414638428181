import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import firebase from 'firebase/app';
import { environment } from 'src/environments/environment';
import { UIMessagingService } from '../services/uimessaging.service';

import { SimpleMessageWindowComponent } from './../components/ui/simple-message-window/simple-message-window.component';
import { PromptUser2FACodeComponent } from './../prompt-user2-facode/prompt-user2-facode.component';
import { AuthService } from './../services/auth.service';
import { FirebaseUtilitiesService } from './../services/firebase-utilities.service';
import { UtilsService } from './../services/utils.service';
import { SmsService } from './../sms.service';

@Component({
  templateUrl: './two-factor-auth-setup-dialog.component.html',
  styleUrls: ['./two-factor-auth-setup-dialog.component.scss'],
})
export class TwoFactorAuthSetupDialogComponent implements OnInit {
  sms_twofacode_needed = false;
  email_twofacode_needed = false;
  favoriteMethod: string;
  methods: string[] = ['SMS', 'Email'];
  sendCodeByEmailBtnDisabled = false;
  phoneNumber = '';
  codeRequiredValue: string;
  codeRequired = false;
  emailAddress: any;
  SMSForm: FormGroup;
  dialogRef: MatDialogRef<PromptUser2FACodeComponent, any>;
  dialogConfig: MatDialogConfig;
  fc = firebase.functions();
  twofacode_needed: boolean;
  method: any;
  constructor(
    private auth_$: AuthService,
    private utilities_$: UtilsService,
    private http: HttpClient,
    private sms_$: SmsService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private firebaseUtilities_$: FirebaseUtilitiesService,
    public _dialogRef: MatDialogRef<TwoFactorAuthSetupDialogComponent>,
    private uiMessaging_$: UIMessagingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.twofacode_needed = false;
    this.SMSForm = this.fb.group({
      phoneNumber: new FormControl(this.phoneNumber, [Validators.required]),
    });
  }

  getErrorMessage(formControl, controlname: string, num: string = '0') {
    if (formControl.invalid) {
      if (formControl.errors.required) {
        return controlname === 'usertype' ? `You need to select a user type` : `You need to provide a ${controlname}.`;
      }
      if (formControl.errors.minlength) {
        return `The field ${controlname} requires at least ${formControl.errors.requiredLength} characters length.`;
      }
      if (formControl.errors.email) {
        return `A valid email address is required.`;
      }
    } else {
      return;
    }
  }

  getPhoneNumberFromUserInput() {
    this.phoneNumber = this.SMSForm.controls.phoneNumber.value;
    return this.phoneNumber;
  }

  getPrompt2faEmail() {
    return this.emailAddress;
  }

  getPrompt2faMessage() {
    return 'Please check your inbox and enter the code here.';
  }

  getPrompt2faTitle() {
    return 'Two-factor Authentication';
  }

  getMethod() {
    return this.method;
  }

  handleCancel(ev) {
    this._dialogRef.close('cancel');
  }

  handleConfirm(ev) {
    if (ev === 1) {
      const message = `You Two-factor Authentication has been set.`;
      const action = '2FA';
      this.uiMessaging_$.toastMessage(message, action);

      this.dialogConfig = {
        width: '350px',
        data: {
          title: 'Thank you!!',
          message:
            '<p>You have set up your two factor authentication successfully.</p>' +
            '<p>The next time you login, you will be prompted to receive your validation code by <b>email</b> or <b>sms</b></p>' +
            '<p>Finally, you will be prompted to verify the code you received in order to continue using the app</p>',
        },
      };
      this.dialog.open(SimpleMessageWindowComponent, this.dialogConfig);
    }
  }

  handleSendNewCode(ev) {}

  handleOptionChange(event) {
    this.method = event.value === 'Email' ? 'email' : 'sms';
  }

  onCountrySelected(event) {
    console.log('Country selected');
  }

  onSubmit() {
    return;
  }

  promptForCode(type?: string) {
    console.log('promptForCode');
    this.dialogConfig = {
      height: 'auto',
      disableClose: true,
      data: {
        title: 'Two-factor Authentication',
        message: 'Please check your inbox and enter the code here.',
        email: this.emailAddress,
      },
    };
    type === 'sms' ? (this.sms_twofacode_needed = true) : (this.email_twofacode_needed = true);
  }

  async sendCodeBySMS(phoneNumber: string) {
    console.log('phoneNumber: ', phoneNumber);
    const validPhoneNumber = await this.firebaseUtilities_$.validatePhoneNumberByUserID(this.auth_$.uid, phoneNumber);
    let message;

    if (!validPhoneNumber) {
      message = `This phone number is already taken by other ` + `user or it's not a valid phone number`;
      const action = `VALIDATE PHONE NUMBER`;
      this.uiMessaging_$.toastMessage(message, action);
      return false;
    }

    this.generateCodeAndSendSMS(phoneNumber);
  }

  generateCodeAndSendSMS(phoneNumber: string) {
    const code = this.utilities_$.generateValidationCode();
    this.firebaseUtilities_$.setUserCode(code, 'phone', this.data.email);
    this.sendSMS(code, '+1' + phoneNumber);
  }

  sendSMS(code: number, phoneNumber: string) {
    const message = `NuagedX has sent you a CODE: ${code}`;
    this.sms_$.sendSMS(message, phoneNumber).subscribe({
      next: res => {
        if (res['data']['status'] === 200) {
          console.log('RES:', res);
          // this.promptForCode();
        }
      },
      complete: () => {
        console.log('Completed SMS sending');
        this.promptForCode('sms');
      },
      error: err => {
        console.error(err);
      },
    });
  }

  /**
   * Validates email structure and uniqueness.
   */
  async validateEmail(email: string): Promise<boolean> {
    const thisUserEmail = this.data.email;
    let validEmail: boolean, message: string;
    const validMessage = 'The email address is valid';

    if (email === thisUserEmail) {
      validEmail = true;
      message = validMessage;
    } else if (!this.utilities_$.validateEmail(email)) {
      message = `This email address is invalid`;
      validEmail = false;
    } else {
      // NOTE: Validate this email address is not taken by other user.
      validEmail = await this.firebaseUtilities_$.validateEmailAlreadyExists(email);
      message = validEmail ? validMessage : 'The email address is already taken.';
    }
    this.uiMessaging_$.toastMessage(message, 'VALIDATE EMAIL');
    return validEmail;
  }

  sendEmail(code, email) {
    const url = `${environment.constants.cloudfunctionsURL}email-simpleEmail`;
    const dest = [email].join(',');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'secret-key',
      }),
    };

    const body =
      `<h2>Please confirm</h2>` +
      `<p>Use the following code to confirm your Two-factor Authentication (2FA):</p>` +
      `<strong>${code}</strong>` +
      `<p>There is a prompt input where you have to paste/type this code.</p>`;
    const subject = `NuagedX has sent you a verification code.`;

    return this.http.post(url, { dest, body, subject }, httpOptions).subscribe({
      next: res => {
        if (res['data']['status'] === 200) {
          this.promptForCode('email');
        }
      },
      complete: () => {
        console.log('Completed');
      },
      error: err => {
        console.error(err);
      },
    });
  }

  async validateCode(code) {
    await this.firebaseUtilities_$.validate2FACode(this.auth_$.uid, code, 'email');
  }

  validateCodeAndGo() {
    const code = (<HTMLInputElement>document.querySelector('#validateCodeInput')).value;
    console.log('code: ', code);
  }
}
