<div [ngClass]="'active-consultants-container'">
  <mat-selection-list #consultants [multiple]="false">
    <mat-list-option *ngFor="let consultant of activeConsultantsList" [value]="consultant" #consultantOption
      [ngClass]="{ 'small-font': true }" (click)="handleOptionClick(consultant)">
      <div fxLayout="row">
        <div fxLayout="column">
          <div [ngClass]="'mat-line small-font'">
            <b>{{ consultant.name }}</b>
          </div>
          <div [ngClass]="'mat-line small-font'">
            {{ consultant.email }}
          </div>
        </div>

        <mat-icon *ngIf="consultant.highlighted" aria-hidden="false" color="warn" [ngClass]="'file-icon'"
          aria-label="The file is shared with this consultant." matTooltip="The file is shared with this consultant.">
          people</mat-icon>

        <button mat-flat-button color="warn" [ngClass]="'small-font'" fxFlexOffset="4"
          (click)="$event.stopPropagation(); unShare(consultant)"
          matTooltip="Stop sharing selected files with this consultant."
          *ngIf="consultantOption.selected && checkSelected(consultant)">
          Unshare all selected
        </button>

        <button mat-flat-button color="warn" matTooltip="Share selected files with this consultant."
          [ngClass]="'small-font'" fxFlexOffset="4" (click)="$event.stopPropagation(); share(consultant)"
          *ngIf="consultantOption.selected && selectedFiles.length > 1 && !checkSelected(consultant)">
          Share all selected
        </button>
      </div>
      <mat-divider></mat-divider>
    </mat-list-option>
  </mat-selection-list>
  <p *ngIf="activeConsultantsList.length === 0" [ngClass]="['small-font', 'margin-left-20']">
    There are <b>no active consultants</b> for this client.
  </p>
</div>
