import 'firebase/firestore';

import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { UserRoles } from 'src/app/dictionaries/UserRoles';

import { AuthService } from '../../services/auth.service';
import { UIMessagingService } from '../../services/uimessaging.service';
import { ConsultantByClientComponent } from '../consultant-by-client/consultant-by-client.component';
import { FirebaseUtilitiesService } from './../../services/firebase-utilities.service';

declare function require(name: string);
const firestore = firebase.firestore();

@Component({
  templateUrl: './allusers.component.html',
  styles: [
    `
      .mdc-dialog__surface {
        min-width: 400px;
      }

      .mdc-list-item > div {
        width: 100%;
      }

      .list-item {
        border-bottom: 1px solid #e2e2e2;
        padding-bottom: 5px;
        padding-top: 4px;
      }

      h4.mat-title {
        margin: 0;
      }

      .close-button {
        margin-right: 0px;
      }

      .div-container {
        width: 80%;
        margin-right: 10px;
      }

      .div-container-2 {
        margin-top: 5px;
      }

      .div-container-3 {
        padding: 25px;
      }

      .div-container-4 {
        display: flex;
        flex-direction: row;
      }

      .submission-button {
        display: none;
      }

      .title-container {
        color: red;
      }

      .icon-item {
        margin-right: 20px;
      }
    `,
  ],
})
export class AllusersComponent implements OnInit {
  dbUsers = firestore.collection('users');
  allUsers: any[] = [];
  currentUser: any;
  userInfoDiag: any;
  deleteConfirm: any;
  roles = [UserRoles.client, UserRoles.admin, UserRoles.consultant, UserRoles.client];
  roleSelected: string;
  clientId: string;
  currentSignedInUser: firebase.User;
  currentSignedInUserRole: string;
  searchCriteria: string;
  cachedUsers: any[] = [];
  userPermissions: any;
  selection = new SelectionModel<any>(true, []);
  @Input() dataFromParent: any = {
    role: '',
    clientid: '',
    close: true,
    search: true,
    title: '',
    subtitle: '',
    files: [],
  };
  close: boolean;
  search: boolean;
  subtitle: string;
  title: string;
  filesTableDataSource: any;
  files: any;
  loader: any;
  clientfiles: any;
  consultants: any;
  UserRoles: { owner: string; admin: string; associate: string; consultant: string; superuser: string; client: string };
  deleteadmin = false;

  constructor(
    public snackBar: MatSnackBar,
    private firebase_utilities_$: FirebaseUtilitiesService,
    public auth$: AuthService,
    public router: Router,
    public ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private uiMessaging_$: UIMessagingService,
    public dialogRef: MatDialogRef<AllusersComponent>,
    private auth_$: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      client: UserRoles.client,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
    this.roleSelected = data.role;
    this.searchCriteria = '';
  }

  ngOnInit() {
    if (this.dataFromParent.role !== '') {
      this.roleSelected = this.dataFromParent.role;
    }
    if (this.dataFromParent.clientid !== '') {
      this.clientId = this.dataFromParent.clientid;
    }

    this.close = this.dataFromParent.close;
    this.search = this.dataFromParent.search;
    this.title = this.dataFromParent.title;
    this.subtitle = this.dataFromParent.subtitle;
    this.clientfiles = this.dataFromParent.files;
    this.consultants = this.dataFromParent.consultants;

    this.auth_$.userData.subscribe({
      next: userData => {
        if (!userData['permissions']) {
          return;
        }
        this.userPermissions = userData['permissions'];
        this.deleteadmin = this.validatePermission('deleteadmin');
      },
    });

    this.auth_$.userData.subscribe(user => {
      if (Object.keys(user).length && user !== null && user !== undefined) {
        this.currentSignedInUser = this.auth_$.currentUserSignedIn;
        this.dbUsers
          .where('uid', '==', user['uid'])
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(async doc => {
              if (doc.data().uid === user['uid']) {
                this.currentSignedInUserRole = doc.data()['role'];
                if (this.clientId) {
                  await this.getAllUsersByClientId('Consultant', this.clientId);
                } else {
                  await this.getAllUsers(this.roleSelected);
                }
              }
            });
          })
          .catch(error => {
            console.log('Error getting documents: ', error);
          });
      }
    });
  }

  validatePermission(permission: string): boolean {
    const userRole = this.auth_$.userData.getValue()['role'];
    if (!this.userPermissions || this.userPermissions.length === 0) {
      console.log('No permissions found yet.');
      return false;
    }
    const filteredPermissions = this.userPermissions
      .filter(p => p.role === userRole)
      .filter(p => p.object === permission);
    return filteredPermissions[0].access;
  }

  async getCreatedBy(uid: string) {
    return this.dbUsers
      .where('uid', '==', uid)
      .get()
      .then(querySnapshot => {
        return querySnapshot.docs[0].data().createdBy;
      });
  }

  async getOwnerID(uid: string) {
    return this.dbUsers
      .where('uid', '==', uid)
      .get()
      .then(querySnapshot => {
        return querySnapshot.docs[0].data().ownerID;
      });
  }

  // NOTE: Get all user from the same OwnerID.
  async getUserRole(uid: string) {
    return this.dbUsers
      .where('uid', '==', uid)
      .get()
      .then(querySnapshot => {
        return querySnapshot.docs[0].data().role;
      });
  }

  async getAllUsers(role?: string) {
    let ownerID = null;
    if (role) {
      const sUR = this.auth_$.userData.getValue()['role'];
      ownerID =
        sUR !== UserRoles.owner ? this.auth$.userData.getValue()['ownerID'] : this.auth_$.userData.getValue()['uid'];
    }

    let allowedUsers = [];
    if (role) {
      allowedUsers.push(role);
    } else {
      allowedUsers = [UserRoles.admin, UserRoles.associate, UserRoles.owner, UserRoles.client, UserRoles.superuser];
    }

    for (let index = 0; index < allowedUsers.length; index++) {
      const userType = allowedUsers[index];
      const users =
        role === UserRoles.owner
          ? await this.dbUsers
              .where('role', '==', UserRoles.owner)
              .where('createdBy', '==', this.auth_$.userData.getValue()['uid'])
              .where('disabled', '==', false)
              .get()
          : await this.dbUsers.where('role', '==', userType).where('ownerID', '==', ownerID).get();

      this.allUsers = this.cachedUsers = users.docs
        .map(doc => doc.data())
        .filter(user => [undefined, false].includes(user.disabled));
      this.ref.detectChanges();
    }
  }

  async getAllUsersByClientId(role?: string, clientId?: string) {
    let ownerID = null;
    if (role) {
      const sUR = await this.getUserRole(this.auth_$.userData.getValue()['uid']);
      if (sUR !== UserRoles.owner) {
        ownerID = await this.getOwnerID(this.auth_$.userData.getValue()['uid']);
      } else {
        ownerID = this.auth_$.userData.getValue()['uid'];
      }
    }

    let allowedUsers = [];
    if (role) {
      allowedUsers.push(role);
    } else {
      allowedUsers = ['Consultant'];
    }

    const entries = [];

    allowedUsers.forEach(userType => {
      this.dbUsers
        .where('role', '==', userType)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const docData = doc.data();
            if (docData.filesSharedWith) {
              docData.filesSharedWith.forEach(item => {
                if (item.patientCaseName === this.clientId) {
                  if (entries.indexOf(docData.email.toLowerCase()) === -1) {
                    entries.push(docData.email.toLowerCase());
                    this.allUsers.push(docData);
                  }
                }
              });
            }
          });
        });
      this.cachedUsers = this.allUsers;
      this.ref.detectChanges();
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.files.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.files.forEach(row => this.selection.select(row));
  }

  async getFileName(fileId: string) {
    return await this.firebase_utilities_$.getFileNameByFileId(fileId);
  }

  async getPatientName(caseId: string) {
    const { FirstName, LastName } = await this.firebase_utilities_$.getPatientNameByCaseName(caseId);
    return `${FirstName} ${LastName}`;
  }

  distinctFilesByCaseName(files: [{ fileId: string; patientCaseName: string }]) {
    return files.filter(
      (value, index, self) => self.findIndex(m => m.patientCaseName === value.patientCaseName) === index,
    );
  }

  async openUser(user: any) {
    if (user.filesSharedWith && user.filesSharedWith.length) {
      user.filesSharedWith = this.distinctFilesByCaseName(user.filesSharedWith);
      for (let index = 0; index < user.filesSharedWith.length; index++) {
        const element = user.filesSharedWith[index];
        element.fileName = await this.getFileName(element.fileId);
        element.patientName = await this.getPatientName(element.patientCaseName);
        if (!element.fileName) {
          element.fileName = `(X)`;
          console.error('There is an error with this file', element.fileId);
        }
      }
    } else {
      user.filesSharedWith = [];
    }

    this.currentUser = user;
    const mdcDialog = require('@material/dialog');
    const MDCDialog = mdcDialog.MDCDialog;
    this.roleSelected = user.role;

    if (!this.dataFromParent.clientid) {
      this.userInfoDiag = new MDCDialog(document.querySelector('.userdiag'));
      this.userInfoDiag.open();
      this.ref.detectChanges();
    } else {
      this.userInfoDiag = this.dialog.open(ConsultantByClientComponent, {
        width: '850px',
        data: {
          clientfiles: this.dataFromParent.files,
          currentUser: this.currentUser,
          casename: this.dataFromParent.clientid,
        },
      });
      this.ref.detectChanges();
    }
  }

  closeModal() {
    this.userInfoDiag.close();
  }

  openDeleteConfirmDialog() {
    const mdcDialog = require('@material/dialog');
    const MDCDialog = mdcDialog.MDCDialog;
    this.deleteConfirm = new MDCDialog(document.querySelector('.deleteconfirm'));
    this.deleteConfirm.open();
  }

  async markDeleted(uid: string) {
    this.auth$.showLoader('Mark as deleted...');
    const userDoc = await (await this.dbUsers.where('uid', '==', uid).get()).docs[0];
    const userData = userDoc.data();
    const { email } = userData;
    const data = (await firestore.collection('files').where('forDelete', '==', false).get()).docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));

    const promises = [];

    data.forEach(file => {
      const sharedUsers = file['sharedUsers'];
      if (sharedUsers && sharedUsers.length > 0) {
        const found = sharedUsers.map(user => user.email).indexOf(email);
        if (found > -1) {
          const docId = file.id;
          const arrayOfUsers = sharedUsers;
          arrayOfUsers.splice(found, 1);
          promises.push(firebase.firestore().collection('files').doc(docId).update({ sharedUsers: arrayOfUsers }));
        }
      }
    });

    const result = await Promise.all(promises);

    await this.updateDocDeleted(userDoc.id, userDoc.data().email.toString());
    this.auth$.hideLoader();
  }

  afterDeleteUser() {
    this.uiMessaging_$.toastMessage('User deleted', 'DONE');
    this.deleteConfirm.close();
    this.userInfoDiag.close();
    if (this.clientId) {
      this.getAllUsersByClientId('Consultant', this.clientId);
    } else {
      this.getAllUsers(this.roleSelected);
    }
  }

  async callCloudFunction(functionName: string, param: string) {
    switch (functionName) {
      case 'deleteUserByEmail':
        const userEmail = param;
        await firebase
          .functions()
          .httpsCallable('admin-deleteUserByEmail')({ userEmail: userEmail })
          .then(result => {
            console.log('Success', result);
            if (result.data.status === 200) {
              this.afterDeleteUser();
            }
          })
          .catch(reason => {
            console.log(reason);
          });
        break;
      default:
        break;
    }
  }

  async updateDocDeleted(docID: string, userEmail: string) {
    await this.dbUsers
      .doc(docID)
      .update({ disabled: true })
      .catch(err => console.log(err));
    return this.callCloudFunction('deleteUserByEmail', userEmail).then(result => {
      console.log('User > deleted', result);
    });
  }

  updateRole(uid: string) {
    let docID = '';
    this.dbUsers
      .where('uid', '==', uid)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          docID = doc.id;
        });
        this.updateRoleWithDocID(docID);
      });
  }

  updateRoleWithDocID(docId: string) {
    this.dbUsers
      .doc(docId)
      .update({
        role: this.roleSelected,
      })
      .then(() => {
        this.uiMessaging_$.toastMessage('Role updated to ' + this.roleSelected, null);
        // FIXME: Need a reload here.
      });
  }

  goBack() {
    window.history.back();
  }

  filterUsers(event) {
    document.getElementById('cancelSearch').style.display = 'block';
    if (this.searchCriteria === '') {
      this.cancelSearch();
    } else {
      this.allUsers = this.allUsers.filter(user => user.name.toLowerCase().includes(this.searchCriteria.toLowerCase()));
    }
  }

  cancelSearch() {
    document.getElementById('cancelSearch').style.display = 'none';
    this.allUsers = this.cachedUsers;
  }
}
