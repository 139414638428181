import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'app-practicepantherlistdocuments',
  templateUrl: './PracticePantherListDocuments.component.html',
  styleUrls: ['./PracticePantherListDocuments.component.scss']
})
export class PracticePantherListDocumentsComponent implements OnInit {
  @Input() documentsList = [];
  @Input() selectedMatter: any;
  @ViewChild('matSelectionList') matSelectionList: MatSelectionList;
  @Output() imporEvent = new EventEmitter<any>();
  importButtonsEnabled = true;

  constructor () { }

  ngOnInit() { }

  importSelectedDocuments() {
    this.imporEvent.emit(this.matSelectionList.selectedOptions.selected);
  }
  importAllDocuments() {
    this.importButtonsEnabled = false;
    this.matSelectionList.selectAll();
    this.importSelectedDocuments();
  }

  handleChange(obj) {
    console.log(obj);
  }

}
