import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { AuthService } from './../../services/auth.service';
import Validation from './../providers/CustomValidators';

@Component({
  selector: 'app-update-user-password',
  templateUrl: './update-user-password.component.html',
  styleUrls: ['./update-user-password.component.scss']
})

export class UpdateUserPasswordComponent implements OnInit {
  submitted = false;
  success = '';
  updateUserPasswordForm: FormGroup;
  passwordStrength: any;

  constructor (
    private dialog: MatDialogRef<UpdateUserPasswordComponent>,
    private auth$: AuthService
  ) { }

  checkError(formControl, controlName: string, errorName?: string) {
    if (errorName) {
      return formControl.controls[controlName].hasError(errorName);
    } else {
      console.log(formControl.controls[controlName]);
      return;
    }
  }

  get f(): { [key: string]: AbstractControl } { return this.updateUserPasswordForm.controls; }

  onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.updateUserPasswordForm.invalid) {
      console.log('the form :', this.updateUserPasswordForm);
      return;
    }

    const { currentPasswordFormControl, newPasswordFormControl } = this.updateUserPasswordForm.value;
    this.updatePassword(currentPasswordFormControl, newPasswordFormControl);
  }

  ngOnInit() {
    this.updateUserPasswordForm = new FormGroup({
      currentPasswordFormControl: new FormControl('', [Validators.required]),
      confirmPasswordFormControl: new FormControl('', [Validators.required, Validators.minLength(6)]),
      newPasswordFormControl: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        () => Validation.passwordStrength(this.passwordStrength)
      ]),
    },
      Validation.match('newPasswordFormControl', 'confirmPasswordFormControl'),
    );
  }

  onStrengthChanged(event) {
    this.passwordStrength = event;
    this.updateUserPasswordForm.controls['newPasswordFormControl'].updateValueAndValidity();
    // this.submitApproval = (event === 100 &&
    // this.clientForm.controls.passwordconfirm.value === this.clientForm.controls.password.value) ?true : false;
  }

  handleLogOut() {
    this.auth$.logout();
  }

  updatePassword(oldPasswordValue, passwordValue) {
    this.dialog.close({ oldPassword: oldPasswordValue, newPassword: passwordValue });
  }

}
