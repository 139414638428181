<mat-drawer-container
  class="example-container"
  autosize
  [hasBackdrop]="true">
  <mat-drawer
    style="border-top: red 5px solid"
    #drawer
    mode="push">
    <div
      class="mdc-drawer__header"
      *ngIf="userData">
      <h3 class="mdc-drawer__title">{{ userData.userName }}</h3>
      <h6 class="mdc-drawer__subtitle userdata-email">
        {{ userData.userEmail }}
      </h6>
      <h6 class="mdc-drawer__subtitle">
        <button
          *ngIf="userData.userDBRole === UserRoles.owner"
          mat-icon-button
          matTooltip="Edit user."
          aria-label="Edit profile"
          class="small-button"
          (click)="editProfile($event)">
          <mat-icon>manage_accounts</mat-icon>
        </button>
        {{ userData.userDBRole }}
        <a
          *ngIf="userData.lpm && userData.lpm === 'clio' && userData.userDBRole !== UserRoles.consultant"
          rel="noopener noreferrer"
          href="https://app.clio.com/nc/#/"
          target="_self"
          class="clio-logo"
          title="Clio Logo">
          <img
            src="./../../../assets/png/clio-blue-square.png"
            alt="Clio logo" />
          Back to Clio
        </a>
      </h6>
    </div>
    <mat-list
      *ngIf="userData"
      [ngClass]="'sidebar-menu-list'"
      role="list">
      <div id="usersMenu"></div>
      <mat-list-item
        [disabled]="!activateMenuItems"
        *ngIf="userData.userDBRole === UserRoles.client">
        <a
          fxLayout="row"
          (click)="openClient()">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >account_circle</i
          >
          <span class="mdc-list-item__text">Client/Matter Profile</span>
        </a>
      </mat-list-item>
      <mat-list-item
        [disabled]="!activateMenuItems"
        *ngIf="![UserRoles.superuser, UserRoles.client].includes(userData.userDBRole)">
        <a
          fxLayout="row"
          (click)="navigateToClient()">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >account_circle</i
          >
          <span class="mdc-list-item__text">Clients/Matters</span>
        </a>
      </mat-list-item>
      <mat-list-item
        id="reportGen"
        [disabled]="!activateMenuItems"
        *ngIf="userData.userDBRole === UserRoles.superuser">
        <a
          (click)="navigateGenerateReport()"
          fxLayout="row">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >insert_drive_file</i
          >
          <span class="mdc-list-item__text">Generate Report</span>
        </a>
      </mat-list-item>

      <mat-list-item
        id="buttonSectionIdent"
        [disabled]="!activateMenuItems"
        *ngIf="userData.userDBRole === UserRoles.superuser">
        <a
          fxLayout="row"
          (click)="createUser(UserRoles.owner)">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >perm_identity</i
          >
          <span class="mdc-list-item__text">Create Owner</span>
        </a>
      </mat-list-item>
      <mat-list-item
        id="buttonSectionIdent"
        [disabled]="!activateMenuItems"
        *ngIf="![UserRoles.superuser, UserRoles.consultant, UserRoles.client].includes(userData.userDBRole)">
        <a
          fxLayout="row"
          (click)="navigateToAdmin()">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >perm_identity</i
          >
          <span class="mdc-list-item__text">Admin List</span>
        </a>
      </mat-list-item>
      <mat-list-item
        id="buttonSectionIdent"
        [disabled]="!activateMenuItems"
        *ngIf="![UserRoles.superuser, UserRoles.consultant, UserRoles.client].includes(userData.userDBRole)">
        <a
          fxLayout="row"
          (click)="navigateToClientUsers()">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >perm_identity</i
          >
          <span class="mdc-list-item__text">Clients List</span>
        </a>
      </mat-list-item>
      <mat-list-item
        [disabled]="!activateMenuItems"
        id="buttonSectionIdent"
        *ngIf="[UserRoles.superuser].includes(userData.userDBRole)">
        <a
          fxLayout="row"
          (click)="navigateToOwner()">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >perm_identity</i
          >
          <span class="mdc-list-item__text">Owners List</span>
        </a>
      </mat-list-item>
      <mat-list-item
        id="buttonSectionIdent"
        [disabled]="!activateMenuItems"
        *ngIf="![UserRoles.superuser, UserRoles.consultant, UserRoles.client].includes(userData.userDBRole)">
        <a
          fxLayout="row"
          (click)="navigateToConsultant()">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >perm_identity</i
          >
          <span class="mdc-list-item__text">Consultant List</span>
        </a>
      </mat-list-item>
      <mat-list-item
        id="buttonSectionIdent"
        [disabled]="!activateMenuItems"
        *ngIf="![UserRoles.superuser, UserRoles.consultant, UserRoles.client].includes(userData.userDBRole)">
        <a
          fxLayout="row"
          (click)="navigateToAssociate()">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >perm_identity</i
          >
          <span class="mdc-list-item__text">Associate List</span>
        </a>
      </mat-list-item>
      <mat-list-item
        [disabled]="!activateMenuItems"
        *ngIf="(userPermissions && createuser) || [UserRoles.owner].includes(userData.userDBRole)">
        <a
          fxLayout="row"
          (click)="createUser('client')">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >accessibility</i
          >
          <span class="mdc-list-item__text">Create Client/Matter</span>
        </a>
      </mat-list-item>
      <mat-list-item
        [disabled]="!activateMenuItems"
        *ngIf="
          ![UserRoles.superuser, UserRoles.consultant, UserRoles.client, UserRoles.admin].includes(userData.userDBRole)
        ">
        <a
          fxLayout="row"
          (click)="createUser('admin')">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >accessibility</i
          >
          <span class="mdc-list-item__text">Create User</span>
        </a>
      </mat-list-item>
      <mat-list-item
        [disabled]="!activateMenuItems"
        *ngIf="
          ![UserRoles.admin, UserRoles.consultant, UserRoles.superuser, UserRoles.client].includes(userData.userDBRole)
        ">
        <a
          fxLayout="row"
          (click)="VideoConferencing()">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >video_call</i
          >
          <span class="mdc-list-item__text">Video Conferencing</span>
        </a>
      </mat-list-item>
      <mat-list-item
        [disabled]="!activateMenuItems"
        *ngIf="userData.userDBRole === UserRoles.owner && userData.plancode !== 'platinumyearly'">
        <a
          fxLayout="row"
          (click)="upgradeYourPlan()">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >upgrade</i
          >
          <span class="mdc-list-item__text">Upgrade your plan</span>
        </a>
      </mat-list-item>
      <mat-list-item
        [disabled]="!activateMenuItems"
        *ngIf="userData.userDBRole === UserRoles.owner">
        <a
          fxLayout="row"
          (click)="openAuditLog()">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >timeline</i
          >
          <span class="mdc-list-item__text">Audit Log</span>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a
          fxLayout="row"
          name="logout"
          (click)="auth_$.logout()">
          <i
            class="material-icons mdc-list-item__graphic"
            aria-hidden="true"
            >power_settings_new</i
          >
          <span class="mdc-list-item__text">Logout</span>
        </a>
      </mat-list-item>
      <div id="ze-snippet-container"></div>
    </mat-list>
  </mat-drawer>

  <mat-drawer-content>
    <header
      [ngClass]="['mdc-top-app-bar', 'header-bar']"
      id="app-bar">
      <div class="mdc-top-app-bar__row drawer">
        <section [ngClass]="['mdc-top-app-bar__section', 'mdc-top-app-bar__section--align-start']">
          <button
            *ngIf="userEnabled"
            class="mdc-button mdc-top-app-bar__navigation-icon"
            id="nuageMenu"
            [ngClass]="{ hidden: maintenance }"
            (click)="drawer.toggle()">
            <span class="mdc-button__label">
              <span class="material-icons menu">menu</span>
            </span>
          </button>

          <span
            *ngIf="userData && loaded"
            class="mdc-top-app-bar__title">
            <a href="/">
              <img
                title="Nuage"
                class="image-size"
                src="../../assets/Nuage-Logo.png" />
            </a>
          </span>
        </section>

        <a
          *ngIf="noUserData && this.currentUrl !== '/login'"
          color="warn"
          class="gotologin-button"
          mat-flat-button
          (click)="goToLogin()"
          >Go to login page</a
        >
      </div>
    </header>
    <div class="mdc-top-app-bar--fixed-adjust">
      <div
        fxLayout="column"
        class="footer">
        <router-outlet></router-outlet>
        <app-footer></app-footer>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
