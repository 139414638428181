import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { folderColors } from 'src/app/dictionaries/folderColors';

import { FirebaseUtilitiesService } from './../../services/firebase-utilities.service';
import { UploadHelperService } from './../../services/upload-helper.service';


@Component({
  selector: 'app-custom-folders',
  templateUrl: './custom-folders.component.html',
  styleUrls: ['./custom-folders.component.scss'],
})

export class CustomFoldersComponent implements OnInit {
  @Output() selectFolder = new EventEmitter<string>();
  @Input() casename: string;
  customfolderfilter: string;
  @Input() selectedFolder: string;
  @Input() currentFolder: any;

  customFolders: any[];
  validation: any;
  customFoldersColor: string;
  selectedFolderId: any;

  constructor (
    private uploadHelper_$: UploadHelperService,
    private FirebaseUtilities_$: FirebaseUtilitiesService,
  ) {
    this.customFoldersColor = folderColors.custom;
  }

  ngOnInit() {
    this.getAllCustomFolders(this.customfolderfilter).then(() => {
      this.customFolders.forEach(cf => {
        if (cf.folderId === this.currentFolder.folderId) {
          this.selectedFolderId = cf.folderId;
          cf.selected = true;
        }
      });
    });

    this.validation = {
      value: false,
      text: (inputValue: string) => (inputValue === '') ? 'Please enter a folder name' : 'This folder name is already taken, please try a different one.'
    };
  }

  select_folder(folderObj) {
    this.customFolders.forEach(cf => delete cf.selected);
    this.selectFolder.emit(folderObj);
  }

  async getAllCustomFolders(filter?) {
    this.customFolders = await this.FirebaseUtilities_$.getCustomFolders(this.casename, filter ? filter : null);
  }

  async createFolder(folderName: string, predefined: boolean, folderColor) {
    const folderObject = {
      belongsTo: this.casename,
      children: '',
      color: folderColor,
      forDelete: false,
      folderId: this.uploadHelper_$.getRandomString(20),
      name: folderName,
      parentFolder: '',
      predefined: predefined,
      type: 'folder',
    };
    const newFolderReference = await this.FirebaseUtilities_$.createFolder(folderObject);
    return newFolderReference;
  }

  handleCreateFolder(folderName: string, predefined: boolean, folderColor: string, event?) {
    if (event.keyCode === 13 || (event.type === 'click' && event.currentTarget.nodeName === 'BUTTON')) {
      this.FirebaseUtilities_$.checkFolderName(folderName).then(async res => {
        if (res) {
          this.validation.value = true;
        } else {
          const { folderId } = (await (await this.createFolder(folderName, predefined, folderColor)).get()).data();
          this.select_folder({ folderId, folderName });
          this.getAllCustomFolders();
          this.validation.value = false;
        }
      });
    } else {
      return false;
    }
  }
}
