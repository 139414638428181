import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomActions } from 'src/app/dictionaries/CustomActions';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import firebase from 'firebase/app';
import { UserRoles } from 'src/app/dictionaries/UserRoles';
import { ContextMenuModel } from 'src/app/models/ContextMenu';
import { ClientMatterService } from 'src/app/services/client-matter.service';
import { ClioService } from 'src/app/services/clio.service';

import { environment } from '../../../environments/environment';
import { UploadTypes } from '../../models/UploadTypes';
import { AuthService } from '../../services/auth.service';
import { FilesService } from '../../services/files.service';
import { FirebaseUtilitiesService } from '../../services/firebase-utilities.service';
import { GapiOperationsService } from '../../services/gapi-operations.service';
import { PermissionsService } from '../../services/permissions.service';
import { UIMessagingService } from '../../services/uimessaging.service';
import { UploadHelperService } from '../../services/upload-helper.service';
import { UsersService } from '../../services/users.service';
import { UtilsService } from '../../services/utils.service';
import { ActiveConsultantsComponent } from '../active-consultants/active-consultants.component';
import { AvailableConsultantsComponent } from '../available-consultants/available-consultants.component';
import { EditFileDialogComponent } from '../edit-file-dialog/edit-file-dialog.component';
import { InfoWindowComponent } from '../info-window/info-window.component';
import { NewFolderComponent } from '../new-folder/new-folder.component';
import { PracticePantherListMattersComponent } from '../practicepanther/PracticePantherListMatters/PracticePantherListMatters.component';
import { SearchFilesComponent } from '../search-files/search-files.component';
import { AllNotesDIalogComponent } from '../ui/all-notes-dialog/all-notes-dialog.component';
import { ConfirmationDialogComponent } from '../ui/confirmation-dialog/confirmation-dialog.component';
import { DocViewerComponent } from '../ui/doc-viewer/doc-viewer.component';
import { NoteDialogComponent } from '../ui/note-dialog/note-dialog.component';
import { SimpleMessageWindowComponent } from '../ui/simple-message-window/simple-message-window.component';
import { UploadDialogComponent } from '../ui/uploaddialog/uploaddialog.component';
import { FileActionsDictionary } from './../../dictionaries/FileActions';
import { LogLevelsDictionary } from './../../dictionaries/LogLevels';
import { LogService } from './../../log.service';
import { AlgoliaService } from './../../services/algolia.service';
import { SharingFilesService } from './../../sharing-files.service';
import { ClioListMattersComponent } from './../clio/ClioListMatters/ClioListMatters.component';
import { ImportAppsComponent } from './../import-apps/import-apps.component';
import { FolderSelectorComponent } from './../ui/folder-selector/folder-selector.component';
import { VOBInfoWindowComponent } from './../vob-info-window/vob-info-window.component';
import { CookiesService } from 'src/app/services/cookies.service';
import { CreateCuaUserComponent } from '../create-cua-user/create-cua-user.component';
import { folderColors } from 'src/app/dictionaries/folderColors';

const localFolderColors = ['rgb(187, 222, 251)', 'rgb(209, 196, 233)', 'rgb(255, 224, 130)'];
const indexFolderColor = '#eadbff';
const indexFolderLabel = 'INDEX';

declare function require(name: string);
declare function dumpFile(file: any, option: string): [];
const unshareIconUrl = './../../assets/svg/unshare.svg';
const permissionsNeeded = [
  'uploadfiles',
  'editfiles',
  'deletefiles',
  'consultantslist',
  'sharefileicon',
  'createfolder',
];

export interface FolderElement {
  name: string;
  id: string;
  color: string;
}
const viewerUrlParts = {
  location: 4,
  dataSet: 6,
  dicomStore: 8,
  studyUID: 10,
};

export interface Consultant {
  companyName: string;
  createdBy: string;
  email: string;
  filesSharedWith?: any[];
  name: string;
  ownerID: string;
  passwordExpirationDate: string;
  role: string;
  uid: string;
  shared: boolean;
}

@Component({
  selector: 'app-clientprofile',
  templateUrl: './clientprofile.component.html',
  styleUrls: ['./clientprofile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClientProfileComponent implements OnInit {
  uploadTypes = [
    { id: '1', name: UploadTypes.FILE },
    { id: '2', name: UploadTypes.VIDEO },
    { id: '3', name: UploadTypes.DICOMDisk },
    { id: '4', name: UploadTypes.VIDEODisk },
  ];
  filesFilter = { name: '', valid: false };
  nofilesMessage = 'No files have been uploaded for this folder yet.';
  unshareAllFilesMsg = 'Unshare all files with this Consultant.';
  activeConsultant = null;
  displayedColumns: string[] = [
    'select',
    'fileName',
    // 'ftype',
    'predefined',
    'fileDesc',
    'fdate',
    'lastModified',
    'uploadedDate',
    'parentFolderName',
    'fileId',
    'isShared',
  ];

  user: any;
  patient: any;
  fdesc: string;
  fdate: string;

  ftype: string;

  files: any[] = [];
  patientName = '...';
  currentFolder = '';
  url: string;

  fileName: string;
  uid: string;
  currentFileOpen: any;
  allowAddNote: boolean;
  allowDeleteNote: boolean;
  realRole: string;
  selectedFile: string;

  width: string;
  casename: string;
  storename: string;
  selection = new SelectionModel<any>(true, []);

  @ViewChild('picker', { static: true }) picker;
  filesTableDataSource = new MatTableDataSource<any>(this.files);
  sortedData = this.files.slice();
  @ViewChild(MatPaginator) uppaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  clientDataStores: any;
  ownerId: any;

  activeConsultants: any[];
  availableConsultants: any[];

  loadingPermissions = true;
  permissions_uploadfiles: any;
  permissions_editfiles: any;
  permissions_deletefiles: any;
  permissions_consultantslist: any;
  permissions_sharefileicon: any;
  permissions_createfolder: any;

  @ViewChild(ActiveConsultantsComponent) active_consultants: ActiveConsultantsComponent;
  @ViewChild(AvailableConsultantsComponent) available_consultants: AvailableConsultantsComponent;
  refreshButton = true;
  refreshing = false;
  getFilesForPatientRunning: Promise<void>;
  defaultFolders: boolean;

  sortTypes: any[] = [
    { value: 'fdate', direction: 'desc', label: 'Date of file' },
    { value: 'ftype', direction: 'desc', label: 'File type' },
    { value: 'fileDesc', direction: 'desc', label: 'File content' },
    { value: 'lastModified', direction: 'desc', label: 'Last Modified' },
    { value: 'uploadedDate', direction: 'desc', label: 'Date Uploaded' },
  ];

  /**
   * BASIC CONTEXT MENU
   */
  title = 'context-menu';
  isDisplayContextMenu: boolean;
  rightClickMenuItems: Array<ContextMenuModel> = [];
  matMenu: MatMenu;
  // reference to the MatMenuTrigger in the DOM
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  menuTopLeftPosition = { x: '0', y: '0' };
  selected = 0;
  foldersList: any;
  currentFolderName: any;
  caseFolders: any[];
  pageSize: number;
  pageSizes: number[];
  objectsCount: number;
  pagesLength: number;
  selectionRecord: any;
  folderColor: string;
  sharedFolders: any[];
  viewNotesButton: boolean;
  indexFolderName: any;
  UserRoles: {
    owner: string;
    admin: string;
    associate: string;
    consultant: string;
    superuser: string;
    client: string;
  };
  FileActionsDictionary: typeof FileActionsDictionary;
  pickedSharedFile = {};
  selectedTabIndex: number;
  loading: boolean;
  loadingMessage: string;
  importFromAppsBtn = true;
  button: any;
  infectedFiles = [];
  section: any;
  clioMatterId: any;
  clioDocumentIdToBeOpened: string;
  targetFolder: any;
  homeFolderName = '';
  userIsConsultant: boolean;
  userIsClient: boolean;
  dicomdirFileName: string;

  constructor(
    private auth_$: AuthService,
    private cookies_$: CookiesService,
    private algolia_$: AlgoliaService,
    private permissions_$: PermissionsService,
    private firebase_utilities_$: FirebaseUtilitiesService,
    private gapi_operations_$: GapiOperationsService,
    private uploadHelper_$: UploadHelperService,
    private utils_$: UtilsService,
    private files_$: FilesService,
    private sharing_files_$: SharingFilesService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public ref: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ClientProfileComponent>,
    private dialog: MatDialog,
    private log_$: LogService,
    private users_$: UsersService,
    private http: HttpClient,
    private clientMatter_$: ClientMatterService,
    private uiMessaging_$: UIMessagingService,
    private clio_$: ClioService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    const { patient, button, section, clioMatterId, clioDocumentId, targetFolder } = data;
    this.dicomdirFileName = 'DICOMDIR';
    this.targetFolder = targetFolder || null;
    this.clioDocumentIdToBeOpened = clioDocumentId;
    this.selectedTabIndex = 0;
    this.FileActionsDictionary = FileActionsDictionary;
    this.UserRoles = {
      owner: UserRoles.owner,
      client: UserRoles.client,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
    this.viewNotesButton = false;
    this.pageSize = 0;
    this.pageSizes = [5, 10, 20, 40, 80, 100];
    this.matIconRegistry.addSvgIcon('unshareIcon', this.domSanitizer.bypassSecurityTrustResourceUrl(unshareIconUrl));
    this.patient = patient;
    this.patientName = this.getPatientName(patient);
    this.casename = this.patient.caseName;
    this.defaultFolders = Boolean(this.patient.defaultFolders);
    this.files = [];
    this.allowAddNote = true;
    this.allowDeleteNote = true;
    this.fdesc = '';
    this.fdate = this.utils_$.getCurrentDate();
    this.ftype = '';
    this.sortedData = this.files.slice();
    this.indexFolderName = 'all';
    this.button = button;
    this.section = section || null;
    this.clioMatterId = clioMatterId || patient.clioMatterId || patient.clioDocId;
  }

  async ngOnInit() {
    this.auth_$.showLoader('Loading the Client/Matter ...');
    // FIXME: #197 Have to move this Getting Permissions to the afterLogin moment instead of wait til here to do the request.
    this.setPermissions();
    this.user = this.auth_$.userData.getValue()['user'];
    this.realRole = this.auth_$.userData.getValue()['realRole'];
    this.userIsConsultant = this.realRole === UserRoles.consultant;
    this.userIsClient = this.realRole === UserRoles.client;
    this.allowAddNote = this.allowDeleteNote = !this.userIsConsultant;
    this.uploadHelper_$.scannedBlocksSource.next([]); // Clean scanned blocks.

    // this.caseOwnerId = this.auth_$.userData.getValue()['ownerID'];

    this.subscribeToFilesAction();
    this.subscribeToInfectedFiles();

    if ([UserRoles.owner, UserRoles.admin, UserRoles.associate].includes(this.user.role)) {
      window.onclick = event => {
        this.cleanHighlighted();
        this.showSharedConsultantsByFile({});
      };
    }

    this.uid = this.auth_$.uid;
    this.activeConsultant = null;

    // const definedDefaultFolders = (await this.getDefinedDefaultFolders(this.realRole)).map(folder => folder.name);
    this.caseFolders = await this.handlingFolders();

    this.subscribeToFileUploads();
    this.selection.clear();

    // REVIEW: #117 Improve this!! WIP
    // this.firebase_utilities_$.getClientCreatorEmailByCaseName(this.casename).then(data => (this.patientCreator = data));
    this.ownerId = this.auth_$.userData.getValue()['ownerID'] || this.auth_$.userData.getValue()['uid'];

    this.handleActiveButton(this.button);
    this.auth_$.hideLoader();

    if (this.targetFolder) {
      await this.browseFolderByName(this.targetFolder);
    }

    if (this.section) {
      await this.openSection(this.section, this.clioMatterId);
    } else {
      await this.getData();
    }

    this.auth_$.hideLoader();
  }

  getPatientName({ FirstName, LastName }): string {
    return `${FirstName.charAt(0).toUpperCase()}${FirstName.slice(1)} ${LastName.charAt(
      0,
    ).toUpperCase()}${LastName.slice(1)}`;
  }

  async handlingFolders() {
    await this.checkCreateDefaultFolders();
    const getFoldersList = await this.firebase_utilities_$.getFolders(this.casename);
    return getFoldersList;
  }

  handleActiveButton(button) {
    if (button) {
      switch (button) {
        case 'clio-matters':
          this.showImportFromApps('clio');
          break;
        case 'practicepanther-matters':
          this.showImportFromApps('practicepanther');
          break;
        default:
          break;
      }
    }
  }

  // FIXME: Use the default folders obtained here to avoid to get them later in the code.
  async checkCreateDefaultFolders() {
    // NOTE: Is the current client profile doesn't have predefined folder, this piece of code would create them.
    if (!this.defaultFolders && [UserRoles.owner, UserRoles.admin, UserRoles.associate].includes(this.realRole)) {
      if (!(await this.firebase_utilities_$.getDefaultFoldersFlag(this.casename))) {
        await this.createDefaultFolders();
        console.log('Default folders created.');
      }
    }
  }

  subscribeToInfectedFiles() {
    this.firebase_utilities_$.infectedFiles.subscribe(x =>
      this.infectedFiles.length > 0 ? this.showInfectedFiles(this.infectedFiles) : null,
    );
  }

  subscribeToFilesAction() {
    if ([UserRoles.admin, UserRoles.owner, UserRoles.superuser].includes(this.user.role)) {
      this.uploadHelper_$.action.subscribe({
        next: action => {
          if (action === 'update') {
            console.log('Updating list...');
            console.log('ngOnInit 322 -- getFilesForPatient');
            this.getFilesForPatient(this.currentFolder, {
              caseFolders: this.caseFolders,
              roleConsultant: this.userIsConsultant,
              roleClient: this.userIsClient,
            });
          }
        },
        error: error => console.log('An error has been reported in action.', error),
        complete: () => console.log('completed.'),
      });
    }
  }

  subscribeToFileUploads() {
    if ([UserRoles.owner, UserRoles.admin, UserRoles.associate].includes(this.user.role)) {
      // NOTE: Set an observer to fetch files after each file upload.
      this.uploadHelper_$.fileUploaded.toPromise().then(obs => {
        if (obs) {
          console.log('ngOnInit 360 -- getFilesForPatient');
          this.getFilesForPatient(this.currentFolder, {
            folderName: this.getFolderName(this.caseFolders, this.currentFolder),
            caseFolders: this.caseFolders,
            roleConsultant: this.userIsConsultant,
            roleClient: this.userIsClient,
          });
        }
      });
    }
  }

  async clientProfileReload(event) {
    console.log('getData()');
    await this.getData();
  }

  backToClio(matterId) {
    window.open(`https://app.goclio.com/matters/${matterId}`, '_self');
  }

  openDocumentInClio(documentId) {
    window.open(`https://app.clio.com/nc/#/documents/${documentId}/details`, '_self');
  }

  removeFilter() {
    this.filesFilter = { name: '', valid: false };
    console.log('removeFilter -- getFilesForPatient');
    this.getFilesForPatient(this.currentFolder, {
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
    });
  }

  getProperViewer(fileName) {
    switch (fileName.split('.').pop()) {
      case 'mp4':
      case 'avi':
        return 'video';
      case 'pdf':
        return 'google';
      case 'doc':
      case 'docx':
      case 'xls':
      case 'xlsx':
      case 'ppt':
      case 'pptx':
      case 'csv':
        return 'office';
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        return 'url';
      default:
        return 'google';
    }
  }

  async previewDocument(filePath, fileName) {
    const viewer = this.getProperViewer(fileName);
    this.auth_$.showLoader('Getting file metadata...');
    const fileMetadata = (await firebase.functions().httpsCallable('firebaseUtilities-fileGetMetadata')({ filePath }))[
      'data'
    ]['url'];
    this.auth_$.hideLoader();

    if (!fileMetadata) {
      this.auth_$.hideLoader();
      this.uiMessaging_$.toastMessage(
        "It seems the file doesn't exist anymore. Please delete the file and import it again",
        'ERROR',
      );
      return;
    }

    if (+fileMetadata['size'] === 0) {
      this.auth_$.hideLoader();
      this.uiMessaging_$.toastMessage(
        "It seems the file doesn't exist anymore. Please delete the file and import it again",
        'ERROR',
      );
      return;
    }

    this.auth_$.showLoader('Loading the document ...');
    const fileUrl = await firebase.functions().httpsCallable('firebaseUtilities-fileGetSignedURL')({ filePath });
    this.auth_$.hideLoader();
    if (!fileUrl) {
      this.uiMessaging_$.toastMessage('Error getting the file URL', 'ERROR');
    }
    this.auth_$.hideLoader();
    this.dialog.open(DocViewerComponent, {
      width: '80%',
      data: {
        src: fileUrl.data.url,
        title: fileName,
        info: fileUrl.data.url,
        viewer: viewer,
      },
    });
  }

  offerToImportClioDocument(clioDocumentId) {
    this.dialog
      .open(SimpleMessageWindowComponent, {
        data: {
          title: 'Import Document',
          message: 'This document does not exist in the current Client/Matter. Would you like to import it?',
          buttons: [
            { text: 'Yes', value: true },
            { text: 'No', value: false },
          ],
        },
      })
      .afterClosed()
      .subscribe({
        next: async res => {
          if (res) {
            await this.importClioDocument(clioDocumentId);
            console.log('offerToImportClioDocument -- getFilesForPatient');
            this.getFilesForPatient(this.currentFolder, {
              caseFolders: this.caseFolders,
              roleConsultant: this.userIsConsultant,
              roleClient: this.userIsClient,
            });
          } else {
            console.log('User declined to import this document.');
          }
        },
        error: error => console.log('An error has been reported in offerToImportClioDocument.', error),
        complete: () => console.log('completed.'),
      });
  }

  getClioFolderId(caseFolders) {
    console.log('caseFolders :', caseFolders);
    const clioFolder = caseFolders.find(folder => folder.name === 'Clio');
    return clioFolder.folderId;
  }

  async importClioDocument(clioDocumentId) {
    this.auth_$.showLoader('Importing document from Clio...');
    const clioDocument = await this.clientMatter_$.importClioDocument(
      clioDocumentId,
      this.casename,
      this.auth_$.userData.getValue()['id'],
      this.getClioFolderId(this.caseFolders),
    );
    this.auth_$.hideLoader();
    if (clioDocument['data'] === null) {
      this.uiMessaging_$.toastMessage('Error importing document from Clio.', 'ERROR');
    } else {
      this.openDocument(clioDocument.data);
    }
  }

  openDocument(clioDocument) {
    this.handleFileClick(new Event('click'), clioDocument.data);
  }

  async openSection(section, clioMatterId) {
    switch (section) {
      case CustomActions.openClioDocument:
        if (this.clioDocumentIdToBeOpened) {
          const clioDocument = await this.clientMatter_$.checkIfClioDocumentExists(
            this.clioDocumentIdToBeOpened,
            this.casename,
          );
          if (clioDocument) {
            this.openDocument(clioDocument);
            return false;
          }

          // Offer to import this document from Clio.
          this.offerToImportClioDocument(this.clioDocumentIdToBeOpened);
        }
        break;
      case CustomActions.uploadDicomDisk:
        this.filesFilter = { name: 'DICOM', valid: true };
        await this.handleFolderClick(new Event('click'), { name: 'DICOM ', folderId: 'all', predefined: true });
        this.openDialog({ section, clioMatterId });
        break;
      case CustomActions.viewMedicalImages:
        this.filesFilter = { name: 'DICOM', valid: true };
        await this.handleFolderClick(new Event('click'), { name: 'DICOM ', folderId: 'all', predefined: true });
        break;
      case CustomActions.openClioMatter:
      case CustomActions.openClioMatterFromDocument:
        console.log(CustomActions.openClioMatter);
        // await this.getData();
        break;
      case CustomActions.goToNuageDx:
        console.log(CustomActions.goToNuageDx);
        // await this.getData();
        break;
      default:
        break;
    }
  }

  async getData() {
    console.log('getData :');

    // NOTE: Start getting the files.
    await this.getFilesForPatient(this.currentFolder, {
      folderName: this.getFolderName(this.caseFolders, this.currentFolder),
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
      caseFolders: this.caseFolders,
    });

    this.updatePaginator();

    if (!this.userIsConsultant && !this.userIsClient) {
      this.updateConsultantsLists();
    }
  }

  addSharedFlags(consultantsList: Consultant[], sharedUsers) {
    if (!consultantsList) {
      return [];
    }
    consultantsList.forEach(consultant => {
      consultant.shared = false;
      if (sharedUsers) {
        sharedUsers.forEach(user => {
          if (consultant.email.toLowerCase() === user.email.toLowerCase()) {
            consultant.shared = true;
          }
        });
      }
    });
    return consultantsList;
  }

  handleFolderNameClick(event, element) {
    event.preventDefault();
    this.browseFolderByName(element.parentFolderName || element.parentFolder);
  }

  async browseFolderByName(folderName) {
    console.log('browseFolderByName');
    this.currentFolder = await this.getFolderId(folderName);
    this.browseFolderContents(this.currentFolder, folderName).then(() => (this.currentFolderName = folderName));
  }

  checkClioAuthToken() {
    return this.auth_$.userData.getValue()['clioAccessToken'];
  }

  checkPracticePantherAuthToken() {
    return this.auth_$.userData.getValue()['practicepantherAccessToken'];
  }

  /**
   * Deactivates the active consultant, clears files selection and getFiles for the patient based on a folderId.
   * @param folderId if the folder you intend to browse.
   * @param folderName string name.
   */
  async browseFolderContents(folderId: string, folderName: string) {
    this.activeConsultant = null;
    this.selection.clear();
    console.log('browseFolderContents', folderName);

    await this.getFilesForPatient(folderId, {
      globalFolders: true,
      folderName: folderName,
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
      caseFolders: this.caseFolders,
    });

    if (!this.userIsConsultant) {
      this.updateConsultantsLists();
    }
  }

  /**
   * Check if the given viewerurl is forbidden to be browsed.
   * @param viewerurl the url to be destructured.
   * @returns boolean
   */
  checkInactiveStore(element: any): boolean {
    if (!element || !element.viewerurl) {
      return;
    }

    const storename = this.utils_$.viewerurlToDicomStore(element.viewerurl, viewerUrlParts.dicomStore);
    const casename = this.utils_$.viewerurlToDicomStore(element.viewerurl, viewerUrlParts.dataSet);

    if (!this.uploadHelper_$.unAvailableStores.length) {
      return;
    }

    const isUnAvailable = this.uploadHelper_$.unAvailableStores.filter(el => {
      const sameStore = el.storename === storename;
      const sameCase = el.casename === casename;

      if (sameCase && sameStore) {
        return true;
      } else {
        return;
      }
    });

    return isUnAvailable.length > 0;
  }

  checkIfSelected(file) {
    for (let index = 0; index < this.selection.selected.length; index++) {
      const element = this.selection.selected[index];
      if (element.fileId === file.fileId) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check if any of the files in the list has any shared user that match with the given consultant email.
   */
  checkIfAnyShared(filesList: any[], consultantemail: string): boolean {
    let stillAnyShared = false;
    for (let index = 0; index < filesList.length; index++) {
      const file = filesList[index];
      for (let innerIndex = 0; innerIndex < file.sharedUsers.length; innerIndex++) {
        const sharedUser = file.sharedUsers[innerIndex].email.toLowerCase();
        if (sharedUser === consultantemail.toLowerCase()) {
          stillAnyShared = true;
        }
      }
    }
    return stillAnyShared;
  }

  clearActiveConsultantsSelected() {
    this.activeConsultants = this.activeConsultants;
  }

  clearSharedFlags(consultantsList) {
    consultantsList.forEach(consultant => delete consultant.shared);
    return consultantsList;
  }

  async createDefaultFolders() {
    const casename = this.casename;
    const defaultFolders = await this.getDefaultFolders();

    const promisesStack = [];

    defaultFolders.forEach(folder =>
      promisesStack.push(
        this.firebase_utilities_$.createFolder({
          children: '',
          name: folder.name,
          color: folder.color,
          parentFolder: '',
          type: 'folder',
          belongsTo: casename,
          predefined: true,
          forDelete: false,
          folderId: this.uploadHelper_$.getRandomString(20),
        }),
      ),
    );

    const promisesResult = await Promise.all(promisesStack);
    this.defaultFolders = await this.firebase_utilities_$.updateDefaultFolders(true, { casename: this.casename });
  }

  cleanHighlighted() {
    document.querySelectorAll('.mat-column-fileName .mat-icon').forEach(item => item.classList.remove('on'));
  }

  async copySelectedTo(folderId: string) {
    const folderName = this.caseFolders.find(folder => folder.folderId === folderId).name;
    return this.firebase_utilities_$.duplicateFilesTo(this.selection.selected, { folderId, folderName });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (typeof a === 'string' && typeof b === 'string') {
      return (a.trim().toLowerCase() < b.trim().toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareDates(a: number | string, b: number | string, isAsc: boolean) {
    const aDate = new Date(a);
    const bDate = new Date(b);
    return (aDate.getTime() < bDate.getTime() ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * Check if emails from the @param usersArr are existing users, otherwise
   * this email(s) will be returned back.
   */
  async clearNonExistentUsers(usersArr: string[]): Promise<{ unLinked: string[]; notExistent: string[] }> {
    const unLinked = [];
    const notExistent = [];
    for (let index = 0; index < usersArr.length; index++) {
      const userEmail = usersArr[index];
      const found = (await this.users_$.getUserByEmail(userEmail)).docs.length;
      if (!found) {
        notExistent.push(userEmail);
      } else {
        unLinked.push(userEmail);
      }
    }
    const message = 'There were some deleted consultants linked to your file. Those have been removed successfully.';
    this.uiMessaging_$.toastMessage(message, 'IMPORTANT');
    return { unLinked, notExistent };
  }

  deleteSingleRow(row, event) {
    event.stopPropagation();
    if (!row) {
      return;
    }
    if (row.type !== 'folder') {
      this.selection.selected[0] = row;
      this.openDeleteConfirmDialog();
    } else {
      console.log('Remove folder', row);
    }
  }

  deleteFilesFromAlgolia(fileIds: string[]) {
    this.algolia_$
      .deleteManyFromAlgolia(fileIds)
      .then(() => this.uiMessaging_$.toastMessage('Files deleted successfully', 'IMPORTANT'));
  }

  /**
   * Delete multiple files from the files list.
   */
  async deleteFiles(files) {
    const fileIds = files.map(file => file.fileId);
    let promisesStack = [];

    this.utils_$.getChunks(10, fileIds).forEach(chunk => promisesStack.push(this.files_$.getFilesByFileIdsArr(chunk)));

    const getFileIdsArr = await Promise.all(promisesStack);
    let filesData = [];
    getFileIdsArr.forEach(
      sp => (filesData = [...filesData, ...sp.docs.map(f => ({ id: f.id, fileId: f.data().fileId }))]),
    );

    promisesStack = [];
    filesData.forEach(file => promisesStack.push(this.files_$.deleteFileByDocId(file)));

    Promise.all(promisesStack).then(deletedFiles => {
      this.deleteFilesFromAlgolia(deletedFiles.map(f => f.fileId));
      const message = 'The file(s) has been deleted.';
      this.uiMessaging_$.toastMessage(message, 'INFO');
      if (!this.userIsConsultant && !this.userIsConsultant) {
        this.updateConsultantsLists();
      }
      const folderName = this.getFolderName(this.caseFolders, this.currentFolder);
      console.log('deleteFiles -- getFilesForPatient');
      this.getFilesForPatient(this.currentFolder, {
        folderName,
        caseFolders: this.caseFolders,
        roleConsultant: this.userIsConsultant,
        roleClient: this.userIsClient,
      });
    });
  }

  @HostListener('document:click')
  documentClick(): void {
    this.isDisplayContextMenu = false;
  }

  fileSharedValidation(row) {
    if (!this.activeConsultant || !row.sharedUsers) {
      return false;
    }
    return row.sharedUsers.filter(item => item.email.toLowerCase() === this.activeConsultant.toLowerCase()).length;
  }

  fetchAlgoliaSearchResults(flag) {
    if (!flag) {
      console.log('fetchAlgoliaSearchResults -- flag is false');
      return;
    }

    const fixMissingFolderName = (): any[] => {
      return this.algolia_$.algoliaSearchResults.map(g => ({
        ...g,
        parentFolderName:
          g.parentFolderName === ''
            ? this.caseFolders.find(f => f.folderId === g.parentFolder).name
            : g.parentFolderName,
      }));
    };

    this.filesTableDataSource = new MatTableDataSource(fixMissingFolderName());
  }

  filesSelected() {
    return Boolean(this.selection.selected.find(selected => selected.type !== 'folder'));
  }

  fileSelected() {
    return (
      Boolean(this.selection.selected.find(selected => selected.type !== 'folder')) &&
      this.selection.selected.length === 1
    );
  }

  focusActiveConsultantsTab() {
    this.selectedTabIndex = 0;
  }

  formatDate(dateString) {
    return new Date(dateString).toLocaleDateString('en-EN');
  }

  getButtonStyle(name) {
    return this.utils_$.getButtonStyle(name);
  }

  getClioAuthorization() {
    this.users_$
      .updateUserByDocId(this.auth_$.userData.value['id'], {
        lastSession: JSON.stringify({ patientDocId: this.casename }),
        client: 'clio',
      })
      .then(() => {
        this.clio_$.redirectToAuthorize(environment.config.clio.redirectsGroup.clientProfile);
      });
  }

  getPracticePantherAuthorization() {
    this.users_$
      .updateUserByDocId(this.auth_$.userData.value['id'], {
        lastSession: JSON.stringify({ patientDocId: this.casename }),
        client: 'practicepanther',
      })
      .then(() => {
        const practice_panther_client_id = '242f0b50-8ee2-4e0a-ac0e-70d6b91463e9';
        const redirect_uri = encodeURIComponent('https://nuagedx-testing.web.app/practicepantherauth');
        const url = `https://app.practicepanther.com/oauth/authorize?response_type=code&client_id=${practice_panther_client_id}&redirect_uri=${redirect_uri}`;
        window.location.href = url;
      });
  }

  getOtherFolderId(casename: string) {
    return this.files_$.getOtherFolderId(casename);
  }

  getFilesCountByClient(casename: string, roleConsultant?: string, consultantEmail?: string) {
    return this.files_$.getFilesCountByClient(casename, roleConsultant, consultantEmail);
  }

  goBack() {
    window.history.back();
  }

  goRefresh(ev) {
    ev ? ev.preventDefault() : (ev = {});
    this.firebase_utilities_$.infectedFiles.next([]);
    console.log('goRefresh -- getFilesForPatient');
    this.getFilesForPatient(this.currentFolder, {
      folderName: this.currentFolderName,
      caseFolders: this.caseFolders,
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
    });
  }

  async setFolderCountLabels(isConsultant, caseFolders) {
    if (isConsultant) {
      return caseFolders;
    } else {
      return await Promise.all(
        caseFolders.map(async folder => ({
          ...folder,
          label: await this.files_$.getFolderLabel(folder.folderId, folder.name, {
            isConsultant: this.userIsConsultant,
            isClient: this.userIsClient,
            useremail: this.auth_$.userData.value['email'],
          }),
        })),
      );
    }
  }

  async getFilesHomeFolder(params): Promise<any> {
    let indexCount, filesShared;
    const useremail = typeof params.user === 'undefined' ? undefined : params.user.email || '';

    if (params.roleConsultant) {
      filesShared = this.auth_$.userData.value['filesSharedWith'].filter(
        ({ patientCaseName }) => patientCaseName === params.casename,
      );

      indexCount = filesShared.length.toString();
      const filesParentFoldeIds = await this.files_$.getParentFolders(filesShared.map(file => file.fileId));
      this.sharedFolders = [];

      for (let index = 0; index < params.caseFolders.length; index++) {
        if (filesParentFoldeIds.includes(params.caseFolders[index].folderId)) {
          this.sharedFolders.push(params.caseFolders[index]);
          // params.caseFolders.splice(index, 1);
        }
      }

      params.caseFolders = this.sharedFolders;
    } else {
      indexCount = (await this.getFilesCountByClient(this.casename, params.roleConsultant, useremail)).toString();
    }

    const indexFolder = {
      type: 'folder',
      name: `${indexFolderLabel} (${indexCount})`,
      folderId: this.indexFolderName,
      color: indexFolderColor,
      predefined: true,
    };

    const foldersWithCountLabels = await this.setFolderCountLabels(params.roleConsultant, params.caseFolders);
    this.files =
      this.realRole === UserRoles.client ? [...foldersWithCountLabels] : [indexFolder, ...foldersWithCountLabels];
    const folders = await Promise.all(
      this.files.map(async folder => this.handleFolder(folder, params, useremail, filesShared)),
    );
    return folders;
  }

  async getFolderExtra(folder, roleConsultant, useremail, filesShared) {
    const name = await this.getFolderNameExtra(folder, roleConsultant, useremail, filesShared);
    return {
      // FIXME: No need to got o database to get this number if Consultant.
      creator: this.uid,
      fileDesc: '',
      fileId: this.uploadHelper_$.getRandomString(17),
      fileName: `${folder.name}`,
      ftype: folder.type,
      filePath: '',
      name,
      notes: [],
      predefined: folder.predefined || false,
      parentFolderName: folder.name,
      sharedUsers: [],
      storename: '',
      viewerurl: '',
    };
  }

  async getFolderNameExtra(folder, roleConsultant, useremail, filesShared) {
    return folder.name && folder.name.substr(0, 5) === indexFolderLabel
      ? `${folder.name}`
      : `${folder.name} ${await this.getFolderNameWithCounter(folder, roleConsultant, useremail, filesShared)}`;
  }

  async getFolderNameWithCounter(folder, roleConsultant, useremail, filesShared) {
    const result = await this.firebase_utilities_$.getNumberOfFilesByFolderId(
      folder.folderId,
      roleConsultant,
      useremail,
      filesShared,
    );
    return result;
  }

  async getFilesIndexFolder(params) {
    const userEmail = this.auth_$.userData.value['email'];
    const localFiles = (await this.files_$.getFilesByCaseName(params.casename, true)).map(f => f.data());
    this.files = localFiles.filter(item => item.type !== 'folder').filter(item => item.forDelete === false);
    if (params.roleConsultant) {
      this.files = this.files
        .filter(o => o.sharedUsers && o.sharedUsers.length > 0)
        .filter(p => p.sharedUsers.find(h => h.email === userEmail));
    }
    // NOTE: Build folders array.
    const filesArr = [];
    for (let index = 0; index < this.files.length; index++) {
      const file = this.files[index];
      const { type } = file;
      if (type !== 'folder') {
        const { name, color } = this.getFoldersByParentFolderId(this.caseFolders, file.parentFolder);
        file['predefined'] = false;
        file['parentFolderName'] = name;
        file['color'] = color;
      }

      // NOTE: If it is not the OWNER you have to check permissiones before add it to the list.
      if (params.roleConsultant) {
        file.isShared = this.isSharedWithActiveConsultant(file.sharedUsers);
        if (file.isShared) {
          filesArr.push(file);
        }
      } else {
        filesArr.push(file);
      }
    }

    // Sort files by fileName:
    filesArr.sort((a, b) => (a.fileName.toLowerCase() > b.fileName.toLowerCase() ? 1 : -1));
    return filesArr;
  }

  async getFolderExtraX(fileName, ftype, folderId, predefined) {
    return {
      creator: this.uid,
      fileDesc: '',
      fileId: this.uploadHelper_$.getRandomString(17),
      fileName: `${fileName}`,
      ftype: ftype,
      filePath: ``,
      name: `${fileName} ${await this.firebase_utilities_$.getNumberOfFilesByFolderId(folderId)}`,
      parentFolderName: fileName,
      notes: [],
      sharedUsers: [],
      storename: '',
      viewerurl: '',
      predefined: predefined || false,
    };
  }

  async getFilesFolder(paramsObject): Promise<any[]> {
    const { folderId, casename } = paramsObject;
    const creator = this.realRole === UserRoles.client ? this.auth_$.userData.value['uid'] : false;
    this.files = await this.files_$.getFilesByParentFolderId(folderId, casename, creator);

    const filesArr = [];
    for (let index = 0; index < this.files.length; index++) {
      let file = this.files[index].data();

      if (file.ftype === 'Folder') {
        const folderExtra = await this.getFolderExtraX(file.fileName, file.ftype, file.folderId, file.predefined);
        filesArr.push({ ...file, ...folderExtra });
      } else {
        const { name, color } = this.getFoldersByParentFolderId(this.caseFolders, file.parentFolder);
        const predefined = false;
        const parentFolderName = name;
        file = { ...file, predefined, parentFolderName, color };

        if (!this.userIsConsultant) {
          filesArr.push(file);
        } else {
          file.isShared = this.isSharedWithActiveConsultant(file.sharedUsers);
          if (file.isShared) {
            filesArr.push(file);
          }
        }
      }

      // Sort files by fileName:
      filesArr.sort((a, b) => (a.fileName.toLowerCase() > b.fileName.toLowerCase() ? 1 : -1));
    }
    return filesArr;
  }

  async getFilesArray(folderId: string, roleConsultant, caseFolders) {
    switch (folderId) {
      case this.homeFolderName:
        return (
          await this.getFilesHomeFolder({
            roleConsultant,
            casename: this.casename,
            caseFolders,
            user: this.user,
          })
        ).map(g => ({ ...g, name: g.fileName }));
      case this.indexFolderName:
        return await this.getFilesIndexFolder({
          roleConsultant,
          casename: this.casename,
          caseFolders,
        });
        break;
      default:
        return await this.getFilesFolder({
          folderId,
          casename: this.casename,
        });
        break;
    }
  }

  /**
   * Get all the files for the current opened Client Profile.
   * @param folderId where the files will be loaded from.
   * @param options globalFolder, folderName
   * @returns a void promise.
   */
  async getFilesForPatient(folderId: string = '', options: any = {}): Promise<any> {
    const { folderName, roleConsultant, roleClient, caseFolders } = options;
    this.refreshing = true;
    this.refreshButton = false;
    this.files = [];

    let filesArr = await this.getFilesArray(folderId, roleConsultant, caseFolders);

    if (this.filesFilter.valid === false) {
      this.filesFilter.name = '';
    }

    if (this.filesFilter.name !== '' && this.filesFilter.valid === true) {
      switch (this.filesFilter.name) {
        case 'DICOM':
          filesArr = filesArr.filter(file => file.fileName === this.dicomdirFileName);
          this.filesFilter.valid = false;
      }
    }

    this.setPaging(filesArr, folderId, folderName);
    this.refreshButton = true;
    this.auth_$.hideLoader();
  }

  showImportFromApps(button?) {
    this.importFromAppsBtn = false;
    const hasClioAccessToken = !!this.auth_$.userData.value['clioAccessToken'] || false;
    const hasPracticePantherAccessToken = !!this.auth_$.userData.value['practicepantherAccessToken'] || false;
    this.dialog
      .open(ImportAppsComponent, {
        height: 'auto',
        width: 'auto',
        data: {
          clioAccessToken: hasClioAccessToken,
          practicepantherAccessToken: hasPracticePantherAccessToken,
          button: button || false,
        },
      })
      .afterClosed()
      .toPromise()
      .then(result => {
        switch (result) {
          case 'clio-signin-and-authorize':
            this.getClioAuthorization();
            break;
          case 'show-clio-matters':
            this.handleClioImport();
            this.importFromAppsBtn = false;
            break;
          case 'practicepanther-signin-and-authorize':
            this.getPracticePantherAuthorization();
            break;
          case 'show-practicepanther-matters':
            this.handlePracticePantherImport();
            this.importFromAppsBtn = false;
            break;
          case 'remove-clio-token':
            this.ngOnInit();
            break;
          default:
            console.log('No App Selected');
            this.importFromAppsBtn = true;
            break;
        }
      });
  }

  getComponent(client) {
    let component;
    switch (client) {
      case 'clio':
        component = ClioListMattersComponent;
        break;
      case 'practicepanther':
        component = PracticePantherListMattersComponent;
        break;
      default:
        component = null;
        break;
    }
    return component;
  }

  showMattersList(mattersData, client?) {
    let component = this.getComponent(client);

    this.dialog
      .open(component, {
        height: 'auto',
        width: 'auto',
        data: {
          matters: mattersData,
          currentCase: this.casename,
          currentFolder: this.currentFolder,
          clientName: this.patientName,
        },
      })
      .afterClosed()
      .subscribe({
        next: val => {
          this.importFromAppsBtn = true;
          if (val === 'IMPORTED') {
            this.uiMessaging_$.toastMessage('The import finished successfully', 'INFO');
            console.log('showMattersList --  getFilesForPatient');
            this.getFilesForPatient('', {
              caseFolders: this.caseFolders,
              roleConsultant: this.userIsConsultant,
              roleClient: this.userIsClient,
            });
          }
        },
        error: err => console.error(err),
      });
  }

  /**
   * Get the folder name of a given folderId.
   * @param caseFolders is the array having all the folders (name,id) of the given casename.
   * @param parentFolderId to match with the caseFolder items.
   * @returns the name of the folder or empty string.
   */
  getFolderName(caseFolders, parentFolderId): string {
    if (['all', ''].includes(parentFolderId)) {
      return '';
    } else {
      const filtered = caseFolders.find(folder => folder.folderId === parentFolderId);
      return filtered ? filtered.name : '';
    }
  }

  getFoldersByParentFolderId(caseFolders: any[], parentFolderId: string): FolderElement {
    const filtered = caseFolders.filter(({ folderId }) => folderId === parentFolderId)[0];

    if (!filtered) {
      console.log('getFoldersByParentFolderId filtered empty: ', parentFolderId);
    }

    const filteredFolders = filtered || {
      name: 'top',
      id: 'top',
      color: 'pink',
    };
    return filteredFolders;
  }

  async getMissingSharedFiles(file) {
    if (!file.sharedUsers) {
      return;
    }
    const sharedUserEmails = file.sharedUsers.map(u => u.email.toLowerCase());
    console.log('sharedUserEmails: ', sharedUserEmails);
    const activeConsultantsEmails = this.activeConsultants.map(ac => ac.email.toLowerCase());
    const notFound = this.findIn(sharedUserEmails, activeConsultantsEmails);
    console.log('notFound: ', notFound);
    if (!notFound.length) {
      return;
    }

    const { unLinked, notExistent } = await this.clearNonExistentUsers(notFound);
    this.repairUnLinkedUsers(unLinked, notFound, file);
    this.repairNonExistent(notExistent, file);
    this.ngOnInit();
  }

  findIn(arr1, arr2) {
    const notFound = [];
    arr1.forEach(e => {
      if (!arr2.includes(e)) {
        notFound.push(e);
      }
    });
    return notFound;
  }

  async getFolderIdByFolderName(folderName: string) {
    console.log('folderName :', folderName);
    const folder = this.caseFolders.find(f => f.name === folderName);
    if (!folder) {
      const folderObject = {
        children: '',
        name: 'Clio',
        color: '#c5f0f6',
        parentFolder: '',
        type: 'folder',
        belongsTo: this.casename,
        predefined: false,
        forDelete: false,
        folderId: this.uploadHelper_$.getRandomString(20),
      };

      this.auth_$.showLoader('Creating missing Clio folder...');
      await this.firebase_utilities_$.createFolder(folderObject);
      this.auth_$.hideLoader();
      return;
    }
    return Promise.resolve(folder.folderId);
  }

  async getFolderId(folderName) {
    return this.getFolderIdByFolderName(folderName);
  }

  async deleteStores(filesIDs) {
    this.clientDataStores = await this.uploadHelper_$.getDataStoresByClient(this.casename);
    const filesIDsToDelete = filesIDs.map(file => file.fileId);
    const storesToDelete = this.getStoresFromFileIDsList(filesIDsToDelete);

    if (!this.clientDataStores) {
      return;
    }

    const dicomStores = this.clientDataStores.map(urlname => {
      this.utils_$.viewerurlToDicomStore(
        `${environment.config.ohifViewerURL}${urlname.name}`,
        viewerUrlParts.dicomStore,
      );
    });
    this.gapi_operations_$
      .deleteStoresByCaseName(this.casename, dicomStores)
      .then(result => {
        console.log(`DICOM Store deleted successfully`, result);
        return 200;
      })
      .catch(reason => {
        // TODO: #187 There is an error when try to delete all files from a client profile.
        console.error(`Error deleting : ${reason}`);
        console.log('Reason', reason);
        return 0;
      });
  }

  getStoreName(fileId): Promise<any> {
    return this.files_$.getFileByFileId(fileId).then(res => {
      const file = res.docs[0].data();
      console.log('file: ', file);
    });
  }

  getStoresFromFileIDsList(filesIDsToDelete) {
    const dataStores = [];
    filesIDsToDelete.forEach(file => dataStores.push(this.getStoreName(file.fileId)));
  }

  async handleFolderClick(event, element) {
    const { predefined, folderId, name } = element;
    this.auth_$.showLoader(`Loading folder '${name}' contents...`);
    let index;

    if (predefined === true && folderId !== 'all') {
      index = 0;
    } else if (folderId === 'all') {
      index = 1;
    } else {
      index = 3;
    }

    this.folderColor = localFolderColors[index];
    await this.browseFolderContents(folderId, name);
    this.updateConsultantsLists();
    this.auth_$.hideLoader();
  }

  handleFileClick(event, element) {
    event.stopPropagation();
    element.ftype === UploadTypes.DICOMDisk
      ? this.handleClickOnDICOMDisk(element)
      : this.handleClickOnOtherFile(element);
  }

  async handleClickOnDICOMDisk(element) {
    this.log_$.storeLog(
      this.auth_$.userData.getValue(),
      LogLevelsDictionary.info,
      { message: 'A study has been browsed.' },
      'BrowseStudy',
      this.casename,
    );

    event.preventDefault();
    const startPoint = element.viewerurl.indexOf('/projects');

    this.auth_$.showLoader('Opening the study viewer...');
    if (environment.config.viewerIndex === 0) {
      // FIXME: WIP
      await this.preStudyViewAction();
    }
    this.auth_$.hideLoader();

    const viewerURL = `${environment.config.ohifViewerURL}${element.viewerurl.substr(startPoint)}`;
    window.open(viewerURL, '_blank');
  }

  private async preStudyViewAction() {
    const randomState = this.uploadHelper_$.getRandomString(20);
    const email = this.auth_$.userData.getValue()['email'];
    const result = (await this.updateUserStudyViewAction(randomState, email))['data'];
    const customToken = result['customToken'];
    const cookieValue = `${randomState}#${email}#${customToken}`;
    const cookieName = 'studyOpenedFromNuageDxApp';

    this.cookies_$.setCookie(cookieName, cookieValue, 1);
    console.log('The cookie was set successfully');
  }

  private updateUserStudyViewAction(randomState, email) {
    return firebase.functions().httpsCallable('user-updateUserStudyViewAction')({ randomState, email });
  }

  async handleOtherExtensionFileClick(filePath, fileName) {
    this.auth_$.showLoader('Opening the document viewer...');
    await this.previewDocument(filePath, fileName);
    this.auth_$.hideLoader();
  }

  async handleClickOnOtherFile(element) {
    const { fileId, fileName } = element;
    const extension = fileName.substring(fileName.length - 3);
    this.auth_$.showLoader('Getting the file path...');
    const filePath = (await this.files_$.getFileByFileId(fileId)).docs[0].data().filePath;
    this.auth_$.hideLoader();

    this.log_$.storeLog(
      this.auth_$.userData.getValue(),
      LogLevelsDictionary.info,
      { message: 'A file has been browsed.', filename: fileName },
      'BrowseFile',
      this.casename,
    );

    if (extension === 'VOB') {
      this.handleVOBFileClick(filePath);
    } else {
      this.handleOtherExtensionFileClick(filePath, fileName);
    }
  }

  handleVOBFileClick(filePath) {
    const storage = firebase.storage();
    const pathReference = storage.ref();
    const dialogRef = this.dialog.open(VOBInfoWindowComponent, {
      height: 'auto',
      width: 'auto',
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then(res => {
        if (res === 'download') {
          firebase
            .functions()
            .httpsCallable('firebaseUtilities-fileGetSignedURL')({ filePath })
            .then(result => {
              console.log('result :', result);
              window.open(result.data.url, '_blank');
            });
        } else if (res === 'getConvertAndExport') {
          pathReference
            .child(filePath)
            .getDownloadURL()
            .then(targetURL => {
              const cloudconvertGetConvertAndExport = firebase
                .functions()
                .httpsCallable('cloudconvert-getConvertAndExport');
              cloudconvertGetConvertAndExport({ url: targetURL }).then(result => console.log('result: ', result));
            });
        }
      });
  }

  handleSelect(value) {
    const type = this.sortTypes[value];
    this.setSort(type.value, type.direction);
  }

  handleTabsClick(event: MatTabChangeEvent) {
    if (event.index === 0 && this.available_consultants) {
      this.available_consultants.selectedOptionsClear();
    } else if (event.index === 1 && this.active_consultants) {
      this.activeConsultant = null;
      this.active_consultants.selectedOptionsClear();
    }
  }

  async handleDeleteAllFiles() {
    /**
     * NOTE: The reason of these duplicated calls using caseName and LegalCaseId is to prevent
     * those records with these values.This is only for old records, new ones should use
     * caseName only.
     */
    const filesToDelete = this.selection.selected.map(file => ({
      fileId: file.fileId,
      sharedUsers: file.sharedUsers,
    }));
    const filesIDs = filesToDelete.map(file => file.fileId);
    const promises = [];
    promises.push(
      this.firebase_utilities_$.deleteFilesByIds(filesIDs).then(() => console.log('The files have been deleted.')),
    );

    // NOTE: Remove shared users references to the files.
    filesToDelete.forEach(file => {
      if (file.sharedUsers) {
        console.log('file.sharedUsers: ', file.sharedUsers);
        const shareUserEmail = file.sharedUsers.map(user => user.email.toLowerCase());
        promises.push(this.firebase_utilities_$.removeFilesByIdsFromUser(file.fileId, shareUserEmail));
      }
    });
    return Promise.all(promises);
  }

  async handleDeleteAllStores() {
    this.clientDataStores = await this.uploadHelper_$.getDataStoresByClient(this.casename);
    if (!this.clientDataStores) {
      return;
    }
    const dicomStores = this.clientDataStores.map(urlname => {
      this.utils_$.viewerurlToDicomStore(
        `${environment.config.ohifViewerURL}${urlname.name}`,
        viewerUrlParts.dicomStore,
      );
    });
    this.gapi_operations_$
      .deleteStoresByCaseName(this.casename, dicomStores)
      .then(result => {
        console.log(`DICOM Store deleted successfully`, result);
        return 200;
      })
      .catch(reason => {
        // TODO: #187 There is an error when try to delete all files from a client profile.
        console.error(`Error deleting : ${reason}`);
        console.log('Reason', reason);
        return 0;
      });
  }

  handleCheckboxClick(ev) {
    ev.preventDefault();
  }

  handleClioImport() {
    if (this.checkClioAuthToken()) {
      this.listClioMatters();
    } else {
      this.getClioAuthorization();
    }
  }

  handlePracticePantherImport() {
    // Check if there is a token
    if (this.checkPracticePantherAuthToken()) {
      // Open matters list.
      this.listPracticePantherMatters();
    } else {
      this.getPracticePantherAuthorization();
    }
  }

  /**
   * handleRowClick happens when user clicks on a single row of the DataTable.
   */
  handleRowClick(element, event, options?) {
    this.cleanHighlighted();
    this.showSharedConsultantsByFile({});

    const { currentFolder } = options;
    if (currentFolder === '') {
      return;
    }
    event.stopPropagation();
    this.selection.toggle(element);

    if (this.selection.selected.length > 0) {
      this.activeConsultants = this.addSharedFlags(this.activeConsultants, this.selection.selected[0].sharedUsers);
      this.availableConsultants = this.addSharedFlags(
        this.availableConsultants,
        this.selection.selected[0].sharedUsers,
      );

      this.viewNotesButton =
        this.selection.selected.length === 1
          ? this.selection.selected[0].notes && this.selection.selected[0].notes.length > 0
          : false;
    } else if (this.selection.selected.length === 0) {
      this.viewNotesButton = false;
      this.activeConsultants = this.clearSharedFlags(this.activeConsultants);
      this.availableConsultants = this.clearSharedFlags(this.availableConsultants);
    }
  }

  /**
   * This is called from the template when an Active Consultant option is clicked.
   */
  handleActiveConsultantClick(event) {
    this.selectionRecord = this.selection.selected;
    if (event === '') {
      this.activeConsultant = null;
      if (this.currentFolder === '') {
        this.setSort('predefined', 'desc');
      } else if (this.currentFolder === 'all') {
        this.setSort('parentFolderName', 'asc');
      } else {
        this.setSort('fileName', 'desc');
      }
      this.selectionRecord.forEach(record => this.selection.toggle(record));
      return;
    }
    this.activeConsultant = event.email || null;
    this.updateDataSharedStatus();
    this.setSort('isShared', 'desc');
    this.selectionRecord.forEach(record => this.selection.toggle(record));
  }

  handleBreadcrumbClick(event) {
    const folderName = this.getFolderName(this.caseFolders, event);
    const roleConsultant = this.userIsConsultant;
    const user = this.user;
    const roleClient = this.userIsClient;
    const caseFolders = this.caseFolders;

    console.log('handleBreadcrumbClick');

    this.getFilesForPatient(event, { folderName, roleConsultant, roleClient, user, caseFolders }).then(() => {
      this.activeConsultant = null;
      this.selection.clear();
    });
  }

  /**
   * Check if the @param fileSharedUsers contains the activeConsultant one.
   */
  isSharedWithActiveConsultant(fileSharedUsers: any[]): boolean {
    // FIXME: Temporal solution while this.activeConsultant keeps null.
    if (this.realRole === UserRoles.consultant) {
      this.activeConsultant = this.auth_$.userData.getValue()['userEmail'];
    }

    if (typeof fileSharedUsers === 'undefined') {
      return false;
    }

    if (this.activeConsultant === null) {
      return fileSharedUsers && Boolean(fileSharedUsers.length);
    }

    for (let index = 0; index < fileSharedUsers.length; index++) {
      const user = fileSharedUsers[index];
      if (user.email === this.activeConsultant) {
        // FIXME: this.activeConsultant should be populated on signin
        return true;
      }
    }
    return false;
  }

  isAllSelected() {
    return this.selection.selected.length === this.files.length;
  }

  async listClioMatters() {
    // FIXME: Make this call part of clio service.
    const url = environment.constants.cloudfunctionsURL + 'clio-securedGetMatters';
    const userdocid = await this.firebase_utilities_$.getUserDocId(this.auth_$.uid);
    this.auth_$.showLoader(`Loading matters for ${this.auth_$.userData.getValue()['userEmail']}`);
    const uid = this.auth_$.uid;
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(token => {
        this.http
          .post(
            url,
            { userdocid, uid },
            {
              headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
            },
          )
          .toPromise()
          .then(res => {
            if (res['data'].length) {
              this.auth_$.hideLoader();
              this.showMattersList(res['data'], 'clio');
            }
          })
          .catch(async err => {
            this.auth_$.hideLoader();
            const errormsg = `Error getting matters from Clio, please try again.`;
            const label = 'ERROR';
            this.uiMessaging_$.toastMessage(errormsg, label);
            console.log(err);
            console.error(err.error.message);
            if (err.error.status === 401) {
              this.importFromAppsBtn = true;
              const result = await this.deleteClioAccessToken().catch(error => {
                console.log(error);
                const message = 'Error deleting Clio token. Please contact the system administrator.';
                this.uiMessaging_$.toastMessage(message, label);
                return false;
              });
              if (result) {
                await this.auth_$.userReady(this.auth_$.userData.getValue(), 'listClioMatters');
              }
            }
            return err;
          });
      });
  }

  deleteClioAccessToken() {
    const url = environment.constants.cloudfunctionsURL + 'clio-deleteClioAccessToken';
    return this.http.post(url, { email: this.auth_$.userData.getValue()['userEmail'] }).toPromise();
  }

  async listPracticePantherMatters() {
    const url = environment.constants.cloudfunctionsURL + 'practicepanther-getMatters';
    const userdocid = await this.firebase_utilities_$.getUserDocId(this.auth_$.uid);
    this.auth_$.showLoader('List Matters...');
    this.http
      .post(url, { userdocid })
      .toPromise()
      .then(res => {
        if (res['length']) {
          this.auth_$.hideLoader();
          this.showMattersList(res, 'practicepanther');
        }
      });
  }

  /**
   * Check/UnCheck all rows in the dataTable.
   */
  masterToggle() {
    const event = document.createEvent('HTMLEvents');
    // tslint:disable-next-line: deprecation
    event.initEvent('click', true, false);
    this.isAllSelected()
      ? this.files
          .filter(row => this.checkIfSelected(row))
          .forEach(row =>
            this.handleRowClick(row, event, {
              currentFolder: this.currentFolder,
            }),
          )
      : this.files
          .filter(row => !this.checkIfSelected(row))
          .forEach(row =>
            this.handleRowClick(row, event, {
              currentFolder: this.currentFolder,
            }),
          );
  }

  async moveSelectedTo(folderId: string) {
    return this.firebase_utilities_$.moveFilesTo(this.selection.selected, folderId);
  }

  checkDuplicatedFolder(folderName: string, currentFolder?: string): boolean {
    return Boolean(
      this.caseFolders.find(folder => folder.name.toLowerCase().trim() === folderName.toLowerCase().trim()),
    );
  }

  createFolder(currentFolder, options) {
    this.dialog
      .open(NewFolderComponent, {})
      .afterClosed()
      .subscribe(async newFolderName => {
        if (!newFolderName) {
          return;
        }

        if (this.checkDuplicatedFolder(newFolderName, currentFolder)) {
          const message = `Folder name ${newFolderName} is already taken`;
          const label = '';
          this.uiMessaging_$.toastMessage(message, label);
          return;
        }

        const folderObject = {
          children: '',
          name: newFolderName.trim(),
          color: '#c5f0f6',
          parentFolder: '',
          type: 'folder',
          belongsTo: this.casename,
          predefined: false,
          forDelete: false,
          folderId: this.uploadHelper_$.getRandomString(20),
        };

        this.auth_$.showLoader('Creating folder...');
        await this.firebase_utilities_$.createFolder(folderObject);
        this.caseFolders.push(folderObject);
        console.log('createFolder -- getFilesForPatient');
        this.getFilesForPatient(currentFolder, {
          ...options,
          caseFolders: this.caseFolders,
          roleConsultant: this.userIsConsultant,
          roleClient: this.userIsClient,
        }).then(() => this.auth_$.hideLoader());
      });
  }

  async noteSave(noteFile, { attachments, name, description, useremail }) {
    let noteAttachments = '';
    let attachmentLists;
    if (attachments) {
      attachmentLists = await this.firebase_utilities_$.storeNoteNewAttachments(attachments, this.patientName);
    } else {
      attachmentLists = [];
    }

    const oldAttachments = [];
    const newAttachments = attachmentLists ? oldAttachments.concat(attachmentLists) : [];

    noteAttachments = JSON.stringify(newAttachments);

    noteFile.notes = noteFile.notes || [];
    const noteObject = {
      title: name,
      note: description,
      createdBy: useremail,
      attachments: noteAttachments,
    };
    noteFile.notes.push(noteObject);

    const docid = (await this.files_$.getFileByFileId(noteFile.fileId)).docs[0].id;
    await this.files_$
      .updateFileByDocId(docid, {
        notes: firebase.firestore.FieldValue.arrayUnion(noteObject),
      })
      .then(() => {
        this.uiMessaging_$.toastMessage('Notes Added Successfully', null);
        return 'Note Added Successfully';
      })
      .catch(error => {
        this.uiMessaging_$.toastMessage(error.message, null);
        return error.message;
      });
  }

  onRightClick(event: MouseEvent, item) {
    event.preventDefault();
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    this.matMenuTrigger.menuData = { item: item };
    this.matMenuTrigger.openMenu();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onlySharedFolders(folder) {
    const isSharedWithConsultant = (sharedUsers: any[], consultantEmail: string) => {
      if (typeof sharedUsers === 'undefined' || !sharedUsers.length) {
        return;
      }
      return sharedUsers.includes(consultantEmail.toLowerCase());
    };
    return isSharedWithConsultant(folder.data().sharedUsers, this.user.email.toLowerCase());
  }

  openDialog(options?) {
    const buildDialogConfig = options => {
      const action_params = {
        files: '',
        type: '',
        desc: '',
        caseName: this.casename,
        creator: this.uid,
      };
      const currentFolder = { folderId: this.currentFolder, folderName: this.currentFolderName };
      const dialogConfigData = {
        id: 1,
        section: options.type ? false : options.section || null,
        clioMatterId: (options && options.clioMatterId) || null,
        title: options && options.section === 'upload_dicom_disk' ? 'Upload Medical Images' : 'Upload Files and Disks',
        currentFolder: currentFolder,
        uploadTypes: this.uploadTypes,
        fdate: this.fdate,
        basicfile: this.files,
        description: this.fdesc,
        type: this.ftype,
        clientName: this.patientName,
        action_params: action_params,
      };
      const dialogConfig = new MatDialogConfig();
      this.dialog.openDialogs.pop();

      dialogConfig.width = '700px';
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.maxHeight = '70vw';
      dialogConfig.data = dialogConfigData;

      return dialogConfig;
    };

    this.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(UploadDialogComponent, buildDialogConfig(options));
    const subs = dialogRef.componentInstance.finish.subscribe(async data => {
      if (data) {
        if (data.action === 'getfiles') {
          this.caseFolders = await this.firebase_utilities_$.getFolders(this.casename);
          this.loading = true;
          this.loadingMessage = 'This list is getting updated, please wait.';
          console.log('openDialog -- getFilesForPatient');
          await this.getFilesForPatient(this.currentFolder, {
            folderName: this.currentFolderName,
            caseFolders: this.caseFolders,
            roleConsultant: this.userIsConsultant,
          });
          this.loading = false;
        }
      } else {
        return false;
      }
    });

    dialogRef
      .keydownEvents()
      .toPromise()
      .then(e => {
        if (!e) {
          return;
        }
        if (e.key === 'Escape') {
          e.preventDefault();
          dialogRef.close();
        }
      });

    dialogRef
      .afterClosed()
      .toPromise()
      .then(result => {
        console.log('The dialog was closed', result);
        subs.unsubscribe();
      });
  }

  openDeleteConfirmDialog() {
    const dialogConfig = new MatDialogConfig();
    const plural = this.selection.selected.length > 1 ? `s` : ``;
    const condition = this.selection.selected.length === this.filesTableDataSource.data.length;

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Confirm deletion',
      message: `Are you sure  you want to delete ${this.selection.selected.length} file${plural} from your uploaded files?`,
      confirm_action: condition ? this.handleDeleteAllFiles : this.deleteFiles,
      deleteAllFiles: condition,
      selected_files: condition ? null : this.selection.selected,
    };

    this.dialog
      .open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (!result) {
          return;
        }
        if (!result.deleteAllFiles) {
          this.deleteFiles(this.selection.selected);
        } else if (result.selectedFiles === null) {
          const deletionResult = await Promise.all([this.handleDeleteAllStores(), this.handleDeleteAllFiles()]);
          console.log('deletionResult :', deletionResult);
        } else {
          console.log('log val', result);
        }
        const folderName = this.currentFolderName;
        console.log('openDeleteConfirmDialog -- getFilesForPatient');
        this.getFilesForPatient(this.currentFolder, {
          folderName,
          caseFolders: this.caseFolders,
          roleConsultant: this.userIsConsultant,
          roleClient: this.userIsClient,
        });
        this.selection.clear();
      });
  }

  openInfoDialog() {
    this.dialog
      .open(InfoWindowComponent, {
        height: 'auto',
        width: 'auto',
      })
      .afterClosed()
      .toPromise()
      .then(() => {
        this.openDialog();
      });
  }

  open() {
    this.picker.open();
  }

  openAddNoteDialog() {
    const selectedFile = this.selection.selected[0];
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '450px';
    dialogConfig.data = {
      title: 'Add Note',
      message: `Please add a note here:`,
      selected_file: selectedFile,
      useremail: this.user.email,
      name: '',
      description: '',
    };

    const dialogRef = this.dialog.open(NoteDialogComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .toPromise()
      .then(result => {
        const afterNoteSave = () => {
          this.selection.clear();
          const folderName = this.currentFolderName;
          console.log('openAddNoteDialog -- getFilesForPatient');
          this.getFilesForPatient(this.currentFolder, {
            folderName,
            caseFolders: this.caseFolders,
            roleConsultant: this.userIsConsultant,
            roleClient: this.userIsClient,
          });
          if (!this.userIsConsultant) {
            this.updateConsultantsLists();
          }
        };
        if (result.type === 'notesave') {
          this.noteSave(result.selected_file, result.data).then(() => afterNoteSave());
        }
      });
  }

  /**
   * Show all notes of a selected file.
   */
  openAllNotes() {
    const dialogConfig = new MatDialogConfig();
    const { fileName, fileId, notes } = this.selection.selected[0];
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'View Notes',
      message: `These are the notes for ${fileName}`,
      selected_files: this.selection.selected,
      notes: notes,
      fileid: fileId,
      patientName: this.casename,
      createdBy: this.user.email,
      currentFolder: this.currentFolder,
      currentFolderName: this.currentFolderName,
    };

    const dialogRef = this.dialog.open(AllNotesDIalogComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .toPromise()
      .then(val => {
        this.selection.clear();
        const folderName = this.currentFolderName;
        const user = this.user;
        console.log('openAllNotes -- getFilesForPatient');
        this.getFilesForPatient(this.currentFolder, {
          folderName,
          roleConsultant: this.userIsConsultant,
          roleClient: this.userIsClient,
          user,
          caseFolders: this.caseFolders,
        });
        if (!this.userIsConsultant) {
          this.updateConsultantsLists();
        }
      });
  }

  openFileSearch() {
    const dialogConfig = new MatDialogConfig();
    this.dialog.openDialogs.pop();

    dialogConfig.width = '400px';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxHeight = '80vw';

    dialogConfig.data = {
      id: 1,
      uploadTypes: this.uploadTypes,
      fdate: this.fdate,
      basicfile: this.files,
      description: this.fdesc,
      type: this.ftype,
      folder: this.currentFolder,
      clientName: this.patientName,
      casename: this.casename,
      action_params: {
        files: '',
        type: '',
        desc: '',
        caseName: this.casename,
        creator: this.uid,
      },
    };

    const dialogRef = this.dialog.open(SearchFilesComponent, dialogConfig);
    const subs = dialogRef.componentInstance.updateResultsEvent.subscribe(() => this.fetchAlgoliaSearchResults(true));

    dialogRef
      .keydownEvents()
      .toPromise()
      .then(e => {
        if (!e) {
          return false;
        }
        if (e.key === 'Escape') {
          e.preventDefault();
          dialogRef.close();
        }
      });

    dialogRef
      .afterClosed()
      .toPromise()
      .then(result => {
        subs.unsubscribe();
      });
  }

  renameElement(element, event) {
    event.stopPropagation();
    if (element) {
      this.selection.selected[0] = element;
    }

    const selectedFile = this.selection.selected[0];
    const dialogConfig = new MatDialogConfig();

    let dialogData = {};

    if (element.type === 'folder') {
      dialogData = {
        title: 'Edit folder',
        name: selectedFile.fileName,
        type: element.type,
        label: 'Folder name',
      };
    } else {
      const newDateParts = selectedFile.fdate.split('/');

      dialogData = {
        title: 'Edit file',
        label: 'File name',
        message: `Please add a note here:`,
        confirm_action: this.noteSave,
        selected_file: selectedFile,
        useremail: this.user.email,
        name: selectedFile.fileName,
        desc: selectedFile.fileDesc,
        date: new Date(newDateParts[2], parseInt(newDateParts[0], 10) - 1, newDateParts[1]),
        description: '',
      };
    }

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = dialogData;

    const dialogRef = this.dialog.open(EditFileDialogComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .toPromise()
      .then(data => (data ? this.updateFile(data, selectedFile) : null));
  }

  /**
   * Update linked users with the selected file if they are unlinked. "Unlinked"
   * means the file is shared with a consultant but the consultant has no
   * registry of this.
   * @param unLinked is the array of email addresses unlinked with the file.
   * @param file selected.
   */
  async repairUnLinkedUsers(unLinked: string[], notFound, file): Promise<void> {
    if (!unLinked.length) {
      return;
    }
    return this.users_$.getUsersByEmailsArray(unLinked).then(sp => {
      if (!sp.docs.length) {
        console.log('Missing consultant', notFound);
        return;
      }
      sp.docs.forEach(doc => {
        this.users_$.updateUserByDocId(doc.id, {
          filesSharedWith: firebase.firestore.FieldValue.arrayUnion({
            fileId: file.fileId,
            patientCaseName: this.casename,
          }),
        });
      });
      const message = 'There were some consultants unlinked with your shared file, those have been repaired';
      const label = 'IMPORTANT';
      this.uiMessaging_$.toastMessage(message, label);
    });
  }

  /**
   * Removes the link of the selected file with users than are deleted
   * or removed from firestore.
   * @param notExistent the array of emails.
   * @param file to be requested.
   */
  async repairNonExistent(notExistent, file): Promise<void> {
    if (!notExistent.length) {
      return;
    }
    const sharedUsers = (await this.files_$.getFileByFileId(file.fileId)).docs[0].data()['sharedUsers'];

    for (let index = 0; index < notExistent.length; index++) {
      const uemail = notExistent[index].toLowerCase();
      const fileDBId = (await this.files_$.getFileByFileId(file.fileId)).docs[0].id;
      this.files_$.updateFileByDocId(fileDBId, {
        sharedUsers: sharedUsers.filter(user => user.email.toLowerCase() !== uemail.toLowerCase()),
      });
    }
  }

  setPageSize(objectsCount: number = 0) {
    for (let index = 0; index < this.pageSizes.length; index++) {
      const element = this.pageSizes[index];
      if (element >= objectsCount) {
        this.pageSize = element;
        break;
      }
    }
    if (objectsCount > this.pageSizes[this.pageSizes.length]) {
      this.pageSize = this.pageSizes[this.pageSizes.length];
    }
    this.pagesLength = Math.ceil(objectsCount / this.pageSize);
  }

  async setPermissions() {
    this.loadingPermissions = true;
    const prefix = 'permissions_';
    let permissionsArr;
    if (
      this.auth_$.getPermissions('clientprofile_permissions') &&
      JSON.parse(this.auth_$.getPermissions('clientprofile_permissions')).length === permissionsNeeded.length
    ) {
      permissionsArr = JSON.parse(this.auth_$.getPermissions('clientprofile_permissions'));
    } else {
      const permissions = await this.validateRoleMulti(permissionsNeeded);
      permissionsArr = permissions.map(item => ({
        name: prefix + item.object,
        value: item.access,
      }));
      this.auth_$.setPermissions(permissionsArr);
    }
    permissionsArr.forEach(item => (this[item.name] = item.value));
    this.loadingPermissions = false;
  }

  async shareSingleFile(row) {
    const consultant = this.activeConsultant;
    this.firebase_utilities_$.shareFile(row, this.activeConsultant, this.casename).then(() => {
      const folderName = this.getFolderName(this.caseFolders, this.currentFolder);
      console.log('shareSingleFile -- getFilesForPatient');
      this.getFilesForPatient(this.currentFolder, {
        folderName,
        caseFolders: this.caseFolders,
        roleConsultant: this.userIsConsultant,
        roleClient: this.userIsClient,
      }).then(() => {
        this.updateActiveConsultant(this.activeConsultant, {
          active: 'isShared',
          direction: 'desc',
        });
        if (!this.userIsConsultant) {
          this.updateConsultantsLists();
        }
        this.utils_$.createNotificationEmail('SHARE', row, this.activeConsultant);
        this.log_$.storeLog(
          this.auth_$.userData.getValue(),
          LogLevelsDictionary.info,
          {
            message: 'A file has been shared.',
            filename: row.fileName,
            user: this.activeConsultant,
          },
          'Share',
          this.casename,
        );
      });
    });
  }

  async shareWith(consultantEmail) {
    const promisesStack = [];
    this.selection.selected.forEach(selectedFile => {
      promisesStack.push(this.firebase_utilities_$.shareFile(selectedFile, consultantEmail, this.casename));
    });

    Promise.all(promisesStack).then(result => {
      this.log_$.storeLog(
        this.auth_$.userData.getValue(),
        LogLevelsDictionary.info,
        {
          message: 'A group of files has been shared.',
          filenames: result.join(','),
          user: consultantEmail,
        },
        'Share',
        this.casename,
      );
      console.log('shareWith -- getFilesForPatient');
      this.getFilesForPatient(this.currentFolder, {
        folderName: this.getFolderName(this.caseFolders, this.currentFolder),
        caseFolders: this.caseFolders,
        roleConsultant: this.userIsConsultant,
        roleClient: this.userIsClient,
      });
      console.log('=====>');
      if (!this.userIsConsultant) {
        console.log('shareWith -- updateConsultantsLists');
        this.updateConsultantsLists();
      }
    });
  }

  async setFileForDelete(file) {
    const fileId = (await this.files_$.getFileByFileId(file.fileId)).docs[0].id;
    this.files_$.updateFileByDocId(fileId, { forDelete: true });
  }

  async selectTarget(type) {
    this.caseFolders = await this.firebase_utilities_$.getFolders(this.casename);
    const treeData = this.caseFolders
      .map(({ name, color, folderId }) => ({ name, id: folderId, color, children: [] }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const dialogConfig = new MatDialogConfig();
    this.dialog.openDialogs.pop();
    dialogConfig.minWidth = '350px';
    dialogConfig.autoFocus = true;
    dialogConfig.data = treeData;

    const dialogRef = this.dialog.open(FolderSelectorComponent, dialogConfig);

    dialogRef
      .keydownEvents()
      .toPromise()
      .then(e => {
        if (!e) {
          return;
        }
        if (e.key === 'Escape') {
          e.preventDefault();
          dialogRef.close();
        }
      });

    dialogRef.afterClosed().subscribe(async folderId => {
      // NOTE: Targeting the root is not allowed.
      if (folderId === '') {
        return;
      }

      switch (type) {
        case FileActionsDictionary.COPY:
          // NOTE: Run copy action.
          await this.copySelectedTo(folderId);
          break;
        case FileActionsDictionary.MOVE:
          // NOTE: Run move action.
          await this.moveSelectedTo(folderId);
          break;
        default:
          break;
      }

      this.browseFolderContents(folderId, this.getFolderName(this.caseFolders, folderId));

      // NOTE: Refresh ActiveConsultants:
      if (!this.userIsConsultant) {
        this.updateConsultantsLists();
      }
    });
  }

  setPaging(filesArr, folderId, folderName) {
    filesArr = filesArr.map(file => ({ ...file, name: file.fileName }));
    this.files = filesArr;
    this.files_$.addPatientFiles(filesArr);
    this.filesTableDataSource = new MatTableDataSource(this.files.slice());

    if (folderId === this.homeFolderName) {
      const sortedFolders = this.sortingIndexFolders(this.files);
      this.filesTableDataSource = new MatTableDataSource(sortedFolders);
    } else {
      this.updateDataTable();
    }

    this.currentFolder = folderId;
    this.currentFolderName = folderName || this.homeFolderName;
    const count = this.currentFolder === this.homeFolderName ? this.files.length + 1 : this.files.length;
    this.setPageSize(count);
    return filesArr;
  }

  /**
   * Use sort function by specific criteria.
   */
  setSort(criteria: string, direction) {
    this.sort.sort({ id: null, start: direction, disableClear: false });
    this.sort.sort({ id: criteria, start: direction, disableClear: false });
    this.updatePaginator();
  }

  showInfectedFiles(files) {
    this.dialog
      .open(SimpleMessageWindowComponent, {
        width: '550px',
        disableClose: true,
        data: {
          title: 'Malicious File Detected',
          message: 'The following files are infected and has been deleted:',
          list: files.map(n => ({ name: n })),
          buttons: [{ text: 'CLOSE AND REFRESH', value: 'refresh' }],
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result === 'refresh') {
          this.goRefresh(null);
        }
      });
  }

  showSharedConsultantsByFile(file) {
    if (!this.activeConsultants) {
      return;
    }
    const scEmails = this.activeConsultants.map(c => c.email);
    if (scEmails.length) {
      this.getMissingSharedFiles(file);
    }
    this.pickedSharedFile = file;
    this.sharing_files_$.pickedFile.next(this.pickedSharedFile);
  }

  getSortedData(data, sort) {
    return data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'isShared':
          return this.compare(a.isShared, b.isShared, false);
        case 'fdate':
          return this.compareDates(a.fdate, b.fdate, isAsc);
        case 'predefined':
          return this.compare(a.predefined, b.predefined, isAsc);
        case 'fileDesc':
          return this.compare(a.fileDesc, b.fileDesc, isAsc);
        case 'lastModified':
          return this.compare(a.lastModified, b.lastModified, isAsc);
        case 'uploadedDate':
          return this.compare(a.uploadedDate, b.uploadedDate, isAsc);
        case 'parentFolderName':
          return this.compare(a.parentFolderName, b.parentFolderName, isAsc);
        case 'ftype':
          return this.compare(a.ftype, b.ftype, isAsc);
        case 'fileName':
          return this.compare(a.fileName, b.fileName, isAsc);
        default:
          return 0;
      }
    });
  }

  handleSortData(sort: Sort) {
    const data = this.files.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = this.getSortedData(data, sort);
    this.filesTableDataSource = new MatTableDataSource(this.sortedData);
    this.updatePaginator();
    if (this.filesTableDataSource.paginator) {
      this.filesTableDataSource.paginator.firstPage();
    }
    this.ref.detectChanges();
    this.selection.clear();
    this.refreshButton = true;
    this.refreshing = false;
  }

  /**
   * Gets the data and:
   * 1. Isolate index link.
   * 2. Isolate predefined items and sort them alphabetically.
   * 3. Isolate custom folders and sort them alphabetically.
   * 4. Join 1, 2 and 3 keeping that order.
   * @param data is an array of files.
   */
  sortingIndexFolders(data) {
    let indexItem;
    if (this.realRole === UserRoles.client) {
      indexItem = false;
    } else {
      let indexINDEX = 0;
      indexItem = data.filter((file, i) => {
        if (file.fileName.substring(0, 5) === indexFolderLabel) {
          indexINDEX = i;
          return true;
        }
      })[0];
      data.splice(indexINDEX, 1);
    }
    const predefinedItems = data.filter(({ predefined }) => predefined === true);
    const customItems = data.filter(({ predefined }) => predefined !== true);
    const predefinedSortedItems = predefinedItems.slice().sort((a, b) => this.compare(a.fileName, b.fileName, true));
    const customSortedItems = customItems.slice().sort((a, b) => this.compare(a.fileName, b.fileName, true));

    return (indexItem ? [indexItem] : []).concat(predefinedSortedItems).concat(customSortedItems);
  }

  /**
   * Once you have selected one or more files, this action will
   * place selected one at the top of the list followed by the
   * non selected ones.
   */
  sortFilesBySelected() {
    const selectedItems = this.selection.selected;
    const selected = selectedItems.map(item => item.fileId);
    const data = this.filesTableDataSource.data.filter(item => !selected.includes(item.fileId));
    this.filesTableDataSource.data = selectedItems.concat(data);
  }

  toggleHighLight(event, file) {
    const classList = event.target.classList;
    if (classList.contains('on')) {
      this.cleanHighlighted();
      this.showSharedConsultantsByFile({});
    } else {
      this.cleanHighlighted();
      event.target.classList.add('on');
      this.showSharedConsultantsByFile(file);
    }
    this.focusActiveConsultantsTab();
  }

  async unShareSingleFile(row) {
    const activeConsultant = this.activeConsultant.toLowerCase();
    await this.firebase_utilities_$.unShareFile(row, activeConsultant, this.casename);
    console.log('unShareSingleFile -- getFilesForPatient');
    this.getFilesForPatient(this.currentFolder, {
      folderName: this.getFolderName(this.caseFolders, this.currentFolder),
      caseFolders: this.caseFolders,
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
    }).then(() => {
      this.updateActiveConsultant(activeConsultant, {
        active: 'isShared',
        direction: 'desc',
      });
      if (!this.userIsConsultant) {
        this.updateConsultantsLists();
      }
      this.utils_$.createNotificationEmail('UNSHARE', row, activeConsultant);
      this.log_$.storeLog(
        this.auth_$.userData.getValue(),
        LogLevelsDictionary.info,
        {
          message: 'A file has been unshared.',
          filename: row.fileName,
          user: activeConsultant,
        },
        'Unsharing',
        this.casename,
      );
    });
  }

  updateActiveConsultant(consultant?, sort?) {
    if (!sort && !consultant) {
      this.activeConsultant = null;
      this.setSort('fileName', 'desc');
      return;
    }

    this.activeConsultant = consultant || null;
    this.updateDataSharedStatus();
    this.setSort(sort.active, sort.direction);
  }

  updateFile(data, file) {
    const afterUpdate = async () => {
      const folderName = this.getFolderName(this.caseFolders, this.currentFolder);
      const message = successmessage + ' Reloading this list...';
      const label = 'INFORMATION';
      this.uiMessaging_$.toastMessage(message, label);
      console.log('updateFile -- getFilesForPatient');
      await this.getFilesForPatient(this.currentFolder, {
        folderName,
        caseFolders: this.caseFolders,
        roleConsultant: this.userIsConsultant,
        roleClient: this.userIsClient,
      });
      this.auth_$.snackBar.dismiss();
      if (!this.userIsConsultant) {
        await this.updateConsultantsLists();
      }
      return successmessage;
    };

    const handleError = error => {
      const message = error.message;
      const label = null;
      this.uiMessaging_$.toastMessage(message, label);
      return error.message;
    };

    const successmessage = data.type === 'folder' ? 'Folder updated successfully' : 'File updated successfully.';

    let ndate = null;
    if (data.date) {
      const date = new Date(data.date.value);
      ndate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }

    if (data.type === 'folder') {
      this.files_$.getFilesByFolderId(file.folderId).then(fileSnapShot => {
        fileSnapShot.forEach(item => {
          const updateObject = {};

          if (data.name) {
            updateObject['name'] = data.name;
          }

          this.files_$
            .updateFileByDocId(item.id, updateObject)
            .then(() => afterUpdate())
            .catch(error => handleError(error));
        });
      });
    } else {
      this.files_$.getFileByFileId(file.fileId).then(fileSnapShot => {
        fileSnapShot.forEach(item => {
          const updateObject = {};
          if (data.name) {
            updateObject['fileName'] = data.name;
          }
          if (data.desc) {
            updateObject['fileDesc'] = data.desc;
          }
          if (ndate) {
            updateObject['fdate'] = ndate;
          }
          this.files_$
            .updateFileByDocId(item.id, updateObject)
            .then(() => afterUpdate())
            .catch(error => handleError(error));
        });
      });
    }
  }

  async updateConsultantsLists() {
    if (this.currentFolder === '') {
      console.log('The current folder is Homepage and there is no need of getting the consultants list');
      return;
    }
    this.activeConsultants = await this.firebase_utilities_$.getActiveConsultantsByClientId(
      UserRoles.consultant,
      this.casename,
    );
    this.availableConsultants = await this.firebase_utilities_$.getAvailableConsultants(
      UserRoles.consultant,
      this.casename,
      this.activeConsultants,
    );
  }

  // FIXME: In case of 'all' should not touch those files already unshared.
  async unShareWith(params) {
    const { consultant, type } = params;
    const array = type === 'single' ? this.selection.selected : this.filesTableDataSource.data;
    const filesToUnShare = [];
    for (let index = 0; index < array.length; index++) {
      if (array[index].sharedUsers && array[index].sharedUsers.length === 0) {
        continue;
      }
      if (this.utils_$.fileIsShared({ email: consultant }, array[index])) {
        await this.firebase_utilities_$.unShareFile(array[index], consultant, this.casename);
        filesToUnShare.push(array[index]);
      } else {
        continue;
      }
    }
    const filesGroup = filesToUnShare.map(item => item.fileName);
    this.log_$.storeLog(
      this.auth_$.userData.getValue(),
      LogLevelsDictionary.info,
      {
        message: 'A group of files has been unshared.',
        filenames: filesGroup.join(','),
        user: consultant,
      },
      'Unshare',
      this.casename,
    );
    const folderName = this.getFolderName(this.caseFolders, this.currentFolder);
    console.log('unShareWith -- getFilesForPatient');
    this.getFilesForPatient(this.currentFolder, {
      folderName,
      caseFolders: this.caseFolders,
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
    });
    if (!this.userIsConsultant) {
      this.updateConsultantsLists();
    }
  }

  async unShareAll(consultantEmail) {
    this.unShareWith({ consultant: consultantEmail, type: 'all' }).then(() => (this.activeConsultant = null));
  }

  updateDataSharedStatus() {
    const newData = [];
    const currentData = this.filesTableDataSource.data;
    currentData.forEach(item => {
      if (typeof item.sharedUsers === 'undefined') {
        item.isShared = false;
      } else {
        item.isShared = this.isSharedWithActiveConsultant(item.sharedUsers) ? true : false;
      }
      newData.push(item);
    });
    this.filesTableDataSource.data = newData;
  }

  updatePaginator() {
    this.filesTableDataSource.paginator = this.uppaginator;
  }

  updateDataTable() {
    this.sortedData = this.files.slice();
    this.filesTableDataSource = new MatTableDataSource<any>(this.files);
    this.updatePaginator();
    if (this.filesTableDataSource.paginator) {
      this.filesTableDataSource.paginator.firstPage();
    }
    this.ref.detectChanges();
    this.selection.clear();
    this.refreshButton = true;
    this.refreshing = false;
  }

  validateRole(role: string) {
    return this.permissions_$.validateRole(role);
  }

  async validateRoleMulti(objectList: any) {
    return await this.permissions_$.validateRoleMulti(objectList);
  }

  handleNoteClick(event, element) {
    console.log('element :', element);
    event.stopPropagation();
    this.openAllNotes();
  }

  handleAddClientUser(event) {
    console.log('event :', event);
    console.log('handleAddClientUser');
    const dialog = this.dialog.open(CreateCuaUserComponent, {
      width: '600px',
      data: {
        patient: this.patient,
        user: event,
      },
    });
    dialog.afterClosed().subscribe(async ({ success, result }) => {
      if (success) {
        const { email } = result;
        await this.clientMatter_$.updateClientMatter(this.casename, email).then(res => {
          console.log('Patient updated :', res);
          this.updateClientData(email);
        });

        await this.createClientUploadFolder(this.casename)
          .then(res => {
            console.log('Folder created :', res);
            this.updateFoldersList().then(() => this.getData());
          })
          .catch(err => {
            console.error('Error creating folder :', err);
          });
      }
    });
  }

  private async updateFoldersList() {
    this.caseFolders = await this.handlingFolders();
  }

  private async createClientUploadFolder(casename) {
    const result = await this.createNewFolder('Client Upload', casename, {
      color: folderColors['Client Upload'],
      type: 'folder',
      predefined: true,
      forDelete: false,
      modal: false,
    });
    return result;
  }

  private updateClientData(clientUserEmail) {
    this.patient.clientUser = clientUserEmail;
  }

  private async createNewFolder(
    folderName: string,
    caseName: string,
    options = {
      color: '#c5f0f6',
      type: 'folder',
      predefined: false,
      forDelete: false,
      modal: false,
    },
  ) {
    if (this.checkDuplicatedFolder(folderName)) {
      if (options.modal) {
        this.uiMessaging_$.toastMessage(`Folder name ${folderName} is already taken`, ``);
      }
      return;
    }

    this.auth_$.showLoader(`Creating folder ${folderName}...`);
    const folderId = this.uploadHelper_$.getRandomString(20);
    const result = await this.firebase_utilities_$.createFolder({
      children: ``,
      parentFolder: ``,
      folderId: folderId,
      belongsTo: caseName,
      name: folderName.trim(),
      color: options.color,
      type: options.type,
      predefined: options.predefined,
      forDelete: options.forDelete,
    });
    this.auth_$.hideLoader();
    return result;
  }

  getDefinedDefaultFolders(userRole) {
    console.log('getDefinedDefaultFolders userRole :', userRole);
    return this.firebase_utilities_$.getDefaultFoldersDefinition(userRole);
  }

  async getDefaultFolders() {
    return (await firebase.firestore().collection('defaultFolders').where('business', '==', 'legal').get()).docs.map(
      d => d.data(),
    );
  }

  async handleFolder(folder, params, useremail, filesShared) {
    const folderExtra = await this.getFolderExtra(folder, params.roleConsultant, useremail, filesShared);
    return { ...folder, ...folderExtra };
  }
}
