import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { FolderButton } from '../../models/FolderButton';
import { FirebaseUtilitiesService } from './../../services/firebase-utilities.service';
import { UtilsService } from './../../services/utils.service';

@Component({
  selector: 'app-choose-target-directory',
  template: `<div fxLayout="column">
    <input
      (keyup)="handleSearchFolder($event, searchFolder.value)"
      #searchFolder
      class="search-folder"
      placeholder="Search for folder"
      autocomplete="off" />
    <div class="folders-list-container">
      <div
        [ngClass]="'predefined-folders'"
        fxflexfill=""
        fxlayoutgap="0px grid">
        <button
          mat-button
          [class]="getButtonClassName(folder.label)"
          (click)="
            handleSelectFolder(null, { folderId: folder.folderId, folderName: folder.label });
            toggleCustomFolders = false
          "
          [ngClass]="{ 'selected-folder': selectedFolder === folder.folderId, 'folder-boxed-button': true }"
          *ngFor="let folder of predefinedFolders">
          <mat-icon class="material-icons">folder</mat-icon> {{ folder.label }}
          <mat-icon
            class="check-icon"
            *ngIf="selectedFolder === folder.folderId"
            >check</mat-icon
          >
        </button>
      </div>
      <div fxLayout="column">
        <app-custom-folders
          fxFill="100"
          [filter]="customFolderFilter"
          (selectFolder)="handleSelectFolder(null, $event)"
          (createAndSelect)="handleCreateAndSelect($event)"
          [selectedFolder]="selectedFolder"
          [currentFolder]="currentFolder"
          [casename]="casename">
        </app-custom-folders>
      </div>
    </div>
  </div> `,
  styles: [
    `
      folder-button {
        margin: 0px;
      }

      .custom-folders-container {
        position: relative;
      }

      .search-folder {
        border: 1px solid #ddd;
        padding: 10px 11px;
      }

      .folder-boxed-button {
        text-align: left;
        border-radius: 0;
        padding: 0 10px;
      }
      .folder-boxed-button .check-icon {
        position: absolute;
        right: 0px;
        top: 0px;
      }
      .predefined-folders {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(80px, 100%));
        gap: 0px 0px;
        clear: both;
        height: auto;
      }

      /***/
      .boxed-button-Damages mat-icon {
        color: #ffc1c1;
      }

      .boxed-button-Discovery mat-icon {
        color: #ffda81;
      }

      .boxed-button-ClientUpload mat-icon {
        color: #cf0cf0;
      }

      .boxed-button-Liability mat-icon {
        color: #d1f3c3;
      }

      .boxed-button-Other mat-icon {
        color: #e7e7e7;
      }

      .boxed-button-Custom mat-icon {
        color: #c5f0f6;
      }

      .mat-button {
        line-height: 25px;
      }

      .folders-list-container {
        max-height: 400px;
        overflow-y: auto;
      }
    `,
  ],
})
export class ChooseTargetDirectoryComponent implements OnInit {
  predefinedFolders: FolderButton[];
  toggleCustomFolders: boolean;
  selectedFolder: string;
  caseName: string;

  @Input() casename: string;
  @Input() currentFolder: any;
  @Output() selectFolderEv = new EventEmitter<string>();
  @Output() selectFolderAndQuitEv = new EventEmitter<string>();
  @Output() justQuit = new EventEmitter<string>();
  customFolderFilter: string;
  eventsSubject: Subject<void> = new Subject<void>();

  @HostListener('document:click', ['$event.target'])
  clickOutside(ex) {
    if ((<HTMLElement>ex).innerText === 'keyboard_arrow_right' || (<HTMLElement>ex).className === 'arrow-button') {
      return false;
    } else if (
      this.predefinedFolders.length > 0 &&
      (<HTMLElement>ex).id !== 'foldernameValue' &&
      !(<HTMLElement>ex).classList.contains('search-folder')
    ) {
      this.justQuit.emit();
    }
  }

  constructor(private firebaseUtilities_$: FirebaseUtilitiesService, private utils_$: UtilsService) {}

  ngOnInit() {
    this.toggleCustomFolders = false;
    this.predefinedFolders = [];

    this.getPredefinedFolders();
  }

  /**
   * Function to sort alphabetically an array of objects by some specific key.
   * @param property Key of the object to sort.
   */
  dynamicSort(property: string) {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  emitEventToChild() {
    this.eventsSubject.next();
  }

  getButtonStyle(name) {
    return { ...this.utils_$.getButtonStyle(name) };
  }

  getButtonClassName(name): string {
    return `boxed-button-${name}`;
  }

  getPredefinedFolders() {
    this.firebaseUtilities_$.getPredefinedFolders(this.casename).then(predefinedFolders => {
      let folderId, folderName;
      const sortedFolders = predefinedFolders.sort(this.dynamicSort('name'));
      const localArray = [];
      sortedFolders.forEach(item => {
        localArray.push({
          label: item.name,
          color: item.color || 'lightgray',
          type: 'normal',
          folderId: item.folderId,
        });
        if (this.currentFolder.folderId === item.folderId) {
          folderId = item.folderId;
          folderName = item.name;
        }
      });
      this.predefinedFolders = localArray;
    });
  }

  handleCreateAndSelect(ev) {}

  handleSearchFolder(event, value) {
    this.customFolderFilter = event.currentTarget.value;
    this.emitEventToChild();
    if (value === '') {
      this.getPredefinedFolders();
    } else {
      this.predefinedFolders = this.predefinedFolders.filter(
        folder => folder.label.toLowerCase().indexOf(value.toLowerCase()) === 0,
      );
    }
  }

  handleSelectFolder(event, folderObj): void {
    if (!event) {
      this.selectFolder(folderObj);
    } else {
      this.selectFolderAndQuit(folderObj);
    }
  }

  randomHexColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  selectFolder(folderObj: any): void {
    this.selectedFolder = folderObj.folderId;
    this.selectFolderEv.emit(Object.entries(folderObj).join('*'));
  }

  selectFolderAndQuit(folderObj: any): void {
    this.selectedFolder = folderObj.folderId;
    this.selectFolderAndQuitEv.emit(Object.entries(folderObj).join('*'));
  }
}
