<app-choose-target-directory
  *ngIf="targetDirectoryChooser"
  [ngStyle]="getRightClickMenuStyle()"
  [ngClass]="'floating-menu'"
  [currentFolder]="currentFolder"
  [casename]="casename"
  (selectFolderEv)="onTargetFolderSelection($event, 1)"
  (selectFolderAndQuitEv)="onTargetFolderSelection($event, 0)"
  (justQuit)="targetDirectoryChooser = false"
  class="choose-target-directory"></app-choose-target-directory>

<app-window-title
  [title]="data.title || 'Upload Files or Disks'"
  [subtitle]="'Client/Matter: ' + casename"
  [closable]="true"></app-window-title>

<div *ngIf="!dataSource.data.length && clioMatterId">
  <app-medical-studies-list></app-medical-studies-list>
</div>

<form
  [formGroup]="formDoc"
  class="upload-form"
  novalidate>
  <div
    fxLayout="row"
    fxLayoutAlign="space-around center"
    [ngClass]="'margin-bottom-20'">
    <mat-form-field [ngClass]="'hidden-component'">
      <app-input-file
        #fileBrowser
        label="Choose file(s)"
        formControlName="basicfile"
        [ngClass]="'file-browser'"
        [multiple]="false"
        [directory]="false"
        [allowDirs]="false"
        placeholder=""
        (change)="handleFileSelection($event)"
        uploadFileType=".gif,.jpg,.tiff,.bmp,.vbo,.mp3,.wav,.mp4,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.csv">
      </app-input-file>
      <br />
    </mat-form-field>
    <button
      type="button"
      *ngIf="!section && section !== 'upload_dicom_disk'"
      [disabled]="editingNode || !chooseFiles"
      mat-flat-button
      color="warn"
      [ngClass]="'browse-button'"
      (click)="browseFiles($event, fileBrowser.ngControl.name)">
      <mat-icon matSuffix>description</mat-icon>
      Browse your desktop for files
    </button>

    <mat-form-field [ngClass]="'hidden-component'">
      <app-input-file
        #folderBrowser
        label="Choose disk or folder"
        formControlName="basicfolder"
        [ngClass]="'folder-browser'"
        [multiple]="true"
        [directory]="true"
        [allowDirs]="true"
        placeholder=""
        (change)="handleFolderSelection($event)"
        accept="{{ uploadFileType }}">
      </app-input-file>
      <br />
    </mat-form-field>
    <button
      type="button"
      #uploadDiskButton
      mat-flat-button
      [disabled]="editingNode"
      *ngIf="treeControl.dataNodes.length === 0"
      color="warn"
      [ngClass]="'browse-button'"
      (click)="browseFiles($event, folderBrowser.ngControl.name)">
      <mat-icon matSuffix>album</mat-icon>
      Browse your desktop for discs to upload additional medical images
    </button>
  </div>

  <p
    class="mat-small"
    *ngIf="!section && section !== 'upload_dicom_disk'"
    [innerHtml]="allowedExtensionsMessage"></p>

  <!-- LIST COMPONENT -->
  <mat-dialog-content>
    <mat-tree
      [dataSource]="dataSource"
      #matTreeControl
      [treeControl]="treeControl">
      <mat-tree-node
        [ngClass]="{ 'edit-mode': node.edit, 'in-progress': node.inprogress, treenode: true, selected: node.selected }"
        *matTreeNodeDef="let node"
        matTreeNodePadding
        #treeNode
        fxLayoutAlign="space-between center"
        fxLayoutGap="10px">
        <mat-checkbox
          [checked]="node.toUpload !== false"
          [value]="node"
          class="example-margin"
          (change)="checkNode($event, node)"></mat-checkbox>

        <div
          fxLayout="column"
          [fxFlex]="node.edit ? 100 : 80">
          <span [ngClass]="node.edit ? 'mat-title' : 'mat-small'">
            <b class="file-title">{{ node.name }}</b>
          </span>

          <div>
            <div fxLayout="column">
              <span
                [ngClass]="'mat-small'"
                *ngIf="!node.edit">
                <b *ngIf="node.name === dicomdirFileName">Date of study:</b>
                <b *ngIf="node.name !== dicomdirFileName">Date of file </b>
                <span
                  *ngIf="node.fdate === ''"
                  [ngClass]="'red-text'"
                  >*</span
                >:
                {{ node.fdate }}
              </span>

              <span
                [ngClass]="'mat-small'"
                *ngIf="!node.edit">
                <b>Content</b>
                <span
                  *ngIf="node.desc === ''"
                  [ngClass]="'red-text'"
                  >*</span
                >
                : {{ node.desc }}
              </span>

              <span
                [ngClass]="'mat-small'"
                *ngIf="!node.edit && changeFolderPermission">
                <b>Folder</b>
                <span
                  *ngIf="!node.target.folderId"
                  [ngClass]="'red-text'"
                  >*</span
                >
                :{{ node.target.folderName }}
              </span>
            </div>

            <!-- EDIT FORM -->

            <form
              action=""
              fxLayout="column">
              <div fxLayout="row">
                <!-- FILE DATE FIELD -->
                <mat-form-field
                  fxFlex="35"
                  color="primary"
                  appearance="fill"
                  *ngIf="node.edit">
                  <mat-label>Date of file</mat-label>
                  <input
                    matInput
                    required
                    autocomplete="off"
                    color="primary"
                    [disabled]="!node.edit"
                    [matDatepicker]="picker"
                    #datepicker
                    [value]="getDateFromString(node.fdate).value"
                    (dateInput)="
                      fieldInputChange_date($event, node);
                      checkEmpty(datepicker);
                      setFocusToNextControl(datepicker);
                      evaluateCompletion(node)
                    " />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <!-- FILE CONTENT FIELD -->
                <mat-form-field
                  fxFlex="40"
                  color="primary"
                  *ngIf="node.edit"
                  appearance="fill">
                  <mat-label>File Content</mat-label>
                  <input
                    matInput
                    required
                    color="primary"
                    #filecontent
                    placeholder="Enter some text"
                    [value]="node.desc"
                    (keyup)="fieldInputChange_content($event, node); checkEmpty(filecontent); evaluateCompletion(node)"
                    autocomplete="off" />
                </mat-form-field>

                <!-- CHOOSE/CHANGE FOLDER BUTTON -->
                <button
                  type="button"
                  fxFlex="25"
                  mat-icon-button
                  #folderBtn
                  [matTooltip]="node.target.folderName ? 'Change folder' : 'Choose folder.'"
                  aria-label="Click here to set the folder for this file."
                  [ngClass]="{
                    'folder-btn': true,
                    'special-icon-button': true,
                    'highlighted-control': node.fdate !== '' && node.desc !== '' && node.target.folderId === ''
                  }"
                  *ngIf="changeFolderPermission && node.target && node.edit"
                  (click)="selectNode(node)">
                  <mat-icon>folder</mat-icon>
                  <label>{{ node.target.folderName ? 'Change folder' : 'Choose folder.' }}</label>
                  <input
                    type="text"
                    [value]="node.target.folderName"
                    (input)="evaluateCompletion(node)"
                    name="folderName"
                    class="folder-name"
                    id="folderChooser_{{ node.ugid }}"
                    size="20" />
                </button>
              </div>

              <div fxLayout="row">
                <button
                  type="button"
                  mat-button
                  aria-label="Click here to save."
                  (click)="saveGroup(node)"
                  *ngIf="node.edit"
                  matTooltip="Save the changes."
                  [color]="node.desc !== '' && node.fdate !== '' && node.target.folderId ? 'warn' : ''"
                  [ngClass]="{
                    'special-icon-button': true,
                    'highlighted-control': node.desc !== '' && node.fdate !== '' && node.target.folderId
                  }">
                  <mat-icon>check</mat-icon> SAVE FILE
                </button>

                <button
                  type="button"
                  mat-button
                  aria-label="Cancel edit."
                  (click)="cancelEdit(node)"
                  *ngIf="node.edit"
                  matTooltip="Cancel the edition.">
                  <mat-icon>close</mat-icon> CANCEL
                </button>

                <button
                  type="button"
                  mat-button
                  (click)="editingNode = false; nodeFxLayout = 'row'; deleteNode($event, node)"
                  *ngIf="node.edit"
                  aria-label="Click here to delete this upload."
                  matTooltip="Click here to delete this upload.">
                  <mat-icon>delete</mat-icon> DELETE
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          fxLayoutAlign="space-between center"
          fxLayout="row"
          fxFlex="nogrow"
          fxFlex="20"
          [ngClass]="{ 'action-buttons-disabled': node.edit }">
          <button
            type="button"
            [disabled]="node.edit"
            mat-flat-button
            aria-label="Click here to edit this upload."
            (click)="gotoEdit(node)"
            matTooltip="Edit this file."
            [ngClass]="{ 'highlighted-control': node.fdate === '' || node.desc === '', 'special-icon-button': true }">
            <mat-icon>create</mat-icon>
          </button>

          <button
            type="button"
            [disabled]="node.edit"
            mat-icon-button
            aria-label="Click here to delete this upload."
            matTooltip="Click here to delete this upload."
            (click)="deleteChild($event, node)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-tree-node>

      <mat-tree-node
        fxLayoutAlign="space-between start"
        *matTreeNodeDef="let node; when: hasChild"
        [ngClass]="{ 'edit-mode': node.edit, grouphead: true }"
        matTreeNodePadding
        #treeNode>
        <button
          type="button"
          fxFlex="none"
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon [ngClass]="'mat-icon-rtl-mirror'">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>

        <div
          fxLayout="column"
          fxFlex>
          <span><b>Group:</b> {{ node.name }}</span>
          <span>{{ node.desc }}</span>
        </div>

        <div
          fxLayout="row"
          fxFlex="nogrow">
          <button
            type="button"
            *ngIf="!node.edit"
            mat-icon-button
            aria-label="Click here to delete this group."
            matTooltip="Click here to delete this group."
            (click)="deleteGroup(node.ugid)">
            <mat-icon>delete</mat-icon>
          </button>
        </div> </mat-tree-node
      >x
    </mat-tree>
  </mat-dialog-content>
  <!-- end LIST COMPONENT -->
</form>

<p
  *ngIf="(nodeInEdit && inEditUnComplete) || (!runValidations() && dataSource.data.length > 0 && !nodeInEdit)"
  [ngClass]="'message mat-body'"
  [innerHtml]="requiredFieldsValidationMessage | safeHtml"></p>

<div
  fxLayout="row"
  [ngClass]="'md-actions'"
  fxLayoutGap="10px"
  fxLayoutAlign="start center">
  <button
    *ngIf="dataSource.data.length > 0"
    mat-flat-button
    color="warn"
    (click)="handleUploadClick()"
    matTooltip="Click to start to upload the files."
    [disabled]="!runValidations() || editingNode || !filesWithoutTarget()">
    {{ getUploadButtonLabel() }}
  </button>
  <div
    fxLayout="row"
    fxLayoutAlign="start center">
    <mat-spinner
      class="spinner"
      diameter="20"
      color="warn"
      strokeWidth="5"
      mode="indeterminate"
      *ngIf="clicked"></mat-spinner>
    <span
      *ngIf="clicked"
      [ngClass]="'mat-body red-text'"
      >Please wait...</span
    >
  </div>
</div>
