import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { AuthService } from 'src/app/services/auth.service';
import { GapiOperationsService } from 'src/app/services/gapi-operations.service';

const dicomdirFileName = 'DICOMDIR';
@Component({
  selector: 'app-sysadmin',
  template: `<p>sysadmin works!</p>
    <button (click)="clearDuplicatedFolders('Clio')">Clear CLIO folders</button>
    <button (click)="clearDuplicatedFolders('Other')">Clear OTHER folders</button>
    <button (click)="clearDuplicatedFolders('Damages')">Clear DAMAGES folders</button>
    <button (click)="clearDuplicatedFolders('Liability')">Clear LIABILITY folders</button>

    <!-- <button (click)="userEmailsToLowerCase()">make al users email to lowercase</button>
<button (click)="seeAllUsersEmails()">See all users emails</button>
<button (click)="listSuperUsers()">list superusers</button>
<button (click)="listViewerURLs()">Check vieweruls</button> -->
    <!-- <button (click)="updateFolderColors()">Update folder colors</button> -->
    <!-- <button (click)="allUsersEmailToLowerCase()">allUsersEmailToLowerCase</button> -->
    <!-- <button mat-button (click)="updateFilesSharedUsers()">Update Files Shared Users</button> -->
    <!-- <button mat-flat-button color="warn" (click)="showSpecificFile('MR BRAIN NECK WO IV CONTRAST STROKE')">
      Show specific file
    </button> -->
    <!-- <button mat-flat-button color="warn" (click)="showUserInfo('dmayerlen1+mayer@gmail.com')">Show user info</button> -->
    <!-- <button mat-flat-button color="warn" (click)="updateFilesSharedUsers()">Update Files Shared Users</button> -->
    <!-- <button mat-flat-button color="warn" (click)="removeSharingReferencesOfFilesDeletedInUsers()">
      Fixing the sharing between users and file
    </button> -->
    <!-- <button mat-flat-button color="warn" (click)="checkingData()"> -->
    <!-- <button mat-flat-button color="warn" (click)="removeSharingReferencesOfFilesDeleted()"> -->
    <!-- <button
      mat-flat-button
      color="warn"
      (click)="removeSharingOfUsersDeleted()">
      removeSharingOfUsersDeleted
    </button> -->
    <!-- <button mat-flat-button color="warn" (click)="usersFilesSharingFixing()"> --> `,
})
export class SysadminComponent implements OnInit {
  constructor(private gapiOperations_$: GapiOperationsService, private auth_$: AuthService) {}

  ngOnInit() {
    this.auth_$.preventRedirections = true;
  }

  checkingData() {
    firebase
      .firestore()
      .collection('users')
      .where('email', '==', 'jandbee14+consultantorr@gmail.com')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log('doc :', doc.data());
        });
      });
  }

  async removeSharingOfUsersDeleted() {
    const listOfUsers = (await firebase.firestore().collection('users').where('forDelete', '==', false).get()).docs.map(
      doc => doc.data().email,
    );

    firebase
      .firestore()
      .collection('files')
      .where('sharedUsers', '>', [])
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const sharedUsers = doc.data().sharedUsers;
          if (sharedUsers && sharedUsers.length > 0) {
            const newSharedUsers = sharedUsers.filter(item => listOfUsers.includes(item.email));
            if (newSharedUsers.length !== sharedUsers.length) {
              firebase.firestore().collection('files').doc(doc.id).update({ sharedUsers: newSharedUsers });
            }
          }
        });
      });
  }

  async clearDuplicatedFolders(foldername: string) {
    const isEmptyFolder = async (folderId: string): Promise<boolean> => {
      const files = await firebase
        .firestore()
        .collection('files')
        .where('parentFolder', '==', folderId)
        .where('forDelete', '==', false)
        .get();
      if (files.docs.length === 0) {
        return true;
      } else {
        return false;
      }
    };

    const clioFolders = (
      await firebase
        .firestore()
        .collection('files')
        .where('name', '==', foldername)
        .where('forDelete', '==', false)
        .get()
    ).docs.map(doc => ({ ...doc.data(), id: doc.id }));
    clioFolders.sort((a, b) => (a['belongsTo'] < b['belongsTo'] ? 1 : -1));
    const result = [];
    let folderName = '';
    let index = 0;

    for (const folder of clioFolders) {
      if (folder['belongsTo'] !== folderName && folder['forDelete'] === false) {
        folderName = folder['belongsTo'];
        index++;
        result[index] = [];
        result[index]['name'] = folderName;
      }
      result[index].push({ ...folder, empty: await isEmptyFolder(folder['folderId']) });
    }

    const duplicatedFolders = result.filter(item => item.length > 1);
    console.log('duplicated CLIO folders :', duplicatedFolders);

    const deleteEmpties = async (foldersToDelete: any[]) => {
      console.log('foldersToDelete :', foldersToDelete);
      for (const folder of foldersToDelete) {
        await firebase.firestore().collection('files').doc(folder.id).update({ forDelete: true });
      }
    };

    const deleteAllButOneEmpty = async (folders: any[]) => {
      console.log('deleteAllButOneEmpty:', folders);
      const folderChoosen = folders[0];
      for (const folder of folders) {
        if (folder.id !== folderChoosen.id) {
          await firebase.firestore().collection('files').doc(folder.id).update({ forDelete: true });
        }
      }
    };

    const relocateFiles = async (folders: any[]) => {
      const folderChoosen = folders[0];
      for (const folder of folders) {
        if (folder.id !== folderChoosen.id) {
          const files = await firebase
            .firestore()
            .collection('files')
            .where('parentFolder', '==', folder.id)
            .where('forDelete', '==', false)
            .get();
          for (const file of files.docs) {
            console.log('file: ', file.data());
            await firebase.firestore().collection('files').doc(file.id).update({ parentFolder: folderChoosen.id });
          }
          await firebase.firestore().collection('files').doc(folder.id).update({ forDelete: true });
        }
      }
    };

    for (const foldersGroup of duplicatedFolders) {
      const emptyCount = foldersGroup.filter(folder => folder.empty).length;
      const notEmptyCount = foldersGroup.filter(folder => !folder.empty).length;
      console.log(foldersGroup.name, { emptyCount: emptyCount, notEmptyCount: notEmptyCount });
      if (notEmptyCount > 1) {
        console.log('PROBLEMS :', foldersGroup.name);
        await relocateFiles(foldersGroup.filter(folder => !folder.empty));
      } else if (emptyCount > 1) {
        console.log('Empties :', foldersGroup.name);
      }

      if (notEmptyCount > 0 && emptyCount > 0) {
        deleteEmpties(foldersGroup.filter(folder => folder.empty));
      }

      if (emptyCount > 1 && notEmptyCount === 0) {
        await deleteAllButOneEmpty(foldersGroup.filter(folder => folder.empty));
      }
    }
  }

  async removeSharingReferencesOfFilesDeletedInUsers() {
    const users = (await firebase.firestore().collection('users').get()).docs
      .map(doc => ({
        filesSharedWith: doc.data().filesSharedWith ? doc.data().filesSharedWith.map(j => j.fileId) : [],
        id: doc.id,
        email: doc.data().email,
      }))
      .filter(u => u.filesSharedWith && u.filesSharedWith.length > 0);

    console.log('users :', users);

    for (const user of users) {
      for (const fileId of user.filesSharedWith) {
        const fileDeleted = await firebase
          .firestore()
          .collection('files')
          .where('fileId', '==', fileId)
          .where('forDelete', '==', true)
          .get();
        if (fileDeleted.docs.length > 0) {
          const sharedUsers = fileDeleted.docs[0].data().sharedUsers;
          if (sharedUsers && sharedUsers.length > 0) {
            const newSharedUsers = sharedUsers.filter(f => f.email !== user.email);

            await firebase
              .firestore()
              .collection('files')
              .doc(fileDeleted.docs[0].id)
              .update({ sharedUsers: newSharedUsers })
              .then(() => {
                console.log('sharedUsers email: ', user.email);
                console.log('sharedUsers deleted: ', fileId);
              });

            const filesSharedWith = await firebase
              .firestore()
              .collection('users')
              .where('email', '==', user.email)
              .limit(1)
              .get();

            const newFilesSharedWith = filesSharedWith.docs[0]
              .data()
              ['filesSharedWith'].filter(f => f.fileId !== fileId);

            await firebase
              .firestore()
              .collection('users')
              .doc(filesSharedWith.docs[0].id)
              .update({ filesSharedWith: newFilesSharedWith })
              .then(() => {
                console.log('sharedUsers deleted: ', fileId);
                console.log('User affected', user.email);
              });
          }
        }
      }
    }

    // const sharedFilesDeleted = (await Promise.all(bringSharedFiles))
    //   .filter(g => g.size > 0)
    //   .map(callbackfn => callbackfn.docs.map(doc => doc.data().fileId))
    //   .flat();

    // console.log('sharedFilesDeleted :', sharedFilesDeleted);

    /* firebase
      .firestore()
      .collection('users')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const filesSharedWith = doc.data().filesSharedWith;
          if (filesSharedWith && filesSharedWith.length > 0) {
            filesSharedWith
              .map(fsw => fsw.fileId)
              .forEach(async fileId => {
                await firebase
                  .firestore()
                  .collection('files')
                  .where('fileId', '==', fileId)
                  .get()
                  .then(fileDoc => {
                    fileDoc.docs.forEach(async f => {
                      if (f.data()['forDelete'] === true) {
                        const newFilesSharedWith = filesSharedWith.filter(fsw => fsw.fileId !== fileId);
                        console.log('filesSharedWith :', filesSharedWith);
                        console.log('newFilesSharedWith :', newFilesSharedWith);
                        // await firebase
                        //   .firestore()
                        //   .collection('users')
                        //   .doc(doc.id)
                        //   .update({ filesSharedWith: newFilesSharedWith })
                        //   .then(() => {
                        //     console.log('fileId sharing reference deleted: ', fileId);
                        //   });
                      }
                    });
                  });
              });
          }
        });
      }); */
  }

  removeSharingReferencesOfFilesDeleted() {
    firebase
      .firestore()
      .collection('files')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc.data().sharedUsers && doc.data().sharedUsers.length > 0 && doc.data().forDelete === true) {
            doc.ref.update({ sharedUsers: firebase.firestore.FieldValue.delete() }).then(() => {
              console.log('sharedUsers deleted: ', doc.data().fileId);
            });
          }
        });
      });
  }

  usersFilesSharingFixing() {
    const unexistentFiles = [];
    firebase
      .firestore()
      .collection('users')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const filesSharedWith = doc.data().filesSharedWith;
          if (filesSharedWith && filesSharedWith.length > 0) {
            // const newFilesSharedWith = filesSharedWith.map(fsw => ({ ...fsw, email: fsw.email.toLowerCase() }));
            // firebase.firestore().collection('users').doc(doc.id).update({ filesSharedWith: newFilesSharedWith });
            filesSharedWith.forEach(fsw => {
              firebase
                .firestore()
                .collection('files')
                .where('fileId', '==', fsw.fileId)
                .limit(1)
                .get()
                .then(fileDoc => {
                  if (fileDoc.docs[0]) {
                    console.log('fileDoc[0] :', fileDoc.docs[0].data());
                    const sharedUsers = fileDoc.docs[0].data().sharedUsers;
                    if (!sharedUsers || sharedUsers.length === 0) {
                      firebase
                        .firestore()
                        .collection('files')
                        .doc(fileDoc.docs[0].id)
                        .update({ sharedUsers: [{ email: doc.data().email, name: doc.data().name }] })
                        .then(() => {
                          console.log('file updated: ', fsw.fileId);
                        });
                    }
                  } else {
                    console.log("file doesn't exist: ", fsw.fileId);
                    unexistentFiles.push(fsw.fileId);
                    const newFilesSharedWith = filesSharedWith.filter(fsw => fsw.fileId !== fsw.fileId);
                    firebase
                      .firestore()
                      .collection('users')
                      .doc(doc.id)
                      .update({ filesSharedWith: newFilesSharedWith })
                      .then(() => {
                        console.log('file removed from user: ' + doc.data()['uid'], fsw.fileId);
                      });
                  }
                });
            });
          }
        });
      });
  }

  showUserInfo(email: string) {
    firebase
      .firestore()
      .collection('users')
      .where('email', '==', email)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(
            'doc :',
            doc.data().filesSharedWith.filter(r => r.patientCaseName === 'FlatzvUnionMedical'),
          );
        });
      });
  }

  updateFilesSharedUsers() {
    firebase
      .firestore()
      .collection('files')
      .where('ftype', '==', 'File')
      // .where('forDelete', '==', false)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const sharedUsers = doc.data().sharedUsers;
          // console.log('sharedUsers :', sharedUsers);
          if (sharedUsers && sharedUsers.length > 0) {
            let newSharedUsers;
            if (sharedUsers[0] === true) {
              newSharedUsers = [];
            } else {
              newSharedUsers = sharedUsers.map(item => ({ email: item.email.toLowerCase(), name: item.name }));
            }
            // console.log('newSharedUsers :', newSharedUsers);
            firebase.firestore().collection('files').doc(doc.id).update({ sharedUsers: newSharedUsers });
          }
        });
      });
  }

  showSpecificFile(fileDesc: string) {
    firebase
      .firestore()
      .collection('files')
      .where('fileDesc', '==', fileDesc)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log('doc :', doc.data().sharedUsers);
          // if (doc.data().sharedUsers) {
          //   const newSharedUsers = doc.data().sharedUsers.map(su => ({ email: su.email.toLowerCase(), name: su.name }));
          //   firebase.firestore().collection('files').doc(doc.id).update({ sharedUsers: newSharedUsers });
          // }
        });
      });
  }

  allUsersEmailToLowerCase() {
    firebase
      .firestore()
      .collection('users')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          firebase.firestore().collection('users').doc(doc.id).update({ email: doc.data().email.toLowerCase() });
        });
      });
  }

  userEmailsToLowerCase(): void {
    firebase
      .firestore()
      .collection('users')
      .get()
      .then(querySnapshot =>
        querySnapshot.forEach(doc =>
          firebase.firestore().collection('users').doc(doc.id).update({
            email: doc.data().email.toLowerCase(),
          }),
        ),
      );
  }

  updateFolderColors() {
    firebase
      .firestore()
      .collection('files')
      .where('type', '==', 'folder')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const folderName = doc.data().name;
          console.log('folderName :', folderName);
          switch (folderName) {
            case 'Damages':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#ffc1c1' });
              break;
            case 'Discovery':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#ffda81' });
              break;
            case 'Liability':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#d1f3c3' });
              break;
            case 'Client Upload':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#ccff00' });
              break;
            case 'Other':
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#e7e7e7' });
              break;
            default:
              firebase.firestore().collection('files').doc(doc.id).update({ color: '#c5f0f6' });
              break;
          }
        });
      })
      .catch(err => console.error(err));
  }

  analyzeViewerURL(viewerurl: string): boolean {
    const i = viewerurl.indexOf('https://');
    const newstring = viewerurl.substring(i + 8);
    const stringSplitted = newstring.split('/');
    const part1 = stringSplitted[5];
    const part2 = stringSplitted[7];
    // console.log({ part1, part2 });
    return part1 === part2;
  }

  hasHTTPSprefix(viewerurl: string): boolean {
    return viewerurl.indexOf('https://') > -1;
  }

  listViewerURLs() {
    firebase
      .firestore()
      .collection('files')
      .where('fileName', '==', dicomdirFileName)
      .get()
      .then(querySnapshot => {
        let counter = 0;
        const data = [];
        querySnapshot.forEach(doc => {
          const viewerurl = doc.data().viewerurl;
          if (this.analyzeViewerURL(viewerurl)) {
            data.push({ data: doc.data(), id: doc.id });
          }
        });
        const belongsTo = data
          .map(item => item.data.belongsTo)
          .filter((item, index, array) => array.indexOf(item) === index);
        belongsTo.forEach(item => {
          this.gapiOperations_$.checkIfDataSetExists(item).then(res => {
            // console.log('Dataset exists?: ', res);
            if (!res) {
              counter++;
              console.log('Dataset does not exist, lets delete the file');
              console.log(`${data['id']}`);
              // firebase.firestore().collection('files').doc(data['id']).update({ forDelete: true });
            }
          });
        });
        console.log(`${counter} rows has been affected.`);
      })
      .catch(err => console.error(err));
  }

  seeAllUsersEmails(): void {
    firebase
      .firestore()
      .collection('users')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(doc.data().email);
        });
      });
  }

  listSuperUsers() {
    firebase
      .firestore()
      .collection('users')
      .where('role', '==', 'Super User')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(doc.data().email);
        });
      });
  }
}
