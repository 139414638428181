import { Component, Inject, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { LogLevelsDictionary } from 'src/app/dictionaries/LogLevels';
import { UserRoles } from 'src/app/dictionaries/UserRoles';
import { LogService } from 'src/app/log.service';

import { AuthService } from '../../services/auth.service';
import { SearchService } from '../../services/search.service';
import { WindowsManagerService } from '../../services/windows-manager.service';
import { ClientProfileComponent } from './../clientprofile/clientprofile.component';
import { DialogData } from '../ui/optionChooser/optionChooser.component';
import { EditClientFormComponent } from './../../edit-client-form/edit-client-form.component';
import { UIMessagingService } from 'src/app/services/uimessaging.service';

const dbPatients = firebase.firestore().collection('patients');
const allowedUsers = [UserRoles.admin, UserRoles.associate, UserRoles.owner, UserRoles.superuser, UserRoles.consultant];

export interface Client {
  FirstName: string;
  LastName: string;
  caseName: string;
  LegalCaseId: string;
  DateOfBirth: string;
  sharedUserFiles: any[];
  ownerID: string;
  defaultFolders: any;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  template: `<div>
    <app-window-title
      [closable]="true"
      [title]="'Client/Matter List'"></app-window-title>
    <div mat-dialog-content>
      <div
        fxLayoutAlign="center center"
        fxLayout="row">
        <mat-form-field
          [ngClass]="'width-90p'"
          color="warn"
          appearance="fill">
          <mat-label> Search Client/Matter </mat-label>
          <input
            #searchClientsInput
            [value]="caseName"
            (keyup)="search($event)"
            (input)="caseName = searchClientsInput.value"
            aria-label="Label"
            matInput
            cdkFocusInitial
            placeholder="by name, Case name, Case ID"
            color="warn" />
        </mat-form-field>
        <div class="mdc-notched-outline">
          <div class="mdc-notched-outline__leading"></div>
          <div class="mdc-notched-outline__trailing"></div>
        </div>
        <button
          (click)="search($event)"
          matTooltip="Click to search."
          mat-icon-button
          color="warn">
          <mat-icon>search</mat-icon>
        </button>
        <button
          (click)="getFilesSharedWith()"
          matTooltip="Click to refresh."
          mat-icon-button
          color="warn">
          <mat-icon>update</mat-icon>
        </button>
      </div>
      <i class="criteria-message mat-small">
        You can search by: 'FirstName', 'LastName', 'caseName', 'LegalCaseId' and 'DateOfBirth'
      </i>
      <div fxLayoutAlign="center center">
        <p *ngIf="!searchResult.length">No results found</p>
      </div>
      <div
        *ngIf="searchResult.length > 0"
        fxLayoutAlign="center center"
        fxLayout="column">
        <h4
          *ngIf="searchResult.length > 1"
          [ngStyle]="{ margin: '0' }"
          id="profileFont">
          {{ searchResult.length }} Results
        </h4>
        <h4
          *ngIf="searchResult.length === 1"
          id="profileFont">
          1 Result
        </h4>
        <mat-list
          [ngClass]="'client-list-container'"
          id="listStyle">
          <mat-list-item
            *ngFor="let item of searchResult"
            [ngClass]="'client-list-item'"
            (click)="dialogdata ? handleSelectClient(item) : routeToProfile(item)">
            <app-client-list-item
              [client]="item"
              (editClient)="editClient($event)"
              [permissions]="{dialogdata,ableToEditClient}"></app-client-list-item>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div> `,
  styles: [
    `
      .search-refine {
        height: 64vh;
        width: 25vw;
      }

      .search-result {
        height: 64vh;
        width: 50vw;
        margin-right: 5vw;
      }

      #listStyle {
        overflow: auto;
        width: 100%;
      }

      @media (max-width: 700px) {
        .search-refine {
          height: 520px;
          width: 80%;
          margin-bottom: 20px;
        }

        .search-result {
          height: 400px;
          width: 80%;
          margin-left: 15px;
        }

        #listStyle {
          overflow: auto;
          width: 80%;
        }
      }

      #listStyle .mdc-list-item:hover {
        transition: background 0.5s;
        background-color: rgb(224, 222, 222);
      }

      .scrolled-list {
        height: 340px;
      }

      .width-90p {
        width: 90%;
      }

      .width-70p {
        width: 70%;
      }

      .client-list-item {
        border-bottom: 1px solid #e2e2e2;
        cursor: pointer;
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;

        &:hover {
          background-color: #f0f0f0;
        }

        .mat-list-item-content {
          width: 100%;
        }
      }

      app-client-list-item {
        width: 100%;
      }

      .client-list-container {
        max-height: 45vh;
      }

      .criteria-message {
        color: red;
        text-align: center;
        width: 100%;
        display: block;
        margin-bottom: 10px;
      }

      .mat-list-base .mat-list-item .mat-list-item-content {
        padding-left: 10px;
        padding-right: 10px;
      }
    `,
  ],
})
export class SearchComponent implements OnInit {
  nameSearch: string;
  searchResult = [];
  patientIdSearch: string;
  caseName: string;
  fileName: string;
  fileID: string;
  fileUploadUser: string;
  currentUser: firebase.User;
  currentUserOwnerID: any;
  data: any;
  userData: { realRole?: string; user?: any; filesSharedWith?: any };
  UserRoles: { owner: string; admin: string; associate: string; consultant: string; superuser: string };
  ownerID: any;
  originalData: any[];
  savedData: any[];
  ableToEditClient = false;

  constructor(
    public search_$: SearchService,
    public router: Router,
    public ng: NgZone,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SearchComponent>,
    private log_$: LogService,
    public auth_$: AuthService,
    private uiMessaging_$: UIMessagingService,
    private windowsManager_$: WindowsManagerService,
    @Inject(MAT_DIALOG_DATA) public dialogdata: DialogData,
  ) {
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
    this.data = [];
    this.nameSearch = '';
    this.patientIdSearch = '';
    this.caseName = '';
    this.fileName = '';
    this.fileID = '';
    this.fileUploadUser = '';
  }

  async ngOnInit() {
    this.ownerID = await this.auth_$.getOwnerID(this.auth_$.uid);
    this.auth_$.user.subscribe({ next: u => (this.currentUser = u) });

    this.auth_$.userData.subscribe({
      next: async userdata => {
        if (Object.keys(userdata).length > 0) {
          this.userData = userdata;
          const { realRole, filesSharedWith, user } = this.userData;

          if ([UserRoles.admin, UserRoles.owner, UserRoles.associate].includes(realRole)) {
            this.ableToEditClient = true;
          }
          const allowedPatients = Array.from(
            new Set(await this.getAllowedPatients(realRole, filesSharedWith, user.uid)),
          );
          this.currentUserOwnerID = (await this.auth_$.getOwnerID(this.auth_$.uid)) || this.auth_$.uid;
          this.getAllPatients(
            this.userData.realRole.toString(),
            this.userData.user.email.toLowerCase(),
            allowedPatients,
          );
        }
      },
      complete: () => console.log('completed'),
    });

    this.search_$.searchResults.subscribe({
      next: results => {
        this.searchResult = [];
        results.docs.forEach(doc => {
          const { uidOfCreator, caseName, ownerID, DateOfBirth, LegalCaseId, defaultFolders, files } = doc.data();
          const { FirstName, LastName } = doc.data();
          const item = {
            FirstName: this.toCapitalCase(FirstName),
            LastName: this.toCapitalCase(LastName),
            caseName,
            LegalCaseId,
            DateOfBirth,
            sharedUserFiles: files,
            ownerID,
            defaultFolders,
          };
          const result = [];
          result.push(item);

          if (
            result.length > 0 ||
            this.data.realRole.toString() === UserRoles.superuser ||
            (uidOfCreator === this.currentUser.uid && !item.ownerID)
          ) {
            this.searchResult.push(item);
          } else {
            console.log('No results found');
            this.searchResult = [];
          }
        });
      },
      error: err => console.error(err),
      complete: () => console.log('Completed search'),
    });
  }

  handleSelectClient(item) {
    this.dialog.open(ClientProfileComponent, {
      width: '90%',
      height: '90%',
      data: {
        patient: {
          client: item.caseName,
          caseName: item.caseName,
          clientUser: item.clientUser,
          defaultFolders: item.defaultFolders,
          clientname: `${item.FirstName} ${item.LastName}`,
          FirstName: item.FirstName,
          LastName: item.LastName,
          clioMatterId: item.clioMatterId || item.clioDocId,
        },
      },
    });
  }

  editClient(item: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = item;
    const dialogRef = this.dialog.open(EditClientFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => (result === 1 ? this.ngOnInit() : false));
  }

  getOwnerIdByRole(role): string {
    return role === UserRoles.owner ? this.currentUser.uid : this.auth_$.userData.getValue()['ownerID'];
  }

  async getAllowedPatients(realRole: string, filesSharedWith: any, userUid: string) {
    let allowedPatients = [];
    switch (realRole) {
      case UserRoles.consultant:
        allowedPatients = filesSharedWith.map((currentvalue, _index, array) => {
          if (array.indexOf(currentvalue.patientCaseName) === -1) {
            return currentvalue.patientCaseName;
          }
        });
        break;
      case UserRoles.owner:
      case UserRoles.associate:
        const casesOwneredDocs = (await dbPatients.where('ownerID', '==', this.getOwnerIdByRole(realRole)).get()).docs;
        casesOwneredDocs.forEach(item => allowedPatients.push(item.data().caseName));
        break;
      default:
        break;
    }
    return allowedPatients;
  }

  refreshCurrentUserSharedFiles() {
    return this.auth_$.userReady(this.auth_$.userData.getValue()['user'], 'refreshCurrentUserSharedFiles');
  }

  async getFilesSharedWith() {
    await this.refreshCurrentUserSharedFiles();
    const dbUser = this.auth_$.userData.getValue();
    const filesSharedWith = dbUser['filesSharedWith'];
    const realRole = dbUser['role'];
    const userEmail = dbUser['email'].toLowerCase();
    const allowedPatients = await this.getAllowedPatients(realRole, filesSharedWith, this.currentUser.uid);
    this.currentUserOwnerID = (await this.auth_$.getOwnerID(this.auth_$.uid)) || this.auth_$.uid;
    this.getAllPatients(realRole, userEmail, allowedPatients);
  }

  matchSearchCriteria(item, text) {
    const criterias = ['FirstName', 'LastName', 'caseName', 'LegalCaseId', 'DateOfBirth'];
    for (const criteria of criterias) {
      if (item[criteria] && item[criteria].toLowerCase().indexOf(text.toLowerCase()) > -1) {
        return true;
      }
    }
    return false;
  }

  search(event) {
    this.searchResult = this.originalData;
    if (event.target.value.length > 0) {
      this.searchResult = this.searchResult.filter(item => this.matchSearchCriteria(item, event.target.value));
    }
  }

  searchRefine() {
    if (this.patientIdSearch.trim() !== '') {
      this.searchResult = this.searchResult.filter(data => data['PatientId'] === this.patientIdSearch);
    }
    if (this.nameSearch.trim() !== '') {
      this.searchResult = this.searchResult.filter(
        data => data['LastName'].toLowerCase() === this.nameSearch.toLowerCase(),
      );
    }
  }

  routeToProfile(patient) {
    this.log_$.storeLog(
      this.auth_$.userData.getValue(),
      LogLevelsDictionary.info,
      { message: 'A user has visited a client profile.', user: this.userData.user.uid },
      'ViewClientProfile',
      patient.caseName,
    );
    const { ownerID } = patient;
    if (!ownerID) {
      this.uiMessaging_$.toastMessage(
        `This profile does not have a valid ownerID, please contact support before retry.`,
        'ALERT',
      );
      return;
    }
    if (!this.windowsManager_$.clientProfileOpened) {
      this.windowsManager_$.profileDialogRef = this.dialog.open(ClientProfileComponent, {
        width: '95vw',
        maxWidth: '95vw',
        data: { patient },
      });
    }
  }

  handleBackToClio(clioMatterId, event) {
    event.preventDefault();
    event.stopPropagation();
    window.open(`https://app.clio.com/matters/${clioMatterId}`, '_self');
  }

  async getInitialData(role) {
    const userOwnerId = [UserRoles.admin, UserRoles.associate].includes(role)
      ? this.userData['ownerID']
      : this.userData.user.uid;
    const allPatientsOfThisOwner = await dbPatients.where('ownerID', '==', userOwnerId).get();
    return allPatientsOfThisOwner.docs.map(doc => {
      const patientData = doc.data();

      return {
        caseName: patientData.caseName,
        origin: patientData.origin,
        DateOfBirth: patientData.DateOfBirth,
        defaultFolders: patientData.defaultFolders,
        FirstName: this.toCapitalCase(patientData.FirstName),
        LastName: this.toCapitalCase(patientData.LastName),
        clioMatterId: patientData.lpmFields?.matter.id,
        name: patientData.name,
        clientUser: patientData.clientUser,
        LegalCaseId: patientData.LegalCaseId,
        ownerID: patientData.ownerID,
        patientDocId: doc.id,
        sharedUserFiles: patientData.sharedUserFiles,
      };
    });
  }

  buildClientObject(patient) {
    const { caseName, ownerID, DateOfBirth, LegalCaseId, defaultFolders, files, FirstName, LastName } = patient;
    return {
      FirstName: this.toCapitalCase(FirstName),
      LastName: this.toCapitalCase(LastName),
      caseName: caseName,
      LegalCaseId: LegalCaseId,
      DateOfBirth: DateOfBirth,
      sharedUserFiles: files,
      ownerID: ownerID,
      defaultFolders: defaultFolders,
    };
  }

  async getAllPatients(realRole: string, email: string, allowedCaseNames?: String[]) {
    if (allowedCaseNames.length === 0) {
      this.searchResult = [];
      return;
    }

    if ([UserRoles.owner, UserRoles.admin, UserRoles.associate].includes(realRole)) {
      this.searchResult = this.originalData = await this.getInitialData(realRole);
    } else if ([UserRoles.consultant].includes(realRole)) {
      const patients = await firebase
        .firestore()
        .collection('patients')
        .where('caseName', 'in', allowedCaseNames)
        .get();
      this.searchResult = patients.docs.map(doc => this.getSearchResult(email, doc.data(), realRole));
    }
    return;
  }

  getSearchResult(email: string, patientData: any, realrole: string) {
    if (
      patientData.files?.filter(file => file.sharedWith.map(e => e.toLowerCase()).includes(email.toLowerCase()))
        .length > 0 ||
      allowedUsers.indexOf(realrole) > -1 ||
      (patientData.uidOfCreator === this.currentUser.uid &&
        patientData.ownerID !== '' &&
        this.currentUserOwnerID === patientData.ownerID)
    ) {
      return this.buildClientObject(patientData);
    }
  }

  toCapitalCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
