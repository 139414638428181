import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderComponent } from 'src/app/loader/loader.component';

import { AuthService } from '../../services/auth.service';

@Component({
  template: `
    <app-window-title
      [title]="'Forgot Password'"
      [closable]="true"></app-window-title>
    <div mat-dialog-content>
      <div
        fxLayout="column"
        fxLayoutAlign="center center">
        <p>Enter your email and we will send you a password reset link</p>
        <mat-form-field
          color="warn"
          class="full-width"
          appearance="fill">
          <mat-label>Email</mat-label>
          <input
            [formControl]="email"
            color="warn"
            placeholder="Enter a valid email address"
            matInput
            [value]="email"
            required />
          <mat-error *ngIf="email.invalid">
            {{ getErrorMessage() }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <mat-dialog-actions
      fxLayout
      fxLayoutAlign="center center"
      class="dialog-actions">
      <button
        type="button"
        [disabled]="email.invalid || loading"
        (click)="submitForgetPassword()"
        mat-flat-button
        color="warn">
        RESET PASSWORD
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .reset-card {
        height: 350px;
        width: 300px;
      }

      .email-reset {
        width: 80%;
        margin-top: 10px;
      }

      .sign-in-text {
        font-family: Roboto, sans-serif;
        text-align: center;
      }

      .submission-button {
        margin-top: 10px;
      }

      .full-width {
        width: 100%;
      }

      .dialog-actions {
        margin-bottom: 0px;
      }
    `,
  ],
})
export class FgpasswordComponent implements OnInit {
  errorMessage: string;
  loading: boolean;
  loader: MatDialogRef<LoaderComponent, any>;
  forgotEmail: FormGroup;
  email = new FormControl('', [Validators.required, Validators.email]);

  constructor(public auth_$: AuthService, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.email = this.data.email;
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  submitForgetPassword() {
    this.auth_$.showLoader('Sending password reset email...');
    this.loading = true;
    this.auth_$.passwordForgetEmail(new String(this.email).trim().toLowerCase()).then(() => {
      this.auth_$.hideLoader();
      this.loading = false;
    });
  }
}
