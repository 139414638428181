import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AddContactComponent } from '../add-contact/add-contact.component';
import { UIMessagingService } from '../services/uimessaging.service';
import { UtilsService } from '../services/utils.service';
import { AuthService } from './../services/auth.service';
import { FirebaseUtilitiesService } from './../services/firebase-utilities.service';
import { ZoomOperationsService } from './../zoom-operations.service';

const mailClient = environment.constants.mailClient;

export interface UserGroup {
  role: string;
  emails: any[];
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();
  return opt.filter(
    (item: any) => item.email.toLowerCase().includes(filterValue) || item.name.toLowerCase().includes(filterValue),
  );
};

@Component({
  selector: 'app-create-zoom-meeting-form',
  templateUrl: './create-zoom-meeting-form.component.html',
  styleUrls: ['./create-zoom-meeting-form.component.scss'],
})
export class CreateZoomMeetingFormComponent implements OnInit {
  guestsForm: FormGroup;
  userGroupOptions: Observable<UserGroup[]>;
  options: string[] = ['One', 'Two', 'Three'];

  userSignedIn: boolean;
  guestsList: string[] = [];
  uid: any;
  email: any;
  zoomUser: any;
  zoomUsersList: any;
  consultantsList: any[];
  timezones: { label: string; value: string }[];
  meetingDate: any;
  meetingAgenda: any;
  meetingTopic: any;
  meetingTime: any;
  meetingDuration: any;
  meetingTimezone: any;
  selectedOptions: any;
  predefinedCalendar: any;
  showAddToCalendar: boolean;
  validationMessage: string;
  userGroups: UserGroup[] = [
    { role: 'Consultant', emails: ['alexis', 'james', 'joe', 'jane'] },
    { role: 'Admin', emails: ['alexis', 'james', 'joe', 'jane'] },
    { role: 'Associate', emails: ['alexis', 'james', 'joe', 'jane'] },
  ];
  customGuests: any = [];
  nextActionMessage: string;
  message = 'The meeting has been created and the guests have been invited by email.';

  constructor(
    public router: Router,
    private auth_$: AuthService,
    private firebaseUtilities_$: FirebaseUtilitiesService,
    private dialogRef: MatDialogRef<CreateZoomMeetingFormComponent>,
    private zoomOperations_$: ZoomOperationsService,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private utils_$: UtilsService,
    private http: HttpClient,
    private uiMessaging_$: UIMessagingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.guestsForm = this._formBuilder.group({
      emailFormControl: new FormControl('', [Validators.required, Validators.email]),
    });
    this.customGuests = [];
    this.validationMessage = '';
    this.showAddToCalendar = false;
    this.zoomUser = data.zoomUser;
    this.predefinedCalendar = data.predefinedCalendar;
    this.timezones = [
      { label: 'Hawai', value: 'Pacific/Honolulu' },
      { label: 'Alaska', value: 'America/Anchorage' },
      { label: 'Pacific Time (US and Canada)', value: 'America/Los_Angeles' },
      { label: 'Mountain Time (US and Canada)', value: 'America/Denver' },
      { label: 'Central Time (US and Canada)', value: 'America/Chicago' },
      { label: 'Eastern Time (US and Canada)', value: 'America/New_York' },
    ];

    this.meetingDate = new Date();
    this.meetingAgenda = '';
    this.meetingTopic = '';
    this.meetingTime = '09:00 AM';
    this.meetingDuration = 60;
    this.meetingTimezone = this.timezones[2].value;

    switch (this.predefinedCalendar) {
      case environment.constants.mailClient.CLIO:
        this.nextActionMessage =
          'Go to your <a href="https://app.clio.com/nc/#/calendars">Clio Calendar</a> and check the event details.';
        break;
      case environment.constants.mailClient.GOOGLE:
        this.nextActionMessage = 'Check your Google Calendar to see the event details.';
        break;
      case environment.constants.mailClient.OUTLOOK:
        this.nextActionMessage = 'Check your inbox to see the invitation.';
        break;
      case environment.constants.mailClient.OFFICE365:
        this.nextActionMessage = 'Check your inbox to see the invitation.';
        break;
      default:
        this.nextActionMessage = 'Check your email to see the Zoom invitation';
        break;
    }
  }

  validIfConsultant(email) {
    return this.consultantsList.filter(i => i.email === email).length > 0;
  }

  addCustomGuest(email) {
    if (!email || !this.utils_$.validateEmail(email)) {
      this.uiMessaging_$.toastMessage('Please enter a valid email address', '');
      return;
    }

    if (this.validIfConsultant(email)) {
      this.consultantsList.find(i => i.email === email).checked = true;
      this.guestsList.find(i => i === email)
        ? console.log('This is user is already part of the guests list.')
        : this.guestsList.push(email);
      return;
    }

    if (this.customGuests.find(i => i === email)) {
      this.guestsList.push(email);
    }

    const handleAddCustomGuest = async (name, jobDescription) => {
      {
        if (!name || !jobDescription) {
          return;
        }
        this.consultantsList.push({ name, email, jobDescription, checked: true });

        let customGuests = await this.getCustomGuests();
        customGuests
          ? customGuests.find((i: { email: string }) => i.email === email)
            ? console.log('This is user is already part of the custom guests list.')
            : customGuests.push({ name, email, jobDescription })
          : (customGuests = [{ name, email, jobDescription }]);

        this.customGuests = customGuests;
        const userData = this.auth_$.userData.getValue();
        const userSettingsData = JSON.stringify({ ...JSON.parse(userData['settings']), ...{ customGuests } });
        await this.firebaseUtilities_$.updateUserSettings(userSettingsData, userData['id']);
        this.guestsList.push(email);
        this.updateCustomGuests();
      }
    };

    if (!this.customGuests.find(i => i.email === email)) {
      this.dialog
        .open(AddContactComponent, { width: '500px', data: { email } })
        .afterClosed()
        .subscribe(async ({ name, jobDescription }) => handleAddCustomGuest(name, jobDescription));
    } else {
      this.guestsList.push(email);
      if (!this.consultantsList.find(i => i.email === email)) {
        this.consultantsList.push({ ...this.customGuests.find(i => i.email === email), checked: true });
      }
    }
  }

  updateCustomGuests() {
    this.userGroups.find(f => f.role === 'Previous guests').emails = this.customGuests;
    console.log('this.userGroups :', this.userGroups);
  }

  getCustomGuests() {
    return this.firebaseUtilities_$.getCustomGuests(this.auth_$.userData.getValue()['id']);
  }

  handleGetICSFile() {
    window.open(this.zoomOperations_$.lastICSDownloadURL, '_blank');
    this.handleWindowClose('');
  }

  addEvent(type, event: MatDatepickerInputEvent<Date>) {
    this.meetingDate = event.value;
  }

  async ngOnInit() {
    const groups = [];
    (await this.firebaseUtilities_$.getAllUsersByOwnerUIDGrouped(this.auth_$.uid)).subscribe({
      next: group => groups.push(group),
      complete: async () => {
        this.userGroups = groups;
        this.customGuests = await this.getCustomGuests();
        this.userGroups.push({ role: 'Previous guests', emails: this.customGuests });
      },
      error: err => console.error('error:', err),
    });

    // tslint:disable-next-line: no-non-null-assertion
    this.userGroupOptions = this.guestsForm.get('emailFormControl')!.valueChanges.pipe(
      startWith(''),
      map(value => this._filterGroup(value || '')),
    );

    this.auth_$.currentUser.subscribe({
      next: async user => {
        if (this.auth_$.uid) {
          const list = await this.firebaseUtilities_$.getConsultantsList(user.uid);
          this.consultantsList = list.map(item => ({ email: item.email, name: item.name }));
        }
      },
    });
  }

  private _filterGroup(value: string): UserGroup[] {
    return value
      ? this.userGroups
          .map(group => ({ role: group.role, emails: _filter(group.emails || [], value) }))
          .filter(group => group.emails.length > 0)
      : this.userGroups;
  }

  handleCreateMeeting() {
    const { zoomUser, guestsList } = this;
    if (guestsList.length === 0) {
      this.validationMessage = 'You must invite at least one person to create an event.';
      return;
    }

    const meetingObject = this.zoomOperations_$.createMeetingObject(this);
    switch (this.predefinedCalendar) {
      case mailClient.OUTLOOK:
      case mailClient.CLIO:
      case mailClient.OTHER:
      case mailClient.GOOGLE:
        this.auth_$.showLoader('Creating meeting...');
        this.zoomOperations_$
          .createMeeting(zoomUser, meetingObject, guestsList)
          .then(result => {
            this.auth_$.hideLoader();
            this.zoomOperations_$.zoomMeeting = result.data.response;
            this.handleMeetingCreated(true, guestsList, zoomUser);
          })
          .catch(err => {
            this.auth_$.hideLoader();
            console.error(err);
            return err;
          });
        break;
      default:
        this.handleWindowClose({ meetingObject, guestsList });
        break;
    }
  }

  getClioGuestsList(guestsList) {
    const clioGuestsList = [];
    const customGuests = this.customGuests;
    const consultantsList = this.consultantsList;
    guestsList.forEach(guestemail =>
      clioGuestsList.push(
        customGuests.find(i => i.email === guestemail) || consultantsList.find(i => i.email === guestemail),
      ),
    );
    return clioGuestsList;
  }

  clioFormatting(guestsList: any) {
    return guestsList.map((guest: any) => ({
      email: guest.email,
      first_name: guest.name.split(' ')[0],
      last_name: guest.name.split(' ')[1] || '',
      title: guest.jobDescription || '',
    }));
  }

  handleCreateClioGuests(guestsList, userdocid): Promise<any> {
    const formattedGuestsList = this.clioFormatting(guestsList);
    const url = `${environment.constants.cloudfunctionsURL}clio-createClioContacts`;
    return this.http.post(url, { userdocid, guestsList: formattedGuestsList }).toPromise();
  }

  async handleMeetingCreated(meetingData, guestsList, zoomUser) {
    const meeting = this.zoomOperations_$.zoomMeeting;
    if (meetingData) {
      const invitationOptions = { ...environment.config.zoom.invitationOptions, first_name: zoomUser.first_name };
      const hostInvitationOptions = { ...invitationOptions, ...{ host_invitation: true } };
      const meetingInvitation = this.zoomOperations_$.createMeetingInvitation(meeting, invitationOptions, 'html');
      const hostInvitation = this.zoomOperations_$.createMeetingInvitation(meeting, hostInvitationOptions, 'html');

      if (this.predefinedCalendar === mailClient.CLIO) {
        await this.handleClioMeetingCreated(guestsList, meeting, meetingInvitation);
        this.zoomOperations_$.buildInvitationAndSend(zoomUser, meeting, guestsList, meetingInvitation, hostInvitation);
      } else {
        this.zoomOperations_$.buildInvitationAndSend(zoomUser, meeting, guestsList, meetingInvitation, hostInvitation);
      }
      this.showAddToCalendar = true;
    } else {
      this.usePredefinedCalendar(this.predefinedCalendar);
    }
  }

  async handleClioMeetingCreated(guestsList, meeting, meetingInvitation) {
    try {
      const guestsListData = this.getClioGuestsList(guestsList).filter(guest => guest !== undefined);
      guestsList = await this.handleCreateClioGuests(guestsListData, this.auth_$.userData.getValue()['id']).catch(
        err => {
          console.error(err);
          return err;
        },
      );
    } catch (err) {
      console.error(err);
    }
    try {
      const createClioEventResult = await this.zoomOperations_$.createClioEvent({
        meeting,
        guestsList,
        meetingInvitation,
      });
      console.log('createClioEventResult :', createClioEventResult);
    } catch (err) {
      console.error('createClioEventResult', err);
    }
  }

  usePredefinedCalendar(predefinedCalendar) {
    switch (predefinedCalendar) {
      case mailClient.GOOGLE:
        /**
         * NOTE: Google service is temporary unavailable.
         * this.goToGoogleCalendar();
         */
        this.uiMessaging_$.toastMessage(
          `The Google Service is temporary unavailable.` +
            `Please choose ${environment.constants.zoomDefaults.DEFAULT_TOPIC} instead.` +
            `Also you can set Office365 as your default calendar service.`,
          'INFO',
        );
        // this.showZoomMeetingForm();
        break;
      case mailClient.OFFICE365:
        console.log('this.goToOffice365Calendar()');
        break;
      case mailClient.OUTLOOK:
        // this.goToOutlookCalendar();
        console.log('this.showZoomMeetingForm()');
        break;
      case mailClient.CLIO:
        // this.goToOtherCalendar();
        console.log('this.showZoomMeetingForm()');
        break;
      case mailClient.OTHER:
        // this.goToOtherCalendar();
        console.log('this.showZoomMeetingForm()');
        break;
      default:
        console.log('Default value');
        break;
    }
  }

  handleWindowClose(meetingObject?) {
    this.dialogRef.close(meetingObject);
  }

  handleGuestsSelection(selectedOptions) {
    this.guestsList = selectedOptions.selected.map(item => item.value);
    this.selectedOptions = selectedOptions;
  }
}
