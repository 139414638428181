import 'firebase/functions';
import 'moment-timezone';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import moment from 'moment';
import { environment } from 'src/environments/environment';

import { LogService } from './log.service';
import { AuthService } from './services/auth.service';
import { UIMessagingService } from './services/uimessaging.service';

interface MeetingInvitationOptions {
  first_name?: string;
  includeDateTime?: boolean;
  host_invitation?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ZoomOperationsService {
  fc = firebase.functions();
  public zoomCreateMeeting = this.fc.httpsCallable('zoom-createMeeting');
  public zoomDeleteMeeting = this.fc.httpsCallable('zoom-deleteMeeting');
  public zoomCreateUser = this.fc.httpsCallable('zoom-createUser');
  public zoomListUsers = this.fc.httpsCallable('zoom-listUsers');
  public zoomListMeetings = this.fc.httpsCallable('zoom-listMeetings');
  public zoomGetUser = this.fc.httpsCallable('zoom-getUser');
  public zoomGetMeetingInvitation = this.fc.httpsCallable('zoom-getMeetingInvitation');

  zoomMeeting: any;
  meetingCreated: boolean;
  lastICSDownloadURL: string;

  constructor(
    private http: HttpClient,
    private auth_$: AuthService,
    private log_$: LogService,
    private uiMessaging_$: UIMessagingService,
  ) {}

  buildInvitationAndSend(zoomUser, meeting, guestsList, meetingInvitation, hostInvitation) {
    const { join_url, topic, host_email, start_time, duration } = meeting;
    const momentStartTime = moment(start_time).utc(false).local();
    const hoursDuration = Math.floor(duration / 60);
    const minutesDuration = duration % 60;

    const icsContent = this.generateICSContent(
      momentStartTime.toObject(),
      hoursDuration,
      minutesDuration,
      topic,
      meetingInvitation,
      join_url,
      zoomUser,
      host_email,
      guestsList,
    );

    const hostingICSContent = this.generateICSContent(
      momentStartTime.toObject(),
      hoursDuration,
      minutesDuration,
      topic,
      hostInvitation,
      join_url,
      zoomUser,
      host_email,
      guestsList,
    );

    this.sendSimpleEmail(`You have been invited to a NuageDx meeting`, meetingInvitation, guestsList, icsContent)
      .toPromise()
      .then(res => {
        if (res['data']['status'] === 200) {
          this.uiMessaging_$.toastMessage(
            `The meeting has been created and the guests has been invited by email. You have an invitation in your inbox too.`,
            'CONFIRMATION',
          );
          this.sendSimpleEmail(
            `You have scheduled a NuageDx meeting`,
            hostInvitation,
            [host_email, 'alexisrengifo@gmail.com'],
            hostingICSContent,
          )
            .toPromise()
            .then(res => {
              if (res['data']['status'] === 200) {
                this.uiMessaging_$.toastMessage(`The host has been invited by email.`, 'CONFIRMATION');
              }
            })
            .catch(err => {
              console.log(
                '🚀 ~ file: zoom-operations.service.ts:80 ~ ZoomOperationsService ~ buildInvitationAndSend ~ err',
                err,
              );
              this.uiMessaging_$.toastMessage(`The host could not be invited by email.`, 'ERROR');
            });
        }
      });

    const data = new File([icsContent], 'invite.ics', { type: 'text/calendar' });
    const icsFile = window.URL.createObjectURL(data);
    this.lastICSDownloadURL = icsFile;
  }

  getEndTime(startTime, duration) {
    return new Date(new Date(startTime).setMinutes(new Date(startTime).getMinutes() + duration)).toISOString();
  }

  async createClioEvent(clioEventParams) {
    const { meeting, guestsList, meetingInvitation } = clioEventParams;
    const userdocid = this.auth_$.userData.getValue()['id'];

    const event = {
      summary: meeting.topic,
      description: meetingInvitation,
      start_at: meeting.start_time,
      end_at: this.getEndTime(meeting.start_time, meeting.duration),
      attendees: guestsList.map(attendee => ({ id: attendee, type: 'Contact' })),
      conference_meeting: { type: 'zoom' },
      all_day: false,
    };

    const url = `${environment.constants.cloudfunctionsURL}clio-createClioEvent`;
    return this.http.post(url, { userdocid, event }).toPromise();
  }

  getCalendarId(userdocid) {
    return this.http.post(`${environment.constants.cloudfunctionsURL}clio-getCalendarId`, { userdocid }).toPromise();
  }

  createMeeting(zoomUser, meetingData?, guestsList?): Promise<any> {
    if (!zoomUser || zoomUser.code) {
      console.error('There is no a valid zoomUser alive, please reload this page.');
      return;
    }

    const { email } = zoomUser;
    if (Object.keys(meetingData).length === 0) {
      meetingData = {
        topic: environment.constants.zoomDefaults.DEFAULT_TOPIC,
        startTime: moment().format('YYYY-MM-DDThh:mm:ss'),
        timezone: moment.tz.guess(),
      };
    }

    const meetingDefaultObject = {
      mainEmail: email,
      scheduleFor: email,
      type: 2,
      settings: {
        waiting_room: false,
        watermark: false,
        approval_type: 2,
        meeting_authentication: false,
        join_before_host: true,
        allow_multiple_devices: true,
      },
    };

    const meetingObject = meetingData
      ? { ...meetingData, ...meetingDefaultObject }
      : { ...meetingDefaultObject, ...meetingData };

    // NOTE: Use this to retreive testing data. Or edit the method to customize it.
    return this.zoomCreateMeeting(meetingObject);
  }

  createMeetingObject(thus) {
    const { meetingTime, meetingDate, meetingTopic, zoomUser, meetingTimezone, meetingAgenda, meetingDuration } = thus;
    if (!zoomUser) {
      console.error('There is no a valid zoomUser alive, please reload this page.');
      return;
    }
    const timeNumbers = meetingTime.split(' ')[0];
    const ampm = meetingTime.split(' ')[1];
    const timeString = timeNumbers.split(':');
    if (ampm === 'PM') {
      if (parseInt(timeString[0], 10) !== 12) {
        timeString[0] = (parseInt(timeString[0], 10) + 12).toString();
      } else {
        timeString[0] = '12';
      }
    } else {
      if (parseInt(timeString[0], 10) === 12) {
        timeString[0] = '00';
      }
    }
    const newDate = new Date(meetingDate);
    newDate.setHours(parseInt(timeString[0], 10), parseInt(timeString[1], 10), 0);
    const { year, month, day, hours, minutes, seconds } = this.getDateVars(newDate);
    const { email } = zoomUser;
    const nMeetingDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${meetingTimezone}`;

    return {
      // auto_recording: 'cloud',
      topic: meetingTopic,
      startTime: nMeetingDate,
      scheduleFor: email,
      timezone: meetingTimezone,
      password: '342234324',
      meetingDescription: meetingAgenda,
      duration: meetingDuration,
      settings: {
        waiting_room: false,
        watermark: false,
        approval_type: 2,
        meeting_authentication: false,
        join_before_host: true,
        allow_multiple_devices: true,
      },
    };
  }

  createMeetingInvitation(meeting, options: MeetingInvitationOptions, meetingtype: string) {
    const { host_email, id, join_url, start_url, start_time, timezone, password, settings } = meeting;
    const dateString = new Date(start_time).toString();
    const idate = new Date(moment(dateString).toString()).toLocaleString(`en-US`, { timeZone: timezone });
    const dateTime = options.includeDateTime ? `Meeting date: ${idate.toString()} (${timezone}) \n` : ``;
    const hostInvitationFlag = options.host_invitation;

    const { global_dial_in_numbers } = settings;
    const globalDialInNumbers = [];
    global_dial_in_numbers.forEach(element => {
      const { country_name, number, type, country } = element;
      const listItem = `${country_name} ${number}`;
      globalDialInNumbers.push(listItem);
    });

    const globalDialInNumbersList = globalDialInNumbers.join('\n');

    const { first_name } = options;

    if (hostInvitationFlag) {
      if (meetingtype === 'html') {
        return (
          `${first_name}, you have scheduled a new meeting:\n` +
          `${dateTime}` +
          `Join ${environment.constants.zoomDefaults.DEFAULT_TOPIC}\n` +
          `<a href="${hostInvitationFlag ? start_url : join_url}" target="_blank">Click to join</a>\n` +
          `Toll free to:\n` +
          `${globalDialInNumbersList}\n` +
          `Meeting ID: ${id}\n` +
          `Passcode: ${password}\n` +
          `Find your local number: ${environment.config.zoom.findLocalNumbersURL}`
        );
      } else {
        return (
          `${first_name}, you have scheduled a new meeting:\n` +
          `${dateTime}` +
          `Join ${environment.constants.zoomDefaults.DEFAULT_TOPIC}\n` +
          `${hostInvitationFlag ? start_url : join_url}\n` +
          `Toll free to:\n` +
          `${globalDialInNumbersList}\n` +
          `Meeting ID: ${id}\n` +
          `Passcode: ${password}\n` +
          `Find your local number: ${environment.config.zoom.findLocalNumbersURL}`
        );
      }
    } else {
      if (meetingtype === 'html') {
        const hostLink = hostInvitationFlag
          ? ''
          : `<p>If you are the host please use this link to join: <a href="${start_url}" target="_blank">Click to join</a></p>\n` +
            `<br>\n`;
        return (
          `${first_name}(${host_email}) has invited you to a meeting:\n` +
          `${dateTime}` +
          `Join ${environment.constants.zoomDefaults.DEFAULT_TOPIC}\n` +
          `<a href="${hostInvitationFlag ? start_url : join_url}" target="_blank">Click to join</a>\n` +
          hostLink +
          `Toll free to:\n` +
          `${globalDialInNumbersList}\n` +
          `Meeting ID: ${id}\n` +
          `Passcode: ${password}\n` +
          `Find your local number: ${environment.config.zoom.findLocalNumbersURL}`
        );
      } else {
        return (
          `${first_name}(${host_email}) has invited you to a meeting:\n` +
          `${dateTime}` +
          `Join ${environment.constants.zoomDefaults.DEFAULT_TOPIC}\n` +
          `${hostInvitationFlag ? start_url : join_url} \n` +
          `Toll free to:\n` +
          `${globalDialInNumbersList} \n` +
          `Meeting ID: ${id}\n` +
          `Passcode: ${password} \n` +
          `Find your local number: ${environment.config.zoom.findLocalNumbersURL}`
        );
      }
    }
  }

  generateICSContent(
    start_time,
    hoursDuration,
    minutesDuration,
    topic,
    meetingInvitation,
    join_url,
    zoomUser,
    host_email,
    guestsList,
  ) {
    const destructuring = object => [object.years, object.months + 1, object.date, object.hours, object.minutes];

    const ics = require('ics');
    const start = destructuring(start_time);
    const end_time = moment(start_time).add(hoursDuration, 'h').add(minutesDuration, 'm').toObject();
    const end = destructuring(end_time);

    const attendees = [];
    guestsList.forEach(guest => {
      attendees.push({
        name: guest,
        email: guest,
        role: 'OPT-PARTICIPANT',
      });
    });

    const event = {
      start: start,
      productId: '-//Google Inc//Google Calendar 70.9054//EN',
      method: 'REQUEST',
      end: end,
      title: topic,
      description: meetingInvitation,
      location: join_url,
      url: join_url,
      busyStatus: 'BUSY',
      organizer: { name: zoomUser.first_name, email: host_email },
      attendees: attendees,
      categories: ['nuagedx'],
      alarms: [{ action: 'display', trigger: { hours: 2, minutes: 30, before: true } }],
      calName: 'NuageDx',
    };

    return ics.createEvent(event, (error, value) => {
      if (error) {
        console.log(error);
        return;
      }

      return value;
    });
  }

  sendEmail(body: string, dests: string[], attachment?) {
    const url = `${environment.constants.cloudfunctionsURL}email-sendEmail`;
    const dest = dests.join(',');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'secret-key',
      }),
    };

    return this.http.post(url, { dest, body, attachment }, httpOptions);
  }

  sendSimpleEmail(subject: string, body: string, dests: string[], attachment?) {
    const url = `${environment.constants.cloudfunctionsURL}email-sendSimpleEmail`;
    const dest = dests.join(',');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'secret-key',
      }),
    };

    return this.http.post(url, { subject, dest, body, attachment }, httpOptions);
  }

  getDateVars(newDate) {
    const year = newDate.getFullYear();
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();
    let hours = newDate.getHours();
    let minutes = newDate.getMinutes();
    let seconds = newDate.getSeconds();

    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return { year, month, day, hours, minutes, seconds };
  }

  returnFakeMeeting(ok: boolean) {
    if (ok) {
      return {
        uuid: 'MU9cAYL1T32oJ/Z1N5qdyg==',
        id: 97795383726,
        host_id: 'NqVdZ_VMR_WjqccmxzWcug',
        host_email: 'eagle.owner@gmail.com',
        topic: 'topic title',
        type: 2,
        status: 'waiting',
        start_time: '2021-06-10T16:00:00Z',
        duration: 60,
        timezone: 'America/Los_Angeles',
        agenda: 'agenda text\ndemo\n....',
        created_at: '2021-06-09T00:08:55Z',
        start_url:
          'https://nuagedx.zoom.us/s/97795383726?zak=eyJ6bV9za20iOiJ6bV9vMm0iLCJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJjbGllbnRzbSIsInVpZCI6Ik5xVmRaX1ZNUl9XanFjY214eldjdWciLCJpc3MiOiJ3ZWIiLCJzdHkiOjk5LCJ3Y2QiOiJhdzEiLCJjbHQiOjAsInN0ayI6InJhV0dmeWY5XzFuQjhVRGRhX2xSSGk5X1ZsX0F4Nk4xZnlqOXByOEtrcFUuQmdZZ1FWQnBNa3gyYldseU5DdEpjRkY0VXk5aE5pOWxVVGx6WWtzME0xQkZSVm9BQUF3elEwSkJkVzlwV1ZNemN6MEFBMkYzTVFBQUFYbnVHYjhZQUJKMUFBQUEiLCJleHAiOjE2MzA5NzMzMzUsImlhdCI6MTYyMzE5NzMzNSwiYWlkIjoiS3FWTjBnMlBSM2VWY1dnek1MVkxrUSIsImNpZCI6IiJ9.EaEX_tlaetZuovHfdhlJp4MBQcctkt-QCGdfs_gm_SI',
        join_url: 'https://nuagedx.zoom.us/j/97795383726?pwd=aGdwMTNGeTFaM2p2NkJkOUZwMmJCQT09',
        registration_url: 'https://nuagedx.zoom.us/meeting/register/tJMqfuyrpjgtGNLWwy5ciYslHgab9-8WLOJR',
        password: '342234324',
        h323_password: '342234324',
        pstn_password: '342234324',
        encrypted_password: 'aGdwMTNGeTFaM2p2NkJkOUZwMmJCQT09',
        settings: {
          host_video: false,
          participant_video: false,
          cn_meeting: false,
          in_meeting: false,
          join_before_host: true,
          jbh_time: 0,
          mute_upon_entry: false,
          watermark: false,
          use_pmi: false,
          approval_type: 0,
          audio: 'both',
          auto_recording: 'none',
          enforce_login: false,
          enforce_login_domains: '',
          alternative_hosts: '',
          close_registration: false,
          show_share_button: true,
          allow_multiple_devices: true,
          registrants_confirmation_email: true,
          waiting_room: true,
          request_permission_to_unmute_participants: false,
          global_dial_in_countries: ['US'],
          global_dial_in_numbers: [
            {
              country_name: 'US',
              city: 'Tacoma',
              number: '+1 2532158782',
              type: 'toll',
              country: 'US',
            },
            {
              country_name: 'US',
              city: 'Washington DC',
              number: '+1 3017158592',
              type: 'toll',
              country: 'US',
            },
            {
              country_name: 'US',
              city: 'Chicago',
              number: '+1 3126266799',
              type: 'toll',
              country: 'US',
            },
            {
              country_name: 'US',
              city: 'Houston',
              number: '+1 3462487799',
              type: 'toll',
              country: 'US',
            },
            {
              country_name: 'US',
              city: 'New York',
              number: '+1 6468769923',
              type: 'toll',
              country: 'US',
            },
            {
              country_name: 'US',
              city: 'San Jose',
              number: '+1 6699006833',
              type: 'toll',
              country: 'US',
            },
          ],
          contact_name: 'Eagle',
          contact_email: 'eagle.owner@gmail.com',
          registrants_email_notification: true,
          meeting_authentication: false,
          encryption_type: 'enhanced_encryption',
          approved_or_denied_countries_or_regions: { enable: false },
          breakout_room: { enable: false },
          alternative_hosts_email_notification: true,
          device_testing: false,
        },
      };
    } else {
      return;
    }
  }
}
