import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AuthService } from './../services/auth.service';
import { FirebaseUtilitiesService } from './../services/firebase-utilities.service';
import { SessionStorageService } from './../services/session-storage.service';
import { UtilsService } from './../services/utils.service';

@Component({
  selector: `app-prompt-2fa-code`,
  template: `
    <p *ngIf="data.message">{{ message }}</p>
    <div
      fxLayout="column"
      fxLayoutAlign="space-between center"
      style="width: 100%;">
      <mat-form-field
        appearance="fill"
        style="width:100%">
        <mat-label>Enter Code</mat-label>
        <input
          matInput
          type="text"
          name="code"
          [(ngModel)]="code"
          id="code" />
      </mat-form-field>

      <button
        style="width: 100%;"
        class="sign-in-button"
        mat-flat-button
        color="warn"
        (click)="validateCode()">
        VERIFY
      </button>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      style="width: 100%;">
      <button
        mat-button
        color="warn"
        (click)="handleResend(); counter = 20"
        [disabled]="counter > 0">
        Send me a new code <span *ngIf="counter > 0">({{ counter }})</span>
      </button>
      <button
        mat-button
        color="warn"
        (click)="handleCancel()">
        Cancel
      </button>
    </div>
  `,
  styles: [
    '.sign-in-button { margin-top: 0; height: 50px; font-size: 1.1em ; width: 100%; min-width: 200px;}',
    '.mat-button { font-size:inherit; font-weight:normal }',
    '.mat-button:hover {text-decoration:underline}',
  ],
})
export class PromptUser2FACodeComponent implements OnInit {
  code: string;
  counter = 20;
  controlType: string;
  @Input() title: string;
  @Input() message: string;
  @Input() email: string;
  @Input() method: string;
  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();
  @Output() sendnewcode = new EventEmitter();
  timer: NodeJS.Timeout;
  constructor(
    private utils_$: UtilsService,
    private auth_$: AuthService,
    public dialogRef: MatDialogRef<PromptUser2FACodeComponent>,
    private firebaseUtilities_$: FirebaseUtilitiesService,
    private sessionStorage_$: SessionStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  handleResend() {
    // this.dialogRef.close('resend');
    // Call a service to resend the code.
    // 1. Generate the code:
    const code = this.utils_$.generateValidationCode();
    this.auth_$.sendVerificationCode(code, this.data.email).then(() => {
      this.sendnewcode.emit(1);
    });
    this.setTimer();
  }

  handleCancel() {
    // this.dialogRef.close('cancel');
    this.cancel.emit(0);
  }

  setTimer() {
    this.timer = setInterval(() => {
      if (this.counter > 0) {
        this.counter--;
      } else {
        clearInterval(this.timer);
      }
    }, 1000);
  }

  ngOnInit(): void {
    if (!this.data.title) {
      this.data.title = this.title || '';
    }
    if (!this.data.message) {
      this.data.message = this.message || '';
    }
    if (!this.data.email) {
      this.data.email = this.email || '';
    }

    this.setTimer();

    // NOTE: This code is used for...?
    // this.auth_$.userData.subscribe({
    //   next: (result) => {
    //     console.log('userData:', result);
    //   }
    // });
    this.code = '';
  }

  handleClose() {
    this.dialogRef.close();
  }

  async validateCode() {
    const answer = await this.firebaseUtilities_$.validate2FACode(this.data.email, this.code, this.method);
    console.log('validateCode: ', answer);
    if (answer === true) {
      this.confirm.emit(1);
      this.sessionStorage_$.setCodeVerified(true);
      this.handleClose();
    } else {
      this.confirm.emit(0);
    }
  }
}
