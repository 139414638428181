<div *ngIf="!twofacode_needed">
  <h3>
    <mat-icon color="warn">verified_user</mat-icon>
    {{ data.title }}
  </h3>
  <label *ngIf="!twofacode_needed" id="example-radio-group-label">
    {{ data.message }}</label
  >

  <div [formGroup]="SMSForm">
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Enter your cell phone number</mat-label>
      <input
        mask="000-000-0000"
        matInput
        formControlName="phoneNumber"
        #phoneNumber
      />
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="codeRequired">
      <mat-label>Code Required</mat-label>
      <input
        matInput
        formControlName="validateCodeInput"
        name="validateCodeInput"
        type="text"
      />
    </mat-form-field>

    <button
      mat-flat-button
      color="warn"
      #sendCodeBySMSBtn
      (click)="
        sendCodeBySMS(phoneNumber.value); sendCodeBySMSBtn.disabled = true
      "
      *ngIf="!codeRequired"
      [ngStyle]="{ width: '100%', display: 'block' }"
    >
      Send CODE
    </button>

    <button
      style="display: inline-block"
      (click)="validateCodeAndGo()"
      color="warn"
      mat-flat-button
      *ngIf="codeRequired"
    >
      VALIDATE CODE
    </button>

    <app-prompt-2fa-code
      [method]="'sms'"
      [title]="''"
      [message]="''"
      [email]="getPrompt2faEmail()"
      (cancel)="handleCancel($event)"
      (confirm)="handleConfirm($event)"
      (sendnewcode)="handleSendNewCode($event)"
      *ngIf="sms_twofacode_needed"
    ></app-prompt-2fa-code>
  </div>
</div>
