import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase/app';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UploadBlock } from 'src/app/models/upload-block';
import { environment } from 'src/environments/environment';

import { SimpleMessageWindowComponent } from '../components/ui/simple-message-window/simple-message-window.component';
import { LogLevelsDictionary } from '../dictionaries/LogLevels';
import { UploadTypes } from './../models/UploadTypes';
import { AlgoliaService } from './algolia.service';
import { AuthService } from './auth.service';
import { ClientMatterService } from './client-matter.service';
import { DicomUtilitiesService } from './dicom-utilities.service';
import { FirebaseUtilitiesService } from './firebase-utilities.service';
import { GapiOperationsService } from './gapi-operations.service';
import { SessionStorageService } from './session-storage.service';
import { UIMessagingService } from './uimessaging.service';
import { UtilsService } from './utils.service';
import { Folder } from '../models/Folder2';

const dicomdirFileName = 'DICOMDIR';
const uTypes = ['none', UploadTypes.FILE, UploadTypes.VIDEO, UploadTypes.DICOMDisk, UploadTypes.VIDEODisk];

export interface UploadGroup {
  id?: any;
  createdAt?: number;
  value?: string;
  files?: any;
}

export interface UploadStack {
  id?: any;
  createdAt?: number;
  value?: string;
  files?: any;
}

export interface UploadDaemon {
  status: any;
  currentGroup: string;
  progress: number;
}

export interface FileObject {
  entry: any;
  entryMeta: any;
}

export interface Dicomdir {
  entry;
  parentFolder?;
  uploadedDate?;
  parentFolderId?;
  parentFolderName?;
  lastModified?;
  entryMeta;
}

export interface ContextParams {
  creator: any;
  fdate: string;
  fdesc: string;
  clientName: string;
  casename: string;
  currentFolder: Folder;
}

export interface FileRegistrationResult {
  belongsTo: string;
  creator: any;
  fdate: any;
  fileDesc: any;
  fileId: string;
  objectID: string;
  fileName: any;
  filePath: any;
  forDelete: boolean;
  ftype: any;
  type: any;
  lastModified: any;
  notes: any[];
  parentFolderName: any;
  parentFolder: any;
  scanAnalysisId: any;
  storename: string;
  uploadedDate: any;
  viewerurl: string;
}

export interface FileEntry {
  filePath: any;
  file: any;
  name: any;
  lastModified: any;
  size: any;
  type: any;
  webkitRelativePath: any;
}

export interface GenerateDicomDirFileFileParam {
  lastModified: any;
  parentFolderName: any;
  parentFolder: any;
  entryMeta: { desc: any; fdate: any; targetFolderId: any };
}

@Injectable({
  providedIn: 'root',
})
export class UploadHelperService {
  paramsObj: BehaviorSubject<object> = new BehaviorSubject({});
  scannedFilesSource: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  scannedBlocksSource: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  uploadBlocksSource: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  uploadChunkReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
  uploadProgressMaximizedO: Subject<boolean> = new Subject();
  fileUploaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _uploadGroups = new BehaviorSubject<UploadGroup[]>([]);
  private _uploadStacks = new BehaviorSubject<UploadStack[]>([]);
  private _uploadDaemon = new BehaviorSubject<UploadDaemon>({ status: 0, currentGroup: '', progress: 0 });
  public action = new BehaviorSubject<string>('');

  readonly uploadGroups = this._uploadGroups.asObservable();
  readonly uploadDaemon = this._uploadDaemon.asObservable();
  readonly uploadStacks = this._uploadStacks.asObservable();

  allowedExts = [[], ['**'], ['mp4', 'mov', 'wmv', 'flv', 'avi'], ['***'], ['vob']];
  allowFolderUpload: boolean;
  dragAndDropControl: boolean;
  gcloud_projectname: any;
  gcloud_location: any;
  healthcare: any;
  paramsObjO = this.paramsObj.asObservable();
  scannedFiles = this.scannedFilesSource.asObservable();
  storename: string;
  uploadBlocks = this.uploadBlocksSource.asObservable();
  uploadChunkReadyO = this.uploadChunkReady.asObservable();
  up_datasetname: string;
  up_params: any;
  up_storecomplementname: any;
  up_storageRef: any;
  up_db: any;
  uDaemon: any;
  unAvailableStores: any;

  public infectedFiles: any[] = [];
  public scanningMonitorInterval: any;

  constructor(
    private gapiOperations_$: GapiOperationsService,
    private utils_$: UtilsService,
    private auth_$: AuthService,
    private firebaseUtilities_$: FirebaseUtilitiesService,
    private uiMessaging_$: UIMessagingService,
    private dicomUtilities_$: DicomUtilitiesService,
    private http: HttpClient,
    private algolia_$: AlgoliaService,
    private sessionStorage_$: SessionStorageService,
    private dialog: MatDialog,
    private clientMatter_$: ClientMatterService,
  ) {
    this.unAvailableStores = [];
    this.uDaemon = { riseEvent: function () {}, stacks: 0 };

    this.uDaemon.riseEvent = event => {
      switch (event) {
        case 'addgroup':
          this.uDaemon.stacks++;
          break;
        default:
          break;
      }
    };
  }

  arraysSubstraction(source, clearlist) {
    return source.filter(file => this.fileApprove(file, clearlist));
  }

  createDataStore(dataset: string) {
    const projectId = environment.config.gapi.projectname;
    const cloudRegion = environment.config.gapi.location;

    const url =
      `${environment.config.healthcareApi}` +
      `/projects/${projectId}` +
      `/locations/${cloudRegion}` +
      `/datasets/${dataset}` +
      `/create`;

    return this.http
      .get(url)
      .toPromise()
      .then(
        data => {
          console.log('The dataset has been created:', data);
          return true;
        },
        reason => {
          console.log(`Some error during dataset creation: ${dataset}`, reason);
          return false;
        },
      )
      .catch(err => {
        console.error(err);
      });
  }

  private createUploadRegistryAuditLog({ entry }, params) {
    this.firebaseUtilities_$.logService_$.storeLog(
      this.firebaseUtilities_$.auth_$.userData.getValue(),
      LogLevelsDictionary.info,
      {
        filename: entry.name,
        filedate: entry.fdate,
        filecontent: entry.desc,
        filetype: entry['type'] || '',
      },
      'Upload',
      params.uploadBlock.casename,
    );
  }

  /* async createUploadBlock(filteredEntries: any[], contextParams) {
    const { creator, fdate, fdesc, clientName, casename, type, currentFolder } = contextParams;
    const cleanCaseName = this.utils_$.cleanString(casename);
    const stackID = `${cleanCaseName}_${this.utils_$.getRandomString()}`;
    const blockFiles = [];

    filteredEntries.forEach(entry => {
      entry.fdate = fdate;
      entry.desc = fdesc || '';

      blockFiles.push({
        entry,
        entryMeta: {
          entryUID: this.utils_$.generateUniqueId(10, 'entry'),
          progress: 0,
          status: 'new',
          stackID,
          target: { folderName: '', folderId: '' },
        },
      });
    });

    return {
      blockFiles: blockFiles,
      fireStoreFiles: [],
      casename: casename,
      clientName: clientName,
      creator: creator,
      desc: fdesc,
      DICOMMetaData: [],
      fdate: fdate,
      id: stackID,
      status: 'new',
      type: type,
      value: 'Some value',
      currentFolder,
    };
  } */

  /**
   * Iterates the studies inside the disc and creates a dicomdir instance per study.
   * @returns UploadBlock which consists of one DICOMDIR file instance per study
   */
  createUploadDICOMBlock(DICOMDIR, studiesData, DICOMFiles, contextParams): any {
    const DICOMMetaData = studiesData;
    const { casename, clientName, creator } = contextParams;
    const newDate = new Date();
    const entry = DICOMDIR;
    const id = `${casename}_${this.utils_$.getRandomString()}`;

    const DICOMUploadBlock = {
      DICOMMetaData: DICOMMetaData,
      blockFiles: this.gettingBlockFiles(DICOMMetaData, entry, this.getDefaultDate(newDate), id),
      fireStoreFiles: DICOMFiles,
      id: id,
      desc: entry.desc,
      type: '3',
      fdate: entry['fdate'],
      casename: casename,
      clientName: clientName,
      creator: creator,
      createdAt: this.utils_$.setDateToString(new Date()),
      value: 'Some value',
      status: 'new',
    };
    console.log('DICOMUploadBlock :', DICOMUploadBlock);
    return DICOMUploadBlock;
  }

  currentUploadProgress(progress: number, filename: string, uploadtarget: number, stackID: string) {
    const uploadtargets = [
      { long: 'no target', short: 'no target' },
      { long: 'firebase database', short: 'fbdb' },
      { long: 'firebase storage', short: 'fbst' },
    ];
    let lIndex;
    let lStack;
    const cUS = this._uploadStacks.getValue();

    cUS.map((e, index) => {
      if (e.id === stackID) {
        lIndex = index;
        lStack = e;
        lStack.files.map(d => {
          if (d.name === filename) {
            d.progress = 100;
          }
        });
      } else {
        lIndex = lStack = undefined;
      }
    });

    if (lStack && lIndex) {
      lStack.progress = progress;
      cUS[lIndex] = lStack;
      this._uploadStacks.next(cUS);
    } else {
      // console.log('Something failed.');
    }
  }

  //  FIXME: Clear unfilled desc/fdate files (WIP).
  clearUnfilledUploadBlocks() {
    const uploadBlocks = this.uploadBlocksSource.getValue();
    const cleared = uploadBlocks.filter(item => item.desc !== '').filter(item => item.fdate !== '');
    this.uploadBlocksSource.next(cleared);
  }

  //  FIXME: Clear unfilled desc/fdate files (WIP).
  clearScannedBlocksSource() {
    const newScannedBlockSource = [];
    this.scannedBlocksSource.value.forEach(item => {
      const blockFiles = [];
      item.blockFiles.forEach(blockFile => {
        const cond1 = blockFile.entry.fdate !== '';
        const cond2 = blockFile.entry.desc !== '';
        if (cond1 || cond2) blockFiles.push(blockFile);
      });
      if (blockFiles.length) {
        item.blockFiles = blockFiles;
        newScannedBlockSource.push(item);
      }
    });
    this.scannedBlocksSource.next(newScannedBlockSource);
  }

  async dataSetExists(datasetname) {
    const projectId = environment.config.gapi.projectname;
    const cloudRegion = environment.config.gapi.location;
    const url = `${environment.config.healthcareApi}/projects/${projectId}/locations/${cloudRegion}/datasets/${datasetname}`;
    return this.http
      .get(url)
      .toPromise()
      .then(
        data => {
          console.log('The dataset exists in:', data);
          return true;
        },
        reason => {
          console.log(`Some error during dataset existence check: ${datasetname}`, reason);
          return false;
        },
      )
      .catch(err => {
        console.error(err);
      });
  }

  /**
   * Clear unAvailableStores var.
   */
  enableStores() {
    this.unAvailableStores = [];
  }

  async fbstorage_deleteFolderContents(folderPath) {
    const ref = firebase.storage().ref(folderPath);
    const list = await ref.listAll();

    if (list.items.length === 0) {
      return new Promise(resolve => resolve(true));
    }

    return ref
      .listAll()
      .then(dir => {
        console.log('********************************');
        console.log('List All results', dir);
        console.log('********************************');

        const promisesStack = [];

        dir.items.forEach(fileRef => {
          console.log('____________________________________________');
          console.log('fileRef: ', fileRef);
          console.log('____________________________________________');

          promisesStack.push(fileRef.delete());
          // promisesStack.push(this.fbstorage_deleteFile(ref.fullPath, fileRef.name));
        });

        return Promise.all(promisesStack)
          .then(result => {
            console.log('----------------------------------');
            console.log('Delete items result: ', result);
            console.log('----------------------------------');

            const promisesStack2 = [];

            if (dir.items.length) {
              dir.prefixes.forEach(folderRef =>
                promisesStack2.push(this.fbstorage_deleteFolderContents(folderRef.fullPath)),
              );
              return Promise.all(promisesStack2).then(a => a);
            } else {
              return new Promise(resolve => resolve(true));
            }
          })
          .catch(err => {
            console.log('::::::::::::::::::::::::::::::::::::::::::');
            console.log('Promises delete files error', err);
            console.log('::::::::::::::::::::::::::::::::::::::::::');
          });
      })
      .catch(error => {
        console.log('::::::::::::::::::::::::::::::::::::::::::');
        console.log('List All errors', error);
        console.log('::::::::::::::::::::::::::::::::::::::::::');
      });
  }

  fbstorage_deleteFolder(folderPath) {
    console.log('fbstorage_deleteFolder :', folderPath);
    const ref = firebase.storage().ref(folderPath);
    return ref.delete();
  }

  fbstorage_deleteFile(pathToFile, fileName) {
    return firebase
      .storage()
      .ref(pathToFile)
      .child(fileName)
      .delete()
      .catch(err => {
        console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
        console.log('Error on fbstorage_deleteFile: ', err);
        console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
      });
  }

  filterFilesByExt(uType, files) {
    return files.filter(file => this.utils_$.hasValidExt(file.name, this.allowedExts[parseInt(uType, 10)]));
  }

  filterUploadBlockFiles(blocks, uploadblock) {
    const cleanList = [];
    const blockFiles = uploadblock.blockFiles;
    blocks.forEach(block => {
      block.blockFiles.forEach(file => {
        blockFiles.forEach(blockFile => {
          if (file.entry.name === blockFile.entry.name) {
            cleanList.push(blockFile);
          }
        });
      });
    });
    uploadblock.blockFiles = this.arraysSubstraction(blockFiles, cleanList);
    return uploadblock;
  }

  fileApprove(file: { entry: { name: any } }, clearList: string | any[]) {
    for (let index = 0; index < clearList.length; index++) {
      if (clearList[index].entry.name === file.entry.name) return false;
    }
    return true;
  }

  private fileRegistration({ params, file }: { params: any; file: Dicomdir }): Promise<FileRegistrationResult> {
    return this.firebaseUtilities_$.fileRegistration({ params, file });
  }

  getVIDEODISKUploadBlock(entries: any[]) {
    return entries
      .filter(videoFile => this.utils_$.getFileExtension(videoFile.name) === 'vob')
      .filter(videoFile => videoFile.name.substr(0, 1) !== '.');
  }

  getRandomString(stringLength: number = 10) {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    let randomstring = '';
    for (let i = 0; i < stringLength; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }

  getScannedFiles(): Observable<any[]> {
    return this.scannedFiles;
  }

  getScannedUploadBlocksList(): Array<UploadBlock> {
    return this.scannedBlocksSource.value;
  }

  async getDICOMDISKUploadBlock(DICOMDIRFile: File, entries: any, contextParams: ContextParams) {
    this.auth_$.showLoader('Getting DICOM files metadata...');

    const { filesData, studiesData } = await this.dicomUtilities_$.getDicomdirMetadata(DICOMDIRFile);
    checkingDataErrors(filesData, studiesData);
    this.auth_$.hideLoader();
    const entriesFilteredByExtension = this.utils_$.filterFilesByExt(entries, this.allowedExts[3]);

    const var1 = entriesFilteredByExtension
      .filter(({ name }) => name !== dicomdirFileName)
      .map(file => ({
        lastModified: file.lastModified,
        webkitRelativePath: file.webkitRelativePath,
        name: file.name,
        size: file.size,
        type: file.type,
        filePath: file.webkitRelativePath,
        file: file,
      }));

    // FIXME: One call of filterFilesByExt is enough
    const entriesDataCleaned = var1
      .map((entry: FileEntry) => {
        const find = filesData.find(_file =>
          this.utils_$.isContained(entry.filePath, this.utils_$.cleanFilePath(_file)),
        );

        return find
          ? {
              file: entry.file,
              name: entry.name,
              lastModified: entry.lastModified,
              seriesNumber: find.SeriesNumber,
              size: entry.size,
              studyInstanceUID: find.StudyInstanceUID,
              studyDescription: find.StudyDescription,
              type: entry.type,
              accessionNumber: find.AccessionNumber,
              webkitRelativePath: entry.webkitRelativePath,
            }
          : undefined;
      })
      .filter(item => item !== undefined);

    const cleanedData = entriesDataCleaned;
    const filePaths = filesData.map(item => item.ReferencedFileID.replace(/\.\\/g, '/').replace(/\\/g, '/'));

    // FIXME: One call of filterFilesByExt is enough
    const DICOMFiles = this.utils_$
      .filterFilesByExt(cleanedData, this.allowedExts[3])
      .filter(item => filePaths.filter(filePath => item.webkitRelativePath.indexOf(filePath) > -1).length > 0);
    return this.createUploadDICOMBlock(DICOMDIRFile, studiesData, DICOMFiles, contextParams);
  }

  generateStoreName(dataset: string, date: string) {
    const randNum = Math.floor(Math.random() * Math.floor(950));
    this.storename = `${dataset}_${this.utils_$.setDateToString(new Date(date))}_${randNum}`;
    return this.storename;
  }

  /**
   * Return a list of dicomstores of the given client.
   */
  async getDataStoresByClient(clientname: string) {
    // NOTE: Check is dataset with clientname exists.duration
    if (!(await this.dataSetExists(clientname))) {
      await this.createDataStore(clientname)
        .then(r => console.log('The dataset has been created:', r))
        .catch(err => console.error('Error creations the dataset', err));
      return [];
    }

    const datasetId = clientname;
    const projectId = environment.config.gapi.projectname;
    const cloudRegion = environment.config.gapi.location;
    const url = `${environment.config.healthcareApi}/projects/${projectId}/locations/${cloudRegion}/datasets/${datasetId}/dicomStores`;

    return this.http
      .get(url)
      .toPromise()
      .then(
        data => (!data['dicomStores'] ? [] : data['dicomStores']),
        reason => reason,
      );
  }

  private generateDicomdirFile(name: string, targetFolder: { folderId: any }, file: GenerateDicomDirFileFileParam) {
    const { lastModified, parentFolderName, parentFolder } = file;
    const { desc, fdate, targetFolderId } = file.entryMeta;
    const uploadedDate = new Date().toString();
    const entry = { name, desc: desc || '', fdate: fdate || '' };
    const entryMeta = { fdate: fdate || '', targetFolderId: targetFolderId || targetFolder.folderId || '' };

    return <Dicomdir>{
      entry,
      entryMeta,
      uploadedDate,
      lastModified,
      parentFolderName,
      parentFolder,
    };
  }

  getRandomStrings(length) {
    const value = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const randoms = [];
    for (let i = 0; i < length; i++) {
      randoms.push(value[Math.floor(Math.random() * value.length)]);
    }
    return randoms.join('');
  }

  /**
   * Get the total size of the upload block for DICOM disk files.
   */
  getDICOMStackSize(ustack: any): number {
    let blockTotalSize = 0;
    ustack.fireStoreFiles.forEach(file => {
      blockTotalSize += file.size;
    });
    return blockTotalSize;
  }

  /**
   * Get the total size of the upload block for files.
   */
  getFilesStackSize(ustack: any): number {
    let blockTotalSize = 0;
    ustack.blockFiles.forEach(file => {
      blockTotalSize += file.entry.size;
    });
    return blockTotalSize;
  }

  async handleScan(scanId) {
    if (!scanId || scanId === '') {
      console.log('No scan id provided');
      return;
    }
    if (scanId.indexOf('*') >= 0) {
      console.log('Scan id contains *');
    }
    const scanParts = scanId.split('*');
    const url =
      `${environment.constants.cloudfunctionsURL}` +
      `${environment.config.malwarescanner.endpoint}` +
      `-getScanResult?id=${scanParts[0]}`;
    const scanResults = await this.http.get(url).toPromise();
    if (scanResults['data']['attributes']['status'] === 'completed') {
      const analysisId = scanResults['data']['id'];
      this.sessionStorage_$.removePendingScan(analysisId, '*');
      const results = scanResults['data']['attributes']['results'];
      const detected = [];

      Object.keys(results).forEach(key => {
        if (results[key]['result'] !== null) {
          detected.push(results[key]['result']);
        }
      });
      if (detected.length > 0) {
        this.uiMessaging_$.toastMessage('Scan completed, malicious file detected, removing the file is on going.', '');
        this.dialog.open(SimpleMessageWindowComponent, {
          data: {
            title: 'Malicious File Detected',
            message: 'Malicious file detected, removing the file is on going.',
            buttons: [{ text: 'OK' }],
          },
        });
        this.removeMaliciousFile(analysisId, scanParts[1]);
      } else {
        console.log('No malicious file detected');
      }
    }
  }

  /**
   * Handle an upload stack when no DICOM disk has been selected.
   */
  async handleSimpleFiles(params) {
    const { uploadBlock, chunkLength } = params;
    const { blockFiles, casename, fdate } = uploadBlock;

    for (let i = 0; i < blockFiles.length; i += chunkLength) {
      let counter = 0;
      for (let fileObject of blockFiles.slice(i, i + chunkLength)) {
        const { entry, entryMeta } = fileObject;
        const { targetFolderName, targetFolderId } = entryMeta;
        const scanCode = `t_${this.utils_$.getRandomString_(15)}`;

        if (entryMeta.type === 'dicom') {
          // FIXME: Review this
          // this.malwareScan(<File>entry, scanCode);

          params = this.updateParams(params, counter);
          const storename = this.generateStoreName(casename, entry.fdate || fdate || new Date().toString());
          const uploadBlock = { ...params.uploadBlock, blockFiles: [fileObject] };

          params = { ...params, storename, up_datastorename: storename, type: 3, uploadBlock };

          const projectname = this.gapiOperations_$.gcloud_projectname;
          const projectlocation = this.gapiOperations_$.gcloud_projectlocation;

          //NOTE - Create the DataStore.
          const createDataStoreResult = await this.gapiOperations_$.createDataStore(
            storename,
            casename,
            projectname,
            projectlocation,
          );

          console.log('createDataStoreResult: ', createDataStoreResult);

          /////////////////////////////////////////////
          /* HANDLING DICOMDIR FILE ---------------------- */
          this.unAvailableStores.push({ storename, casename });

          //NOTE - File storing. (Storage)
          const targetData = { folderId: targetFolderId, folderName: targetFolderName };
          const dicomdirFile = this.generateFakeDicomdirFile(dicomdirFileName, targetData, entry, entryMeta);

          await this.firebaseUtilities_$
            .fileStorageAdd(dicomdirFile, params.filepath)
            .catch(error => console.log('fileStorageAdd error:', error));

          await this.afterFileStorageAddition(params, dicomdirFile);

          /* --------------------------- END DICOMDIR FILE */
          /////////////////////////////////////////////

          await this.handlingHealthCareJob(uploadBlock, params.uploadBlock.blockFiles);
        } else {
          fileObject = {
            ...fileObject,
            lastModified: entry.lastModified,
            parentFolderName: entryMeta.targetFolderName,
            uploadedDate: new Date().toString(),
          };

          this.malwareScan(<File>entry, scanCode);

          params = {
            ...params,
            scanAnalysisId: scanCode,
            filepath: `patient/${uploadBlock.casename}/${entry.name}`,
            studyuid: null,
            lastModified: new Date(entry.lastModified).toString(),
            type: 1,
          };

          // Add the fileObject to storage.
          await this.firebaseUtilities_$.fileStorageAdd(fileObject, params.filepath);

          // Register the fileObject.
          const fileObj = await this.fileRegistration({ params, file: fileObject });

          // Add upload registry to Audit Log.
          this.createUploadRegistryAuditLog(fileObject, params);

          // Add fileObject to the Algolia index.
          this.firebaseUtilities_$.algoliaObjects.push(fileObj);
        }

        this.fileUploaded.next(true);
        uploadBlock.setStatus('completed');
        counter++;
      }
    }

    this.algolia_$.storeToAlgolia();

    switch (params.uploadBlock.type) {
      case '1':
      case '2':
        this.allowFolderUpload = false;
        break;
      case '4':
        this.dragAndDropControl = true;
        this.allowFolderUpload = true;
        break;
      default:
        break;
    }

    this.uiMessaging_$.toastMessage('Files Upload completed', 'STATUS');
  }

  private getStudyUID(studyInstanceUID) {
    return studyInstanceUID ? `/study/${studyInstanceUID}` : null;
  }

  /**
   * Add studyuid, filepath and caseName to params.
   * @param params
   * @param index
   * @returns params object.
   */
  private updateParams(params, index) {
    params.studyuid = this.getStudyUID(params.uploadBlock.DICOMMetaData[index].StudyInstanceUID);
    params.filepath = `patient/${params.uploadBlock.casename}/${dicomdirFileName}`;
    params.caseName = params.uploadBlock.casename;
    return params;
  }

  /**
   * Handle an upload stack when this is a DICOM Disk type.
   */
  async handleDICOMDisk(params) {
    const { uploadBlock, targetFolder } = params;
    const { blockFiles, DICOMMetaData } = uploadBlock;

    if (DICOMMetaData.length > 0) {
      // There is at least one StudyUID on DICOMDIR file.
      for (let index = 0; index < DICOMMetaData.length; index++) {
        const _file = blockFiles[index];
        if (_file.toUpload === false) {
          console.log("This file won't be uploaded.", _file);
        } else {
          console.log('This file will be uploaded.', _file);
          params = this.updateParams(params, index);

          // const file = this.generateDicomdirFile(dicomdirFileName, params, index);
          const dicomdirFile = this.generateDicomdirFile(dicomdirFileName, targetFolder, _file);

          console.log('-- dicomdirFile: ', dicomdirFile);
          this.unAvailableStores.push({ storename: params.storename, casename: params.uploadBlock.casename });

          // File storing. (Storage)
          await this.firebaseUtilities_$.fileStorageAdd(dicomdirFile, params.filepath);
          params = { ...params, type: 3 };
          await this.afterFileStorageAddition(params, dicomdirFile);
        }
      }
    }

    const global = this.getGlobal(params);

    this.currentUploadProgress(global, dicomdirFileName, 1, params.uploadBlock.id);
    // document.getElementById('progbar').style.width = `${global}%`;

    // NOTE: DICOMDIR file included on firestore could cause failure during the import process.
    this.dragAndDropControl = true;
    this.allowFolderUpload = true;

    params.uploadBlock.setStatus('inprogress');

    /******* HANDLE DATASTORE CREATION ******** */
    const dataStoresByClient = await this.getDataStoresByClient(params.uploadBlock.casename);
    const matchDataStoresByClient = this.matchDataStoresByClient(
      params.storename,
      !dataStoresByClient ? [] : dataStoresByClient,
    );

    if (!dataStoresByClient.length || !matchDataStoresByClient) {
      const createDataStore = await this.gapiOperations_$.createDataStore(
        params.storename,
        params.uploadBlock.casename,
        this.gapiOperations_$.gcloud_projectname,
        this.gapiOperations_$.gcloud_projectlocation,
      );

      if (!createDataStore) {
        this.uiMessaging_$.toastMessage('The Data Store could not be created.', 'ERROR');
        return;
      }
    }
    /******* END HANDLE DATASTORE CREATION ******** */

    const dicomImportResult = await this.handleDICOMImport(params);
    if (!dicomImportResult) {
      this.uiMessaging_$.toastMessage('DICOM Import failed', 'ERROR');
      return;
    }
    params.uploadBlock.setStatus('completed');
    this.uiMessaging_$.toastMessage('DISK Upload completed', 'STATUS');
  }

  async handleDICOMImport(params) {
    params.uploadBlock.blockFiles = params.uploadBlock.blockFiles.filter(({ toUpload }) => toUpload !== false);
    const files = params.uploadBlock.fireStoreFiles.filter(({ name }) => name !== dicomdirFileName);
    const authorizedAccessionNumbers = this.getAuthorizedAccessNumbers(params.uploadBlock.blockFiles);
    const filteredFiles = this.getFilteredFiles(authorizedAccessionNumbers, files);

    // Create HealthCare Object.
    const healthCareObject = await this.generateHealthCareObject(params, filteredFiles);

    // const totalSizeOfUpload = calculateTotalSizeOfTheUpload(healthCareObject.files);

    if (healthCareObject.files.length === 0) {
      console.log('No files to upload');
      return false;
    }

    return this.uploadHealthCareObject(healthCareObject);
  }

  reproduceDicomDirFileInClio(params) {
    this.clientMatter_$.generateDicomDirFile(params);
  }

  checkIfParentFolderIsClio(parentFolder) {
    return parentFolder.folderName === 'Clio';
  }

  async malwareScan(file, temporalScanCode) {
    const malwareScanResults = await this.sendFileToMalwareScan(<File>file);
    const resultsData = malwareScanResults['result']['data'];
    const ananlysisId = resultsData['id'];
    const ananlysisStatus = resultsData['attributes']['status'];
    const ananlysisResults = resultsData['attributes']['results'];

    if (ananlysisStatus === 'completed' && this.validateScanResult(ananlysisResults)) {
      const msg = 'Malicious file detected. Removal in progress.';
      console.log('msg :', msg);
      this.uiMessaging_$.toastMessage('Scan completed, malicious file detected, removing the file is on going.', '');
      this.dialog.open(SimpleMessageWindowComponent, {
        data: {
          title: 'Malicious File Detected',
          message: 'Malicious file detected, removing the file is on going.',
          buttons: [{ text: 'OK' }],
        },
      });
      this.removeMaliciousFile(ananlysisId, temporalScanCode);
    } else {
      this.sessionStorage_$.setPendingScan(ananlysisId, temporalScanCode);
      this.setScanningMonitoring(10000);
    }
  }

  matchDataStoresByClient(storename: string, dataStoresByClient: any) {
    const dStores = dataStoresByClient.map(item =>
      item.name.substr(item.name.lastIndexOf('/') + 1, item.name.length - 1),
    );
    return dStores.includes(storename);
  }

  /**
   * Store selected files or folders into blocks of files in scannedBlockSource,
   * this is a temporal store before click the UPLOAD button and send them to cloud
   * store.
   */
  pushUploadBlock(uploadBlock: UploadBlock): boolean {
    if (!uploadBlock.blockFiles) {
      this.uiMessaging_$.toastMessage('This upload block has no files inside it, please retry.', 'ERROR');
      return;
    }

    uploadBlock.ugid = this.getRandomString();
    uploadBlock.fdate = new Date().toString();
    uploadBlock.blockFiles.forEach((file, index) => {
      file.ugid = uploadBlock.ugid;
      file.fileUploadCode = this.getRandomString(8);
      if (file.entryMeta.type === 'dicom') file.DICOMMetaData = uploadBlock.DICOMMetaData[index];
    });

    const blocks = this.scannedBlocksSource.value;
    const uploadBlockCleaned = this.filterUploadBlockFiles(blocks, uploadBlock);

    if (uploadBlockCleaned.blockFiles.length > 0) {
      this.scannedBlocksSource.next([...this.scannedBlocksSource.value, uploadBlockCleaned]);
      return true;
    } else return false;
  }

  async removeMaliciousFile(analysisId, tempScanCode) {
    if (!analysisId) {
      console.log('No analysis id provided');
      return;
    }
    const doc = (
      await firebase.firestore().collection('files').where('scanAnalysisId', '==', tempScanCode).limit(1).get()
    ).docs[0];

    const fileInfo = doc.data();
    const storageLocation = fileInfo.filePath;
    const storageRef = firebase.storage().ref().child(storageLocation);

    // Delete the file
    storageRef
      .delete()
      .then(() => {
        this.uiMessaging_$.toastMessage('Malicious file removed.', '');
        this.action.next('update');
      })
      .catch(error => {
        this.uiMessaging_$.toastMessage('There was an error on file removal', '');
      });
    doc.ref
      .delete()
      .then(() => {
        this.uiMessaging_$.toastMessage('Malicious file reference removed.', '');
        this.action.next('update');
      })
      .catch(error => {});
  }

  removeCompleted() {
    const nextScannedBlockSource = this.scannedBlocksSource.value.filter(item => item.status !== 'completed');
    this.scannedBlocksSource.next(nextScannedBlockSource);
  }

  removeUploadBlock(ugid: string) {
    const clonedSBS = this.scannedBlocksSource.value.filter((item, index) => item.ugid !== ugid);
    this.scannedBlocksSource.next(clonedSBS);
  }

  removeNode(name: string, ugid: string) {
    const blocksToRemove = [];
    this.scannedBlocksSource.value.forEach((uploadBlock: UploadBlock) => {
      if (uploadBlock.ugid === ugid) {
        const remaining = uploadBlock.deleteBlockFile(name);
        if (remaining.length === 0) {
          blocksToRemove.push(ugid);
        }
      }
    });

    // Remove BLOCKS.
    const remainig = this.scannedBlocksSource.value.filter(ub => !blocksToRemove.includes(ub.ugid));
    this.scannedBlocksSource.next(remainig);
  }

  /**
   * Set a specific uploadStack with status in-progress: true.
   */
  setInProgress(uploadStackID: string) {
    const newScannedBlocksSource = [];
    this.scannedBlocksSource.value.forEach(item => {
      if (item.id === uploadStackID) item.inprogress = true;
      newScannedBlocksSource.push(item);
    });
    this.scannedBlocksSource.next(newScannedBlocksSource);
  }

  setScanningMonitoring(time) {
    if (!this.scanningMonitorInterval && this.sessionStorage_$.getScanIds() !== '') {
      this.scanningMonitorInterval = setInterval(() => {
        const scanIds = this.sessionStorage_$.getScanIds().split(',');
        console.log('scanIds :', scanIds);
        scanIds.forEach(scanId => this.handleScan(scanId));
      }, time);
    } else {
      console.log('Scanning monitoring already set');
    }
  }

  /**
   * Sort upload stack by smallest first.
   */
  sortUStacksBySize(ustacks: any[]): any {
    ustacks.forEach(ustack => {
      ustack.totalsize = ustack.type === '3' ? this.getDICOMStackSize(ustack) : this.getFilesStackSize(ustack);
    });
    return ustacks.sort((a, b) => (a.totalsize > b.totalSize ? 1 : -1));
  }

  private _generateStoreName(casename, fdate) {
    const date = fdate === '' || fdate === undefined ? this.utils_$.getDateToString(new Date()) : fdate;
    const randNum = Math.floor(Math.random() * Math.floor(950));
    const dateToString = this.utils_$.setDateToString(new Date(date));
    return `${casename}_${dateToString}_${randNum}`;
  }

  private getUploadPromise(uploadBlock, targetFolder, options, chunkLength) {
    const nameSuffix = this.getNameSuffix(uploadBlock);
    const index = 0;
    const uploadTypes = uTypes;

    if (uploadBlock.type === '3') {
      const storename = this.generateStoreName(uploadBlock.casename, uploadBlock.fdate);
      const params = {
        uploadBlock,
        uploadTypes,
        nameSuffix,
        index,
        targetFolder,
        storename,
        options,
      };
      return this.handleDICOMDisk(params);
    } else {
      const params = { uploadBlock, uploadTypes, nameSuffix, index, targetFolder, chunkLength };
      return this.handleSimpleFiles(params);
    }
  }

  /**
   * takes upload stacks, put them inprogress and
   * send them to the server.
   */
  async startUpload(targetFolder?, options?) {
    // FIXME: Is this working?
    /* this.setDaemonStatus('starting');
    this.setDaemonProgress(0); */
    const chunkLength = 10;
    const newScannedBlockData = this.sortUStacksBySize(this.scannedBlocksSource.value);
    this.scannedBlocksSource.next(newScannedBlockData);
    const status = ['inprogress', 'completed'];
    const promisesStack = [];

    for (let index = 0; index < this.scannedBlocksSource.value.length; index++) {
      const uploadBlock = this.scannedBlocksSource.value[index];

      if (!status.includes(uploadBlock.getStatus())) {
        uploadBlock.setStatus(status[0]);
        this.setInProgress(uploadBlock.id);
        promisesStack.push(this.getUploadPromise(uploadBlock, targetFolder, options, chunkLength));
      }
    }
    return Promise.all(promisesStack);
  }

  /**
   * Store DCMs in Firebase Storage.
   * @param filteredFiles - data
   * @param dataStorePath
   * @param chunksize
   * @param counter
   */
  async storeDCMFiles(filteredFiles, dataStorePath, chunksize, counter, datastore) {
    const resultsStack = [];
    for (let v = 0; v < filteredFiles.length; v++) {
      const promisesStack = this.populatePromises(chunksize, filteredFiles, counter, dataStorePath, datastore);

      // When all Store Actions are done.
      await Promise.all(promisesStack)
        .then(r => {
          resultsStack.push(r);
          console.log('storeDICOMFileInFirebaseStorage storing files result:', r);
        })
        .catch(e => console.error('storeDICOMFileInFirebaseStorage storing files error:', e));

      v += chunksize - 1;
    }
    console.log('resultsStack :', resultsStack);
    return resultsStack;
  }

  /**
   * What to do after the DICOM Files import to Google HealthCare process.
   * @param importResult
   */
  handleImportResults(importResult, dataStorePath) {
    if (importResult === 0 || importResult['ok'] === false) {
      this.uiMessaging_$.toastMessage(`There was an error during the importing process.`, null);
    } else {
      // Enable Stores.
      this.unAvailableStores = [];

      this.uiMessaging_$.toastMessage(`DICOM disc (medical images) imported successfully.`, null, { duration: 0 });

      // NOTE: Has to send an email...

      // NOTE: Delete the folder after 10 seconds.
      setTimeout(
        () =>
          this.fbstorage_deleteFolderContents(dataStorePath)
            .then(async () => await this.fbstorage_deleteFolder(dataStorePath))
            .catch(e => console.error('fbstorage_deleteFolderContents ::: e :', e)),
        10000,
      );
    }
  }

  async uploadHealthCareObject(healthcareCreatedObj) {
    const { files, dataset, datastore } = healthcareCreatedObj;
    const dataStorePath = `dcmstore/${dataset}/${datastore}/`;

    // Avoid DICOMDisk and DICOMDIR
    const filteredFiles = files.filter(file => ![dicomdirFileName, 'DICOMDisk'].includes(file.name));
    const chunksize = 100;
    let counter = 0;
    const result = await this.storeDCMFiles(filteredFiles, dataStorePath, chunksize, counter, datastore);
    console.log('result storeDCMFiles: ', result);

    // UI Information.
    this.uiMessaging_$.toastMessage('DICOM disc (medical images) uploaded successfully.', null);

    // Import DICOM Files to Google HealthCare.
    return this.gapiOperations_$
      .importDICOMFiles(dataset, datastore)
      .then(importResult => {
        this.handleImportResults(importResult, dataStorePath);
        return importResult;
      })
      .catch(err => {
        console.log('++++++++++++++++++++++++++++++++');
        console.log('Error in IMPORT DICOM FILES', err);
        console.log('++++++++++++++++++++++++++++++++');
        err;
      });
  }

  sendFileToMalwareScan(entry: any) {
    const malwareScannerEndpoint =
      `${environment.constants.cloudfunctionsURL}` + `${environment.config.malwarescanner.endpoint}` + `-analizeFile`;
    const form = document.createElement('form');
    const formData = new FormData(form);
    formData.append('myupload', entry, entry.name);
    return this.http.post(malwareScannerEndpoint, formData).toPromise();
  }

  /**
   * Update Scanned Block Source with the fdate and desc values.
   * @param ugid is the indentifier of the block to be modified.
   * @param node has the new values.
   */
  updateScannedBlockSource(
    ugid: string,
    node: { entryUID: any; fdate: any; desc: any; target: any; fileUploadCode: any },
  ) {
    const { fdate, desc, target, fileUploadCode } = node;
    const { folderId, folderName } = target;
    if (!ugid) {
      console.error('The ugid is undefined.');
      return;
    }

    const newScannedBlockSource = [];
    let updateFlag = false;
    this.scannedBlocksSource.value.forEach(item => {
      if (item.ugid === ugid) {
        updateFlag = true;
        const blockFiles = [];
        item.blockFiles.forEach(blockFile => {
          if (blockFile.fileUploadCode === fileUploadCode) {
            blockFile.entry.fdate = fdate;
            blockFile.entry.desc = desc;
            blockFile.entryMeta.desc = desc;
            blockFile.entryMeta.targetFolderId = folderId;
            blockFile.entryMeta.targetFolderName = folderName;
          }
          blockFiles.push(blockFile);
        });
        item.blockFiles = blockFiles;
        item.fdate = fdate;
      }
      newScannedBlockSource.push(item);
    });

    if (updateFlag) this.scannedBlocksSource.next(newScannedBlockSource);
    else console.log(`There is no ugid that match with ${ugid} inside scannedBlocksSource`);
  }

  validateScanResult(scanResult: any) {
    const results = scanResult;
    const detected = [];
    Object.keys(results).forEach(key => {
      if (results[key]['result'] !== null) {
        detected.push(results[key]['result']);
      }
    });
    if (detected.length > 0) {
      console.log('Detected');
      return true;
    } else {
      console.log('Not Detected');
      return false;
    }
  }

  getAuthorizedAccessNumbers(blockFiles: any[]) {
    return blockFiles
      .filter(({ toUpload }) => toUpload !== false)
      .map(({ entry }) => entry.accessionNumber)
      .filter(accessionNumber => accessionNumber !== undefined);
  }

  getFilteredFiles(authorizedAccessionNumbers: string | any[], files: any[]) {
    return authorizedAccessionNumbers.length
      ? files
          .filter(({ name }) => name !== dicomdirFileName)
          .filter(({ accessionNumber }) => authorizedAccessionNumbers.includes(accessionNumber))
      : files.filter(({ name }) => name !== dicomdirFileName);
  }

  generateHealthCareObject(
    { uploadBlock, storename, storeComplementname }: { uploadBlock: any; storename: string; storeComplementname: any },
    filteredFiles: any,
  ) {
    return this.gapiOperations_$.healthcareCreate({
      datasetname: uploadBlock.casename.replace(/\s/g, ''),
      datastorename: storename.replace(/\s/g, ''),
      files: filteredFiles,
      date: uploadBlock.fdate,
      storecomplementname: storeComplementname ? storeComplementname.replace(/\s/g, '') : '',
      stackID: uploadBlock.id,
    });
  }

  formatYearMonthDate(datestring: string) {
    const year = datestring.substring(0, 4);
    const month = datestring.substring(4, 6);
    const day = datestring.substring(6, 8);
    // return `${year}/${month}/${day}`;
    return `${month}/${day}/${year}`;
  }

  checkIfDicomFile(file: { name: string }) {
    const extension = this.utils_$.getFileExtension(file.name);
    return !extension || extension === 'dcm' ? true : false;
  }

  private generateFakeDicomdirFile(
    name: string,
    targetFolder: { folderId: any; folderName?: any },
    file: { fdate?: any; lastModified?: any; parentFolderName?: any; parentFolder?: any },
    entryMeta: { desc: any; fdate: any; targetFolderId: any },
  ) {
    const { lastModified, parentFolderName, parentFolder } = file;
    const { desc, fdate, targetFolderId } = entryMeta;
    const uploadedDate = new Date().toString();

    entryMeta = {
      ...entryMeta,
      fdate: fdate || file.fdate || '',
      targetFolderId: targetFolderId || targetFolder.folderId || '',
    };

    const entry = { name, desc: desc || '', fdate: fdate || file.fdate || '' };
    return <Dicomdir>{ entry, entryMeta, uploadedDate, lastModified, parentFolderName, parentFolder };
  }

  private async afterFileStorageAddition(params: any, dicomdirFile: Dicomdir) {
    // File registering. (Firestore)
    const fileData = await this.fileRegistration({ params, file: dicomdirFile });

    // File indexing. (Algolia)
    this.firebaseUtilities_$.algoliaObjects.push(fileData);

    // Add upload registry to Audit Log.
    this.createUploadRegistryAuditLog(dicomdirFile, params);

    // This is duplicated for the DICOM file
    // this.fileUploaded.next(true);

    ///////////////////////////////////////
    //FIXME --- NOT SURE WHY THIS IS NEEDED

    // If come from Clio, create a copy of DICOMDIR file on the same folder.
    if (this.checkIfParentFolderIsClio(params.targetFolder) && params.options.target) {
      console.log('Reproduce DICOMDIR file on Clio');
      this.reproduceDicomDirFileInClio({ target: params.targetFolder, options: params.options, fileData });
    }

    // --- NOT SURE WHY THIS IS NEEDED
    ///////////////////////////////////////
  }

  private async handlingHealthCareJob(uploadBlock: any, blockFiles: any) {
    // Generate HealthcareObject.
    const healthCareObject = await this.generateHealthCareObject(
      { uploadBlock, storename: this.storename, storeComplementname: this.up_storecomplementname },
      blockFiles,
    );

    // Test HealthcareObject.
    if (healthCareObject.files.length === 0) {
      console.log('No files to upload');
      return false;
    }

    //NOTE - Run the import process.
    const dicomImportResult = await this.uploadHealthCareObject(healthCareObject);

    if (!dicomImportResult) {
      this.uiMessaging_$.toastMessage('DICOM Import failed', 'ERROR');
      return;
    }

    //  params.uploadBlock.setStatus('completed');
    this.uiMessaging_$.toastMessage('DISK Upload completed', 'STATUS');
  }

  private async handleSimpleFile(
    entry: File | any,
    fdate: any,
    fdesc: string,
    blockFiles: any[],
    entryUID: string,
    progress: number,
    stackID: string,
    status: string,
    target: { folderName: string; folderId: string },
    DICOMMetadata: any[],
  ) {
    let blockFile: { entry: any; entryMeta: any };
    let entryMeta: {
      entryUID: string;
      progress: number;
      status: string;
      stackID: string;
      target: { folderName: string; folderId: string };
      type?: string;
      desc?: any;
    };
    if (this.checkIfDicomFile(entry)) {
      const dicomFileData = await this.dicomUtilities_$.getDICOMFileData(entry);
      const studyData = JSON.parse(dicomFileData.data)[0];
      // const { AccessionNumber, StudyDate, StudyDescription, StudyID, StudyInstanceUID } = studyData;
      const { StudyDate, StudyDescription } = studyData;
      entry.fdate = this.formatYearMonthDate(StudyDate) || '';
      entry.desc = StudyDescription || fdesc || '';
      DICOMMetadata.push(studyData);
      entryMeta = { entryUID, progress, status, stackID, target, type: 'dicom', desc: entry.desc };
    } else {
      entry.fdate = fdate;
      entry.desc = fdesc || '';
      DICOMMetadata.push({});
      entryMeta = { entryUID, progress, status, stackID, target };
    }
    blockFile = { entry, entryMeta };
    blockFiles.push(blockFile);
  }

  async createUploadBlock(
    filteredEntries: any[],
    contextParams: {
      creator: any;
      fdate: any;
      fdesc: any;
      clientName: any;
      casename: any;
      currentFolder?: any;
      type?: any;
    },
  ) {
    const { creator, fdate, fdesc, clientName, casename, type, currentFolder } = contextParams;
    const cleanCaseName = this.utils_$.cleanString(casename);
    const blockFiles = [];
    const DICOMMetadata = [];

    const entryUID = this.utils_$.generateUniqueId(10, 'entry');
    const progress = 0;
    const status = 'new';
    const stackID = `${cleanCaseName}_${this.utils_$.getRandomString()}`;
    const target = { folderName: '', folderId: '' };

    for (let index = 0; index < filteredEntries.length; index++) {
      const entry = filteredEntries[index];
      await this.handleSimpleFile(
        entry,
        fdate,
        fdesc,
        blockFiles,
        entryUID,
        progress,
        stackID,
        status,
        target,
        DICOMMetadata,
      );
    }

    return {
      blockFiles,
      fireStoreFiles: [],
      casename,
      clientName,
      creator,
      desc: fdesc,
      DICOMMetaData: DICOMMetadata,
      fdate,
      id: stackID,
      status: 'new',
      type,
      value: 'Some value',
      currentFolder,
    };
  }

  private getFilePath(dataStorePath: any, datastore: any, file: { name: string }) {
    return `${dataStorePath}${datastore}_${this.utils_$.cleanString(file.name)}_${Date.now()}_${this.getRandomStrings(
      4,
    )}`;
  }

  private getDicomInStoragePromise(file: { name: string }, dataStorePath: any, datastore: any, StudyDescription: any) {
    return this.firebaseUtilities_$.storeDICOMFileInFirebaseStorage(
      file,
      this.getFilePath(dataStorePath, datastore, file),
      { studyDescription: StudyDescription },
    );
  }

  private getNameSuffix(uploadBlock: { fdate: string | number | Date }) {
    return this.utils_$.getNewStoreName_complement(new Date(uploadBlock.fdate).valueOf().toString());
  }

  private populatePromises(chunksize, filteredFiles, counter, dataStorePath, datastore) {
    const promisesStack = [];
    for (let h = 0; h < chunksize; h++) {
      const fileEntry = filteredFiles[counter];
      if (!fileEntry) continue;

      const StudyDescription = this.getStudyDescription(fileEntry);
      const file = fileEntry['file'] || fileEntry['entry'];

      if (!file) continue;
      if (!StudyDescription) console.log('No studyDescription for file :', file);
      promisesStack.push(this.getDicomInStoragePromise(file, dataStorePath, datastore, StudyDescription));

      // Store the reference in Algolia.
      this.firebaseUtilities_$.algoliaObjects.push(file);
      counter++;
    }
    return promisesStack;
  }

  private buildEntryMeta(stackID, singleDicomFile, desc, currentFolder) {
    const entryUID = this.utils_$.generateUniqueId(10, 'entry');
    const progress = 0;
    const status = 'new';
    const target = { folderName: '', folderId: '' };

    let entryMeta: any = { entryUID, progress, status, stackID, target };

    if (singleDicomFile) {
      entryMeta = {
        ...entryMeta,
        desc: desc,
        type: 'dicom',
        targetFolderName: currentFolder.folderName,
        targetFolderId: currentFolder.folderId,
      };
    }

    return entryMeta;
  }

  private getStudyDescription(fileEntry) {
    return fileEntry.DICOMMetaData ? fileEntry.DICOMMetaData.StudyDescription : fileEntry.studyDescription;
  }

  private getGlobal(params) {
    return (
      (params.index * 100) /
      params.uploadBlock.blockFiles.filter(file => typeof (file.toUpload === 'boolean') && file.toUpload === false)
        .length
    );
  }

  private gettingBlockFiles(DICOMMetaData: string | any[], entry: any, defaultDate: string, stackID: string) {
    const blockFiles = [];
    for (let index = 0; index < DICOMMetaData.length; index++) {
      const { StudyDate, StudyDescription, StudyInstanceUID, AccessionNumber, StudyID, SeriesNumber } =
        DICOMMetaData[index];
      const studyDescription = StudyDescription ? StudyDescription.trim() : '-';
      const studyDate = StudyDate ? this.utils_$.dateStringToFormattedString(StudyDate) : defaultDate;
      const entryUID = this.utils_$.generateUniqueId(10, 'entry');
      const fdate = this.utils_$.dateToString(StudyDate);
      const status = 'new';
      const progress = 0;
      const target = { folderName: '', folderId: '' };

      const entryMeta = {
        fdate,
        desc: studyDescription,
        entryUID,
        progress,
        status,
        stackID,
        target,
      };

      const singleEntry = {
        ...entry,
        desc: studyDescription,
        studyID: StudyID,
        studyInstanceUID: StudyInstanceUID,
        seriesNumber: SeriesNumber,
        accessionNumber: AccessionNumber,
        entryUID: entryUID,
        studyDate: studyDate,
        fdate: studyDate,
      };

      blockFiles.push({ entry: singleEntry, entryMeta, lastModified: entry.lastModified });
    }
    return blockFiles;
  }

  private getDefaultDate(newDate) {
    return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
  }
}

function checkingDataErrors(filesData, studiesData) {
  if (!filesData || filesData.length === 0) {
    console.error('Something went wrong with the DICOMDIR file, filesData is empty');
    return;
  }

  if (!studiesData || studiesData.length === 0) {
    console.error('Something went wrong with the DICOMDIR file, filesData is empty');
    return;
  }
}

function calculateTotalSizeOfTheUpload(files: File[]) {
  let sum = 0;
  files.reduce((acc, file) => {
    sum += file.size;
    return acc;
  }, 0);
  return sum / 1024 / 1024;
}
