import 'firebase/firestore';
import 'firebase/storage';

import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { BehaviorSubject, from, of, zip } from 'rxjs';
import { distinct, groupBy, map, mergeMap, toArray } from 'rxjs/operators';
import { UserRoles } from 'src/app/dictionaries/UserRoles';

import { environment } from '../../environments/environment';
import { LogLevelsDictionary } from './../dictionaries/LogLevels';
import { LogService } from './../log.service';
import { Client } from './../models/Client';
import { Folder } from './../models/Folder';
import { AuthService } from './auth.service';
import { FilesService } from './files.service';
import { UtilsService } from './utils.service';
import { MatDialog } from '@angular/material/dialog';
import { ClioAccessTokenDialogComponent } from '../components/clio/clio-access-token-dialog/clio-access-token-dialog.component';
import { HttpParams, HttpRequest } from '@angular/common/http';
import { UIMessagingService } from './uimessaging.service';
import { AlgoliaService } from './algolia.service';

const dbPatients = firebase.firestore().collection('patients');
const dbFiles = firebase.firestore().collection('files');
const dbPlans = firebase.firestore().collection('plans');
const dbUsers = firebase.firestore().collection('users');
const dbDefaultFolders = firebase.firestore().collection('defaultFolders');

interface MenuItem {
  name: string;
  uid: string;
  clients: any[];
}

interface localEntry1 {
  desc: any;
  name: any;
  fdate: any;
}

interface localEntryMeta1 {
  fdate: any;
  targetFolderId: any;
}

interface localUploadTypes1 {
  [x: string]: any;
}

interface CreateFileObjectParams {
  caseName: any;
  creator: any;
  path: any;
  studyuid: any;
  entryMeta: any;
  uploadTypes: any;
  type: any;
  up_datastorename: any;
  entry: any;
  lastModified: any;
  parentFolder: any;
  parentFolderId: any;
  scanAnalysisId: any;
  uploadedDate: any;
}

@Injectable({
  providedIn: 'root',
})
export class FirebaseUtilitiesService {
  db = firebase.firestore();
  algoliaObjects: any[] = [];
  UserRoles: { owner: string; admin: string; associate: string; consultant: string; superuser: string };
  public infectedFiles = new BehaviorSubject<File[]>([]);

  constructor(
    public auth_$: AuthService,
    private files_$: FilesService,
    public logService_$: LogService,
    private utils_$: UtilsService,
    private dialog: MatDialog,
    private uiMessaging_$: UIMessagingService,
    private algolia_$: AlgoliaService,
  ) {
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
  }

  async getDefaultFolders(casename: string) {
    return (
      await dbFiles
        .where('belongsTo', '==', casename)
        .where('type', '==', 'folder')
        .where('predefined', '==', true)
        .get()
    ).docs.map(c => c.data());
  }

  async getDefaultFoldersDefinition(userRole: string) {
    return (await dbDefaultFolders.where('restricted', 'array-contains', userRole).get()).docs.map(c => c.data());
  }

  /**
   * List users under an owner UID grouped by type of user.
   */
  async getAllUsersByOwnerUIDGrouped(uid: any) {
    const data = (await dbUsers.where('ownerID', '==', uid).where('disabled', '==', false).get()).docs
      .map(i => ({ email: i.data().email, role: i.data().role, name: i.data().name }))
      .sort((a, b) => a.role.localeCompare(b.role));

    return from(data).pipe(
      groupBy(user => user.role),
      mergeMap(group => zip(of(group.key), group.pipe(toArray()))),
      map(arr => ({ role: arr[0], emails: arr[1].map(e => ({ name: e.name, email: e.email })) })),
    );
  }

  updateUserSettings(userSettings: string, userdocid: string) {
    return dbUsers.doc(userdocid).update({ settings: userSettings });
  }

  async getAllFolders(casename: string) {
    const folders = (
      await firebase
        .firestore()
        .collection('files')
        .where('type', '==', 'folder')
        .where('belongsTo', '==', casename)
        .get()
    ).docs.map(i => i.data());
    return folders;
  }

  async getCaseOwnerId(casename: string): Promise<string> {
    return (await dbPatients.where('caseName', '==', casename).limit(1).get()).docs[0].data().ownerID;
  }

  /**
   * Creates a special row in files collection. This record is only to describe a folder.
   * Have to specify child elements ids and parent folder if any, root (top level) is by default.
   */

  async getPlan(plandocid) {
    return (await dbPlans.doc(plandocid).get()).data();
  }

  async getUserDocId(uid: string) {
    const qs = await this.db.collection('users').where('uid', '==', uid).limit(1).get();
    return qs.docs[0].id;
  }

  async getUserInfoByEmail(useremail: any) {
    return (await dbUsers.where('email', '==', useremail).limit(1).get()).docs[0].data();
  }

  async createFolder(folderObject: Folder) {
    const { type, children, parentFolder, name, belongsTo, folderId, predefined, color } = folderObject;
    return dbFiles.add({
      type,
      children,
      parentFolder,
      name,
      color,
      predefined,
      belongsTo,
      forDelete: false,
      folderId,
    });
  }

  async checkFolderName(foldername: string) {
    return (
      await firebase
        .firestore()
        .collection('files')
        .where('type', '==', 'folder')
        .where('name', '==', foldername)
        .where('forDelete', '==', false)
        .limit(1)
        .get()
    ).docs[0];
  }

  async clearAllSharedFilesByCaseName(caseName: string) {
    (await dbUsers.get()).docs.forEach(item => {
      const filesSharedWith = item.data().filesSharedWith;
      if (filesSharedWith) {
        const fileCaseNameMatch = filesSharedWith.find(sharedFile => sharedFile.patientCaseName === caseName);
        if (fileCaseNameMatch) {
          const newFilesSharedWith = filesSharedWith.filter(sharedFile => sharedFile.patientCaseName !== caseName);
          dbUsers.doc(item.id).update({ filesSharedWith: newFilesSharedWith });
        }
      }
    });
  }

  async createImportFolder(folderName: string, folderColor: string, caseName: string, folderId: string) {
    const folderObject = {
      children: '',
      name: folderName,
      color: folderColor,
      parentFolder: '',
      type: 'folder',
      belongsTo: caseName,
      predefined: true,
      forDelete: false,
      folderId: folderId,
    };
    await this.createFolder(folderObject);
    return folderId;
  }

  // FIXME: #198
  async deleteFilesByIds(fileIds) {
    const promisesStack = [];
    (await dbFiles.where('fileId', 'in', fileIds).get()).docs.forEach(doc => {
      promisesStack.push(dbFiles.doc(doc.id).update({ forDelete: true }));
    });
    return Promise.all(promisesStack);
  }

  async deleteFolder(folderId) {
    // 1. Check its contents.
    // 2. Clear all sharing properties of child elements.
    // 3. Remove folder contents.
    // 4. Remove folder.
  }

  /**
   * Handle physical and logic duplication of selected files into the target folder.
   * @param selected files to be duplicated
   * @param folderId of the target folder.
   */
  async duplicateFilesTo(selected: any[], { folderId, folderName }) {
    const promisesStack = [];
    let newFilesArr = selected.map(file => ({ ...file, newName: this.getNewFileName(file.fileName) }));

    newFilesArr.forEach(file => {
      const splitted = file.filePath.split('/');
      splitted.pop();
      promisesStack.push(
        firebase
          .functions()
          .httpsCallable('files-duplicateFile')({
            fileId: file.fileId,
            bucketSource: environment.config.storageBucket,
            bucketTo: environment.config.storageBucket,
            sourceFile: file.filePath,
            newFileName: `${splitted.join('/')}/${file.newName}`,
          })
          .catch(err => {
            console.log('err :', err);
            return null;
          }),
      );
    });

    const duplicatedStorageFiles = (await Promise.all(promisesStack)).filter(file => file.data !== null);

    // Store all duplicated files in firestore.
    const duplicateInFirestorePromises = duplicatedStorageFiles.map(({ data }) =>
      this.duplicateFirestoreFile(data.fileId, data.filePath, { folderId, folderName }),
    );

    return Promise.all(duplicateInFirestorePromises).then(result => {
      this.algolia_$.storeToAlgolia();
      return result;
    });
  }

  /**
   * Create a new firestore record based on an existing one but with new filePath, parentFolder and fileId.
   */
  async duplicateFirestoreFile(originalFileId: string, filePath: string, { folderId, folderName }) {
    const originalFile = (await dbFiles.where('fileId', '==', originalFileId).get()).docs[0].data();
    const fileId = this.getRandomString(17);
    const objectID = fileId;
    const type = originalFile.type || originalFile.ftype.toLowerCase();
    const parentFolderName = folderName;
    const parentFolder = folderId;
    const fileObject = { ...originalFile, filePath, parentFolder, parentFolderName, fileId, objectID, type };
    const promisesList = [];
    promisesList.push(dbFiles.add(fileObject));
    this.algolia_$.algoliaObjects.push(fileObject);

    // NOTE: Add new file reference to the original file shared UserRoles.
    if (originalFile.sharedUsers?.length > 0) {
      promisesList.push(this.updateSharedUsers(originalFile));
    }
    return Promise.all(promisesList);
  }

  async storeDICOMFileInFirebaseStorage(file, filepath: string, options) {
    const promise = new Promise((resolve, reject) => {
      const uploadTask = firebase.storage().ref().child(filepath).put(file);

      uploadTask.on(
        'state_changed',
        ({ bytesTransferred, totalBytes }) => {
          const percentage = this.roundDecimals((bytesTransferred / totalBytes) * 100, 2);
          if (!options.studyDescription) console.log('File no study description: ', file);
          if (percentage === 100) {
            this.handleMessaging(true, { percentage, file });
            console.log('==========================================');
            console.log('File uploaded: ' + file.name, filepath);
            console.log('==========================================');
          }
        },
        error => {
          console.log('++++++++++++++++++++++++++++++++++++++++++');
          console.log('Upload task on error: ', error);
          console.log('++++++++++++++++++++++++++++++++++++++++++');
          this.logService_$.log(LogLevelsDictionary.error, error);
          reject(error);
        },
        () => {
          this.handleMessaging(false, { file });
          setTimeout(() => resolve(true), 100);
        },
      );
    }).catch(error => {
      console.log('++++++++++++++++++++++++++++++++++++++++++');
      console.log('Error in promise: ', error);
      console.log('++++++++++++++++++++++++++++++++++++++++++');
      return error;
    });
    return promise;
  }

  private handleMessaging = (type, options?) => {
    const { percentage, file } = options;
    if (type === false) {
      const message = `File: ${file['name']} stored successfully`;
      this.logService_$.log(LogLevelsDictionary.info, message);
      this.uiMessaging_$.toastMessage(message, 'INFORMATION');
      return;
    }

    console.log("file['name']", file['name']);
    const message = options.studyDescription
      ? `STUDY: ${options.studyDescription} | ${file['name']}: ${percentage}%.`
      : `${file['name']}: ${percentage}%.`;

    this.uiMessaging_$.toastMessage(message, 'PROGRESS');
    this.logService_$.log(LogLevelsDictionary.info, message);
  };

  private getPercentageFromSnapshot(snapshot) {
    return this.roundDecimals((snapshot.bytesTransferred / snapshot.totalBytes) * 100, 2);
  }

  private handleStateChanged(snapshot, fileName) {
    const { state } = snapshot;
    if (state === 'running') {
      this.uiMessaging_$.toastMessage(`${fileName}: ${this.getPercentageFromSnapshot(snapshot)}%.`, 'PROGRESS');
    }
  }

  private handleErrorOnFileStorageAdd({ code }) {
    switch (code) {
      case 'storage/unauthorized':
        console.log(`User doesn't have permission to access the object`);
        break;
      case 'storage/canceled':
        console.log(`User canceled the upload`);
        break;
      case 'storage/unknown':
        console.log(`Unknown error occurred, inspect error.serverResponse`);
        break;
    }
  }

  private handleUploadCompleted(uploadTask) {
    uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => console.log('File available at', downloadURL));
  }

  /**
   * Adds the file to the firestore collection.
   */
  fileStorageAdd(file: any, path: string) {
    // Add file to storage.
    const uploadTask = firebase
      .storage()
      .ref()
      .child(path)
      .put(<File>file.entry);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        snapshot => this.handleStateChanged(snapshot, file.entry.name),
        error => {
          this.handleErrorOnFileStorageAdd(error);
          reject(error);
        },
        async () => {
          this.handleUploadCompleted(uploadTask);
          resolve(true);
        },
      );
    });
  }

  async removeFileRegistry(fullpath: any, fileentry: any) {
    const docs = await dbFiles.where('filePath', '==', fullpath).get();
    const promisesStack = [];
    docs.forEach(doc => promisesStack.push(doc.ref.delete()));
    return Promise.all(promisesStack);
  }

  async fileRegistration({ params, file }) {
    const { scanAnalysisId, filepath, uploadBlock, targetFolder, studyuid, uploadTypes, type, storename, options } =
      params;
    const { folderName, folderId } = targetFolder;
    const { casename, creator } = uploadBlock;
    const { entry, entryMeta, uploadedDate, parentFolderName } = file;
    const lastModified = params.lastModified || new Date(file.lastModified).toString() || '';
    const parentFolder = parentFolderName || folderName;
    const parentFolderId = file.parentFolderId || folderId;

    // File Registration.

    const fileObject = this.createFileObject({
      caseName: casename,
      creator: creator,
      path: filepath,
      studyuid: studyuid,
      entryMeta: entryMeta,
      uploadTypes: uploadTypes,
      type: type,
      up_datastorename: storename,
      entry: entry,
      lastModified: lastModified,
      parentFolder: parentFolder,
      parentFolderId: parentFolderId,
      scanAnalysisId: scanAnalysisId,
      uploadedDate: uploadedDate,
    });

    if (options && options.section === 'upload_dicom_disk') {
      console.log('Call createClioViewerLinkFile');
      // this.createClioViewerLinkFile(desc, studyuid ? this.viewerurlGenerator(caseName, up_datastorename, studyuid) : '')
    }

    // Add file registry to firestore.
    await this.addFileToFirestore(fileObject);
    return fileObject;
  }

  async createClioViewerLinkFile(desc: any, viewerurl: any, clioMatterId: any) {
    return firebase.functions().httpsCallable('createClioViewerLinkFile')({ desc, viewerurl, clioMatterId });
  }

  async getUsersCount(uid: string) {
    const roles = ['Client Admin', 'Associate'];
    return (
      await firebase
        .firestore()
        .collection('users')
        .where('disabled', '==', false)
        .where('ownerID', '==', uid)
        .where('role', 'in', roles)
        .get()
    ).docs.length;
  }

  async getUsersWithActionsLogged(owneruid: string) {
    return (await firebase.firestore().collection('logs').where('ownerID', '==', owneruid).get()).docs.map(d =>
      d.data(),
    );
  }

  /**
   * This builds the menu items for the filterByUser.
   */
  async getUsersByOwneruid(owneruid: string): Promise<MenuItem[]> {
    // UTILITIES
    const toCapitalCase = sstr => sstr.replace(/([a-z])/i, (str, firstLetter) => firstLetter.toUpperCase());
    /**
     * Getting clients names for ui improvement.
     */
    const getClientsData = async (_owneruid: string) =>
      (await dbPatients.where('ownerID', '==', _owneruid).get()).docs
        .map(r => r.data())
        .map(e => ({ name: `${e.FirstName} ${e.LastName}`, ownerID: e.ownerID, casename: e.caseName }));
    const allTheClientsData = await getClientsData(owneruid);
    const getClientName = (clientcasename, allClientsData) =>
      toCapitalCase(allClientsData.find(i => i.casename === clientcasename).name);

    // Filter all the clients who has a log registry from a given user uid/ownerid
    const getClients = (uid: string, source: any[]) => {
      const red = source.filter(i => i.uid === uid);
      const result = [];
      from(red)
        .pipe(
          distinct(g => g.client),
          map(c => ({ uid: c.uid, client: c.client, clientname: getClientName(c.client, allTheClientsData) })),
        )
        .subscribe(g => result.push(g));
      return result;
    };

    // Get all the users who has log registries from a given ownerid.
    const getUsersWithActionsLogged = (await this.getUsersWithActionsLogged(owneruid))
      .filter(e => e.client !== null)
      .map(item => ({ uid: item.uid, client: item.client }));

    // LETS BUILD THE LIST OF CONSULTANTS.
    const consultants: MenuItem[] = [];
    from(getUsersWithActionsLogged)
      .pipe(distinct(n => n.uid))
      .subscribe(async n => {
        const { name, uid } = await this.getUserByUID(n.uid);
        const clients = getClients(uid, getUsersWithActionsLogged);
        consultants.push({ name, uid, clients });
      });

    return consultants;
  }

  async getUserByUID(uid: string) {
    return (await dbUsers.where('uid', '==', uid).limit(1).get()).docs[0].data();
  }

  async getPlanUsersLimit(plan: { id: string }) {
    if (!plan) {
      console.error('There was an issue with getPlanUsersLimit, plan is undefined');
      return;
    }
    return (await dbPlans.doc(plan.id).get()).data()['users'];
  }

  async getFileNameByFileId(fileId: string) {
    const obj = (await dbFiles.where('fileId', '==', fileId).limit(1).get()).docs[0];
    return obj ? obj.data()['fileName'] : false;
  }

  async getTwoFactorData(uid: string) {
    return (await dbUsers.where('uid', '==', uid).limit(1).get()).docs[0].data();
  }

  async getFolder(foldertype: string, caseName: string) {
    switch (foldertype) {
      case 'clio':
        const folders = (
          await firebase
            .firestore()
            .collection('files')
            .where('type', '==', 'folder')
            .where('name', '==', 'Clio')
            .where('belongsTo', '==', caseName)
            .get()
        ).docs;

        if (folders.length) {
          return folders[0].data()['folderId'];
        } else {
          const folderId = this.utils_$.getRandomString_(20);
          await this.createImportFolder('Clio', '#1e81b0', caseName, folderId);
          return folderId;
        }

      default:
        return '';
    }
  }

  async getTwoFactorDataByEmail(email: string) {
    let userData;
    return dbUsers
      .where('email', '==', email)
      .limit(1)
      .get()
      .then(sp => {
        userData = sp.docs[0].data();
        return {
          twoFactorSet: userData.twoFactorAuthenticationSet || null,
          twoFactorEnabled: userData.twoFactorEnabled || null,
          twoFactorMethod: userData.twoFactorMethod || null,
        };
      })
      .catch(err => err);
  }

  /**
   * Examine the given folder and count the number of files inside.
   * @param folderId of the folder to examine.
   * @returns a promise with a count string as end.
   */
  async getNumberOfFilesByFolderId(
    folderId: string = '',
    roleConsultant?: string,
    useremail?: string,
    filesShared?: any[],
  ): Promise<string> {
    const { docs } = await firebase
      .firestore()
      .collection('files')
      .where('parentFolder', '==', folderId)
      .where('forDelete', '==', false)
      .get();

    const length =
      roleConsultant && useremail
        ? docs.filter(doc => (areSharedUsers(doc) ? mailIncluded(doc) : false)).length
        : docs.length;

    return length > 0 ? `(${length})` : ``;

    function mailIncluded(doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>): unknown {
      return doc
        .data()
        ['sharedUsers'].map(({ email }) => email)
        .includes(useremail);
    }

    function areSharedUsers(doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
      return doc.data()['sharedUsers'] && doc.data()['sharedUsers'].length;
    }
  }

  async getPatientNameByCaseName(caseName: string) {
    return (await dbPatients.doc(caseName).get()).data();
  }

  getUserSettings(uid: string) {
    return dbUsers
      .where('uid', '==', uid)
      .limit(1)
      .get()
      .then(res => res.docs[0].data().settings);
  }

  async getCustomGuests(userdocid: string): Promise<any[]> {
    const userSettings = JSON.parse((await dbUsers.doc(userdocid).get()).data()['settings']);
    return userSettings.customGuests || [];
  }

  async getConsultantsList(ownerID: string) {
    return (
      await dbUsers
        .where('role', '==', 'Consultant')
        .where('ownerID', '==', ownerID)
        .where('disabled', '==', false)
        .get()
    ).docs.map(item => item.data());
  }

  /**
   * Creating a new filename in order to make a copy.
   */
  getNewFileName(fileName: string) {
    const separator = '.';
    const connector = '_';
    let name = fileName;
    let extension = '';
    const splitted = fileName.split(separator);
    if (splitted.length > 1) {
      extension = separator + splitted[splitted.length - 1];
      splitted.pop();
      name = splitted.join(separator);
    }
    return name + connector + Date.now() + Math.random().toString() + extension;
  }

  /**
   * Utility function to generate a unique string.
   * @param stringLength The length of the resulting string.
   * @returns A random string with the given length.
   */
  getRandomString(stringLength: number = 10): string {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    let randomstring = '';
    for (let i = 0; i < stringLength; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }

  /**
   * Get an array of key paired (name, id) objects containing the folders from a given casename.
   * @param casename limit the result for the give case name only.
   * @returns a list of folder records.
   */
  async getFolders(casename: string): Promise<any> {
    return firebase
      .firestore()
      .collection('files')
      .where('type', '==', 'folder')
      .where('belongsTo', '==', casename)
      .where('forDelete', '==', false)
      .get()
      .then(({ docs }) => docs.map(doc => doc.data()));
  }

  async getPredefinedFolders(casename: string) {
    const predefinedFolders = [];
    (
      await dbFiles
        .where('type', '==', 'folder')
        .where('belongsTo', '==', casename)
        .where('forDelete', '==', false)
        .where('predefined', '==', true)
        .get()
    ).docs.forEach(doc => {
      predefinedFolders.push(doc.data());
    });
    return predefinedFolders;
  }

  async getDefaultFoldersFlag(casename: string): Promise<boolean> {
    return (await dbPatients.where('caseName', '==', casename).limit(1).get()).docs[0].data().defaultFolders;
  }

  async getCustomFolders(casename, filter?: string): Promise<any[]> {
    const customFolders = [];
    await dbFiles
      .where('type', '==', 'folder')
      .where('forDelete', '==', false)
      .where('belongsTo', '==', casename)
      .where('predefined', '==', false)
      .get()
      .then(fileSnapshot =>
        fileSnapshot.forEach(item => {
          customFolders.push(item.data());
        }),
      );

    return filter && filter !== ''
      ? customFolders
          .filter(folder => folder.name.toLowerCase().indexOf(filter.toLowerCase()) === 0)
          .sort(this.dynamicSort('name'))
      : customFolders.sort(this.dynamicSort('name'));
  }

  /**
   * Function to sort alphabetically an array of objects by some specific key.
   * @param property Key of the object to sort.
   */
  dynamicSort(property: string) {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return (a, b) =>
      sortOrder === -1 ? b[property].localeCompare(a[property]) : a[property].localeCompare(b[property]);
  }

  async getFilesByClientId(clientId) {
    const docs = (
      await firebase
        .firestore()
        .collection('files')
        .where('forDelete', '==', false)
        .where('belongsTo', '==', clientId)
        .get()
    ).docs;
    const files = [];
    docs.forEach(doc => files.push(doc.data()));
    return files;
  }

  async getActiveConsultantsByClientId(role: string, clientId: string, clientFiles?: any[]) {
    clientFiles = clientFiles || (await this.getFilesByClientId(clientId));
    const allowedRoles = role ? [role] : ['Consultant'];
    const entries = [];
    const localAllUsers = [];

    for (let index = 0; index < allowedRoles.length; index++) {
      const userType = allowedRoles[index];
      const usersList = (await dbUsers.where('role', '==', userType).get()).docs;

      for (let idx = 0; idx < usersList.length; idx++) {
        const userData = usersList[idx].data();
        const filesArr = Object.values(clientFiles);

        if (typeof userData.filesSharedWith === 'undefined') {
          continue;
        }
        if (!userData.filesSharedWith.length) {
          continue;
        }
        if (!filesArr.length) {
          continue;
        }

        userData.filesSharedWith.forEach(userSharedFile => {
          // NOTE: Validate if item exists.
          let itemExists = false;

          for (let ix = 0; ix < filesArr.length; ix++) {
            const fileItem = filesArr[ix];
            if (fileItem['fileId'] === userSharedFile.fileId) {
              itemExists = true;
              break;
            }
          }

          if (
            itemExists &&
            userSharedFile.patientCaseName === clientId &&
            entries.indexOf(userData.email) === -1 &&
            !userData.disabled
          ) {
            entries.push(userData.email);
            localAllUsers.push(userData);
          }
        });
      }
    }

    return localAllUsers;
  }

  async getAvailableConsultants(role = 'Consultant', clientId, againstList) {
    // NOTE: Get the owner Id of the client.
    const ownerID = (await dbPatients.where('caseName', '==', clientId).limit(1).get()).docs[0].data().ownerID;

    // NOTE: List all consultants with this ownerID.
    const consultants = (await dbUsers.where('role', '==', 'Consultant').where('ownerID', '==', ownerID).get()).docs;

    const availableLocalAllUsers = [];
    consultants.forEach(consultant => {
      const filteredAgainst = againstList.filter(item => item.email === consultant.data().email);
      if (!consultant.data().disabled && !filteredAgainst.length) {
        availableLocalAllUsers.push(consultant.data());
      }
    });
    return availableLocalAllUsers;
  }

  getRole(uid: string) {
    if (!uid) {
      console.error('getRole: uid not provided');
      return;
    }
    return new Promise(resolve => {
      dbUsers
        .where('uid', '==', uid)
        .get()
        .then(querySnapshot => querySnapshot.forEach(doc => resolve(doc.data().role)));
    });
  }

  /**
   * Returns the email adress of the creator of the given casename.
   */
  async getClientCreatorEmailByCaseName(caseName) {
    const message = `This client has been created for an unauthorized user
        please contact your administrator before proceed with any action.`;
    const userRoles = [UserRoles.consultant, UserRoles.associate, UserRoles.admin, UserRoles.owner];
    const uidOfCreator = await dbPatients
      .where('caseName', '==', caseName)
      .limit(1)
      .get()
      .then(item => item.docs[0].data().uidOfCreator);
    const email = await dbUsers
      .where('uid', '==', uidOfCreator)
      .limit(1)
      .get()
      .then(item => {
        const { role, emailstr } = item.docs[0].data();
        if (!userRoles.includes(role)) {
          this.uiMessaging_$.toastMessage(message, 'ALERT');
        }
        return emailstr;
      });
    return email;
  }

  async getPatientSharedFilesByConsultant(patientcasename: string, useremail: any) {
    const files = dbFiles;
    const outputFiles = [];
    const filteredFiles = await files.where('belongsTo', '==', patientcasename).get();
    for (const file of filteredFiles.docs) {
      const _file = file.data();
      if (_file.sharedUsers) {
        const sharedUsers = _file.sharedUsers.map(sharedUser => sharedUser.email.toLowerCase());
        _file.isShared = sharedUsers.indexOf(useremail.toLowerCase()) > -1 ? 1 : 0;
        outputFiles.push(_file);
      }
    }
    return outputFiles;
  }

  /**
   * Just update the parent folder of selected files.
   * @param selected The selected file to be moved.
   * @param folderId The target folder to move selected into.
   * @returns A promisesStack of the updates.
   */
  async moveFilesTo(selected: any[], folderId: string) {
    const files = selected.map(file => file.fileId);
    const promisesStack = [];
    await dbFiles
      .where('fileId', 'in', files) // FIXME: This will fail with more than 10 files.
      .get()
      .then(snapshot =>
        snapshot.docs.forEach(item => promisesStack.push(dbFiles.doc(item.id).update({ parentFolder: folderId }))),
      );

    return Promise.all(promisesStack);
  }

  roundDecimals(num, decimalPlaces) {
    const p = Math.pow(10, decimalPlaces);
    return Math.round(num * p) / p;
  }

  // FIXME: #198 Improve files removal.
  /**
   * (WIP)
   */
  removeFilesByIdsFromUser(fileId, sharedUsersEmail) {
    console.log('fileId: ', fileId);
    sharedUsersEmail.forEach(email => {
      dbUsers
        .where('email', '==', email)
        .get()
        .then(docs => {
          docs.forEach(doc => {
            const filesShared = doc.data().filesSharedWith.filter(file => file.fileId !== fileId);
            dbUsers.doc(doc.id).update({ filesSharedWith: filesShared });
          });
        });
    });

    return dbFiles
      .where('fileId', '==', fileId)
      .get()
      .then(snapshot =>
        snapshot.docs.forEach(doc =>
          dbFiles.doc(doc.id).update({
            sharedUsers: doc.data().sharedUsers.map(user => sharedUsersEmail.includes(user.email.toLowerCase())),
          }),
        ),
      );
  }

  async setNoFactorAuthSetup(email: string, method?: string, enabled?: boolean, twoFactorAuthSet?: boolean) {
    const user = await dbUsers.where('email', '==', email).limit(1).get();
    if (user.empty) {
      throw new Error(`User doesn't exist`);
    }

    dbUsers.doc(user.docs[0].id).update({
      twoFactorAuthenticationSet: twoFactorAuthSet || true,
      twoFactorEnabled: enabled || false,
      twoFactorMethod: method || null,
    });
  }

  async setUserCode(code, type, email) {
    const userId = (await dbUsers.where('email', '==', email).limit(1).get()).docs[0].id;
    return dbUsers.doc(userId).update({
      twofactorCode: JSON.stringify({ code: code, type: type }),
      twoFA_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  async getPatientSharedFilesByConsultantV2(matterId: string, useremail: any, clientId: string) {
    const outputFiles = [];
    const filteredFiles = await dbFiles.where('belongsTo', '==', clientId).get();
    for (const file of filteredFiles.docs) {
      const _file = file.data();
      if (_file.sharedUsers) {
        const sharedUsers = _file.sharedUsers.map(sharedUser => sharedUser.email);
        _file.isShared = sharedUsers.indexOf(useremail) > -1 ? 1 : 0;
        outputFiles.push(_file);
      }
    }
    return outputFiles;
  }

  async shareFile(fileToShare, useremail, patientcasename) {
    const fileId = fileToShare.fileId;
    const userSharingWithData = (await dbUsers.where('email', '==', useremail).get()).docs[0].data();
    const filesGet = await dbFiles.where('fileId', '==', fileId).get();
    const filedoc = filesGet.docs[0];
    const fileid = filedoc.id;
    const file = filedoc.data();
    let newArr = [];

    if (file.sharedUsers && file.sharedUsers.length > 0) {
      if (file.sharedUsers.filter(item => item.email.toLowerCase() === useremail.toLowerCase()).length) {
        this.uiMessaging_$.toastMessage('Something went wrong', 'URGENT');
        return;
      }
      newArr = file.sharedUsers.concat([{ email: useremail.toLowerCase(), name: userSharingWithData.name }]);
    } else {
      newArr = [{ email: useremail.toLowerCase(), name: userSharingWithData.name }];
    }

    await dbFiles
      .doc(fileid)
      .update({ sharedUsers: newArr })
      .catch(err => err);
    this.updateFilesArray(patientcasename, useremail.toLowerCase());
    await this.updateUserSharedWith(fileToShare, useremail.toLowerCase(), patientcasename);
    const message = `Shared files for ${useremail.toLowerCase()} are now updated`;
    console.log('message: ', message);
    this.uiMessaging_$.toastMessage(message, null);
    return fileToShare.fileName;
  }

  async setTwoFactorAuthSetup(email: string, method?: string, enabled?: boolean, twoFactorAuthSet?: boolean) {
    console.log('twoFactorAuthSet: ', twoFactorAuthSet);
    const docId = (await dbUsers.where('email', '==', email).limit(1).get()).docs[0].id;
    try {
      dbUsers.doc(docId).update({
        twoFactorAuthenticationSet: twoFactorAuthSet || true,
        twoFactorEnabled: enabled || false,
        twoFactorMethod: method || null,
      });
    } catch (err) {
      return err;
    } finally {
      return true;
    }
  }

  storeNoteNewAttachments(attachments: any[], patientName: string) {
    const attachmentsPaths = [];
    const promisesStack = [];

    attachments.forEach(attachment => {
      const metadata = {};
      const filePath = `/patient/${patientName}/${attachment.name}`;
      attachmentsPaths.push(filePath);

      const promise = new Promise((resolve, reject) => {
        firebase
          .storage()
          .ref()
          .child(filePath)
          .put(attachment, metadata)
          .on(
            'state_changed',
            sp => console.log('In progress'),
            error => {
              reject(error);
              console.log('error: ', error);
            },
            () => {
              console.log('Finished');
              resolve(1);
            },
          );
      });
      promisesStack.push(promise);
    });

    // NOTE: Hay que retornar una promesa para leer resultados.
    return Promise.all(promisesStack).then(result => {
      return attachmentsPaths;
    });
  }

  updateNotesByFile(fileid: string, notesArray: any[]) {
    return dbFiles
      .where('fileId', '==', fileid)
      .limit(1)
      .get()
      .then(sp => dbFiles.doc(sp.docs[0].id).update({ notes: notesArray }));
  }

  async updateClient(clientObject: Client) {
    const { CaseName, FirstName, LastName, DateOfBirth } = clientObject;
    const docId = (await dbPatients.where('caseName', '==', CaseName).limit(1).get()).docs[0].id;
    await dbPatients.doc(docId).update({
      FirstName: FirstName,
      LastName: LastName,
      DateOfBirth: DateOfBirth,
    });
  }

  async updateSharedUsers(originalFile) {
    const { sharedUsers, belongsTo, fileId } = originalFile;
    const emails = sharedUsers.map(user => user.email);
    const newFile = [{ fileId, patientCaseName: belongsTo }];
    const sharersQS = await dbUsers.where('email', 'in', emails).get();
    const promisesList = [];

    sharersQS.forEach(userDoc =>
      promisesList.push(
        dbUsers.doc(userDoc.id).update({ filesSharedWith: firebase.firestore.FieldValue.arrayUnion(...newFile) }),
      ),
    );
    return Promise.all(promisesList);
  }

  /**
   * Updates the Firebase Authentication User Email
   */
  async updateUserAuthEmail(email: string) {
    const user = firebase.auth().currentUser;
    if (user !== null) {
      try {
        user.updateEmail(email);
      } catch (error) {
        return error;
      }
    }
  }

  /**
   * Updates the Firebase Authentication User PhoneNumber
   */
  async updateUserAuthPhoneNumber(phoneNumber: any) {
    const user = firebase.auth().currentUser;
    if (user !== null) {
      try {
        user.updatePhoneNumber(phoneNumber);
      } catch (error) {
        return error;
      }
    }
  }

  /**
   * Updates the Firestore User Email
   */
  async updateUserEmail(email: string) {
    const userDocId = (await dbUsers.where('uid', '==', this.auth_$.uid).limit(1).get()).docs[0].id;
    return dbUsers.doc(userDocId).update({ email });
  }

  async updateFilesArray(casename: string, useremail: string) {
    const filesList = await this.getPatientSharedFilesByConsultant(casename, useremail);
    this.files_$.filesArray.next(filesList);
    this.uiMessaging_$.toastMessage('Share successful', null);
  }

  /**
   * Updates the Firestore User Phone number
   */
  async updateUserPhoneNumber(phoneNumber: string) {
    const userDocId = (await dbUsers.where('uid', '==', this.auth_$.uid).limit(1).get()).docs[0].id;
    return dbUsers.doc(userDocId).update({ phoneNumber });
  }

  /**
   * Updates both Firestore data email and Authentication data email with the given @param email:string.
   */
  async updateUserAndAuthEmail(email: string): Promise<void> {
    await this.updateUserAuthEmail(email);
    await this.updateUserEmail(email);
  }

  async getUserNameByEmail(email: string) {
    return (await dbUsers.where('email', '==', email).limit(1).get()).docs[0].data().name;
  }

  async getFileDocIdByFileId(fileId: string) {
    return (await dbFiles.where('fileId', '==', fileId).get()).docs[0].id;
  }

  async unShareFile(fileToUnshare, email, patientcasename) {
    const name = await this.getUserNameByEmail(email);
    const fileID = await this.getFileDocIdByFileId(fileToUnshare.fileId);
    const fileObject = { sharedUsers: firebase.firestore.FieldValue.arrayRemove({ email, name }) };
    await dbFiles.doc(fileID).update(fileObject);
    console.log('email :', email);
    await this.updateDeleteUserSharedWith(fileToUnshare, email);

    const message = `Unshare successful of ${fileToUnshare.fileName} and ${email}`;
    this.uiMessaging_$.toastMessage(message, null);
    const sharedFilesByPatientAndConsultant = await this.getPatientSharedFilesByConsultant(
      patientcasename,
      email.toLowerCase(),
    );

    // NOTE: Useful for UNsharing action from CONSULTANTS tab.
    this.files_$.filesArray.next(sharedFilesByPatientAndConsultant);
    return sharedFilesByPatientAndConsultant;
  }

  validateClioAccessToken(token?: string) {
    const clioAccessToken = this.auth_$.userData.getValue()['clioAccessToken'];
    return clioAccessToken ? JSON.parse(clioAccessToken)['access_token'] : false;
  }

  redirectToClioAuthorize() {
    const params = new HttpParams()
      .set('response_type', 'code')
      .set('client_id', environment.config.clio.client_id)
      .set('client_secret', environment.config.clio.client_secret)
      .set('redirect_uri', environment.config.clio.redirectsGroup.clientProfile);
    const request = new HttpRequest('GET', environment.config.clio.authorizeURL, null, { params });
    window.location.href = request.urlWithParams;
  }

  async updateDefaultCalendar(predefinedCalendar: string, uid: string) {
    switch (predefinedCalendar) {
      case environment.constants.mailClient.CLIO:
        const validationResult = this.validateClioAccessToken(uid);
        if (!validationResult) {
          this.handleGetClioAuthorization(
            {
              origin: 'updateDefaultCalendar',
              message: 'Your default calendar now is CLIO, but you need to Authorize its usage first.',
            },
            this.auth_$.userData['clioAccessToken'],
          );
        } else {
          console.log('The user has already authorized CLIO usage.');
        }
        break;
      default:
        break;
    }

    dbUsers
      .doc(this.auth_$.userData.getValue()['id'])
      .update({ settings: JSON.stringify({ calendar: predefinedCalendar }) })
      .then(updated => console.log('Default calendar updated'));

    return { calendar: predefinedCalendar };
  }

  async setDefaultCalendar(predefinedCalendar: string) {
    return dbUsers
      .doc(this.auth_$.userData.getValue()['id'])
      .update({ settings: JSON.stringify({ calendar: predefinedCalendar }) });
  }

  async handleGetClioAuthorization(data, clioAccessToken?: string) {
    this.dialog
      .open(ClioAccessTokenDialogComponent, { width: '500px', data })
      .afterClosed()
      .subscribe(async (origin: string) => {
        if (origin === 'updateDefaultCalendar') {
          firebase
            .firestore()
            .collection('users')
            .doc(this.auth_$.userData.value['id'])
            .update({ lastSession: { origin }, client: 'clio' })
            .then(() => {
              if (!clioAccessToken || !JSON.parse(clioAccessToken)['access_token']) this.redirectToClioAuthorize();
              else console.log('The user has already authorized CLIO usage.');
            })
            .catch(err => console.log('err :', err));
        }
      });
  }

  /**
   * Update the fileSharedWith field with the changes (file: {patientCaseName,fileId}).
   * @param file which has been shared.
   * @param email of the consultant who will have sharing access to it..
   * @param patientcasename the file is inside.
   */
  async updateUserSharedWith(file: any, email: string, patientcasename: string): Promise<void> {
    const user = (await dbUsers.where('email', '==', email).get()).docs[0];
    const newValue = { patientCaseName: patientcasename, fileId: file.fileId };
    return dbUsers.doc(user.id).update({ filesSharedWith: firebase.firestore.FieldValue.arrayUnion(newValue) });
  }

  async updateDeleteUserSharedWith(file: any, email: string) {
    const user = (await dbUsers.where('email', '==', email).get()).docs[0];
    const filesSharedWith = user.data().filesSharedWith.filter(item => item.fileId !== file.fileId);
    await dbUsers.doc(user.id).update({ filesSharedWith });
    this.uiMessaging_$.toastMessage(`Shared files for ${email} are now updated`, null);
  }

  updateDefaultFolders(flag: boolean, contextParams): Promise<boolean> {
    const { casename } = contextParams;
    return dbPatients
      .where('caseName', '==', casename)
      .limit(1)
      .get()
      .then(snapshot => {
        snapshot.forEach(item => {
          dbPatients.doc(item.id).update({ defaultFolders: flag });
        });
        return flag;
      });
  }

  async validateUserCreation() {
    const uid = this.auth_$.uid;
    const userData = this.auth_$.userData.getValue();
    const usersLimit = await this.getPlanUsersLimit(userData['plan']);
    const usersCount = await this.getUsersCount(uid);

    return usersLimit > usersCount;
  }

  getCurrentPlan() {
    return this.auth_$.userData['plan'];
  }

  /**
   * Just sticks string into a large urlstring.
   */
  viewerurlGenerator(caseName: string, up_datastorename: string, studyuid: string) {
    caseName = caseName.replace(/\s/g, '');
    up_datastorename = up_datastorename.replace(/\s/g, '');
    studyuid = studyuid.replace(/\s/g, '');
    return (
      `/projects/` +
      `${environment.config.gapi.projectname}/locations/` +
      `${environment.config.gapi.location}/datasets/` +
      `${caseName}/dicomStores/` +
      `${up_datastorename}` +
      `${studyuid}`
    );
  }

  /**
   * Validate is the given email is already registered as user in firestore.
   * @returns true if exists and false if not.
   */
  async validateEmailAlreadyExists(email: string): Promise<boolean> {
    return (await dbUsers.where('email', '==', email).limit(1).get()).docs.length === 0;
  }

  async validatePhoneNumberByUserID(uid: string, phoneNumber: string) {
    let emailUserDocs;

    try {
      emailUserDocs = (await dbUsers.where('phoneNumber', '==', phoneNumber).limit(1).get()).docs;
    } catch (error) {
      return error;
    }

    // NOTE: The phoneNumber doesn't exist.
    if (!emailUserDocs.length) {
      await this.updateUserAuthPhoneNumber(phoneNumber);
      await this.updateUserPhoneNumber(phoneNumber);
      return true;
    }

    // NOTE: The email exists.
    const emailUser = emailUserDocs[0].data();

    // NOTE: Check is the email entered is in database and its uid is the same that the current user (uid).
    if (emailUser['uid'] === uid) {
      console.log('You are overwriting with the same email address.');
      return true;
    } else {
      console.error('This email address is already taken, please use a different one.');
      return false;
    }
  }

  async validate2FACode(email: string, code: any, type: string) {
    const range = 30; // 30 minutes
    const userData = (await dbUsers.where('email', '==', email).limit(1).get()).docs[0].data();
    const { twoFA_timestamp, twofactorCode } = userData;

    const codeRegisteredAt = twoFA_timestamp.toDate();
    console.log('codeRegisteredAt :', codeRegisteredAt);
    const limitDate = new Date(codeRegisteredAt.getTime() + range * 60000);
    console.log('limitDate :', limitDate);
    const currentDate = firebase.firestore.Timestamp.now().toDate();
    console.log('currentDate :', currentDate);

    if (currentDate <= limitDate && currentDate > codeRegisteredAt) {
      // pass
      const dbCode = JSON.parse(twofactorCode).code;
      if (parseInt(dbCode, 10) === parseInt(code, 10)) {
        try {
          await this.setTwoFactorAuthSetup(email, type, true, true);
        } catch (err) {
          console.error(err);
          return new Error(err);
        } finally {
          console.log(`2fa set.`);
          return true;
        }
      } else {
        this.uiMessaging_$.toastMessage('The code entered is invalid, please try again', null);
        return new Error(`2fa couldn't be set. Please try again.`);
      }
    } else {
      // rejected
      this.uiMessaging_$.toastMessage('The code is not valid, please try *send me a new code*', null);
      return new Error(`Code invalid.`);
    }
  }

  getConsultantsByClientId(ownerUID: any) {
    throw new Error('Method not implemented.');
  }

  redirectToClioAuthorizeV2(paramsObj) {
    const { redirect_uri, scope, state, authVersionURL, client_id } = paramsObj;
    let params = new HttpParams().set('response_type', 'code');

    if (client_id) {
      params = params.set('client_id', client_id);
    }

    if (redirect_uri) {
      params = params.set('redirect_uri', redirect_uri);
    } else {
      params = params.set('redirect_url', environment.config.clio.redirectsGroup.clientProfile);
    }

    if (scope) {
      params = params.set('scope', scope);
    }

    if (state) {
      params = params.set('state', state);
    }

    const request = new HttpRequest('GET', authVersionURL || environment.config.clio.authorizeURL, null, { params });
    alert(request.urlWithParams);
    window.location.href = request.urlWithParams;
  }

  private async addFileToFirestore(fileObject) {
    await dbFiles.add(fileObject);
  }

  private getFileId(caseName) {
    return `${new Date().getTime().toString()}_${this.getRandomString(6)}_${caseName}`;
  }

  private generateViewerUrl(studyuid, caseName, up_datastorename) {
    return studyuid ? this.viewerurlGenerator(caseName, up_datastorename, studyuid) : '';
  }

  private createFileObject(params: CreateFileObjectParams) {
    const fileId = this.getFileId(params.caseName);
    const typeString = params.uploadTypes[params.type] || '';

    return {
      belongsTo: params.caseName,
      creator: params.creator,
      fdate: params.studyuid === null ? params.entry.fdate : params.entryMeta.fdate,
      fileDesc: params.entry.desc || '',
      fileId: fileId,
      objectID: fileId,
      fileName: params.entry.name,
      filePath: params.path,
      forDelete: false,
      ftype: typeString,
      type: typeString.toLowerCase(),
      lastModified: params.lastModified,
      notes: [],
      parentFolderName: params.parentFolder,
      parentFolder: params.entryMeta.targetFolderId || params.parentFolderId || '',
      scanAnalysisId: params.scanAnalysisId || '',
      storename: `${params.entry.name}${params.studyuid === null ? '' : params.up_datastorename}`,
      uploadedDate: params.uploadedDate || '',
      viewerurl: this.generateViewerUrl(params.studyuid, params.caseName, params.up_datastorename),
    };
  }
}
