<app-window-title
  [title]="title"
  [closable]="true">
</app-window-title>
<mat-dialog-content>
  <p
    [innerHTML]="message"
    class="mat-body"></p>
  <table
    mat-table
    [dataSource]="dataSource"
    *ngIf="dataSource.data.length"
    class="mat-elevation-z8">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef>
        Name
      </th>
      <td
        mat-cell
        *matCellDef="let element">
        <span class="red-text">{{ element.title }}</span>
      </td>
    </ng-container>

    <!-- Note Content Column -->
    <ng-container matColumnDef="description">
      <th
        mat-header-cell
        *matHeaderCellDef>
        Note content
      </th>
      <td
        mat-cell
        *matCellDef="let element">
        <span [ngClass]="'note-content'">{{ element.note }}</span>
      </td>
    </ng-container>

    <!-- Created By Column -->
    <ng-container matColumnDef="createdby">
      <th
        mat-header-cell
        *matHeaderCellDef>
        Created by
      </th>
      <td
        mat-cell
        *matCellDef="let element">
        {{ element.createdBy }}
      </td>
    </ng-container>

    <!-- Attachments Column -->
    <ng-container matColumnDef="attachments">
      <th
        mat-header-cell
        *matHeaderCellDef>
        Attachments
      </th>
      <td
        mat-cell
        *matCellDef="let element">
        <ul *ngIf="element.attachments">
          <li *ngFor="let attachment of jsonParse(element.attachments)">
            <a
              class="link-style"
              target="_blank"
              (click)="browseFile(attachment)"
              >{{ substringFileName(attachment) }}</a
            >
          </li>
        </ul>
      </td>
    </ng-container>

    <!-- Delete Column -->
    <ng-container matColumnDef="delete">
      <th
        mat-header-cell
        *matHeaderCellDef>
        Actions
      </th>
      <td
        mat-cell
        *matCellDef="let element; let i = index">
        <button
          color="warn"
          mat-button
          (click)="editNoteHandler($event, i)">
          Edit
        </button>
        <button
          color="warn"
          mat-button
          (click)="deleteNoteHandler($event, i)">
          Delete
        </button>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <p></p>
</mat-dialog-content>
