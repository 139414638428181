import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import firebase from 'firebase/app';

@Component({
  selector: 'app-edit-note',
  templateUrl: './edit-note.component.html',
  styles: [
    `
      .link-style {
        color: red;
        cursor: pointer;
      }
      .link-style:hover {
        color: orangered;
      }
    `,
  ],
})
export class EditNoteComponent implements OnInit {
  finish: any;
  uploadFileType: string;
  noteTitle: any;
  createdBy: any;
  noteIndex: any;
  basicfile: any;
  note: any;
  formDoc: FormGroup;
  patientName: any;
  noteAttachments: string[];
  fileEntries: File[];

  constructor(
    private dialogRef: MatDialogRef<EditNoteComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.noteTitle = data.title;
    this.createdBy = data.createdBy;
    this.note = data.note;
    this.noteIndex = data.index;
    this.patientName = data.patientName;
    this.noteAttachments = data.attachments;

    this.basicfile = data.basicfile;
    this.formDoc = this.fb.group({
      notetitle: [this.noteTitle, []],
      note: [this.note, []],
      basicfile: [this.basicfile, []],
    });
  }

  ngOnInit(): void {
    this.fileEntries = [];
  }

  jsonParse(jsonString) {
    return JSON.parse(jsonString);
  }

  browseFile(path: string) {
    firebase
      .storage()
      .ref()
      .child(path)
      .getDownloadURL()
      .then(url => {
        window.open(url, '_blank');
      });
  }

  substringFileName(path) {
    return path.split('/')[3];
  }

  handleFileSelection(event: Event): void {
    const entries = Array.from((<HTMLInputElement>event.target).files);
    if (!entries.length) {
      return;
    }

    this.fileEntries = entries;
  }

  filterDuplicatedFiles(entries: any, entriesSource: any) {
    return entries.filter(entry => this.locateEntryByName(entry, entriesSource));
  }

  locateEntryByName(entry, entries: any) {
    return entries.filter(item => entry.name === item.fileName).length === 0;
  }

  openInfoDialog(event, formcontrolname) {
    (<HTMLElement>(
      document.querySelector(`app-input-file[formcontrolname="${formcontrolname}"] input[type="file"`)
    )).click();
  }

  browseFiles(event, formcontrolname) {
    this.openInfoDialog(event, formcontrolname);
  }

  getValue(target: EventTarget): string {
    return (target as HTMLInputElement).value;
  }

  saveNote() {
    const data = {
      type: 'save',
      noteIndex: this.noteIndex,
      note: {
        title: this.noteTitle,
        note: this.note,
        createdBy: this.createdBy,
        attachments: this.noteAttachments,
      },
      newAttachments: this.fileEntries,
    };
    this.dialogRef.close(data);
  }
}
