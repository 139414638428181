<div fxLayout="row">
  <div fxFlex>
    <h2 class="mat-headline">Create Client/Matter</h2>
  </div>

  <div
    fxFlex="none"
    fxFlexOffset="1">
    <button
      mat-flat-button
      mat-dialog-close
      color="warn">
      X
    </button>
  </div>
</div>
<div mat-dialog-content>
  <div
    fxLayoutAlign="center center"
    fxLayout="column">
    <form style="width: 100%">
      <div
        fxLayout="column"
        fxLayoutAlign="center center">
        <div class="mdc-text-field mdc-text-field--filled mdc-text-field--no-label fname">
          <input
            type="text"
            class="mdc-text-field__input"
            [value]="fname"
            #firstNameInput
            (input)="fname = firstNameInput.value"
            required
            placeholder="First Name" />
        </div>
        <div class="mdc-text-field-helper-line">
          <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
            Client must have a first name
          </div>
        </div>

        <!--Email Text Field -->
        <div class="mdc-text-field mdc-text-field--filled mdc-text-field--no-label lname">
          <input
            type="text"
            class="mdc-text-field__input"
            placeholder="Last Name"
            #lastNameInput
            [value]="lname"
            (input)="lname = lastNameInput.value"
            required />
        </div>
        <div class="mdc-text-field-helper-line">
          <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
            Client must have a last name
          </div>
        </div>

        <!-- Password Text Field -->
        <div class="mdc-text-field mdc-text-field--filled mdc-text-field--no-label patientID">
          <input
            type="text"
            class="mdc-text-field__input"
            [value]="patientId"
            #patientIdInput
            (input)="patientId = patientIdInput.value"
            required
            placeholder="Matter Name" />
        </div>
        <div class="mdc-text-field-helper-line">
          <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
            Client must have a Matter Name
          </div>
        </div>

        <div class="mdc-text-field mdc-text-field--filled mdc-text-field--no-label lcase">
          <input
            type="text"
            class="mdc-text-field__input"
            [value]="legalCaseId"
            #legalCaseIdInput
            (input)="legalCaseId = legalCaseIdInput.value"
            required
            placeholder="Legal Case Id" />
        </div>
        <div class="mdc-text-field-helper-line">
          <div class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
            Client must have a case id
          </div>
        </div>

        <mat-form-field appearance="fill">
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="Date of Birth"
            [formControl]="date" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
    <div
      fxLayoutAlign="center center"
      fxLayout="column">
      <h5 style="color: red; font-family: Arial">{{ errorMessage }}</h5>
    </div>
  </div>
</div>
<mat-dialog-actions align="center">
  <button
    mat-flat-button
    (click)="createPatient()"
    color="warn">
    CREATE CLIENT/MATTER
  </button>
</mat-dialog-actions>
