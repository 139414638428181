import firebase from 'firebase/app';

export class User {
  role: string;
  email: string;
  name: string;
  firstname?: string;
  phone?: string;
  lastname?: string;
  clientMatter?: string;
  uid: string;
  ownerID: string;
  disabled: boolean;
  plancode: string;
  plan: firebase.firestore.DocumentReference;
  testaccount: boolean;
  constructor(
    role: string,
    email: string,
    name: string,
    uid: string,
    ownerID: string,
    plan?: string,
    firstname?: string,
    lastname?: string,
    clientMatter?: string,
    phone?: string,
    testaccount?: boolean,
  ) {
    this.role = role;
    this.email = email;
    this.name = name;
    this.firstname = firstname || '';
    this.lastname = lastname || '';
    this.clientMatter = clientMatter || '';
    this.uid = uid;
    this.ownerID = ownerID;
    this.disabled = false;
    this.plan = plan !== '' ? firebase.firestore().collection('plans').doc(plan) : null;
    this.plancode = plan || null;
    this.phone = phone || '';
    this.testaccount = testaccount || false;
  }
}
