<input
  #input
  accept="{{ uploadFileType }}"
  type="file"
  [attr.class]="class"
  [attr.multiple]="multiple ? '' : null"
  [attr.directory]="directory ? '' : null"
  [attr.allowdirs]="allowDirs ? '' : null"
  [attr.webkitdirectory]="directory ? '' : null"
  name="fileinput"
/>
<label for="fileinput">{{ label }}</label>
