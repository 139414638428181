import 'firebase/firestore';

import firebase from 'firebase/app';

// const site_uri = 'nuagedx-qa.web.app';
const site_uri = 'toysbox.nuagedx.com';
const version = '1.10 - d:219';
const projectId = 'nuagedx-dev';
const viewerUrls = ['https://viewer.nuagedx.com/', 'https://viewer-qa.web.app/', 'https://viewer-qa-1.web.app/'];
const viewerIndex = 0;

export const environment = {
  production: false,
  maintenance: false,
  config: {
    sessionTimeLimit: 2 * 60 * 60 * 1000, // 2 hours.
    databaseURL: 'https://nuagedx-dev.firebaseio.com',
    healthcareApi: 'https://us-central1-nuagedx-dev.cloudfunctions.net/healthcareApi',
    location: 'us-central1',
    apiKey: 'AIzaSyBPYsY-89MlrB5w3-cQf92-ILGiHF46AZ0',
    authDomain: 'nuagedx-dev.firebaseapp.com',
    projectId: projectId,
    storageBucket: 'nuagedx-dev.appspot.com',
    messagingSenderId: '409596160399',
    appId: '1:409596160399:web:c94b8198a805007e',
    viewerIndex: viewerIndex,
    ohifViewerURL: viewerUrls[viewerIndex],
    host: `https://${site_uri}/`,
    version: version,
    algolia: {
      appId: '3RFWKF3793',
      apiKey: '5ff8756359c3dca2ad643a1284d7a26e',
      filesIndex: 'dev_FILES',
    },
    malwarescanner: { endpoint: 'malwarescanner' },
    gapi: {
      clientId: '409596160399-jvos1lrt8q6lqc1u2u9fi2vokn4upe4a.apps.googleusercontent.com',
      healthcareRest: 'https://content.googleapis.com/discovery/v1/apis/healthcare/v1/rest',
      healthcareRest_v1beta1: 'https://www.googleapis.com/discovery/v1/apis/healthcare/v1beta1/rest',
      platformScope: 'https://www.googleapis.com/auth/cloud-platform',
      apiKey: 'AIzaSyCaMW--IxXHWpzH9YsM_gxMOL9tFldan5o',
      location: 'us-central1',
      projectname: projectId,
    },
    clio: {
      client_id: 'rHRqUKxKFh1cQkiPJ9oK4ZCLktkil0V7JJbbWubk',
      client_secret: 'dA8CI7LZndm236U8zPtXO4BPyZEaZYdimv3NhcOB',
      redirect_uri: `https://${site_uri}/clioauth`,
      authorizeURL: 'https://app.clio.com/oauth/authorize',
      redirectsGroup: {
        updateDefaultCalendar: `https://${site_uri}/clioauth/calendar`,
        clientProfile: `https://${site_uri}/clioauth/client`,
      },
      SSO: {
        key: 'f38a14bc-2e90-4811-b847-a89fe408f7f1',
        secret: 'xn6zFuII6AF82rSLKu8RFGHNqA',
        callback: `https://${site_uri}/sso_callback`,
        authVersionURL: 'https://account.clio.com/oauth2/auth',
        appIntegrationsCallBackURL: `https://app.clio.com/app_integrations_callback`,
      },
    },
    snackBar: {
      duration: 7000,
      verticalPosition: 'top',
      panelClass: 'snackbar-error',
    },
    zoom: {
      clientId: '7AwbYkq9SUK2uqp6RQQfkg',
      clientSecret: 'i2Iu4J8tkhebMtEjJBnY8zr66TPDvZGN',
      redirectURL: `https://${site_uri}/meetings`,
      findLocalNumbersURL: 'https://nuagedx.zoom.us/u/ac0xN4965m',
      invitationOptions: {
        includeDateTime: true,
        host_invitation: false,
      },
    },
    microsoft: {
      oauth: {
        appId: '59617e48-485c-42ca-98ee-914b43b3999c',
        redirectUri: `https://${site_uri}/meetings`,
        scopes: ['user.read', 'calendars.readwrite', 'mailboxsettings.read'],
      },
    },
    email: { targets: { notifications: ['alexisrengifo@gmail.com'] } },
  },
  constants: {
    cloudfunctionsURL: 'https://us-central1-nuagedx-dev.cloudfunctions.net/',
    mailClient: {
      GOOGLE: 'Google',
      OFFICE365: 'Office365',
      OUTLOOK: 'Outlook',
      OTHER: 'Other',
      CLIO: 'Clio',
    },
    zoomDefaults: { DEFAULT_TOPIC: 'Nuagedx meeting' },
  },
};

firebase.initializeApp(environment.config);
