import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import firebase from 'firebase/app';

import { AuthService } from '../../services/auth.service';
import { UtilsService } from '../../services/utils.service';
import { SharingFilesService } from './../../sharing-files.service';

const unshareIconUrl = './../../assets/svg/unshare.svg';
@Component({
  selector: 'app-active-consultants',
  templateUrl: './active-consultants.component.html',
  styleUrls: ['./active-consultants.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActiveConsultantsComponent implements OnInit {
  consultantsList: any[] = [];
  clientId: string;
  currentSignedInUser: firebase.User;
  cachedUsers: any[] = [];
  selection = new SelectionModel<any>(true, []);
  @Input() dataFromParent: any = [];
  @Input() selectedFiles: any = [];
  @Output() shareEvent: EventEmitter<any> = new EventEmitter();
  @Output() unshareEvent: EventEmitter<any> = new EventEmitter();
  @Output() rowClickEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('consultants') consultants: MatSelectionList;
  files: any;
  clientfiles: any;
  role: any;
  activeConsultantsList: any[];
  activeCachedUsers: any[];
  optionSelectedClick: number;
  currentValue: string;
  pickedFile: {};

  constructor(
    public snackBar: MatSnackBar,
    public auth$: AuthService,
    public router: Router,
    private Utils$: UtilsService,
    public ref: ChangeDetectorRef,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private sharing_files_$: SharingFilesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.optionSelectedClick = 0;
    this.currentValue = '';
    this.matIconRegistry.addSvgIcon('unshareIcon', this.domSanitizer.bypassSecurityTrustResourceUrl(unshareIconUrl));
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit() {
    this.activeConsultantsList = this.dataFromParent;
    this.auth$.user.subscribe(async user => {
      this.currentSignedInUser = user;
    });

    this.sharing_files_$.pickedFile.subscribe({
      next: pickedFile => {
        this.highLightPicked(pickedFile);
      },
    });
  }

  clearPicked() {
    this.activeConsultantsList.forEach(consultant => delete consultant.highlighted);
  }

  highLightPicked(file) {
    this.clearPicked();
    if (Object.keys(file).length) {
      if (file.sharedUsers && file.sharedUsers.length) {
        const sharedEmails = file.sharedUsers.map(user => user.email.toLowerCase());
        this.activeConsultantsList.forEach(consultant => {
          if (sharedEmails.includes(consultant.email.toLowerCase())) {
            consultant.highlighted = true;
          }
        });
      }
    }
  }

  selectedOptionsClear() {
    this.consultants.selectedOptions.clear();
  }

  rowClick(consultant) {
    this.rowClickEvent.emit(consultant);
    this.currentValue = consultant === '' ? '' : consultant.email;
    this.optionSelectedClick = consultant === '' ? 0 : 1;
  }

  handleOptionClick(consultant) {
    if (consultant.email === this.currentValue) {
      this.consultants.selectedOptions.clear();
      this.rowClick('');
    } else {
      this.rowClick(consultant);
    }
  }

  /**
   * checkSelected
   */
  checkSelected(consultant) {
    if (this.selectedFiles.length > 1) {
      if (this.checkSelectedShared(consultant, this.selectedFiles)) {
        return true;
      }
      return false;
    }
  }

  checkSelectedShared(consultant, selectedFiles) {
    let counder = 0;
    if (selectedFiles.length < 2) {
      return false;
    }
    selectedFiles.forEach(file => {
      if (this.fileIsShared(consultant, file)) {
        counder++;
      }
    });
    if (counder === selectedFiles.length) {
      return true;
    }
    return false;
  }

  checkSelectedNotShared(consultant, selectedFiles) {
    if (selectedFiles.length < 2) {
      return false;
    }
    let counder = 0;
    selectedFiles.forEach(file => {
      if (!this.fileIsShared(consultant, file)) {
        counder++;
      }
    });
    if (counder === selectedFiles.length) {
      return true;
    }
    return false;
  }

  /**
   * Check if file is shared with the given consultant.
   */
  fileIsShared(consultant, file) {
    return this.Utils$.fileIsShared(consultant, file);
  }

  share(consultant) {
    this.shareEvent.emit(consultant.email);
  }

  unShare(consultant) {
    this.unshareEvent.emit({ consultant: consultant.email, type: 'single' });
  }

  unShareAll(consultant) {
    this.unshareEvent.emit({ consultant: consultant.email, type: 'all' });
  }
}
