import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { UserRoles } from 'src/app/dictionaries/UserRoles';

const dbFiles = firebase.firestore().collection('files');
@Injectable({
  providedIn: 'root'
})
export class ReportService {
  UserRoles: { owner: string; admin: string; associate: string; consultant: string; superuser: any; };
  constructor () {
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
  }

  async getFilesBelongsTo(patient: string) {
    const files = [];
    const storageRef = firebase.storage();
    const sp = await dbFiles
      .where('belongsTo', '==', patient)
      .where('forDelete', '==', false)
      .get();

    for (let index = 0; index < sp.docs.length; index++) {
      const item = sp.docs[index];
      const itemData = item.data();

      if (itemData.type === 'folder') {
        continue;
      }

      console.log('itemData :', itemData);

      const ref = storageRef.ref(itemData.filePath);

      let itemExists = false;
      await ref.getDownloadURL().then(result => {
        itemExists = true;
      }).catch(err => {
        this.deleteThisFile(itemData);
        itemExists = false;
      });

      if (itemExists === false) {
        continue;
      }

      if (itemData.forDelete === false) {
        files.push(item.data());
      }
    }
    return files;
  }

  async deleteThisFile(itemData: firebase.firestore.DocumentData) {
    const id2Delete = itemData.fileId;
    (await dbFiles.where('fileId', '==', id2Delete).limit(1).get()).docs.forEach(doc => {
      dbFiles.doc(doc.id).update({ forDelete: true });
    });
  }

  async getAllPatientsForClient(ownerID: string) {
    const db = firebase.firestore();
    const patients = [];
    const docs = (await db.collection('patients').where('ownerID', '==', ownerID).get()).docs;
    for (let index = 0; index < docs.length; index++) {
      const patient = docs[index].data();
      const files = await this.getFilesBelongsTo(patient.caseName);

      patients.push({
        DateOfBirth: patient.DateOfBirth,
        FirstName: patient.FirstName,
        LastName: patient.LastName,
        LegalCaseId: patient.LegalCaseId,
        caseName: patient.caseName,
        fileSearchQuery: patient.fileSearchQuery,
        files: files,
        uidOfCreator: patient.uidOfCreator
      });
    }
    return patients;
  }

  async getAllFilesForEachPatientForClient(ownerID: string) {
    const filesForEachPatient = [];
    const patients = await this.getAllPatientsForClient(ownerID);
    patients.forEach(({ caseName, files }) => filesForEachPatient.push({ caseName, files }));
    return filesForEachPatient;
  }

  async getAllFilesAndCountForClient(ownerID: string) {
    const allFiles = [];
    const patients = await this.getAllFilesForEachPatientForClient(ownerID);
    patients.forEach(({ files }) => {
      const filteredArr = files.filter(file => file.forDelete === false);
      allFiles.push.apply(allFiles, filteredArr);
    });
    return { allFilesForClient: allFiles, countOfFiles: allFiles.length };
  }

  async getSizeForEachFile(ownerID: string, allFilesForClient?) {
    const storageRef = firebase.storage().ref();
    const filesWithSize = [];
    let i = 0;

    if (!allFilesForClient) {
      allFilesForClient = (await this.getAllFilesAndCountForClient(ownerID))['allFilesForClient'];
    }

    for (let index = 0; index < allFilesForClient.length; index++) {
      const file = allFilesForClient[index];
      const fileRef = storageRef.child(file.filePath);
      let fileMetaData: any;

      try {
        fileMetaData = await fileRef.getMetadata();
      } catch (e) {

        // NOTE: Delete when error of not loading.
        dbFiles.where('fileId', '==', file.fileId).get().then(docs => {
          docs.forEach(item => {
            dbFiles.doc(item.id).update({ forDelete: true }).then(item_ => {
              // console.log('>>> item.id: ', item.id);
            });
          });
        });
        continue;
      }

      if (!fileMetaData) { continue; }
      file.size = fileMetaData.size;
      file.hash = fileMetaData.md5Hash;
      filesWithSize.push(file);
      i++;
    }

    return filesWithSize;
  }

  async getTotalFileUsageSizeForClient(ownerID: string) {
    let totalSizeInBytes = 0;
    const allFilesWithSize = await this.getSizeForEachFile(ownerID);
    allFilesWithSize.forEach(({ size }) => totalSizeInBytes += size);
    return totalSizeInBytes;
  }

  getNumberOfClients() {
    const allClients = [];
    const db = firebase.firestore();
    return db.collection('users').where('role', '==', UserRoles.owner).get().then(qs => {
      qs.forEach((doc) => allClients.push({ name: doc.data().name, email: doc.data().email, uid: doc.data().uid }));
      return allClients;
    });
  }
}
