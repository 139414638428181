import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UtilsService } from './../../services/utils.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() currentFolder = '';
  @Input() folderColor = '';
  @Input() currentFolderName = '';
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  folderColors: {
    INDEX: string;
    Damages: string;
    ClientUpload: string;
    Discovery: string;
    Liability: string;
    Other: string;
    custom: string;
  };
  constructor(private utils_$: UtilsService) {}

  getButtonStyle(name) {
    return this.utils_$.getButtonStyle(name);
  }

  handleGo(target: string) {
    this.buttonClick.emit(target);
  }
}
