import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { AuditLogFilterByUserComponent } from '../components/audit-log-filter-by-user/audit-log-filter-by-user.component';
import { SearchComponent } from '../components/search/search.component';
import { ShowUserInfoComponent } from '../components/show-user-info/show-user-info.component';
import { FirebaseUtilitiesService } from '../services/firebase-utilities.service';

interface MenuItem {
  name: string;
  uid: string;
  clients: any[];
}

@Component({
  selector: 'app-audit-log-viewer',
  templateUrl: './audit-log-viewer.component.html',
  styleUrls: ['./audit-log-viewer.component.scss'],
})
export class AuditLogViewerComponent {
  displayedColumns: string[] = ['created', 'name', 'role', 'ip', 'action', 'content'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedClient: any;
  filteredClient: any;
  filterByUserMenuItems: MenuItem[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public userData: any,
    private firebase_utilities_$: FirebaseUtilitiesService,
    public dialog: MatDialog,
  ) {
    this.setDefaultDatasource();
  }

  filterByClient() {
    const dialogRef = this.dialog.open(SearchComponent, { width: '600px', data: { selectClient: true } });
    dialogRef.afterClosed().subscribe(result => {
      const { client, clientname } = result;
      let logs = this.userData.logs
        .filter(f => f.client === client)
        .map(r => ({
          created: r.created.toDate().toLocaleString(),
          name: r.name,
          role: r.role,
          useremail: r.useremail,
          ip: r.ip,
          action: r.action,
          content: r.content,
        }));
      logs = logs.sort((a, b) => (Date.parse(a.created) > Date.parse(b.created) ? -1 : 1));
      this.dataSource = new MatTableDataSource(logs);
      this.dataSource.paginator = this.paginator;
      this.filteredClient = clientname;
    });
  }

  async filterByUser(): Promise<void> {
    const getUSersOfCurrentOwner = await this.getUsersOfCurrentOwner();
    const dialogRef = this.dialog.open(AuditLogFilterByUserComponent, {
      width: '300px',
      data: { usersData: getUSersOfCurrentOwner },
    });

    const handleUserSelected = result => {
      const { client, useruid } = result;
      let logs = this.userData.logs
        .filter(f => f.client === client)
        .filter(f => f.uid === useruid)
        .map(({ useremail, role, name, created, ip, action, content }) => ({
          created: created.toDate().toLocaleString(),
          name,
          role,
          useremail,
          ip,
          action,
          content,
        }));
      logs = logs.sort((a, b) => (Date.parse(a.created) > Date.parse(b.created) ? -1 : 1));
      this.dataSource = new MatTableDataSource(logs);
      this.dataSource.paginator = this.paginator;
      this.filteredClient = client;
    };

    dialogRef.afterClosed().subscribe(result => handleUserSelected(result));
  }

  getUsersOfCurrentOwner() {
    const current_uid = this.userData.user.user.uid;
    return this.firebase_utilities_$
      .getUsersByOwneruid(current_uid)
      .then(r => (this.filterByUserMenuItems = r))
      .catch(err => console.error('There was an error with the function getUsersByOwneruid', err));
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  resetFilters() {
    this.setDefaultDatasource();
  }

  setDefaultDatasource(): void {
    let logs = this.userData.logs.map(r => ({
      created: r.created.toDate().toLocaleString(),
      name: r.name,
      role: r.role,
      useremail: r.useremail,
      ip: r.ip,
      action: r.action,
      content: r.content,
    }));
    logs = logs.sort((a, b) => (Date.parse(a.created) > Date.parse(b.created) ? -1 : 1));

    this.dataSource = new MatTableDataSource(logs);
    this.dataSource.paginator = this.paginator;
  }

  async showUserInfo(element) {
    const userInfo = await this.firebase_utilities_$.getUserInfoByEmail(element.useremail);
    this.dialog.open(ShowUserInfoComponent, { data: userInfo });
  }
}
