<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close color="accent">X</button>
</mat-dialog-actions>
<h2 class="mat-title">Edit note</h2>
<form [formGroup]="formDoc" novalidate>
  <mat-form-field style="width: 100%">
    <mat-label>Title</mat-label>
    <input
      matInput
      formControlName="notetitle"
      placeholder="Ex. Note title."
      (keyup)="noteTitle = getValue($event.target)"
      [value]="noteTitle"
    />
  </mat-form-field>
  <br />
  <mat-form-field style="width: 100%">
    <mat-label>Content</mat-label>
    <textarea
      matInput
      formControlName="note"
      placeholder="Ex. Note text."
      (keyup)="note = getValue($event.target)"
      [value]="note"
      rows="6"
    ></textarea>
  </mat-form-field>
  <br />

  <ul class="mat-body" *ngIf="noteAttachments">
    <li *ngFor="let attachment of jsonParse(noteAttachments)">
      <a class="link-style" target="_blank" (click)="browseFile(attachment)">{{
        substringFileName(attachment)
      }}</a>
    </li>
  </ul>

  <br />
  <mat-form-field [ngClass]="'hidden-component'">
    <app-input-file
      #fileBrowser
      label=""
      formControlName="basicfile"
      [ngClass]="'file-browser'"
      [multiple]="true"
      [directory]="false"
      [allowDirs]="false"
      placeholder=""
      (change)="handleFileSelection($event)"
      accept="{{ uploadFileType }}"
    >
    </app-input-file>
    <br />
  </mat-form-field>

  <button
    style="margin-left: 10px"
    mat-flat-button
    color="warn"
    [ngClass]="'browse-button'"
    (click)="browseFiles($event, fileBrowser.ngControl.name)"
  >
    Browse for files <mat-icon matSuffix>note</mat-icon>
  </button>

  <ul *ngIf="fileEntries.length" [ngClass]="'mat-body'">
    <li style="list-style-type: none">Selected files:</li>
    <li style="list-style-type: circle" *ngFor="let item of fileEntries">
      {{ item.name }}
    </li>
  </ul>

  <button mat-button color="warn" (click)="saveNote()">SAVE</button>
</form>
