import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';

import { FirebaseUtilitiesService } from '../../services/firebase-utilities.service';
import { FilesService } from '../../services/files.service';
import { UIMessagingService } from 'src/app/services/uimessaging.service';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
})
export class ShareDialogComponent implements OnInit {
  selectable = true;
  selectedFiles: any[];
  patientUsers: any[];
  consultants: any[];
  otherfilesconsultants: any[];
  sameownerconsultants: any[];
  selectedEmail: string;
  caseName: string;
  subscription: any;
  errorMessage: any;
  consultantsMode = 'Active';
  consultantsOptions: string[] = ['Active', 'Available'];
  initOtherFilesConsultants: any;
  availableConsultantsShare = false;
  selectedFile: any;
  activeConsultantSelected: any;
  activeConsultants: any[];
  availableConsultants: any[];
  sharedFiles: any[];
  patientFiles: any;
  ownerId: any;
  @ViewChild('availableFilesList') availableFilesList: MatSelectionList;
  constructor(
    private dialogRef: MatDialogRef<ShareDialogComponent>,
    private firebaseUtilities$: FirebaseUtilitiesService,
    private files$: FilesService,
    private uiMessaging_$: UIMessagingService,
    @Inject(MAT_DIALOG_DATA)
    {
      selectedFiles,
      patientUsers,
      consultants,
      ownerId,
      patientFiles,
      otherfilesconsultants,
      sameownerconsultants,
      casename,
    },
  ) {
    this.selectedFiles = selectedFiles;
    this.patientUsers = patientUsers;
    this.ownerId = ownerId;
    this.patientFiles = patientFiles;
    this.consultants = consultants;
    this.otherfilesconsultants = otherfilesconsultants;
    this.sameownerconsultants = sameownerconsultants;
    this.caseName = casename;
    this.selectedEmail = '';
  }

  async ngOnInit() {
    this.files$.filesArray.subscribe(obs => {
      if (obs.length > 0) {
        this.sharedFiles = obs;
      }
    });
  }

  onConsultantsTypeClick() {
    this.selectedFiles.forEach(selectedFile => (selectedFile.shared = null));
  }

  // NOTE: Click on FILES SELECTED list.
  fileItemClick(event, matList) {
    if (this.consultantsMode === 'Active') {
      event.preventDefault();
      event.stopPropagation();
      return;
    } else {
      this.selectedFile = matList.selectedOptions.length ? matList.selectedOptions.selected[0].value : [];
      this.availableConsultantsShare = true;
      // matList.selectedOptions.selected[0]._element.nativeElement.querySelector('.fakecheckbox')
    }
  }

  async handleMultiShare(sameOwnerConsultants: MatSelectionList) {
    if (!sameOwnerConsultants.selectedOptions.selected.length) {
      const message = `Please select at least one consultant to share`;
      this.uiMessaging_$.toastMessage(message, 'ERROR');
      return;
    }

    const emails = sameOwnerConsultants.selectedOptions.selected.map(item => item.value);

    if (!this.availableFilesList.selectedOptions.selected.length) {
      const message = `Please select at least one file to share`;
      this.uiMessaging_$.toastMessage(message, 'ERROR');
      return;
    }

    const filesList = this.availableFilesList.selectedOptions.selected;

    for (let idx = 0; idx < filesList.length; idx++) {
      const file = filesList[idx].value;
      for (let index = 0; index < emails.length; index++) {
        const email = emails[index];
        await this.firebaseUtilities$.shareFile(file, email, this.caseName);

        /************** TODO: Refactor this part */
        // NOTE: Get the recent shared Consultanat and add the selected file to its shared files.
        const newConsultant = this.sameownerconsultants.find(consultant => consultant.email === email);

        if (!newConsultant) {
          this.availableFilesList.deselectAll();
          return;
        }

        if (!newConsultant.filesSharedWith) {
          newConsultant.filesSharedWith = [];
        }

        newConsultant.filesSharedWith.push({
          patientCaseName: this.caseName,
          fileId: this.selectedFile.fileId,
        });

        // NOTE: Update otherfilesconsultants list (Active list).
        const emailExists = this.otherfilesconsultants.find(consultant => consultant.email === newConsultant.email);
        if (!emailExists) {
          this.otherfilesconsultants.push(newConsultant);
        }
        // NOTE: Remove shared consultant from Available list.
        this.sameownerconsultants = this.sameownerconsultants.filter(consultant => consultant.email !== email);
        /************************************** */
      }
    }

    // clear selection.
    this.availableFilesList.deselectAll();
  }

  async unShare(event, file) {
    event.stopPropagation();
    await this.firebaseUtilities$.unShareFile(file, this.activeConsultantSelected.email, this.caseName);
    const sharedFiles = [];
    this.activeConsultantSelected.filesSharedWith.forEach(sFile => {
      if (sFile.fileId !== file.fileId) {
        sharedFiles.push(sFile);
      }
    });

    this.activeConsultantSelected.filesSharedWith = sharedFiles;
    const selectedIds = this.selectedFiles.map(fl => fl.fileId);

    const selectedConsultantFiles = this.activeConsultantSelected.filesSharedWith.map(item => item.fileId);
    const noEmpty = selectedConsultantFiles.some(item => selectedIds.includes(item));
    if (!noEmpty) {
      this.otherfilesconsultants = this.otherfilesconsultants.filter(
        item => item.email !== this.activeConsultantSelected.email,
      );
      this.sameownerconsultants.push(this.activeConsultantSelected);
      this.selectedFiles.forEach(sFile => {
        delete sFile['shared'];
      });
      this.activeConsultantSelected = null;
    } else {
      this.onActiveConsultantsClicked(null, this.activeConsultantSelected);
    }
  }

  async share(event, file) {
    event.stopPropagation();
    // TODO: Have to locate where this action ends and add a listener to subscribe to in order to fire other actions.
    await this.firebaseUtilities$.shareFile(file, this.activeConsultantSelected.email, this.caseName);

    // NOTE: Refilling active consultant shared files.
    const newFiles = this.activeConsultantSelected.filesSharedWith
      .filter(nfile => nfile.fileId !== file.fileId)
      .filter(nfile => nfile.patientCaseName === this.caseName);
    newFiles.push(file);
    this.activeConsultantSelected.filesSharedWith = newFiles;
    this.onActiveConsultantsClicked(null, this.activeConsultantSelected);
  }

  async shareWithMe(consultantEmail) {
    await this.firebaseUtilities$.shareFile(this.selectedFile, consultantEmail, this.caseName);

    // NOTE: Get the recent shared Consultanat and add the selected file to its shared files.
    const newConsultant = this.sameownerconsultants.find(consultant => consultant.email === consultantEmail);
    if (!newConsultant.filesSharedWith) {
      newConsultant.filesSharedWith = [];
    }
    newConsultant.filesSharedWith.push({
      patientCaseName: this.caseName,
      fileId: this.selectedFile.fileId,
    });

    // NOTE: Update otherfilesconsultants list (Active list).
    this.otherfilesconsultants.push(newConsultant);

    // NOTE: Remove shared consultant from Available list.
    this.sameownerconsultants = this.sameownerconsultants.filter(consultant => consultant.email !== consultantEmail);
  }

  onActiveConsultantsClicked(allpusers, consultant) {
    this.activeConsultantSelected = consultant;
    if (!consultant.filesSharedWith || !consultant.filesSharedWith.length) {
      this.selectedFiles.forEach(sfile => {
        sfile.shared = 0;
      });
    } else {
      this.selectedFiles.forEach(file => {
        file.shared = 0;
        consultant.filesSharedWith.forEach(cFile => {
          if (file.fileId === cFile.fileId) {
            file.shared = 1;
          }
        });
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

  shareFile(selectedEmail) {
    this.dialogRef.componentInstance.patientUsers = [];
    this.dialogRef.close({
      action: 'share',
      selectedEmail,
      files: this.selectedFiles,
      casename: this.caseName,
    });
  }

  delete(selectedToDelete) {
    this.dialogRef.close({ action: 'delete', selectedToDelete });
  }
}
