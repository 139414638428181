import { HttpParams, HttpRequest } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BodyType } from '@microsoft/microsoft-graph-types';
import firebase from 'firebase/app';
import moment from 'moment';
import { UserRoles } from 'src/app/dictionaries/UserRoles';
import { GoogleEvent } from 'src/app/models/GoogleEvent';

import { ClioService } from '../../services/clio.service';
import { UIMessagingService } from '../../services/uimessaging.service';
import { environment } from './../../../environments/environment';
import { CreateZoomMeetingFormComponent } from './../../create-zoom-meeting-form/create-zoom-meeting-form.component';
import { DefaultCalendarSetUpComponent } from './../../default-calendar-set-up/default-calendar-set-up.component';
import { NewMicrosoftEvent } from './../../models/NewMicrosoftEvent';
import { AuthService } from './../../services/auth.service';
import { FirebaseUtilitiesService } from './../../services/firebase-utilities.service';
import { GapiOperationsService } from './../../services/gapi-operations.service';
import { MicrosoftGraphService } from './../../services/microsoft-graph.service';
import { MicrosoftAuthService } from './../../services/microsoft.auth.service';
import { ZoomOperationsService } from './../../zoom-operations.service';
import { RedirectionService } from 'src/app/services/redirection.service';

const mailClient = environment.constants.mailClient;
const dateFormat = 'YYYY-MM-DDTHH:mm:ss';
@Component({
  selector: 'app-meetings-page',
  templateUrl: './meetings-page.component.html',
  styleUrls: ['./meetings-page.component.scss'],
})
export class MeetingsPageComponent implements OnInit {
  userSignedIn: boolean;
  guestsList: string[] = [];
  uid: any;
  email: any;
  zoomUser: any;
  zoomUsersList: any;
  consultantsList: any[];
  timezones: { label: string; value: string }[];
  meetingDate: any;
  meetingAgenda: any;
  meetingTopic: any;
  meetingTime: any;
  meetingDuration: any;
  meetingTimezone: any;
  selectedOptions: any;
  defaultCalendar: string;
  googleCalendarButton: boolean;
  outlookCalendarButton: boolean;
  meetingCreated: boolean;
  zoomMeeting: any;
  isSignedIn: boolean;
  predefinedCalendar: any;
  user: firebase.firestore.DocumentData;
  zoomCreateMeeting: firebase.functions.HttpsCallable;
  zoomCreateUser: firebase.functions.HttpsCallable;
  zoomListUsers: firebase.functions.HttpsCallable;
  zoomListMeetings: firebase.functions.HttpsCallable;
  zoomGetUser: firebase.functions.HttpsCallable;
  zoomGetMeetingInvitation: firebase.functions.HttpsCallable;
  lastICSDownloadURL: string;
  UserRoles: { owner: string; admin: string; associate: string; consultant: string; superuser: string };
  userData: {};
  googleSignedIn = false;
  loading: boolean;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private auth_$: AuthService,
    private firebaseUtilities_$: FirebaseUtilitiesService,
    private gapiOperations_$: GapiOperationsService,
    private microsoftAuth_$: MicrosoftAuthService,
    private microsoftGraph_$: MicrosoftGraphService,
    private zoomOperations_$: ZoomOperationsService,
    private uiMessaging_$: UIMessagingService,
    private dialog: MatDialog,
    private clio_$: ClioService,
    private redirect_$: RedirectionService,
  ) {
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };

    this.user = { role: '' };
    this.defaultCalendar = null;

    this.googleCalendarButton = false;
    this.outlookCalendarButton = false;
    this.meetingCreated = false;
    this.timezones = [
      { label: 'Hawai', value: 'Pacific/Honolulu' },
      { label: 'Alaska', value: 'America/Anchorage' },
      { label: 'Pacific Time (US and Canada)', value: 'America/Los_Angeles' },
      { label: 'Mountain Time (US and Canada)', value: 'America/Denver' },
      { label: 'Central Time (US and Canada)', value: 'America/Chicago' },
      { label: 'Eastern Time (US and Canada)', value: 'America/New_York' },
    ];

    this.meetingDate = new Date();
    this.meetingAgenda = '';
    this.meetingTopic = '';
    this.meetingTime = '09:00 AM';
    this.meetingDuration = 60;
    this.meetingTimezone = this.timezones[2].value;
  }

  async ngOnInit() {
    this.loading = true;

    this.auth_$.userData.subscribe(userData => {
      if (Object.keys(userData).length) {
        this.handleCurrentUser(userData['uid'], userData['email']);
        this.userData = userData;
        if (this.userData['userDBRole'] === 'Consultant') {
          this.router.navigate(['/']);
        } else {
          this.route.queryParams.subscribe(params => {
            params['defaultCalendar'] ? this.setDefaultCalendar(params['defaultCalendar']) : null;
          });
        }
      } else {
        console.log('userData is empty');
        this.userData = null;
        this.redirect_$.goToLogin();
      }
    });
  }

  redirectToClioAuthorize() {
    const params = new HttpParams()
      .set('response_type', 'code')
      .set('client_id', environment.config.clio.client_id)
      .set('client_secret', environment.config.clio.client_secret)
      .set('redirect_uri', environment.config.clio.redirectsGroup.clientProfile);
    const request = new HttpRequest('GET', environment.config.clio.authorizeURL, null, { params });
    window.location.href = request.urlWithParams;
  }

  handleValidToken() {
    console.log('Clio token is valid');
  }

  async handleCurrentUser(uid: string, email: string): Promise<void> {
    if (!uid) {
      return;
    }

    this.user = await this.auth_$.getUserByUID(uid);
    this.getUserSettings(uid);
    this.getConsultantsList(uid);
    this.getZoomUser(email);
  }

  async getZoomUser(useremail) {
    // NOTE: Client Admin doesn't have permissions to SCHEDULE A MEETING
    if ([UserRoles.associate, UserRoles.owner].includes(this.user.role)) {
      this.zoomUsersList = await this.listZoomUsers();
      if (!this.zoomUsersList.length) {
        console.log('No Zoom users found');
        return;
      }
      const filtered = this.zoomUsersList.find(item => item.email === useremail);
      this.zoomUser = filtered ? filtered : await this.createUser();
    } else {
      this.zoomUser = undefined;
    }
  }

  getUserSettings(useruid) {
    this.auth_$
      .getUserSettings(useruid)
      .then(res => {
        if (res) {
          this.defaultCalendar = JSON.parse(res).calendar;
          const userData = this.auth_$.userData.value;
          if (Object.keys(userData).length > 0) {
            if (JSON.parse(res).calendar === environment.constants.mailClient.CLIO) {
              const validateAccessToken = this.firebaseUtilities_$.validateClioAccessToken();
              Boolean(validateAccessToken)
                ? this.handleValidToken()
                : this.clio_$.handleClioGetAccessToken(this.dialog, this.auth_$, this.redirectToClioAuthorize);
            }
            this.getGoogleUserSignedStatus();
          }
        }
      })
      .catch(err => console.log('err :', err));
  }

  async getConsultantsList(useruid) {
    this.consultantsList = (await this.firebaseUtilities_$.getConsultantsList(useruid)).map(item => ({
      email: item.email,
      name: item.name,
    }));
  }

  async createUser(lUser = this.user): Promise<any | undefined> {
    if ([UserRoles.owner, UserRoles.associate].includes(lUser.role)) {
      let newZoomUser = await this.zoomOperations_$
        .zoomCreateUser({
          action: `custCreate`,
          user_info: { email: lUser.email, type: 2, first_name: lUser.name, last_name: '' },
        })
        .catch(err => err)
        .then(res => res.data);

      if (newZoomUser.code === 409) {
        newZoomUser = await await this.zoomOperations_$
          .zoomGetUser({ userId: lUser.email })
          .catch(err => err)
          .then(res => res.data.response);
      }

      return newZoomUser;
    } else {
      console.error('User role is not owner or associate');
      return undefined;
    }
  }

  stringToCapitalCase(str: string) {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  setDefaultCalendar(calendar: string) {
    console.log('setDefaultCalendar', calendar);
    this.defaultCalendar = this.stringToCapitalCase(calendar);
    this.updateDefaultCalendar(this.defaultCalendar, this.auth_$.userData.getValue()['id']);
    this.userData['defaultCalendar'] = this.defaultCalendar;
  }

  validateClioAccessToken(token?: string) {
    const clioAccessToken = this.auth_$.userData.getValue()['clioAccessToken'];
    if (!clioAccessToken) {
      return false;
    }
    return JSON.parse(clioAccessToken)['access_token'];
  }

  async updateDefaultCalendar(predefinedCalendar: string, uid: string) {
    switch (predefinedCalendar) {
      case environment.constants.mailClient.CLIO:
        const validationResult = this.validateClioAccessToken(uid);
        if (!validationResult) {
          this.clio_$.handleGetClioAuthorization(
            {
              width: '500px',
              data: {
                origin: 'updateDefaultCalendar',
                message: 'Your default calendar now is CLIO, but you need to Authorize its usage first.',
                userdocid: this.auth_$.userData.value['id'],
              },
            },
            this.auth_$.userData.getValue()['clioAccessToken'],
          );
        } else {
          console.log('The user has already authorized CLIO usage.');
        }
        break;
      default:
        break;
    }

    this.firebaseUtilities_$.setDefaultCalendar(predefinedCalendar).then(() => console.log('Default calendar updated'));

    return { calendar: predefinedCalendar };
  }

  changeDefaultCalendar(ev) {
    ev.preventDefault();
    this.openDefaultCalendarSetUp();
  }

  async handleCreateMeeting(zoomUser, meetingData?, guestsList?) {
    if (zoomUser.code) {
      const message = `${zoomUser.message} Please contact the system administrator for support.`;
      const label = 'OK';
      this.uiMessaging_$.toastMessage(message, label);
      return;
    }

    let result;
    this.auth_$.showLoader('Setting up the meeting structure...');
    try {
      result = await this.zoomOperations_$.createMeeting(zoomUser, meetingData ?? {}, guestsList);
      this.auth_$.hideLoader();
    } catch (error) {
      this.auth_$.hideLoader();
      console.error(error);
      const message = `${error.message}`;
      const label = 'OK';
      this.uiMessaging_$.toastMessage(message, label);
      return error;
    }

    this.afterCreateMeeting(result, zoomUser, meetingData, guestsList);
  }

  afterCreateMeeting(result, zoomUser, meetingData?, guestsList?) {
    if (!result) {
      const message = `There was an error with meeting creation.`;
      const label = 'ERROR';
      this.uiMessaging_$.toastMessage(message, label);
    } else {
      this.zoomOperations_$.zoomMeeting = result.data.response;
      if (!this.defaultCalendar) {
        this.meetingCreated = true;
      } else {
        this.handleMeetingCreated(meetingData, guestsList, zoomUser);
      }
    }
  }

  handleOpen(blobURL) {
    window.open(blobURL, '_blank');
  }

  usePredefinedCalendar(predefinedCalendar) {
    switch (predefinedCalendar) {
      case mailClient.GOOGLE:
        this.goToGoogleCalendar();
        break;
      case mailClient.OFFICE365:
        this.goToOffice365Calendar();
        break;
      case mailClient.OUTLOOK:
        // this.goToOutlookCalendar();
        this.showZoomMeetingForm();
        break;
      case mailClient.OTHER:
        // this.goToOtherCalendar();
        this.showZoomMeetingForm();
        break;
      case mailClient.CLIO:
        this.showZoomMeetingForm();
        break;
      default:
        console.log('Default value');
        break;
    }
  }

  handleMeetingCreated(meetingData, guestsList, zoomUser) {
    const meeting = this.zoomOperations_$.zoomMeeting;
    if (!meeting) {
      const message = 'There was an error with meeting creation.';
      const label = 'ERROR';
      this.uiMessaging_$.toastMessage(message, label);
    }
    if (meetingData) {
      const invitationOptions = { ...environment.config.zoom.invitationOptions, first_name: zoomUser.first_name };
      const hostInvitationOptions = { ...invitationOptions, ...{ host_invitation: true } };
      const meetingInvitation = this.zoomOperations_$.createMeetingInvitation(meeting, invitationOptions, 'text');
      const hostInvitation = this.zoomOperations_$.createMeetingInvitation(meeting, hostInvitationOptions, 'html');

      if (this.predefinedCalendar === mailClient.CLIO) {
        const createClioEvent = this.zoomOperations_$.createClioEvent({
          meeting,
          guestsList,
          meetingInvitation: hostInvitation,
        });
      }

      this.zoomOperations_$.buildInvitationAndSend(zoomUser, meeting, guestsList, meetingInvitation, hostInvitation);
    } else {
      this.usePredefinedCalendar(this.defaultCalendar);
    }
  }

  createOffice365CalendarEvent() {
    const zoomMeeting = this.zoomOperations_$.zoomMeeting;
    const model = new NewMicrosoftEvent();
    const { join_url, timezone, start_time, duration } = zoomMeeting;
    const timeZone = timezone;
    const { first_name } = this.zoomUser;
    const meetingInvitationText = this.zoomOperations_$.createMeetingInvitation(
      zoomMeeting,
      { first_name, host_invitation: false },
      'text',
    );

    model.body = {
      contentType: <BodyType>'text',
      content: meetingInvitationText,
    };

    const momentDate = moment.utc(moment(start_time)).tz(timeZone);
    const startDate = momentDate.format(dateFormat);
    model.start = startDate;

    const hoursDuration = Math.floor(duration / 60);
    const minutesDuration = duration % 60;
    const endDate = momentDate.clone().add(hoursDuration, 'h').add(minutesDuration, 'm');
    model.end = endDate.format(dateFormat);

    model.subject = environment.constants.zoomDefaults.DEFAULT_TOPIC;
    model.location = join_url;
    const graphEvent = model.getGraphEvent(timeZone);

    this.microsoftGraph_$
      .addEventToCalendar(graphEvent)
      .then(response => (response ? this.gapiOperations_$.gotoUrl(response.webLink, '_blank') : null))
      .catch(error => console.error(`Error creating event. + ${error.message}`));
  }

  async createOtherCalendarEvent() {
    const zoomMeeting = this.zoomOperations_$.zoomMeeting;
    const model = new NewMicrosoftEvent();
    // const timeZone = this.microsoftAuth_$.user?.timeZone ?? 'UTC';
    const { join_url, timezone } = zoomMeeting;
    const timeZone = timezone;
    const { first_name } = this.zoomUser;
    model.body = {
      contentType: <BodyType>'text',
      content: this.zoomOperations_$.createMeetingInvitation(
        zoomMeeting,
        { first_name, host_invitation: false },
        'text',
      ),
    };
    const newDate = new Date();
    model.start = newDate.toISOString();
    newDate.setTime(newDate.getTime() + 30 * 60 * 1000);
    model.end = newDate.toISOString();
    model.subject = environment.constants.zoomDefaults.DEFAULT_TOPIC;
    model.location = join_url;
    const graphEvent = model.getGraphEvent(timeZone);

    this.microsoftGraph_$
      .addEventToCalendar(graphEvent)
      .then(response => {
        if (response) {
          this.gapiOperations_$.gotoUrl(response.webLink, '_blank');
        }

        console.log('Event created.');
      })
      .catch(error => {
        console.error(`Error creating event. + ${error.message}`);
      });
  }

  async createOutlookCalendarEvent() {
    const zoomMeeting = this.zoomOperations_$.zoomMeeting;
    const model = new NewMicrosoftEvent();
    // const timeZone = this.microsoftAuth_$.user?.timeZone ?? 'UTC';
    const { id, join_url, timezone } = zoomMeeting;
    const timeZone = timezone;
    const { first_name } = this.zoomUser;
    model.body = {
      contentType: <BodyType>'text',
      content: this.zoomOperations_$.createMeetingInvitation(
        zoomMeeting,
        { first_name, host_invitation: false },
        'text',
      ),
    };
    const newDate = new Date();
    model.start = newDate.toISOString();
    newDate.setTime(newDate.getTime() + 30 * 60 * 1000);
    model.end = newDate.toISOString();
    model.subject = environment.constants.zoomDefaults.DEFAULT_TOPIC;
    model.location = join_url;
    const graphEvent = model.getGraphEvent(timeZone);

    this.microsoftGraph_$
      .addEventToCalendar(graphEvent)
      .then(response => {
        if (response) {
          this.gapiOperations_$.gotoUrl(response.webLink, '_blank');
        }
        // this.alerts_$.addSuccess('Event created.');
        console.log('Event created.');
      })
      .catch(error => {
        console.error(`Error creating event. + ${error.message}`);
      });
  }

  showZoomMeetingForm() {
    const dialogConfig = new MatDialogConfig();
    this.dialog.openDialogs.pop();

    dialogConfig.minWidth = 800;
    dialogConfig.autoFocus = true;
    dialogConfig.maxHeight = '80vw';
    dialogConfig.data = {
      id: 1,
      zoomUser: this.zoomUser,
      predefinedCalendar: this.defaultCalendar,
    };

    const dialogRef = this.dialog.open(CreateZoomMeetingFormComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .toPromise()
      .then(result => {
        if (result === '' || !result) {
          return;
        }
        const { meetingObject, guestsList } = result;

        this.zoomOperations_$.createMeeting(this.zoomUser, meetingObject, guestsList).then(meetingResult => {
          if (!meetingResult) {
            const message = 'There was an error with meeting creation.';
            const label = 'ERROR';
            this.uiMessaging_$.toastMessage(message, label);
          } else {
            this.zoomOperations_$.zoomMeeting = meetingResult.data.response;
            if (!this.defaultCalendar) {
              this.meetingCreated = true;
            } else {
              this.handleMeetingCreated(false, guestsList, this.zoomUser);
            }
          }
        });
      });
  }

  async listZoomUsers() {
    return this.zoomOperations_$
      .zoomListUsers()
      .then(result => result.data.users)
      .catch(reason => reason);
  }

  async listZoomMeetings() {
    return this.zoomOperations_$
      .zoomListMeetings({ userId: this.zoomUser.email })
      .then(result => {
        const meetingsArray = result.data.meetings;
        const pastMeetings = meetingsArray.filter(meeting => {
          const startDate = moment.utc(meeting.start_time);
          const endDate = moment.utc();
          return startDate.isBefore(endDate);
        });

        pastMeetings.forEach(meeting => {
          this.zoomOperations_$
            .zoomDeleteMeeting({ meetingId: meeting.id })
            .then(answer => console.log('delete Meeting:', answer));
        });

        console.log(result);
      })
      .catch(reason => {
        console.error(reason);
      });
  }

  getUser(email: string) {
    return this.zoomOperations_$
      .zoomGetUser({ email })
      .then(result => {
        return result;
      })
      .catch(reason => {
        return reason;
      });
  }

  getMeetingInvitation(meetingId: string): Promise<string> {
    return this.zoomOperations_$.zoomGetMeetingInvitation({ meetingId }).then(result => {
      const meetingInvitation = result.data.response.invitation;
      return meetingInvitation;
    });
  }

  goToOutlookCalendar() {
    if (this.defaultCalendar !== mailClient.OUTLOOK) {
      this.defaultCalendar = mailClient.OUTLOOK;
    }
    this.updateDefaultCalendar(this.defaultCalendar, this.auth_$.uid);
    this.microsoftAuth_$.signIn().then(() => {
      this.createOutlookCalendarEvent();
    });
  }

  goToOtherCalendar() {
    if (this.defaultCalendar !== mailClient.OTHER) {
      this.defaultCalendar = mailClient.OTHER;
    }
    this.updateDefaultCalendar(this.defaultCalendar, this.auth_$.uid);
    this.microsoftAuth_$.signIn().then(() => {
      this.createOtherCalendarEvent();
    });
  }

  goToOffice365Calendar() {
    if (this.defaultCalendar !== mailClient.OFFICE365) {
      this.defaultCalendar = mailClient.OFFICE365;
    }
    this.updateDefaultCalendar(this.defaultCalendar, this.auth_$.uid);
    this.microsoftAuth_$.signIn().then(() => {
      this.createOffice365CalendarEvent();
    });
  }

  async localCreateMeeting() {
    const { first_name } = this.zoomUser;
    const zoomMeeting = this.zoomOperations_$.zoomMeeting;
    const { join_url, start_time, timezone } = zoomMeeting;
    const eventObject: GoogleEvent = {
      summary: environment.constants.zoomDefaults.DEFAULT_TOPIC,
      start: { dateTime: start_time, timeZone: timezone },
      end: { dateTime: start_time, timeZone: timezone },
      location: join_url,
      attendees: [],
      description: this.zoomOperations_$.createMeetingInvitation(
        zoomMeeting,
        { first_name, host_invitation: false },
        'html',
      ),
      recurrence: [],
      reminders: { useDefault: true },
    };

    this.gapiOperations_$.createGoogleCalendarEvent(eventObject).then(res => {
      if (res['htmlLink']) {
        this.gapiOperations_$.gotoUrl(res['htmlLink'], '_blank');
        window.location.reload();
      }
    });
  }

  async gapiClientLoad() {
    const gapiInitialized = await this.gapiOperations_$.initClient();
  }

  async getGoogleUserSignedStatus() {
    await this.gapiOperations_$.handleClientLoad();
    const gapiInitialized = await this.gapiOperations_$.initClient();
    if (gapiInitialized.code !== 200) {
      console.error(gapiInitialized);
    } else {
      const gapi = this.gapiOperations_$.getGapi();
      const gapiInstance = gapi.auth2.getAuthInstance();
      if (gapiInstance.isSignedIn.get()) {
        console.log('Already SIGNED IN');
        this.googleSignedIn = true;
      } else {
        console.log('NOT SIGNED IN');
        this.googleSignedIn = false;
      }
    }
  }

  async gapiSignIn() {
    await this.gapiOperations_$.handleClientLoad();
    const gapiInitialized = await this.gapiOperations_$.initClient();
    if (gapiInitialized.code !== 200) {
      console.error(gapiInitialized);
    } else {
      const gapi = this.gapiOperations_$.getGapi();
      if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
        // this.localCreateMeeting();
        console.log('Already SIGNED IN');
        this.googleSignedIn = true;
      } else {
        gapi.auth2.getAuthInstance().isSignedIn.listen(status => {
          if (status) {
            window.location.reload();
          }
        });
        gapi.auth2.getAuthInstance().signIn();
      }
    }
  }

  async gapiSignOut() {
    await this.gapiOperations_$.handleClientLoad();
    const gapiInitialized = await this.gapiOperations_$.initClient();
    if (gapiInitialized.code !== 200) {
      console.error(gapiInitialized);
    } else {
      const gapi = this.gapiOperations_$.getGapi();
      if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
        gapi.auth2.getAuthInstance().signOut();
        window.location.reload();
      } else {
        console.log('YOU ARE NOT SIGNED IN');
      }
    }
  }

  async afterGapiLoaded() {
    const gapiInitialized = await this.gapiOperations_$.initClient();
    if (gapiInitialized.code !== 200) {
      console.error(gapiInitialized);
    } else {
      this.meetingCreated = true;
      const gapi = this.gapiOperations_$.getGapi();

      if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
        this.localCreateMeeting();
      } else {
        gapi.auth2.getAuthInstance().signIn();
      }
    }
  }

  async goToGoogleCalendar() {
    const { first_name } = this.zoomUser;
    if (this.defaultCalendar !== mailClient.GOOGLE) {
      this.defaultCalendar = mailClient.GOOGLE;
    }

    this.updateDefaultCalendar(this.defaultCalendar, this.auth_$.uid);

    await this.gapiOperations_$.handleClientLoad();
    this.afterGapiLoaded();
  }

  handleListMeetings(event) {
    this.listZoomMeetings().then(answer => {
      console.log('answer list meetings', answer);
    });
  }

  hideCalendarButtons() {
    this.googleCalendarButton = false;
    this.outlookCalendarButton = false;
  }

  handleGuestsSelection(selectedOptions) {
    this.guestsList = selectedOptions.selected.map(item => item.value);
    this.selectedOptions = selectedOptions;
  }

  inviteGuestsByEmail(invitationText: string, guestsList: string[]) {
    this.zoomOperations_$.sendEmail(invitationText, guestsList);
  }

  onNgModelChange(event) {
    console.log('event: ', event);
  }

  openDefaultCalendarSetUp() {
    this.dialog.openDialogs.pop();
    this.dialog
      .open(DefaultCalendarSetUpComponent, {
        disableClose: true,
        autoFocus: true,
        maxHeight: '80vw',
        data: { defaultCalendar: this.defaultCalendar },
      })
      .afterClosed()
      .toPromise()
      .then(result => {
        switch (result) {
          case 5:
            return;
          case '':
            result = 'none';
            break;
          default:
            this.defaultCalendar = result;
            this.updateDefaultCalendar(this.defaultCalendar, this.auth_$.uid);
            break;
        }
      });
  }

  openCalendar(calendar) {
    let message, config, action;
    switch (calendar) {
      case mailClient.OFFICE365:
        this.gapiOperations_$.gotoUrl('https://office.live.com/start/Calendar.aspx', '_blank');
        break;
      case mailClient.OUTLOOK:
        message = 'Please go to the calendar of your preference and check your scheduled events.';
        action = 'IMPORTANT';
        this.uiMessaging_$.toastMessage(message, action);
        break;
      case mailClient.OTHER:
        message = 'Please go to the calendar of your preference and check your scheduled events.';
        action = 'IMPORTANT';
        this.uiMessaging_$.toastMessage(message, action);
        break;
      case mailClient.GOOGLE:
        this.gapiOperations_$.gotoUrl('https://calendar.google.com/', '_blank');
        break;
      case mailClient.CLIO:
        this.gapiOperations_$.gotoUrl('https://app.clio.com/nc/#/calendars', '_blank');
        break;
      default:
        console.log('No predefined calendar is defined.');
        break;
    }
  }
}
