import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirmation-dialog',
  template: `
    <app-window-title
      [title]="title"
      [closable]="true"></app-window-title>
    <mat-dialog-content>
      <p
        [innerHTML]="message"
        class="mat-body"></p>
    </mat-dialog-content>
    <mat-dialog-actions
      fxLayout
      fxLayoutAlign="center center"
      style="margin-bottom: 0px">
      <button
        (click)="confirm()"
        mat-flat-button
        color="warn">
        Yes
      </button>
      <button
        (click)="exit()"
        mat-flat-button
        color="warn">
        No
      </button>
    </mat-dialog-actions>
  `,
})
export class ConfirmationDialogComponent {
  title: string;
  message: string;
  confirm_action: Function;
  selected_files: [];
  deleteAllFiles: boolean;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) { title, message, confirm_action, selected_files, deleteAllFiles },
  ) {
    this.title = title;
    this.message = message;
    this.confirm_action = confirm_action;
    this.selected_files = selected_files;
    this.deleteAllFiles = deleteAllFiles;
  }

  exit() {
    this.dialogRef.close();
  }

  async confirm() {
    this.dialogRef.close({
      selectedFiles: this.selected_files,
      deleteAllFiles: this.deleteAllFiles,
    });
  }

  close() {
    this.dialogRef.close();
  }
}
