import { RedirectionService } from './../services/redirection.service';
import { LocalStorageService } from './../services/local-storage.service';
import { SimpleMessageWindowComponent } from './../components/ui/simple-message-window/simple-message-window.component';
import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRoles } from 'src/app/dictionaries/UserRoles';

import { AllusersComponent } from '../components/allusers/allusers.component';
import { AuthService } from './../services/auth.service';
import { SessionStorageService } from '../services/session-storage.service';
import { SearchComponent } from '../components/search/search.component';

@Component({
  template: `<div
    class="homepage-container"
    *ngIf="!userEnabled">
    <a
      class="logo-link"
      href="/">
      <img
        alt="NuageDX Logo"
        src="../../assets/Nuage-Logo.png"
        class="logo-image" />
    </a>
    <p class="homepage-paragraph">
      A unique application that allows you to upload files (e.g. PDF, Word, videos, JPEG, Medical images and more) from
      your desktop into folders where they are stored. You can then seamlessly share selected files with others. You can
      also choose to collaborate while simultaneously viewing the same files while in a video conference session.
      <br />
      <br />
      <a href="http://www.nuagedx.com">www.nuagedx.com</a>
    </p>
  </div> `,
  styles: [
    `
      .homepage-paragraph {
        width: 40%;
        margin: auto;
        margin-top: 40px;
        text-align: justify;
        text-align: center;

        a:link {
          color: red;
        }
      }

      .logo-image {
        width: 100%;
      }

      .homepage-container {
        margin-left: 30px;
        margin-right: 30px;
      }

      .logo-link {
        width: 300px;
        margin: auto;
        display: block;
      }
    `,
  ],
})
export class HomePageComponent implements OnInit {
  userData: {};
  userEnabled: boolean;
  loaded: boolean;

  constructor(
    private dialog: MatDialog,
    private redirection_$: RedirectionService,
    private localStorage_$: LocalStorageService,
    private sessionStorage_$: SessionStorageService,
    private auth_$: AuthService,
    public ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.loaded = false;
  }

  handleRoute() {
    if (!this.activatedRoute.snapshot.url[0]) {
      return;
    }

    let role;
    let rolesAllowed;
    let component: any = AllusersComponent;

    switch (this.activatedRoute.snapshot.url[0].path) {
      case 'consultants':
        rolesAllowed = [UserRoles.admin, UserRoles.owner];
        role = UserRoles.consultant;
        break;
      case 'owners':
        rolesAllowed = [UserRoles.superuser];
        role = UserRoles.owner;
        break;
      case 'associates':
        rolesAllowed = [UserRoles.admin, UserRoles.owner];
        role = UserRoles.associate;
        break;
      case 'admins':
        rolesAllowed = [UserRoles.owner];
        role = UserRoles.admin;
        break;
      case 'clients':
        rolesAllowed = [UserRoles.admin, UserRoles.owner, UserRoles.associate];
        role = UserRoles.client;
        break;
      case 'clientsmatters':
        rolesAllowed = [UserRoles.admin, UserRoles.owner, UserRoles.associate];
        role = this.auth_$.userData.value['role'];
        component = SearchComponent;
        break;
      case 'lpmwelcome':
        rolesAllowed = [UserRoles.owner];
        this.dialog
          .open(SimpleMessageWindowComponent, {
            width: '600px',
            data: {
              title: 'Welcome to Nuage Diagnostics',
              message: `We are excited to have you on board. Please feel free to reach out to us at any time.`,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.router.navigate(['/']);
          });
        return;
      default:
        break;
    }

    if (rolesAllowed.includes(this.auth_$.userData.value['role'])) {
      console.log('Open All Users');
      this.dialog
        .open(component, { width: '600px', data: { role } })
        .afterClosed()
        .subscribe(answer => {
          if (answer && answer.client) {
          } else {
            this.router.navigate(['']);
          }
        });
    } else {
      this.dialog
        .open(SimpleMessageWindowComponent, {
          width: '600px',
          data: {
            title: 'Access Denied',
            message: `You do not have access to this page.`,
          },
        })
        .afterClosed()
        .subscribe(() => {
          this.router.navigate(['']);
        });
      return true;
    }
  }

  ngOnInit(): void {
    this.redirection_$.checkAndRedirectQaSite();

    this.auth_$.auth.onAuthStateChanged({
      next: async user => {
        if (user) await this.yesUserAction();
        else this.noUserAction();
      },
      error: err => console.log(err),
      complete: () => console.log('complete'),
    });
  }

  goToLoginClio() {
    this.redirection_$.goToLoginClio();
  }

  async yesUserAction() {
    if (this.sessionStorage_$.getAddToClioStarted() === 'true') {
      await this.auth_$.logout();
      this.sessionStorage_$.setAddToClioStarted(true);
      return;
    }
    this.userEnabled = true;

    // If this comes from Clio Open App action, and user is not fully registered, redirect to complete registration page.
    if (this.activatedRoute.snapshot.queryParams.from === 'clio') this.fromClioAction();
    else this.notFromClioAction();
  }

  notFromClioAction() {
    const routeHandled = this.handleRoute();
    if (!routeHandled) {
      if (
        !this.auth_$.userData.getValue()['testaccount'] &&
        this.auth_$.isClioRegistered() === false &&
        this.auth_$.userData.getValue()['lpm'] === 'clio' &&
        this.auth_$.userData.getValue()['plancode'] === 'lpmp'
      ) {
        this.redirection_$.redirectToCompleteClioRegistration();
        return;
      }
    }
  }

  fromClioAction() {
    this.sessionStorage_$.setSSO('0');
    this.auth_$.userData.subscribe(userData => {
      console.log('userData :', userData);
      if (Object.keys(userData).length > 0) {
        console.log('userData :', userData);
        if (
          !this.auth_$.isClioRegistered() &&
          !userData['clioSSO'] &&
          !userData['testaccount'] &&
          this.auth_$.userData.getValue()['plancode'] === 'lpmp'
        ) {
          this.redirection_$.redirectToCompleteClioRegistration();
          return;
        } else {
          if (this.localStorage_$.getLPMWelcome()) {
            this.router.navigate(['/']);
            return;
          } else {
            this.router.navigate(['/', 'lpmwelcome']);
            this.localStorage_$.setLPMWelcome('true');
          }
        }
      } else {
        this.goToLoginClio();
      }
    });
  }

  noUserAction() {
    console.log('user not logged in');
    if (this.activatedRoute.snapshot.queryParams.from === 'clio') {
      this.goToLoginClio();
    }
  }
}
