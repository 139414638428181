import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MDCRadio } from '@material/radio';
import { MDCRipple } from '@material/ripple';
import { MDCTextField } from '@material/textfield';
import firebase from 'firebase/app';
import { UserRoles } from 'src/app/dictionaries/UserRoles';

import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-createadmin',
  templateUrl: './createadmin.component.html',
  styleUrls: ['./createadmin.component.css']
})
export class CreateadminComponent implements OnInit {
  name: string;
  email: string;
  password: string;
  checkboxAdmin: any;
  checkboxAssociate: any;
  checkboxConsultant: any;
  errorMessage: string;
  curentUser: firebase.User;
  userType: String;
  UserRoles: { owner: string; admin: string; associate: string; consultant: string; superuser: string; };
  constructor (public auths: AuthService,
    public router: Router,
    public ng: NgZone,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateadminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
    this.name = '';
    this.email = '';
    this.password = '';
    this.errorMessage = '';
    this.userType = 'a User';
  }

  ngOnInit() {
    this.auths.user.subscribe((user) => {
      if (user !== null && user !== undefined) {
        this.curentUser = user;
        const db = firebase.firestore();
        document.getElementById('nuageMenu').style.display = 'block';
        db.collection('users').where('uid', '==', user.uid).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data()['role'] === UserRoles.consultant) {
              this.ng.run(() => this.router.navigateByUrl('/'));
            }
          });
        });
      } else if (user === undefined && user === null) {
        document.getElementById('nuageMenu').style.display = 'none';
        this.router.navigateByUrl('/');
      }
    });
    const nameSignup = new MDCTextField(document.querySelector('.name-signup'));
    const emailSignup = new MDCTextField(document.querySelector('.email-signup'));
    const passwordSignup = new MDCTextField(document.querySelector('.password-signup'));
    const consultantRadio = new MDCRadio(document.querySelector('.cons-radio'));
    const adminRadio = new MDCRadio(document.querySelector('.admin-radio'));
    const associateRadio = new MDCRadio(document.querySelector('.associate-radio'));
    this.checkboxAdmin = document.getElementById('radio-admin');
    this.checkboxAssociate = document.getElementById('radio-associate');
    this.checkboxConsultant = document.getElementById('radio-consultant');
    const selector = '.mdc-button, .mdc-icon-button, .mdc-card__primary-action';
    const ripples = [].map.call(document.querySelectorAll(selector), function (el) {
      return new MDCRipple(el);
    });
    this.auths.errorAuth.subscribe((error) => {
      this.errorMessage = error;
    });
  }

  async createUser() {
    const ownerID = (await this.auths.getUserRole(this.curentUser.uid) === UserRoles.owner)
      ? this.curentUser.uid
      : await this.auths.getOwnerID(this.curentUser.uid);

    if (this.email === '' || this.name === '' || this.password === '') {
      this.errorMessage = 'Please fill in all fields';
    } else if (this.checkboxAssociate.checked) {
      const user = new User(UserRoles.associate, this.email, this.name, '', ownerID);
      return this.auths.createUserAndSendPasswordResetEmail(this.password, user, this.curentUser.uid);
    } else if (this.checkboxAdmin.checked) {
      const user = new User(UserRoles.admin, this.email, this.name, '', ownerID);
      return this.auths.createUserAndSendPasswordResetEmail(this.password, user, this.curentUser.uid);
    } else if (this.checkboxConsultant.checked) {
      const user = new User(UserRoles.consultant, this.email, this.name, '', ownerID);
      return this.auths.createUserAndSendPasswordResetEmail(this.password, user, this.curentUser.uid);
    } else {
      this.errorMessage = 'You have not selected an account type';
      return this.errorMessage;
    }

  }

  onCheckedAccount(type: String) {
    this.userType = type;
  }

  goBack() {
    window.history.back();
  }
}
