import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UIMessagingService } from 'src/app/services/uimessaging.service';

@Component({
  selector: 'app-note-dialog',
  templateUrl: './note-dialog.component.html',
  styles: [
    `
      .display-block {
        display: block;
      }
    `,
  ],
})
export class NoteDialogComponent {
  title: string;
  message: string;
  name: string;
  description: string;
  selected_file: any;
  useremail: string;
  uploadFileType: string;
  fileEntries: File[];
  basicFileModel: any;
  fileName: string;

  constructor(
    private dialogRef: MatDialogRef<NoteDialogComponent>,
    public snackBar: MatSnackBar,
    private uiMessaging_$: UIMessagingService,
    @Inject(MAT_DIALOG_DATA) { title, message, name, description, useremail, selected_file },
  ) {
    this.title = title;
    this.message = message;
    this.name = name;
    this.description = description;
    this.selected_file = selected_file;
    console.log('this.selected_file :', this.selected_file);
    this.fileName = this.selected_file.fileName;
    this.useremail = useremail;
  }

  handleFileSelection(event: Event): void {
    const entries = Array.from((<HTMLInputElement>event.target).files);
    if (!entries.length) {
      return;
    }

    this.fileEntries = entries;
  }

  browseFiles() {
    (<HTMLElement>document.querySelector(`app-input-file.file-browser input[type="file"`)).click();
  }

  confirm() {
    this.uiMessaging_$.toastMessage(`The note has been added.`, null);
    this.close({
      type: 'notesave',
      selected_file: this.selected_file,
      data: {
        title: this.title,
        message: this.message,
        description: this.description,
        name: this.name,
        useremail: this.useremail,
        attachments: this.fileEntries,
      },
    });
  }

  close(type?) {
    this.dialogRef.close(type);
  }
}
