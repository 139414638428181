<ul [ngStyle]="{ padding: 0, margin: 0, 'list-style': 'none' }">
  <li [ngClass]="['custom-folders-container']" fxflexfill="" fxlayoutgap="0px grid">
    <button mat-flat-button matTooltip="{{ item.name }}" *ngFor="let item of customFolders"
      (click)="select_folder({ folderId: item.folderId, folderName: item.name }); item.selected = true "
      [ngClass]="{ 'selected-folder': item.selected === true, 'folder-boxed-button': true, 'boxed-button-Custom': true }">
      <mat-icon class="material-icons">folder</mat-icon>
      <span class="folder-name">{{ item.name }}</span>
      <mat-icon *ngIf="item.folderId === selectedFolder" [ngClass]="'check-icon'">check</mat-icon>
    </button>
  </li>
  <li class="create-folder-row">
    <input #foldername id="foldernameValue" type="text" placeholder="Create a folder" [ngClass]="'input-text'"
      (keyup)="handleCreateFolder(foldername.value, false, customFoldersColor, $event)" />
    <button (click)="handleCreateFolder(foldername.value, false, customFoldersColor, $event); foldername.value = ''"
      [ngClass]="'arrow-button'">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <span [ngClass]="['mat-body', 'message-box']" *ngIf="validation.value">{{ validation.text(foldername.value)
      }}</span>
  </li>
</ul>
