<app-window-title
  class="subtitled"
  [title]="pricing.title"
  [closable]="data.closable"></app-window-title>
<span
  class="subtitle"
  [innerHTML]="pricing.subtitle"></span>

<div id="lastContainer">
  <div>
    <div>
      <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
        <div
          class="table-1 fusion-no-small-visibility fusion-no-medium-visibility pricing-table"
          id="pricing-table">
          <table
            class="pricing-table"
            width="100%">
            <thead>
              <tr>
                <th style="text-align: left">The ultimate in file sharing for consultations and collaborations</th>
                <th>
                  <h5 class="plan-name">Silver</h5>
                  <a
                    color="warn"
                    class="choose-plan-button"
                    mat-flat-button
                    (click)="choosePlan(pricingplans.silver, $event)"
                    >Select plan</a
                  >
                </th>
                <th>
                  <h5 class="plan-name">Gold</h5>
                  <a
                    color="warn"
                    class="choose-plan-button"
                    mat-flat-button
                    (click)="choosePlan(pricingplans.gold, $event)"
                    >Select plan</a
                  >
                </th>
                <th>
                  <h5 class="plan-name">Platinum</h5>
                  <a
                    color="warn"
                    class="choose-plan-button"
                    disabled="true"
                    mat-flat-button
                    (click)="choosePlan(pricingplans.platinum, $event)"
                    >Select plan</a
                  >
                </th>
                <th class="highlighted-plan">
                  <h5 class="plan-name">Clio Platinum</h5>
                  <a
                    color="warn"
                    class="choose-plan-button"
                    mat-flat-button
                    (click)="choosePlan(pricingplans.lpmp, $event)"
                    >Select plan</a
                  >
                </th>
                <th>
                  <div fxLayout>
                    <div fxFlex="50">
                      <h5
                        style="text-align: center; --fontSize: 15; line-height: 1; --minFontSize: 15"
                        data-fontsize="15"
                        data-lineheight="15px"
                        class="fusion-responsive-typography-calculated">
                        Enterprise Platinum
                      </h5>
                      <a
                        color="warn"
                        class="choose-plan-button"
                        mat-flat-button
                        (click)="choosePlan(pricingplans.enterpriseplatinum, $event)"
                        >Select plan</a
                      >
                    </div>
                    <div fxFlex="50">
                      <h5
                        style="text-align: center; --fontSize: 15; line-height: 1; --minFontSize: 15"
                        data-fontsize="15"
                        data-lineheight="15px"
                        class="fusion-responsive-typography-calculated">
                        Enterprise Platinum (+)
                      </h5>
                      <a
                        color="warn"
                        class="choose-plan-button"
                        mat-flat-button
                        (click)="choosePlan(pricingplans.enterpriseplatinumplus, $event)"
                        >Select plan</a
                      >
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="center">&nbsp;</td>
                <td align="center">
                  <h2
                    style="margin: 0px !important; --fontSize: 33; line-height: 1.5"
                    data-fontsize="33"
                    data-lineheight="49.5px"
                    class="fusion-responsive-typography-calculated">
                    <strong>$19.95<sup style="font-size: 0.5em">/mo</sup></strong>
                  </h2>
                </td>
                <td align="center">
                  <h2
                    style="margin: 0px !important; --fontSize: 33; line-height: 1.5"
                    data-fontsize="33"
                    data-lineheight="49.5px"
                    class="fusion-responsive-typography-calculated">
                    <strong>$24.95<sup style="font-size: 0.5em">/mo</sup></strong>
                  </h2>
                </td>
                <td align="center">
                  <h2
                    style="margin: 0px !important; --fontSize: 33; line-height: 1.5"
                    data-fontsize="33"
                    data-lineheight="49.5px"
                    class="fusion-responsive-typography-calculated">
                    <strong>$49.95<sup style="font-size: 0.5em">/mo</sup></strong>
                  </h2>
                </td>
                <td
                  class="highlighted-plan"
                  align="center">
                  <h2
                    style="margin: 0px !important; --fontSize: 33; line-height: 1.5"
                    data-fontsize="33"
                    data-lineheight="49.5px"
                    class="fusion-responsive-typography-calculated">
                    <strong>$35.95<sup style="font-size: 0.5em">/mo</sup></strong>
                  </h2>
                </td>
                <td align="center">
                  <div fxLayout>
                    <div fxFlex="50">
                      <h2
                        style="margin: 0px !important; --fontSize: 33; line-height: 1.5"
                        data-fontsize="33"
                        data-lineheight="49.5px"
                        class="fusion-responsive-typography-calculated">
                        <strong>$44.95<sup style="font-size: 0.5em">/mo</sup></strong>
                      </h2>
                    </div>
                    <div fxFlex="50">
                      <h2
                        style="margin: 0px !important; --fontSize: 33; line-height: 1.5"
                        data-fontsize="33"
                        data-lineheight="49.5px"
                        class="fusion-responsive-typography-calculated">
                        <strong>$39.95<sup style="font-size: 0.5em">/mo</sup></strong>
                      </h2>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="left">
                  Each subscriber can simultaneously share same documents with one (1) administrator and one (1)
                  associate of choice
                </td>
                <td align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  class="highlighted-plan"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
              </tr>
              <tr>
                <td align="left">Owner (subscriber) can add additional users.</td>
                <td
                  style="text-align: center"
                  align="left">
                  &nbsp;
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  class="highlighted-plan"
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
              </tr>
              <tr>
                <td align="left">Unlimited number of consultants/experts.</td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  class="highlighted-plan"
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
              </tr>
              <tr>
                <td align="left">DICOM image disc upload and Cloud storage (medical imaging studies)</td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  class="highlighted-plan"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
              </tr>
              <tr>
                <td align="left">Upload from Practice Management systems &amp; Desktop</td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  class="highlighted-plan"
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
              </tr>
              <tr>
                <td align="left">Document, JPEG, photo, illustration, diagram, video upload and cloud storage</td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  class="highlighted-plan"
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
              </tr>
              <tr>
                <td align="left">HIPAA Compliant Storage</td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  class="highlighted-plan"
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
              </tr>
              <tr>
                <td align="left">
                  Built-in Zoom video conference feature with
                  <strong>unlimited Video conference<br />time</strong> and 300 participants/session
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  class="highlighted-plan"
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
              </tr>
              <tr>
                <td align="left">Built-in DICOM viewer for medical imaging studies</td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  class="highlighted-plan"
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
              </tr>
              <tr>
                <td align="left">Video conference recording and storage</td>
                <td
                  style="text-align: center"
                  align="left">
                  &nbsp;
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  class="highlighted-plan"
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  align="left">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
              </tr>
              <tr>
                <td align="left">
                  2TB /month storage comes with plan<span
                    style="color: rgb(255, 0, 0); --darkreader-inline-color: #b63d3d"
                    data-darkreader-inline-color=""
                    ><strong>**</strong></span
                  >
                  - Unlimited storage as needed
                </td>
                <td>
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td style="text-align: center">&nbsp;</td>
                <td style="text-align: center">&nbsp;</td>
                <td
                  class="highlighted-plan"
                  style="text-align: center">
                  &nbsp;
                </td>
                <td style="text-align: center">&nbsp;</td>
              </tr>
              <tr>
                <td align="left">
                  4TB /month storage comes with plan<span
                    style="color: rgb(255, 0, 0); --darkreader-inline-color: #b63d3d"
                    data-darkreader-inline-color=""
                    ><strong>**</strong></span
                  >
                  - Unlimited storage as needed
                </td>
                <td>&nbsp;</td>
                <td style="text-align: center">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td style="text-align: center">&nbsp;</td>
                <td
                  class="highlighted-plan"
                  style="text-align: center">
                  &nbsp;
                </td>
                <td style="text-align: center">&nbsp;</td>
              </tr>
              <tr>
                <td align="left">
                  5TB /month storage comes with plan<span
                    style="color: rgb(255, 0, 0); --darkreader-inline-color: #b63d3d"
                    data-darkreader-inline-color=""
                    ><strong>**</strong></span
                  >
                  - Unlimited storage as needed
                </td>
                <td style="text-align: center">&nbsp;</td>
                <td style="text-align: center">&nbsp;</td>
                <td style="text-align: center">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  class="highlighted-plan">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td style="text-align: center">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
              </tr>
              <tr>
                <td align="left">Remote Client upload – discs and documents <br /></td>
                <td style="text-align: center">&nbsp;</td>
                <td style="text-align: center">&nbsp;</td>
                <td style="text-align: center">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td
                  style="text-align: center"
                  class="highlighted-plan">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
                <td style="text-align: center">
                  <img
                    src="./../../assets/png/check.png"
                    alt=""
                    class="aligncenter"
                    width="18"
                    height="18" />
                </td>
              </tr>
              <tr>
                <td align="left">
                  Video conference transcription (<img
                    class="alignnone small-asterisk"
                    src="./../../assets/png/blue-asterisk.png" />coming soon) – no extra charge
                </td>
                <td style="text-align: center">&nbsp;</td>
                <td style="text-align: center">&nbsp;</td>
                <td style="text-align: center">
                  <img
                    class="aligncenter big-asterisk"
                    src="./../../assets/png/blue-asterisk.png" />
                </td>
                <td
                  style="text-align: center"
                  class="highlighted-plan">
                  <img
                    class="aligncenter big-asterisk"
                    src="./../../assets/png/blue-asterisk.png" />
                </td>
                <td style="text-align: center">
                  <img
                    class="aligncenter big-asterisk"
                    src="./../../assets/png/blue-asterisk.png" />
                </td>
              </tr>
              <tr>
                <td align="left">
                  Voice recognition input (<img
                    class="alignnone small-asterisk"
                    src="./../../assets/png/blue-asterisk.png" />coming soon) – no extra charge
                </td>
                <td style="text-align: center">&nbsp;</td>
                <td style="text-align: center">&nbsp;</td>
                <td style="text-align: center">
                  <img
                    class="aligncenter big-asterisk"
                    src="./../../assets/png/blue-asterisk.png" />
                </td>
                <td
                  style="text-align: center"
                  class="highlighted-plan">
                  <img
                    class="aligncenter big-asterisk"
                    src="./../../assets/png/blue-asterisk.png" />
                </td>
                <td style="text-align: center">
                  <img
                    class="aligncenter big-asterisk"
                    src="./../../assets/png/blue-asterisk.png" />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <h5 class="plan-name">Silver</h5>
                  <a
                    color="warn"
                    class="choose-plan-button"
                    mat-flat-button
                    (click)="choosePlan(pricingplans.silver, $event)"
                    >Select plan</a
                  >
                </td>
                <td>
                  <h5 class="plan-name">Gold</h5>
                  <a
                    color="warn"
                    class="choose-plan-button"
                    mat-flat-button
                    (click)="choosePlan(pricingplans.gold, $event)"
                    >Select plan</a
                  >
                </td>
                <td>
                  <h5 class="plan-name">Platinum</h5>
                  <a
                    color="warn"
                    class="choose-plan-button"
                    mat-flat-button
                    disabled="true"
                    (click)="choosePlan(pricingplans.platinum, $event)"
                    >Select plan</a
                  >
                </td>
                <td class="highlighted-plan">
                  <h5 class="plan-name">Clio Platinum</h5>
                  <a
                    color="warn"
                    class="choose-plan-button"
                    mat-flat-button
                    (click)="choosePlan(pricingplans.lpmp, $event)"
                    >Select plan</a
                  >
                </td>
                <td>
                  <div fxLayout>
                    <div fxFlex="50">
                      <h5
                        style="text-align: center; --fontSize: 15; line-height: 1; --minFontSize: 15"
                        data-fontsize="15"
                        data-lineheight="15px"
                        class="fusion-responsive-typography-calculated">
                        Enterprise Platinum
                      </h5>
                      <a
                        color="warn"
                        class="choose-plan-button"
                        mat-flat-button
                        (click)="choosePlan(pricingplans.enterpriseplatinum, $event)"
                        >Select plan</a
                      >
                    </div>
                    <div fxFlex="50">
                      <h5
                        style="text-align: center; --fontSize: 15; line-height: 1; --minFontSize: 15"
                        data-fontsize="15"
                        data-lineheight="15px"
                        class="fusion-responsive-typography-calculated">
                        Enterprise Platinum (+)
                      </h5>
                      <a
                        color="warn"
                        class="choose-plan-button"
                        mat-flat-button
                        (click)="choosePlan(pricingplans.enterpriseplatinumplus, $event)"
                        >Select plan</a
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="fusion-text fusion-text-1"
          style="display: none">
          <div
            class="fusion-pricing-table pricing-table-1 sep-boxed-pricing row fusion-columns-4 columns-4 fusion-clearfix fusion-no-large-visibility">
            <div class="panel-wrapper fusion-column column col-lg-3 col-md-3 col-sm-3 fusion-pricingtable-column">
              <div class="panel-container">
                <div class="fusion-panel">
                  <div class="panel-heading">
                    <h3
                      class="title-row"
                      style="--fontsize: 18; line-height: 1.5; --minfontsize: 18"
                      data-fontsize="18"
                      data-lineheight="27px">
                      SILVER
                    </h3>
                  </div>
                  <div class="panel-body pricing-row">
                    <div class="price price-with-decimal">
                      <span class="currency">$</span><span class="integer-part">19</span
                      ><sup class="decimal-part">95</sup><span class="time">mo</span>
                    </div>
                  </div>
                  <ul class="list-group">
                    <li class="list-group-item normal-row">
                      Each subscriber can simultaneously share same documents with one (1) administrator and/or one (1)
                      associate of choice
                    </li>
                    <li class="list-group-item normal-row">Owner (subscriber) can add additional users.</li>
                    <li class="list-group-item normal-row">Unlimited number of consultants/experts</li>
                    <li class="list-group-item normal-row">
                      DICOM image disc upload and Cloud Storage (medical imaging studies).
                    </li>
                    <li class="list-group-item normal-row">Upload from Practice Management systems &amp; Desktop</li>
                    <li class="list-group-item normal-row">
                      Document and video (MP4, MOV, AVI) upload and Cloud storage
                    </li>
                    <li class="list-group-item normal-row">HIPAA Compliant Storage.</li>
                    <li class="list-group-item normal-row">
                      Built-in Zoom video conference feature with unlimited Video conference time and 300
                      participants/session
                    </li>
                    <li class="list-group-item normal-row">Built-in DICOM viewer for medical imaging studies</li>
                    <li class="list-group-item normal-row">Video conference recording and storage</li>
                    <li class="list-group-item normal-row">
                      2TB /month storage comes with plan** Unlimited storage as needed
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="panel-wrapper fusion-column column col-lg-3 col-md-3 col-sm-3 fusion-pricingtable-column">
              <div class="panel-container">
                <div class="fusion-panel">
                  <div class="panel-heading">
                    <h3
                      class="title-row"
                      style="--fontsize: 18; line-height: 1.5; --minfontsize: 18"
                      data-fontsize="18"
                      data-lineheight="27px">
                      GOLD
                    </h3>
                  </div>
                  <div class="panel-body pricing-row">
                    <div class="price price-with-decimal">
                      <span class="currency">$</span><span class="integer-part">24</span
                      ><sup class="decimal-part">95</sup><span class="time">mo</span>
                    </div>
                  </div>
                  <ul class="list-group">
                    <li class="list-group-item normal-row">
                      Each subscriber can simultaneously share same documents with one (1) administrator and one (1)
                      associate of choice
                    </li>
                    <li class="list-group-item normal-row">Owner (subscriber) can add additional users.</li>
                    <li class="list-group-item normal-row">Unlimited number of consultants/experts</li>
                    <li class="list-group-item normal-row">
                      DICOM image disc upload and Cloud storage (medical imaging studies)
                    </li>
                    <li class="list-group-item normal-row">Upload from Practice Management systems &amp; Desktop</li>
                    <li class="list-group-item normal-row">
                      Document and video (MP4, MOV, AVI) upload and Cloud storage
                    </li>
                    <li class="list-group-item normal-row">HIPAA Compliant Storage</li>
                    <li class="list-group-item normal-row">
                      Built-in Zoom video conference feature with unlimited Video conference time and 300
                      participants/session
                    </li>
                    <li class="list-group-item normal-row">Built-in DICOM viewer for medical imaging studies</li>
                    <li class="list-group-item normal-row">Video conference recording and storage</li>
                    <li class="list-group-item normal-row">
                      4TB /month storage comes with plan** Unlimited storage as needed
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="panel-wrapper fusion-column column col-lg-3 col-md-3 col-sm-3 fusion-pricingtable-column">
              <div class="panel-container">
                <div class="fusion-panel">
                  <div class="panel-heading">
                    <h3
                      class="title-row"
                      style="--fontsize: 18; line-height: 1.5; --minfontsize: 18"
                      data-fontsize="18"
                      data-lineheight="27px">
                      PLATINUM
                    </h3>
                  </div>
                  <div class="panel-body pricing-row">
                    <div class="price price-with-decimal">
                      <span class="currency">$</span><span class="integer-part">49</span
                      ><sup class="decimal-part">95</sup><span class="time">mo</span>
                    </div>
                  </div>
                  <ul class="list-group">
                    <li class="list-group-item normal-row">
                      Each subscriber can simultaneously share same documents with one (1) administrator and one (1)
                      associate of choice
                    </li>
                    <li class="list-group-item normal-row">Owner (subscriber) can add additional users.</li>
                    <li class="list-group-item normal-row">Unlimited number of consultants/experts</li>
                    <li class="list-group-item normal-row">
                      DICOM image disc upload and Cloud storage (medical imaging studies)
                    </li>
                    <li class="list-group-item normal-row">Upload from Practice Management systems &amp; Desktop</li>
                    <li class="list-group-item normal-row">
                      Document and video (MP4, MOV, AVI) upload and Cloud storage
                    </li>
                    <li class="list-group-item normal-row">HIPAA Compliant Storage</li>
                    <li class="list-group-item normal-row">
                      Built-in Zoom video conference feature with unlimited Video conference time and 300
                      participants/session
                    </li>
                    <li class="list-group-item normal-row">Built-in DICOM viewer for medical imaging studies</li>
                    <li class="list-group-item normal-row">Video conference recording and storage</li>
                    <li class="list-group-item normal-row">
                      5TB /month storage comes with plan** Unlimited storage as needed
                    </li>
                    <li class="list-group-item normal-row">Remote Client upload – discs and documents</li>
                    <li class="list-group-item normal-row">
                      Video conference transcription (*coming soon) – no extra charge
                    </li>
                    <li class="list-group-item normal-row">Voice recognition (*coming soon) – no extra charge</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="panel-wrapper fusion-column column col-lg-3 col-md-3 col-sm-3 fusion-pricingtable-column">
              <div class="panel-container">
                <div class="fusion-panel">
                  <div class="panel-heading">
                    <h3
                      class="title-row"
                      style="--fontsize: 18; line-height: 1.5; --minfontsize: 18"
                      data-fontsize="18"
                      data-lineheight="27px">
                      Enterprise Platinum and Enterprise Platinum Plus (+)
                    </h3>
                  </div>
                  <div class="panel-body pricing-row">
                    <div class="price price-with-decimal">
                      <span class="price-unit"
                        ><span class="currency">$</span><span class="integer-part">44</span
                        ><sup class="decimal-part">95</sup><span class="time">mo</span></span
                      ><span class="price-unit"
                        ><span class="currency">$</span><span class="integer-part">39</span
                        ><sup class="decimal-part">95</sup><span class="time">mo</span></span
                      >
                    </div>
                  </div>
                  <ul class="list-group">
                    <li class="list-group-item normal-row">
                      Each subscriber can simultaneously share same documents with one (1) administrator and one (1)
                      associate of choice
                    </li>
                    <li class="list-group-item normal-row">Owner (subscriber) can add additional users.</li>
                    <li class="list-group-item normal-row">Unlimited number of consultants/experts</li>
                    <li class="list-group-item normal-row">
                      DICOM image disc upload and Cloud storage (medical imaging studies)
                    </li>
                    <li class="list-group-item normal-row">Upload from Practice Management systems &amp; Desktop</li>
                    <li class="list-group-item normal-row">
                      Document and video (MP4, MOV, AVI) upload and Cloud storage
                    </li>
                    <li class="list-group-item normal-row">HIPAA Compliant Storage</li>
                    <li class="list-group-item normal-row">
                      Built-in Zoom video conference feature with unlimited Video conference time and 300
                      participants/session
                    </li>
                    <li class="list-group-item normal-row">Built-in DICOM viewer for medical imaging studies</li>
                    <li class="list-group-item normal-row">Video conference recording and storage</li>
                    <li class="list-group-item normal-row">
                      5TB /month storage comes with plan** Unlimited storage as needed
                    </li>
                    <li class="list-group-item normal-row">Remote Client upload – discs and documents</li>
                    <li class="list-group-item normal-row">
                      Video conference transcription (*coming soon) – no extra charge
                    </li>
                    <li class="list-group-item normal-row">Voice recognition (*coming soon) – no extra charge</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style="display: none"
          class="table-1">
          <div class="table-1">
            <div class="table-1">
              <div class="table-1">
                <div class="table-1">
                  <h3
                    class="title-row"
                    style="--fontsize: 18; line-height: 1.5; --minfontsize: 18"
                    data-fontsize="18"
                    data-lineheight="27px">
                    SILVER
                  </h3>
                </div>
                <div class="table-1">
                  <div class="table-1">
                    <span class="currency">$</span><span class="integer-part">19</span><sup class="decimal-part">95</sup
                    ><span class="time">mo</span>
                  </div>
                </div>
                <ul class="list-group">
                  <li class="list-group-item normal-row">
                    Each subscriber can simultaneously share same documents with one (1) administrator and/or one (1)
                    associate of choice
                  </li>
                  <li class="list-group-item normal-row">Owner (subscriber) can add additional users.</li>
                  <li class="list-group-item normal-row">Unlimited number of consultants/experts</li>
                  <li class="list-group-item normal-row">
                    DICOM image disc upload and Cloud Storage (medical imaging studies).
                  </li>
                  <li class="list-group-item normal-row">Upload from Practice Management systems &amp; Desktop</li>
                  <li class="list-group-item normal-row">
                    Document and video (MP4, MOV, AVI) upload and Cloud storage
                  </li>
                  <li class="list-group-item normal-row">HIPAA Compliant Storage.</li>
                  <li class="list-group-item normal-row">
                    Built-in Zoom video conference feature with unlimited Video conference time and 300
                    participants/session
                  </li>
                  <li class="list-group-item normal-row">Built-in DICOM viewer for medical imaging studies</li>
                  <li class="list-group-item normal-row">Video conference recording and storage</li>
                  <li class="list-group-item normal-row">
                    2TB /month storage comes with plan** Unlimited storage as needed
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="table-1">
            <div class="table-1">
              <div class="table-1">
                <div class="table-1">
                  <h3
                    class="title-row"
                    style="--fontsize: 18; line-height: 1.5; --minfontsize: 18"
                    data-fontsize="18"
                    data-lineheight="27px">
                    GOLD
                  </h3>
                </div>
                <div class="table-1">
                  <div class="table-1">
                    <span class="currency">$</span><span class="integer-part">24</span><sup class="decimal-part">95</sup
                    ><span class="time">mo</span>
                  </div>
                </div>
                <ul class="list-group">
                  <li class="list-group-item normal-row">
                    Each subscriber can simultaneously share same documents with one (1) administrator and one (1)
                    associate of choice
                  </li>
                  <li class="list-group-item normal-row">Owner (subscriber) can add additional users.</li>
                  <li class="list-group-item normal-row">Unlimited number of consultants/experts</li>
                  <li class="list-group-item normal-row">
                    DICOM image disc upload and Cloud storage (medical imaging studies)
                  </li>
                  <li class="list-group-item normal-row">Upload from Practice Management systems &amp; Desktop</li>
                  <li class="list-group-item normal-row">
                    Document and video (MP4, MOV, AVI) upload and Cloud storage
                  </li>
                  <li class="list-group-item normal-row">HIPAA Compliant Storage</li>
                  <li class="list-group-item normal-row">
                    Built-in Zoom video conference feature with unlimited Video conference time and 300
                    participants/session
                  </li>
                  <li class="list-group-item normal-row">Built-in DICOM viewer for medical imaging studies</li>
                  <li class="list-group-item normal-row">Video conference recording and storage</li>
                  <li class="list-group-item normal-row">
                    4TB /month storage comes with plan** Unlimited storage as needed
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="table-1">
            <div class="table-1">
              <div class="table-1">
                <div class="table-1">
                  <h3
                    class="title-row"
                    style="--fontsize: 18; line-height: 1.5; --minfontsize: 18"
                    data-fontsize="18"
                    data-lineheight="27px">
                    PLATINUM
                  </h3>
                </div>
                <div class="table-1">
                  <div class="table-1">
                    <span class="currency">$</span><span class="integer-part">49</span><sup class="decimal-part">95</sup
                    ><span class="time">mo</span>
                  </div>
                </div>
                <ul class="list-group">
                  <li class="list-group-item normal-row">
                    Each subscriber can simultaneously share same documents with one (1) administrator and one (1)
                    associate of choice
                  </li>
                  <li class="list-group-item normal-row">Owner (subscriber) can add additional users.</li>
                  <li class="list-group-item normal-row">Unlimited number of consultants/experts</li>
                  <li class="list-group-item normal-row">
                    DICOM image disc upload and Cloud storage (medical imaging studies)
                  </li>
                  <li class="list-group-item normal-row">Upload from Practice Management systems &amp; Desktop</li>
                  <li class="list-group-item normal-row">
                    Document and video (MP4, MOV, AVI) upload and Cloud storage
                  </li>
                  <li class="list-group-item normal-row">HIPAA Compliant Storage</li>
                  <li class="list-group-item normal-row">
                    Built-in Zoom video conference feature with unlimited Video conference time and 300
                    participants/session
                  </li>
                  <li class="list-group-item normal-row">Built-in DICOM viewer for medical imaging studies</li>
                  <li class="list-group-item normal-row">Video conference recording and storage</li>
                  <li class="list-group-item normal-row">
                    5TB /month storage comes with plan** Unlimited storage as needed
                  </li>
                  <li class="list-group-item normal-row">Remote Client upload – discs and documents</li>
                  <li class="list-group-item normal-row">
                    Video conference transcription (*coming soon) – no extra charge
                  </li>
                  <li class="list-group-item normal-row">Voice recognition (*coming soon) – no extra charge</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="table-1">
            <div class="table-1">
              <div class="table-1">
                <div class="table-1">
                  <h3
                    class="title-row"
                    style="--fontsize: 18; line-height: 1.5; --minfontsize: 18"
                    data-fontsize="18"
                    data-lineheight="27px">
                    Enterprise Platinum and Enterprise Platinum Plus (+)
                  </h3>
                </div>
                <div class="table-1">
                  <div class="table-1">
                    <span class="currency">$</span><span class="integer-part">44</span
                    ><sup class="decimal-part">95-39</sup><span class="time">mo</span>
                  </div>
                </div>
                <ul class="list-group">
                  <li class="list-group-item normal-row">
                    Each subscriber can simultaneously share same documents with one (1) administrator and one (1)
                    associate of choice
                  </li>
                  <li class="list-group-item normal-row">Owner (subscriber) can add additional users.</li>
                  <li class="list-group-item normal-row">Unlimited number of consultants/experts</li>
                  <li class="list-group-item normal-row">
                    DICOM image disc upload and Cloud storage (medical imaging studies)
                  </li>
                  <li class="list-group-item normal-row">Upload from Practice Management systems &amp; Desktop</li>
                  <li class="list-group-item normal-row">
                    Document and video (MP4, MOV, AVI) upload and Cloud storage
                  </li>
                  <li class="list-group-item normal-row">HIPAA Compliant Storage</li>
                  <li class="list-group-item normal-row">
                    Built-in Zoom video conference feature with unlimited Video conference time and 300
                    participants/session
                  </li>
                  <li class="list-group-item normal-row">Built-in DICOM viewer for medical imaging studies</li>
                  <li class="list-group-item normal-row">Video conference recording and storage</li>
                  <li class="list-group-item normal-row">
                    5TB /month storage comes with plan** Unlimited storage as needed
                  </li>
                  <li class="list-group-item normal-row">Remote Client upload – discs and documents</li>
                  <li class="list-group-item normal-row">
                    Video conference transcription (*coming soon) – no extra charge
                  </li>
                  <li class="list-group-item normal-row">Voice recognition (*coming soon) – no extra charge</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          class="fusion-text fusion-text-2 md-text-align-center pricing-text"
          style="--awb-font-size: 15px; --awb-line-height: 18px; --awb-text-transform: none; display: none">
          <h4
            class="fusion-responsive-typography-calculated"
            style="
              text-align: left;
              --fontsize: 19;
              line-height: 1.5;
              --minfontsize: 19;
              --fontSize: 19;
              --minFontSize: 19;
            "
            data-fontsize="19"
            data-lineheight="28.5px">
            <span
              style="color: rgb(255, 0, 0); --darkreader-inline-color: #b63d3d"
              data-darkreader-inline-color=""
              >1st year Introductory offer</span
            >
          </h4>
          <ul style="text-align: left">
            <li>
              1<sup>st</sup> month no charge with Silver, Gold, Platinum, Enterprise Platinum and Enterprise
              Platinum-Plus subscription with 30-day trial period 1<sup>st</sup> year.
            </li>
          </ul>
          <h4
            class="fusion-responsive-typography-calculated"
            style="--fontsize: 19; --minfontsize: 19; --fontSize: 19; line-height: 1.5; --minFontSize: 19"
            data-fontsize="19"
            data-lineheight="28.5px">
            <span
              style="color: rgb(255, 0, 0); --darkreader-inline-color: #b63d3d"
              data-darkreader-inline-color=""
              >Silver $19.95/month with yearly renewable subscription</span
            >
          </h4>
          <ul>
            <li>
              <p style="padding-left: 0px; display: inline !important">
                <span
                  style="color: rgb(102, 102, 102); --darkreader-inline-color: #60686c"
                  data-darkreader-inline-color=""
                  >DICOM </span
                ><span
                  style="color: rgb(102, 102, 102); --darkreader-inline-color: #60686c"
                  data-darkreader-inline-color=""
                  >image disc upload and Cloud Storage.</span
                >
              </p>
            </li>
            <li>
              <span
                style="color: rgb(102, 102, 102); --darkreader-inline-color: #60686c"
                data-darkreader-inline-color=""
                >Each subscriber simultaneously shares same documents with (1) administrator and/or (1) associate</span
              >
            </li>
            <li>Unlimited number of consultants &amp; experts.</li>
            <li>Upload from Practice Management systems &amp; Desktop.</li>
            <li>Document, JPEG, photo, illustration, diagram, video upload and cloud storage.</li>
            <li>HIPAA Compliant Storage.</li>
            <li>Unlimited Zoom Video conference time /session.</li>
            <li>300 participants/session.</li>
            <li>Unlimited storage as needed.</li>
            <li>
              2TB storage/month<span
                style="color: rgb(255, 0, 0); --darkreader-inline-color: #b63d3d"
                data-darkreader-inline-color=""
                >**</span
              >
              (overage @ $0.20/GB/month).
            </li>
            <li>Built-in DICOM viewer for medical imaging studies.</li>
          </ul>
          <h4
            class="fusion-responsive-typography-calculated"
            style="--fontsize: 19; --minfontsize: 19; --fontSize: 19; line-height: 1.5; --minFontSize: 19"
            data-fontsize="19"
            data-lineheight="28.5px">
            <span
              style="color: rgb(255, 0, 0); --darkreader-inline-color: #b63d3d"
              data-darkreader-inline-color=""
              >Gold $24.95/month with yearly renewable subscription</span
            >
          </h4>
          <p style="padding-left: 40px">
            All the benefits of<i> </i>Silver plus<span
              style="
                font-family: ProximaNova-Regular, Arial, sans-serif;
                background-color: rgba(255, 255, 255, 0);
                font-style: var(--body_typography-font-style, normal);
                letter-spacing: var(--body_typography-letter-spacing);
                --darkreader-inline-bgcolor: rgba(220, 218, 215, 0);
              "
              data-darkreader-inline-bgcolor=""
              >:</span
            >
          </p>
          <ul>
            <li>
              <span
                style="color: rgb(92, 92, 92); --darkreader-inline-color: #596164"
                data-darkreader-inline-color=""
                >Add one additional user (associate or administrator).</span
              >
            </li>
            <li>
              <span
                style="color: rgb(92, 92, 92); --darkreader-inline-color: #596164"
                data-darkreader-inline-color=""
                >4TB storage/month<span
                  style="color: rgb(255, 0, 0); --darkreader-inline-color: #b63d3d"
                  data-darkreader-inline-color=""
                  >**</span
                >
                (overage @ $0.20/GB/month).</span
              >
            </li>
            <li>
              <span
                style="color: rgb(92, 92, 92); --darkreader-inline-color: #596164"
                data-darkreader-inline-color=""
                >Video conference recording and storage.</span
              >
            </li>
          </ul>
          <h4
            class="fusion-responsive-typography-calculated"
            style="
              --fontsize: 19;
              --minfontsize: 19;
              padding-left: 0px;
              --fontSize: 19;
              line-height: 1.5;
              --minFontSize: 19;
            "
            data-fontsize="19"
            data-lineheight="28.5px">
            <span
              style="color: rgb(255, 0, 0); --darkreader-inline-color: #b63d3d"
              data-darkreader-inline-color=""
              >Platinum $49.95/month with yearly renewable subscription</span
            >
          </h4>
          <p style="padding-left: 40px">
            All the benefits of Gold plus:<span
              style="color: rgb(0, 0, 0); --darkreader-inline-color: #181a1b"
              data-darkreader-inline-color=""
              ><br />
            </span>
          </p>
          <ul>
            <li>Add UNLIMITED additional users (associates and/or administrators).</li>
            <li>
              5TB storage/month<span
                style="color: rgb(255, 0, 0); --darkreader-inline-color: #b63d3d"
                data-darkreader-inline-color=""
                >**</span
              >
              (overage @ $0.15/GB/month).
            </li>
            <li>
              Remote Client upload for documents and discs<span
                style="color: rgb(0, 154, 201); --darkreader-inline-color: #3d7a8d"
                data-darkreader-inline-color=""
                >*</span
              >.
            </li>
            <li>
              Voice recognition input<span
                style="color: rgb(0, 154, 201); --darkreader-inline-color: #3d7a8d"
                data-darkreader-inline-color=""
                >*</span
              >
              (available soon at no extra charge).
            </li>
            <li>
              Zoom video conference transcription<span
                style="color: rgb(0, 154, 201); --darkreader-inline-color: #3d7a8d"
                data-darkreader-inline-color=""
                >*</span
              >
              (available soon at no extra charge).
            </li>
          </ul>
          <div>
            <h4
              class=""
              style="--fontsize: 19; line-height: 1.5; --minfontsize: 19"
              data-fontsize="19"
              data-lineheight="28.5px">
              <span
                style="color: rgb(255, 0, 0); --darkreader-inline-color: #b63d3d"
                data-darkreader-inline-color=""
                >Enterprise Platinum $44.95/ month with prepaid 1-year subscription and yearly renewable
                subscription</span
              >
            </h4>
            <p style="padding-left: 40px">All the benefits of Platinum.</p>
            <p style="text-align: left; padding-left: 400px">Or</p>
            <h4
              class=""
              style="--fontsize: 19; line-height: 1.5; --minfontsize: 19"
              data-fontsize="19"
              data-lineheight="28.5px">
              <span
                style="color: rgb(255, 0, 0); --darkreader-inline-color: #b63d3d"
                data-darkreader-inline-color=""
                >Enterprise Platinum-Plus $39.95/ month with prepaid 3-year subscription and 3-year renewable
                subscription</span
              >
            </h4>
            <p style="padding-left: 40px">All the benefits of Platinum.</p>
          </div>
          <hr />
          <p>
            <span
              style="color: rgb(255, 12, 0); font-size: 20px; --darkreader-inline-color: #b6423d"
              data-fusion-font="true"
              data-darkreader-inline-color=""
              ><strong
                style="color: red; --darkreader-inline-color: #b63d3d"
                data-darkreader-inline-color=""
                >**</strong
              ></span
            ><span
              style="
                color: rgb(102, 102, 102);
                font-size: 15px;
                background-color: rgba(255, 255, 255, 0);
                font-style: var(--body_typography-font-style, normal);
                font-weight: var(--body_typography-font-weight);
                letter-spacing: var(--body_typography-letter-spacing);
                --darkreader-inline-color: #60686c;
                --darkreader-inline-bgcolor: rgba(220, 218, 215, 0);
              "
              data-darkreader-inline-color=""
              data-darkreader-inline-bgcolor=""
              >Cumulative storage/month for all documents, images, recordings, and video is included in your monthly
              subscription pricing. You will incur an extra fee based on the accumulated storage above your subscription
              level allocation (overage).</span
            >
          </p>
        </div>
        <div
          class="fusion-builder-row fusion-builder-row-inner fusion-row fusion-flex-align-items-flex-start"
          style="
            width: 104% !important;
            max-width: 104% !important;
            margin-left: calc(-4% / 2);
            margin-right: calc(-4% / 2);
            display: none;
          ">
          <div
            class="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-0 fusion_builder_column_inner_1_3 1_3 fusion-flex-column"
            style="
              --awb-bg-size: cover;
              --awb-width-large: 33.333333333333%;
              --awb-margin-top-large: 0px;
              --awb-spacing-right-large: 5.76%;
              --awb-margin-bottom-large: 20px;
              --awb-spacing-left-large: 5.76%;
              --awb-width-medium: 100%;
              --awb-order-medium: 0;
              --awb-spacing-right-medium: 1.92%;
              --awb-spacing-left-medium: 1.92%;
              --awb-width-small: 100%;
              --awb-order-small: 0;
              --awb-spacing-right-small: 1.92%;
              --awb-spacing-left-small: 1.92%;
            ">
            <div
              class="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column"></div>
          </div>
          <div
            class="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-1 fusion_builder_column_inner_1_3 1_3 fusion-flex-column"
            style="
              --awb-bg-size: cover;
              --awb-width-large: 33.333333333333%;
              --awb-margin-top-large: 0px;
              --awb-spacing-right-large: 5.76%;
              --awb-margin-bottom-large: 20px;
              --awb-spacing-left-large: 5.76%;
              --awb-width-medium: 100%;
              --awb-order-medium: 0;
              --awb-spacing-right-medium: 1.92%;
              --awb-spacing-left-medium: 1.92%;
              --awb-width-small: 100%;
              --awb-order-small: 0;
              --awb-spacing-right-small: 1.92%;
              --awb-spacing-left-small: 1.92%;
            ">
            <div
              class="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column"></div>
          </div>
          <div
            class="fusion-layout-column fusion_builder_column_inner fusion-builder-nested-column-2 fusion_builder_column_inner_1_3 1_3 fusion-flex-column"
            style="
              --awb-bg-size: cover;
              --awb-width-large: 33.333333333333%;
              --awb-margin-top-large: 0px;
              --awb-spacing-right-large: 5.76%;
              --awb-margin-bottom-large: 20px;
              --awb-spacing-left-large: 5.76%;
              --awb-width-medium: 100%;
              --awb-order-medium: 0;
              --awb-spacing-right-medium: 1.92%;
              --awb-spacing-left-medium: 1.92%;
              --awb-width-small: 100%;
              --awb-order-small: 0;
              --awb-spacing-right-small: 1.92%;
              --awb-spacing-left-small: 1.92%;
            ">
            <div
              class="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
