<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close color="accent">X</button>
</mat-dialog-actions>

<h1 mat-dialog-title>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
    <div>
      <span class="mat-line mat-small">Shared files with consultant:</span>
      <div class="mat-line">{{ data.currentUser.name }}</div>
      <div class="mat-line mat-small">
        {{ data.currentUser.email }}
      </div>
    </div>
    <div>
      <span class="mat-h4 mat-line">Client name:</span>
      <div class="mat-line red-text">{{ data.casename }}</div>
    </div>
  </div>
</h1>

<div mat-dialog-content style="margin: auto">
  <div style="margin: 10px" *ngIf="data.currentUser">
    <mat-divider></mat-divider>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="gappx">
      <div
        class="mat-elevation-z0 stickyheader-table-container"
        style="width: 100%"
      >
        <table
          mat-table
          matSort
          matSortActive="fdate"
          matSortDirection="desc"
          matSortDisableClear
          [dataSource]="filesTableDataSource"
          class="mat-elevation-z1"
          style="width: 100%"
        >
          <!-- File Name Column -->
          <ng-container matColumnDef="fileName">
            <th mat-header-cell *matHeaderCellDef>File name</th>
            <td mat-cell *matCellDef="let element">
              <a
                href="{{ element.URL }}>"
                *ngIf="!element.viewerurl"
                attr.data-storename="{{ element.storename }}"
                (click)="handleFileClick($event, element)"
                target="_blank_"
                download
                >{{ element.fileName }}</a
              >
              <button
                [ngClass]="{ 'view-link': true }"
                (click)="handleFileClick($event, element)"
                *ngIf="element.viewerurl"
              >
                <mat-icon aria-hidden="false" aria-label="preview icon"
                  >preview</mat-icon
                >
                {{ element.fileName }}
              </button>
            </td>
          </ng-container>

          <!-- File Description Column -->
          <ng-container matColumnDef="fileDesc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              File content
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.fileDesc }}
            </td>
          </ng-container>

          <!-- Date of File Column -->
          <ng-container matColumnDef="fdate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Date of File
            </th>
            <td mat-cell *matCellDef="let element">{{ element.fdate }}</td>
          </ng-container>

          <!-- FilePath Column -->
          <ng-container matColumnDef="isShared">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Share action
            </th>
            <td mat-cell *matCellDef="let element">
              <button
                class="red-background white-text"
                (click)="unShareWithMe($event, element)"
                *ngIf="element.isShared"
              >
                UnShare
              </button>

              <button
                *ngIf="!element.isShared"
                (click)="shareWithMe($event, element)"
              >
                Share
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="selection.toggle(row)"
          ></tr>
        </table>
      </div>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="column"></div>
  </div>
</div>

<div class="mdc-dialog__scrim"></div>
