import { HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase';

import { environment } from '../../environments/environment';
import { ClioAccessTokenDialogComponent } from '../components/clio/clio-access-token-dialog/clio-access-token-dialog.component';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ClioService {
  constructor(private dialog: MatDialog) {}

  handleClioGetAccessToken(dialog: MatDialog, auth_$: AuthService, redirectToClioAuthorize: () => void) {
    console.log('ClioService handleClioGetAccessToken');
    dialog
      .open(ClioAccessTokenDialogComponent, {
        width: '500px',
        data: {
          origin: 'updateDefaultCalendar',
          message: 'Your default calendar now is CLIO, but you need to Authorize its usage first.',
        },
      })
      .afterClosed()
      .subscribe(origin => {
        if (origin === 'updateDefaultCalendar') {
          firebase
            .firestore()
            .collection('users')
            .doc(auth_$.userData.value['id'])
            .update({ lastSession: { origin }, client: 'clio' })
            .then(() => {
              if (
                !auth_$.userData['clioAccessToken'] ||
                !JSON.parse(auth_$.userData['clioAccessToken'])['access_token']
              ) {
                redirectToClioAuthorize();
              } else {
                console.log('clioAccessToken: No need to get Authorization Code');
              }
            })
            .catch(err => console.log('err :', err));
          // await this.updateUserDefaultCalendar(predefinedCalendar);
        }
      });
  }

  async handleGetClioAuthorization(dialogConfig?, clioAccessToken?) {
    this.dialog
      .open(ClioAccessTokenDialogComponent, {
        width: dialogConfig.width || '500px',
        closeOnNavigation: dialogConfig.closeOnNavigation || true,
        disableClose: dialogConfig.disableClose || false,
        data: {
          closable: dialogConfig.data.closable,
          origin: dialogConfig.data.origin || 'updateDefaultCalendar',
          message:
            dialogConfig.data.message ||
            'Your default calendar now is CLIO, but you need to Authorize its usage first.',
        },
      })
      .afterClosed()
      .subscribe(async (origin: string) => {
        if (origin === 'updateDefaultCalendar') {
          firebase
            .firestore()
            .collection('users')
            .doc(dialogConfig.data.userdocid)
            .update({ lastSession: { origin }, client: 'clio' })
            .then(() => {
              if (!clioAccessToken || !JSON.parse(clioAccessToken)['access_token']) {
                this.redirectToClioAuthorize();
              } else {
                console.log('clioAccessToken: No need to get Authorization Code');
              }
            })
            .catch(err => console.log('err :', err));
        }
        if (origin === 'afterLogin') {
          firebase
            .firestore()
            .collection('users')
            .doc(dialogConfig.data.userdocid)
            .update({ lastSession: { origin } })
            .then(() => this.redirectToClioAuthorize())
            .catch(err => console.log('err :', err));
        }
      });
  }

  redirectToClioAuthorize() {
    const params = new HttpParams()
      .set('response_type', 'code')
      .set('client_id', environment.config.clio.client_id)
      .set('client_secret', environment.config.clio.client_secret)
      .set('redirect_uri', environment.config.clio.redirectsGroup.clientProfile);
    const request = new HttpRequest('GET', environment.config.clio.authorizeURL, null, { params });
    console.log('request.urlWithParams: ', request.urlWithParams);
    window.location.href = request.urlWithParams;
  }

  redirectToAuthorize(callbackUrl: string) {
    const params = new HttpParams()
      .set('response_type', 'code')
      .set('client_id', environment.config.clio.client_id)
      .set('client_secret', environment.config.clio.client_secret)
      .set('redirect_uri', callbackUrl);
    const request = new HttpRequest('GET', environment.config.clio.authorizeURL, null, { params });
    window.location.href = request.urlWithParams;
  }

  validateClioAccessToken(clioAccessToken) {
    return clioAccessToken ? JSON.parse(clioAccessToken)['access_token'] : false;
  }

  public completeAddToClioAction() {
    window.location.href = environment.config.clio.SSO.appIntegrationsCallBackURL;
  }

  public clioGetAuthorizationCodeV3({ userdocid, code, redirect_uri }) {
    console.log('clio.service:clioGetAuthorizationCodeV3:127');
    return firebase.functions().httpsCallable('clio-getAuthorizationCodeV3')({ userdocid, code, redirect_uri });
  }

  updateClioSSO(value, userdocid) {
    return firebase.functions().httpsCallable('clio-updateSSO')({ value, userdocid });
  }

  getAuthorizationCode({ userdocid, code, redirect_uri }) {
    return firebase.functions().httpsCallable('clio-getAuthorizationCode')({ userdocid, code, redirect_uri });
  }
}
