import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MDCTextField } from '@material/textfield';
import { MDCTextFieldHelperText } from '@material/textfield/helper-text';
import firebase from 'firebase/app';
import moment from 'moment';
import { UserRoles } from 'src/app/dictionaries/UserRoles';

import { AuthService } from '../../services/auth.service';
import { UIMessagingService } from '../../services/uimessaging.service';

const dbUsers = firebase.firestore().collection('users');
@Component({
  selector: 'app-createpatient',
  templateUrl: './createpatient.component.html',
  styleUrls: ['./createpatient.component.css'],
})
export class CreatepatientComponent implements OnInit {
  fname: string;
  lname: string;
  patientId: string;
  legalCaseId: string;
  errorMessage: string;
  date: FormControl = new FormControl();
  ownerID: any;
  UserRoles: { owner: string; admin: string; associate: string; consultant: string; superuser: string };
  constructor(
    public auths: AuthService,
    public router: Router,
    public ng: NgZone,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreatepatientComponent>,
    private uiMessaging_$: UIMessagingService,
  ) {
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
    this.fname = '';
    this.lname = '';
    this.patientId = '';
    this.legalCaseId = '';
    this.errorMessage = '';
  }

  async ngOnInit() {
    this.authInit();
    this.initializeComponents();

    this.auths.errorAuth.subscribe(error => {
      this.errorMessage = error;
    });

    const currentUserUID = this.auths.currentUser.value.uid;
    const userobj = await dbUsers
      .where('uid', '==', currentUserUID)
      .limit(1)
      .get()
      .then(item => ({
        role: item.docs[0].data().role,
        createdBy: item.docs[0].data().createdBy,
        ownerID: item.docs[0].data().ownerID,
      }));
    if (userobj.role === UserRoles.owner) {
      this.ownerID = currentUserUID;
    } else if (userobj.ownerID) {
      this.ownerID = userobj.ownerID;
    } else {
      const message = `The current user has an invalid ownerID, please contact your administrator before retry`;
      const label = 'ALERT';
      this.uiMessaging_$.toastMessage(message, label);
    }
  }

  initializeComponents() {
    const firstName = new MDCTextField(document.querySelector('.fname'));
    const lastName = new MDCTextField(document.querySelector('.lname'));
    const patientIdQuery = new MDCTextField(document.querySelector('.patientID'));
    const helperText = new MDCTextFieldHelperText(document.querySelector('.mdc-text-field-helper-text'));
    const legalId = new MDCTextField(document.querySelector('.lcase'));
  }

  authInit() {
    this.auths.user.subscribe(user => {
      if (user !== null && user !== undefined) {
        const db = firebase.firestore();

        db.collection('users')
          .where('uid', '==', user.uid)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              if (doc.data()['role'] !== UserRoles.client && doc.data()['role'] !== UserRoles.admin) {
                this.determineRoute(doc.data()['role'], user);
              }
            });
          });
      }
    });
  }

  cleanBlankSpaces(str: string) {
    return str.replace(/[^(A-Za-z0-9)]/g, '-');
  }

  createPatient() {
    const dateValue = moment(this.date.value).add(1, 'M').toDate();
    const dateString = `${dateValue.getMonth().toString()}/${dateValue.getDate().toString()}/${dateValue
      .getFullYear()
      .toString()}`;
    if (this.fname.trim() === '' || this.lname.trim() === '' || this.cleanBlankSpaces(this.patientId) === '') {
      this.auths.firePatientCantAdd();
    } else {
      this.auths.createPatient(
        this.fname.toLowerCase(),
        this.lname.toLowerCase(),
        this.cleanBlankSpaces(this.patientId),
        this.cleanBlankSpaces(this.legalCaseId),
        dateString,
        this.ownerID,
      );
    }
  }

  determineRoute(role: any, user: any) {
    if (role === UserRoles.consultant) {
      this.ng.run(() => this.router.navigateByUrl(`search/${user.uid}/${user.email}/${role}`));
    } else {
      this.ng.run(() => this.router.navigateByUrl('/'));
    }
  }

  goBack() {
    window.history.back();
  }
}
