import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { CustomActions } from '../dictionaries/CustomActions';
import { AuthService } from './auth.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private sessionStorage_$: SessionStorageService, private auth_$: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const customActions = [
      CustomActions.uploadDicomDisk,
      CustomActions.openClioMatter,
      CustomActions.viewMedicalImages,
      CustomActions.meetingsScheduling,
      CustomActions.openClioDocument,
      CustomActions.openClioMatterFromDocument,
      CustomActions.goToNuageDx,
    ];
    if (customActions.includes(route.routeConfig.path as CustomActions)) {
      // If the user is signed in, then it can go to custom actions
      // >> If the user is fully registered (has a Clio account), then it can go to custom actions
      if (!this.auth_$.isClioRegistered()) {
        // If the user is not fully registered, then it should go to the registration page
        this.sessionStorage_$.setReferer(route.root['_routerState'].url);
        this.router.navigateByUrl(`/login/clio?sso=0`);
        return;
      } else {
        console.log('The user is fully registered');
      }
    }

    if (this.sessionStorage_$.getSignedIn()) {
      console.log('You are logged in');
      return true;
    } else {
      this.router.navigate(['/login'], {
        queryParams: { referer: state.url },
        replaceUrl: true,
        state: { referer: state.url },
      });
      return false;
    }
  }
}
