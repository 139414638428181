<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close color="accent">X</button>
</mat-dialog-actions>

<h2 class="mat-headline"><span>Sharing Files</span></h2>

<p *ngIf="consultantsMode === 'Active'" class="mat-body">
  Shared files has already been shared with selected consultant.
</p>

<mat-dialog-content>
  <div class="container">
    <mat-selection-list
      *ngIf="consultantsMode === 'Active'"
      #activeFileList
      [multiple]="false"
      class="active-content no-effect"
      id="activeFileList"
    >
      <mat-list-option
        [ngClass]="item.shared ? 'selected-row' : 'no-selected-row'"
        (click)="fileItemClick($event, activeFileList)"
        *ngFor="let item of selectedFiles"
        [value]="item"
        disableRipple="true"
      >
        <div style="padding-top: 20px; padding-bottom: 20px">
          <div class="fakecheckbox"></div>

          <span class="mat-body">{{ item.filename }} </span>

          <button
            (click)="share($event, item)"
            *ngIf="item.shared === 0"
            mat-button
            color="warn"
          >
            Share
          </button>

          <button
            (click)="unShare($event, item)"
            *ngIf="item.shared === 1"
            mat-button
            color="accent"
          >
            UnShare
          </button>
        </div>
      </mat-list-option>
    </mat-selection-list>

    <mat-selection-list
      *ngIf="consultantsMode === 'Available'"
      #availableFilesList
      class="available-content"
      id="availableFileList"
    >
      <mat-list-option
        [ngClass]="item.shared ? 'selected-row' : 'no-selected-row'"
        (click)="fileItemClick($event, availableFilesList)"
        *ngFor="let item of selectedFiles"
        [value]="item"
        disableRipple="true"
      >
        <div style="padding-top: 20px; padding-bottom: 20px">
          <div class="fakecheckbox"></div>

          <span class="mat-body">{{ item.filename }} </span>

          <button
            (click)="share($event, item)"
            *ngIf="item.shared === 0"
            color="warn"
          >
            Share
          </button>

          <button
            (click)="unShare($event, item)"
            *ngIf="item.shared === 1"
            mat-button
            color="accent"
          >
            UnShare
          </button>
        </div>
      </mat-list-option>
    </mat-selection-list>

    <div class="right-side-zone" fxLayout="column" fxFlex="90">
      <mat-radio-group
        #consultantsType
        id="consultantsType"
        name="fieldName"
        ngDefaultControl
        (change)="onConsultantsTypeClick()"
        [(ngModel)]="consultantsMode"
      >
        <mat-radio-button
          class="vertical-radio-button"
          [value]="item"
          *ngFor="let item of consultantsOptions"
        >
          {{ item }}
        </mat-radio-button>
      </mat-radio-group>

      <div *ngIf="consultantsMode == 'Active'">
        <p *ngIf="!selectedFiles" class="mat-body mat-small">
          No files selected
        </p>

        <p
          *ngIf="!otherfilesconsultants.length"
          class="mat-small margin-left-20"
        >
          There are no active consultants for this client profile currently.
        </p>

        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          *ngIf="otherfilesconsultants.length > 0"
        >
          <mat-selection-list [multiple]="false" #allpusers id="allpusers">
            <mat-list-option
              *ngFor="let user of otherfilesconsultants"
              (click)="onActiveConsultantsClicked(allpusers, user)"
              [value]="user.email"
            >
              <mat-checkbox
                [checked]="allpusers._value == user.email"
                color="accent"
                (click)="(false)"
              >
                <span matLine>{{ user.name }}</span>
                <span matLine>{{ user.email }}</span>
              </mat-checkbox>
            </mat-list-option>
          </mat-selection-list>

          <div
            *ngIf="
              otherfilesconsultants.length === 0 && selectedFiles.length === 1
            "
          >
            <h3 id="profileFont">No consultant has access to this file</h3>
          </div>
        </div>
      </div>

      <mat-card *ngIf="consultantsMode === 'Available'">
        <mat-card-content>
          <mat-selection-list #sameOwnerConsultants id="sameOwnerConsultants">
            <mat-list-option
              [value]="consultant.email"
              *ngFor="let consultant of sameownerconsultants"
            >
              <span matLine>{{ consultant.name }}</span>
              <span matLine>{{ consultant.email }}</span>
            </mat-list-option>
          </mat-selection-list>

          <p
            class="mat-body mat-small"
            *ngIf="sameownerconsultants.length === 0"
          >
            There are no consultants available.
          </p>
        </mat-card-content>

        <button
          mat-flat-button
          color="warn"
          mat-button
          [disabled]="
            !sameOwnerConsultants.selectedOptions.selected.length ||
            !availableFilesList.selectedOptions.selected.length
          "
          (click)="handleMultiShare(sameOwnerConsultants)"
        >
          Share
        </button>
      </mat-card>
    </div>
  </div>
</mat-dialog-content>
