<mat-grid-list cols="6" rowHeight="2:1">
  <mat-grid-tile *ngFor="let plan of plans">
    <button [ngClass]="{ selected: plan.active, current: plan.key === data.currentPlan, 'plan-button': true }"
      (click)="handleSelectPlan(plan, true)">
      {{ plan.name }} {{ plan.key === data.currentPlan ? "(current)" : "" }}
    </button>
  </mat-grid-tile>
</mat-grid-list>

<mat-tab-group color="warn" [(selectedIndex)]="tabIndex">
  <mat-tab label="{{ planTitle }} (plan details)">
    <div [innerHtml]="planValue | safeHtml">}</div>
    <button *ngIf="planSelected !== data.currentPlan" [disabled]="isUpgradable(planSelected)"
      (click)="handleUpgrade(planSelected)" mat-flat-button color="warn"> UPGRADE </button>
  </mat-tab>
  <mat-tab label="Compare plans">
    <table class="tg" [cellPadding]="3" [cellSpacing]="0" [border]="0" style="font-size: small">
      <thead>
        <tr>
          <th class="tg-0pky" style="text-align: left"> The ultimate in file sharing for consultations and
            collaborations </th>
          <th class="tg-c3ow">Silver</th>
          <th class="tg-c3ow">Gold</th>
          <th class="tg-c3ow">Platinum</th>
          <th class="tg-c3ow">Enterprise Platinum & Enterprise Platinum (+)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="tg-0pky"></td>
          <td class="tg-c3ow">$35/mo</td>
          <td class="tg-c3ow">$50/mo</td>
          <td class="tg-c3ow">$99/mo</td>
          <td class="tg-c3ow">$90 / 85 <sup>/mo</sup></td>
        </tr>
        <tr>
          <td class="tg-0pky">Each subscriber can simultaneously share same documents with one (1) administrator and one
            (1) associate of choice.</td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
        </tr>
        <tr>
          <td class="tg-0pky">Owner (subscriber) can add additional users.</td>
          <td class="tg-c3ow"></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
        </tr>
        <tr>
          <td class="tg-0pky">Unlimited number of consultants/experts.</td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="17" height="17" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="17" height="17" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="17" height="17" /></td>
        </tr>
        <tr>
          <td class="tg-0pky">
            DICOM image disc upload and Cloud storage (medical imaging studies)
          </td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="17" height="17" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="17" height="17" /></td>
        </tr>
        <tr>
          <td class="tg-0pky">
            Upload from Practice Management systems &amp; Desktop
          </td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="17" height="17" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
        </tr>
        <tr>
          <td class="tg-0pky">
            Document, JPEG, photo, illustration, diagram, video upload and cloud
            storage
          </td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
        </tr>
        <tr>
          <td class="tg-0pky">HIPAA Compliant Storage</td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
        </tr>
        <tr>
          <td class="tg-0pky">
            Built-in Zoom video conference feature with unlimited Video
            conference time and 300 participants/session
          </td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
        </tr>
        <tr>
          <td class="tg-0pky">
            Built-in DICOM viewer for medical imaging studies
          </td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
        </tr>
        <tr>
          <td class="tg-0pky">Video conference recording and storage</td>
          <td class="tg-c3ow"></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
        </tr>
        <tr>
          <td class="tg-0pky"> 3TB /month storage comes with plan**<br />Unlimited storage as needed </td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="18" height="18" /></td>
          <td class="tg-c3ow"></td>
          <td class="tg-c3ow"></td>
          <td class="tg-c3ow"></td>
        </tr>
        <tr>
          <td class="tg-0pky"> 5TB /month storage comes with plan**<br />Unlimited storage as needed </td>
          <td class="tg-c3ow"></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="17" height="17" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="17" height="17" /></td>
          <td class="tg-c3ow"><img src={{checkImage}} alt="Image" width="17" height="17" /></td>
        </tr>
        <tr>
          <td class="tg-0pky">Remote Client upload – discs and documents (<span style="color:#00b5ff">*</span>coming
            soon)</td>
          <td class="tg-c3ow"></td>
          <td class="tg-c3ow"></td>
          <td class="tg-c3ow"><img src={{blueAsterisk}} width="13" height="13" /></td>
          <td class="tg-c3ow"><img src={{blueAsterisk}} width="13" height="13" /></td>
        </tr>
        <tr>
          <td class="tg-0pky"> Video conference transcription (<span style="color:#00b5ff">*</span>coming soon)<br />–
            <span style="font-style: italic">no extra charge</span>
          </td>
          <td class="tg-c3ow"></td>
          <td class="tg-c3ow"></td>
          <td class="tg-c3ow"><img src={{blueAsterisk}} width="13" height="13" /></td>
          <td class="tg-c3ow"><img src={{blueAsterisk}} width="13" height="13" /></td>
        </tr>
        <tr>
          <td class="tg-0pky"> Voice recognition (<span style="color:#00b5ff">*</span>coming soon)<br />– <i>no extra
              charge</i></td>
          <td class="tg-c3ow"></td>
          <td class="tg-c3ow"></td>
          <td class="tg-c3ow"><img src={{blueAsterisk}} alt="Image" width="13" height="13" /></td>
          <td class="tg-c3ow"><img src={{blueAsterisk}} alt="Image" width="13" height="13" /></td>
        </tr>
      </tbody>
    </table>
  </mat-tab>
</mat-tab-group>
