import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { FirebaseUtilitiesService } from '../../services/firebase-utilities.service';
import { FilesService } from '../../services/files.service';

// TODO: Implement a Logging Service.

@Component({
  selector: 'app-consultant-by-client',
  templateUrl: './consultant-by-client.component.html',
  styleUrls: ['./consultant-by-client.component.css']
})
export class ConsultantByClientComponent implements AfterViewInit {
  filesTableDataSource: any;
  selection = new SelectionModel<any>(true, []);
  files: any;
  displayedColumns: string[] = [
    'fileName',
    'fileDesc',
    'fdate',
    'isShared'
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fu$: FirebaseUtilitiesService
  ) {
    const newClientFiles = [];
    data.clientfiles.forEach(file => {
      if (file.sharedUsers && file.sharedUsers.length > 0) {
        const fileSharedWithArr = file.sharedUsers.map(item => item.email.toLowerCase());
        file.isShared = fileSharedWithArr.indexOf(data.currentUser.email.toLowerCase()) > -1 ? 1 : 0;
        newClientFiles.push(file);
      }
    });
    this.filesTableDataSource = new MatTableDataSource<any>(newClientFiles);
    this.filesTableDataSource.paginator = this.paginator;
    this.filesTableDataSource.sort = this.sort;
    if (this.filesTableDataSource.paginator) {
      this.filesTableDataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    const newClientFiles = [];
    this.data.clientfiles.forEach(file => {
      if (file.sharedUsers && file.sharedUsers.length > 0) {
        newClientFiles.push(file);
      }
    });
    this.filesTableDataSource = new MatTableDataSource<any>(newClientFiles);
    this.filesTableDataSource.paginator = this.paginator;
    this.filesTableDataSource.sort = this.sort;
    if (this.filesTableDataSource.paginator) {
      this.filesTableDataSource.paginator.firstPage();
    }
  }

  async shareWithMe(ev: Event, fileToShare) {
    await this.fu$.shareFile(fileToShare, this.data.currentUser.email, this.data.casename);
    this.updateShares(fileToShare);
  }

  async unShareWithMe(ev: Event, fileToShare) {
    await this.fu$.unShareFile(fileToShare, this.data.currentUser.email, this.data.casename);
    this.updateShares(fileToShare);
  }

  updateShares(file) {
    this.data.clientfiles.forEach(item => {
      if (item.fileId === file.fileId) {
        item.isShared = item.isShared === 1 ? 0 : 1;
      }
    });
    this.ngAfterViewInit();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filesTableDataSource.filter = filterValue.trim().toLowerCase();

    if (this.filesTableDataSource.paginator) {
      this.filesTableDataSource.paginator.firstPage();
    }
  }

  handleCheckboxClick(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    const event = document.createEvent('HTMLEvents');
    event.initEvent('click', true, false);
    ev.currentTarget.parentNode.parentNode.dispatchEvent(event);
  }

  handleFileClick(event, element) {
    console.log('HandleFileClick');
  }
}
