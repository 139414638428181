import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import firebase from 'firebase/app';
import { Observer, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { OptionChooserComponent } from './components/ui/optionChooser/optionChooser.component';
import { AuthService } from './services/auth.service';
import { FirebaseUtilitiesService } from './services/firebase-utilities.service';
import { UIMessagingService } from './services/uimessaging.service';
import { UtilsService } from './services/utils.service';
import { SmsService } from './sms.service';
import { TwoFactorAuthRequestDialogComponent } from './two-factor-auth-request/two-factor-auth-request.dialog';
import { TwoFactorAuthSetupDialogComponent } from './two-factor-auth-setup-dialog-component/two-factor-auth-setup-dialog.component';
import { TwoFactorSignInModalComponent } from './two-factor-sign-in-modal/two-factor-sign-in-modal.component';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorAuthenticationService {
  public email: string;
  dialogConfig: MatDialogConfig;
  dialofRef: any;
  methodUserChoice = new Subject();
  methodUserChoiceObserver: Observer<string> = {
    next: val => this.sendCode(val),
    error: error => console.error(error),
    complete: () => console.log('Completed'),
  };

  constructor(
    private dialog: MatDialog,
    private firebaseUtilities_$: FirebaseUtilitiesService,
    private utils_$: UtilsService,
    private auth_$: AuthService,
    private sms_$: SmsService,
    private http: HttpClient,
    private uiMessaging_$: UIMessagingService,
  ) {}

  async sendCode(method: string) {
    console.log('sendCode');
    await this.send2faCODE(method, this.email);
    this.promptToVerifyCode(method, this.email);
  }

  getUserChoice() {
    this.methodUserChoice.subscribe(this.methodUserChoiceObserver);

    const dialogConfig: MatDialogConfig = {
      width: '300px',
      disableClose: true,
      data: {
        title: 'Two-Factor Authentication',
        message: 'Please choose a method to get your <b>verification code</b>:<br/><br/>',
        options: [
          { name: 'Email', value: 'email' },
          { name: 'SMS', value: 'sms' },
        ],
        mode: 'button',
      },
    };

    const dialog = this.dialog.open(OptionChooserComponent, dialogConfig);
    dialog.afterClosed().subscribe(v => {
      this.setUserChoice(v || null);
    });
  }

  setUserChoice(value: string) {
    if (!value) {
      console.error(`value:${value} is null or undefined`);
      return;
    } else {
      this.methodUserChoice.next(value);
      this.methodUserChoice.complete();
      this.methodUserChoice.unsubscribe();
    }
  }

  async handleTwoFactorSignIn(email) {
    this.email = email;
    const twoFactorData = await this.getTwoFactorAuthData(email);
    const { twoFactorSet, twoFactorEnabled, twoFactorMethod } = twoFactorData;
    if (twoFactorSet) {
      try {
        if (twoFactorEnabled) {
          this.getUserChoice();
        } else {
          console.log('The user has 2FA disabled');
        }
      } catch (error) {
        this.auth_$.errorAuth.next(error.message);
      }
    } else {
      if (!this.auth_$.isClioRegistered()) {
        // NOTE: Prompt the user: Do you want to set 2FA now?
        this.promptForCode2FASetup(email);
      }
    }
  }

  promptToVerifyCode(twoFactorMethod: any, email: string) {
    console.log('A: email: ', email);
    console.log('A: twoFactorMethod: ', twoFactorMethod);
    this.dialogConfig = {
      width: '450px',
      disableClose: true,
      data: {
        email: email,
        method: twoFactorMethod,
        title: `Two-factor Authentication`,
        message: `Please enter the code you received.`,
      },
    };
    this.dialog
      .open(TwoFactorSignInModalComponent, this.dialogConfig)
      .afterClosed()
      .subscribe(res => {
        // Do something with res
        console.log(res);
        switch (res) {
          case 'cancel':
            this.auth_$.logout();
            window.location.reload();
            break;
          default:
            break;
        }
      });
  }

  promptForCode2FASetup(email) {
    this.dialog
      .open(TwoFactorAuthRequestDialogComponent, { disableClose: true, width: '400px' })
      .afterClosed()
      .subscribe(res => {
        if (res.answer === 0) {
          // this.handleLogin();
        }
        this.handleTwoFactorAuthRequestAnswer(res, email);
      });
  }

  handleTwoFactorAuthRequestAnswer(res: any, email) {
    if (res) {
      res.answer ? this.twoFactorSetup(email) : this.noTwoFactorAuthSetup(email);
    }
  }

  noTwoFactorAuthSetup(email: string) {
    this.firebaseUtilities_$.setNoFactorAuthSetup(email, 'no-method', false, true);
  }

  twoFactorSetup(email) {
    this.dialogConfig = { disableClose: true, width: '450px' };
    this.dialogConfig.data = {
      email: email,
      title: 'Two-factor Authentication Setup',
      message: 'We need to get and validate your phone number',
    };
    const dialogRef = this.dialog.open(TwoFactorAuthSetupDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: res => {
        console.log('TwoFactorAuthSetupDialogComponent RES', res);
        if (res.answer && res.answer === 1) {
          dialogRef.close('1');
        } else if (res === 'cancel') {
          console.log('The user has cancelled the 2fa setup.');
        }
      },
    });
  }

  async send2faCODE(twoFactorMethod: any, email: string) {
    console.log('A: twoFactorMethod: ', twoFactorMethod);
    console.log('A: email: ', email);

    switch (twoFactorMethod) {
      case 'email':
        await this.confirmEmail(email);
        break;
      case 'sms':
        await this.sendCodeBySMS(email);
        break;
      default:
        break;
    }
  }

  /**
   * Using the @param email the informetion of Two-factor authentication is retreived.
   */
  async getTwoFactorAuthData(email: string) {
    let twoFactorData;
    try {
      twoFactorData = await this.firebaseUtilities_$.getTwoFactorDataByEmail(email);
    } catch (err) {
      console.error('Error', err);
      twoFactorData = err;
    }
    return twoFactorData;
  }

  async sendCodeBySMS(email: string) {
    const sp = await firebase.firestore().collection('users').where('email', '==', email).limit(1).get();
    const phoneNumber = sp.docs[0].data()['phoneNumber'];
    const validPhoneNumber = await this.firebaseUtilities_$.validatePhoneNumberByUserID(this.auth_$.uid, phoneNumber);
    let message;

    if (!validPhoneNumber) {
      message = `This phone number is already taken by other ` + `user or it's not a valid phone number`;
      const action = `VALIDATE PHONE NUMBER`;

      this.uiMessaging_$.toastMessage(message, action);
      return false;
    }

    const code = this.utils_$.generateValidationCode();
    this.firebaseUtilities_$.setUserCode(code, 'phone', email);

    // SEND SMS.
    const msg = `NuagedX has sent you a CODE: ${code}`;
    this.sms_$.sendSMS(msg, phoneNumber).subscribe({
      next: res => {
        if (res['data']['status'] === 200) {
          console.log('RES:', res);
          // this.promptForCode();
        }
      },
      complete: () => {
        console.log('Completed SMS sending');
        // this.promptForCode();
      },
      error: err => {
        console.error(err);
      },
    });
  }

  confirmEmail(email, type?: number) {
    console.log('A: email: ', email);
    const code = this.utils_$.generateValidationCode();
    console.log('A: code: ', code);
    this.firebaseUtilities_$.setUserCode(code, 'email', email);
    const url = `${environment.constants.cloudfunctionsURL}email-simpleMail`;
    const dest = email;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'secret-key',
      }),
    };

    const body =
      `<h2>Please confirm</h2>` +
      `<p>Use the following code to confirm your Two-factor Authentication (2FA):</p>` +
      `<strong>${code}</strong>` +
      `<p>There is a prompt input where you have to paste/type this code.</p>`;
    const subject = `NuagedX has sent you a verification code`;

    return this.http.post(url, { dest, body, subject }, httpOptions).subscribe({
      next: res => {
        if (res['data']['status'] === 200) {
          console.log('RES:', res);
        }
      },
      complete: () => {
        console.log('Completed');
      },
      error: err => {
        console.error(err);
      },
    });
  }
}
