<app-window-title
  [closable]="true"
  [subtitle]="'Filename: ' + fileName"
  [title]="title"></app-window-title>
<mat-dialog-content>
  <p
    [innerHTML]="message"
    class="mat-body"></p>
  <mat-form-field
    class="display-block"
    appearance="fill">
    <mat-label>Note name</mat-label>
    <input
      [(ngModel)]="this.name"
      matInput
      placeholder="Ex. Note name"
      (ngModelChange)="this.name = $event" />
  </mat-form-field>
  <mat-form-field
    class="display-block"
    appearance="fill">
    <mat-label>Note content</mat-label>
    <textarea
      (ngModelChange)="this.description = $event"
      matInput
      [(ngModel)]="this.description"
      rows="8"
      cols="40"
      placeholder="Ex. This is my note content..."></textarea>
  </mat-form-field>

  <br />
  <mat-form-field [ngClass]="'hidden-component'">
    <app-input-file
      label=""
      [ngClass]="'file-browser'"
      [multiple]="true"
      [directory]="false"
      [allowDirs]="false"
      placeholder=""
      (change)="handleFileSelection($event)"
      accept="{{ uploadFileType }}"
      [(ngModel)]="basicFileModel">
    </app-input-file>
    <br />
  </mat-form-field>

  <button
    style="margin-left: 10px"
    mat-flat-button
    color="warn"
    [ngClass]="'browse-button'"
    (click)="browseFiles()">
    Browse for files <mat-icon matSuffix>note</mat-icon>
  </button>

  <ul
    *ngIf="fileEntries"
    [ngClass]="'mat-body'">
    <li style="list-style-type: none">Selected files:</li>
    <li
      style="list-style-type: circle"
      *ngFor="let item of fileEntries">
      {{ item.name }}
    </li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    type="button"
    mat-flat-button
    color="warn"
    (click)="confirm()">
    SAVE NOTE
  </button>
</mat-dialog-actions>
