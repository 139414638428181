import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-client-list-item',
  template: `
    <div
      fxLayout="row"
      fxLayoutAlign=" center">
      <mat-icon
        fxFlex="5"
        fxFlex="none"
        >account_circle</mat-icon
      >

      <div
        fxLayout="column"
        fxFlex="90"
        fxFlexOffset="2">
        <span
          [ngStyle]="{ margin: '0' }"
          class="mat-subheading-1">
          {{ client.FirstName && client.LastName ? client.FirstName + ' ' + client.LastName : client.name }}
        </span>
        <span
          class="mat-body"
          fxFlex>
          Matter Name - {{ client.caseName }} | Legal Case Id - {{ client.LegalCaseId }} | DOB -
          {{ client.DateOfBirth }}
        </span>
      </div>
      <button
        fxFlex="5"
        *ngIf="!permissions.dialogdata && permissions.ableToEditClient"
        (click)="handleEditClient($event, client)"
        mat-icon-button
        class="edit-client-button"
        aria-label="Click here to edit this client."
        matTooltip="Click here to edit this client.">
        <mat-icon>create</mat-icon>
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .edit-client-button {
        width: 30px;
        height: 30px;
        line-height: initial;
      }
    `,
  ],
})
export class ClientListItemComponent {
  @Input() client: any;
  @Input() permissions: any;
  @Output() editClient = new EventEmitter<any>();

  constructor() {}

  public handleEditClient(event, item) {
    event.preventDefault();
    event.stopPropagation();
    this.editClient.emit(item);
  }
}
